import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReactApexChart from 'react-apexcharts';
import DatePicker from "react-datepicker"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { TOGGLE_LEFT_MENU } from 'store/layout/actions'
import { GET, httpOk } from 'helpers/api'
import { formatDate, formatMoney, returnSign } from 'helpers/helpers';

function Sales() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const date = new Date()

	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [loader, setLoader] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		ids: []
	})
	const [salesChart, setSalesChart] = useState({
		series: [
			{
				name: '',
				data: []
			}
		],
		options: {
			chart: {
				height: 350,
				type: 'bar'
			},
			plotOptions: {
				bar: {
					columnWidth: '60%'
				}
			},
			colors: ['#00E396'],
			dataLabels: {
				enabled: false
			},
			legend: {
				show: false,
			}
		},
	})
	const [chequesChart, setChequesChart] = useState({
		series: [
			{
				name: '',
				data: []
			}
		],
		options: {
			chart: {
				height: 350,
				type: 'bar'
			},
			plotOptions: {
				bar: {
					columnWidth: '60%'
				}
			},
			colors: ['#00E396'],
			dataLabels: {
				enabled: false
			},
			legend: {
				show: false,
			}
		},
	})

	async function searchWithFilter(filterDataCopy) {
		setLoader(true)
		//var filterDataCopy = { ...filterData }
		var filterUrl = "/chain/report/sales"
		var urlParams = ""

		if (filterDataCopy.ids)
			urlParams += returnSign(urlParams) + 'ids=' + filterDataCopy.ids
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			var salesChartCopy = { ...salesChart }
			var chequesChartCopy = { ...chequesChart }
			//console.log(salesChartCopy.series[0])
			salesChartCopy.series[0] = response.data['sales']['series']
			chequesChartCopy.series[0] = response.data['cheques']['series']
			setSalesChart(salesChartCopy)
			setChequesChart(chequesChartCopy)
			setData(response.data)
			setLoader(false)
		}
	}

	function handleCheckboxChangeAll(e) {
		var filterDataCopy = { ...filterData }

		filterDataCopy.ids = []
		if (e.target.checked) {
			for (let i = 0; i < poses.length; i++) {
				filterDataCopy.ids.push(poses[i]['id'])
			}
		}

		setFilterData(filterDataCopy)
	}

	function handleCheckboxChange(e, id) {
		var filterDataCopy = { ...filterData }

		if (e.target.checked) {
			filterDataCopy.ids.push(id)
		} else {
			filterDataCopy.ids = filterDataCopy.ids.filter(item => item !== id)
		}

		setFilterData(filterDataCopy)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')

		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		dispatch(TOGGLE_LEFT_MENU(false))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex gap-3">
				<div style={{ 'minWidth': '200px' }}>
					<div className="card mb-3" style={{ 'height': 'calc(100vh - 300px)', 'overflow': 'auto' }}>
						<div className="card-body">
							<h5 className="mb-3">{t('poses')}</h5>
							<div className="flex-column">
								<div className="form-check">
									<input id="all"
										className="form-check-input"
										type="checkbox"
										onChange={(e) => handleCheckboxChangeAll(e)}
									/>
									<label className="form-check-label" htmlFor="all">
										{t('all')}
									</label>
								</div>
								{poses?.map((item, index) => (
									<div className="form-check" key={index}>
										<input id={`pos${index}`} className="form-check-input" type="checkbox"
											checked={filterData?.ids?.includes(item.id)}
											onChange={(e) => handleCheckboxChange(e, item.id)}
										/>
										<label className="form-check-label" htmlFor={`pos${index}`}>
											{item.short_name}
										</label>
									</div>
								))}
							</div>
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<div>
								<div className="form-group">
									<label>{t('period')}</label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={filterData.start_date ? new Date(filterData.start_date) : ''}
											onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
								<div className="form-group">
									<label>{t('period')}</label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={filterData.end_date ? new Date(filterData.end_date) : ''}
											onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
								<button className="btn btn-primary w-100"
									onClick={() => searchWithFilter(filterData)}>
									{t('apply')}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="w-100">
					<div className="card mb-3">
						<div className="card-body">
							<h5 className="mb-2">{t('sales')}</h5>
							<div className="table-resposive">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th>#</th>
											<th>{t('pos')}</th>
											<th className="text-end">{t('sales')}</th>
											<th className="text-end">{t('share')}</th>
											<th className="text-end">{t('income')}</th>
											<th className="text-end">{t('share')}</th>
											<th className="text-end">{t('cheques')}</th>
											<th className="text-end">{t('share')}</th>
											<th className="text-end">{t('products')}</th>
											<th className="text-end">{t('share')}</th>
											<th className="text-end">{t('guests')}</th>
											<th className="text-end">{t('share')}</th>
										</tr>
									</thead>
									<tbody>
										{data?.cheques_table?.map((item, index) => (
											<tr key={index}>
												<td>{index + 1}</td>
												<td>{item.pos_name}</td>
												<td className="text-end">{formatMoney(item.total_sales)}</td>
												<td className="text-end" style={{ height: '1px' }}>
													<div className="progress-container">
														<div className="progress-bar bg-success" style={{ width: `${item.percentage_sales}%`, opacity: '0.3' }}></div>
														<b style={{ position: 'absolute', right: '5px', top: 0 }}>
															{formatMoney(item.percentage_sales, 2)}%
														</b>
													</div>
												</td>
												<td className="text-end">{formatMoney(item.total_income)}</td>
												<td className="text-end" style={{ height: '1px' }}>
													<div className="progress-container">
														<div className="progress-bar bg-success" style={{ width: `${item.percentage_sales}%`, opacity: '0.3' }}></div>
														<b style={{ position: 'absolute', right: '5px', top: 0 }}>
															{formatMoney(item.percentage_income, 2)}%
														</b>
													</div>
												</td>
												<td className="text-end">{formatMoney(item.total_cheques)}</td>
												<td className="text-end" style={{ height: '1px' }}>
													<div className="progress-container">
														<div className="progress-bar bg-success" style={{ width: `${item.percentage_sales}%`, opacity: '0.3' }}></div>
														<b style={{ position: 'absolute', right: '5px', top: 0 }}>
															{formatMoney(item.percentage_cheques, 2)}%
														</b>
													</div>
												</td>
												<td className="text-end">{formatMoney(item.total_products)}</td>
												<td className="text-end" style={{ height: '1px' }}>
													<div className="progress-container">
														<div className="progress-bar bg-success" style={{ width: `${item.percentage_sales}%`, opacity: '0.3' }}></div>
														<b style={{ position: 'absolute', right: '5px', top: 0 }}>
															{formatMoney(item.percentage_products, 2)}%
														</b>
													</div>
												</td>
												<td className="text-end">{formatMoney(item.total_people_number)}</td>
												<td className="text-end" style={{ height: '1px' }}>
													<div className="progress-container">
														<div className="progress-bar bg-success" style={{ width: `${item.percentage_sales}%`, opacity: '0.3' }}></div>
														<b style={{ position: 'absolute', right: '5px', top: 0 }}>
															{formatMoney(item.percentage_sales, 2)}%
														</b>
													</div>
												</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td></td>
											<td><b>{t('total')}</b></td>
											<td className="text-end"><b>{formatMoney(data.overall_sales)}</b></td>
											<td className="text-end"><b>100%</b></td>
											<td className="text-end"><b>{formatMoney(data.overall_income)}</b></td>
											<td className="text-end"><b>100%</b></td>
											<td className="text-end"><b>{formatMoney(data.overall_cheques)}</b></td>
											<td className="text-end"><b>100%</b></td>
											<td className="text-end"><b>{formatMoney(data.overall_products)}</b></td>
											<td className="text-end"><b>100%</b></td>
											<td className="text-end"><b>{formatMoney(data.overall_people_number)}</b></td>
											<td className="text-end"><b>100%</b></td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</div>

					<div className="d-flex gap-3 mb-3">
						<div className="card w-100">
							<div className="card-body">
								<h5>{t('sales')}</h5>
								{loader ?
									<Skeleton height={300} />
									:
									<ReactApexChart
										type="bar"
										height={300}
										series={salesChart.series}
										options={salesChart.options}
									/>
								}
							</div>
						</div>

						<div className="card w-100">
							<div className="card-body">
								<h5>{t('cheques')}</h5>
								{loader ?
									<Skeleton height={300} />
									:
									<ReactApexChart
										type="bar"
										height={300}
										series={chequesChart.series}
										options={chequesChart.options}
									/>
								}
							</div>
						</div>
					</div>

					<div className="d-flex gap-3 mb-3">
						<div className="card w-100">
							<div className="card-body">
								<h5>{t('products')}</h5>
								{/* <ReactApexChart
									type="bar"
									height={250}
									series={salesChartData3.series}
									options={salesChartData3.options}
								/> */}
							</div>
						</div>

						<div className="card w-100">
							<div className="card-body">
								<h5>{t('menu')}</h5>
								{/* <ReactApexChart
									type="bar"
									height={250}
									series={salesChartData3.series}
									options={salesChartData3.options}
								/> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Sales
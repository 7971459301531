import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GET, httpOk } from "helpers/api"
import { formatDate, formatMoney } from 'helpers/helpers'

function Preview() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const [data, setData] = useState({})

	async function getData() {
		const response = await GET(`/wms-transfer-branch/${id}`, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('transfer')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('preview')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4 className="fw-600">{t('document_from')} {formatDate(data.created_at)}</h4>
					<div className="flex-column preview-info">
						<div className="d-flex gap-2">
							<div className="fw-600">{t('pos')}:</div>
							<div>{data.pos_name}</div>
						</div>
						<div className="d-flex gap-2">
							<div className="fw-600">{t('external_pos')}:</div>
							<div>{data.to_pos_name}</div>
						</div>
						<div className="d-flex gap-2">
							<div className="fw-600">{t('note')}:</div>
							<div>{data.note}</div>
						</div>
						<div className="d-flex gap-2">
							<div className="fw-600">{t('document')}:</div>
							<div>{data.document_number}</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('name2')}</th>
									<th style={{ 'width': '10%' }}>{t('type')}</th>
									<th style={{ 'width': '20%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
									<th style={{ 'width': '10%' }} className="text-start">{t('unit')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('cost_price')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.items?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td>{item.product_type_name}</td>
										<td></td>
										<td className="text-end">{item.quantity}</td>
										<td className="text-start">{item.unit_name}</td>
										<td className="text-end">{formatMoney(item.cost_price)}</td>
										<td className="text-end">{formatMoney(item.total_price)}</td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td><b>{t('total')}</b></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tfoot>
						</table>
					</div>

					<div className="d-flex justify-content-end mt-5">
						<button type="button" className="btn btn-warning btn-rounded btn-wide"
							onClick={() => navigate(-1)}>
							{t('cancel')}
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default Preview
import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
//import XLSX from 'xlsx'

import FilterSearch from 'pages/parts/FilterSearch'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, returnSign, findKeyFromArrayByValue, formatDate } from 'helpers/helpers'

function Calculation() {
	const { t } = useTranslation()
	const reduxAccount = useSelector(state => state.account)

	const [poses, setPoses] = useState([])
	const [suppliers, setSuppliers] = useState([])
	const [categories, setCategories] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: reduxAccount.pos_id,
		supplier_id: null,
		category_id: null,
		date_balance: null,
		search: '',
	})
	const [data, setData] = useState([])

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = "/report-calculation"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.supplier_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.supplier_id
		if (filterData.category_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.category_id
		if (filterData.date_balance)
			urlParams += returnSign(urlParams) + 'date_balance=' + filterData.date_balance
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (`/report-calculation${urlParams}` !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
		} else {
			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setData(response.data)
			}
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			supplier_id: null,
			category_id: null,
			date_balance: null,
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await GET("/report-calculation", {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.supplier_id === null &&
			filterData.category_id === null &&
			filterData.date_balance === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getSuppliers() {
		const response = await GET('/helper/suppliers')
		if (httpOk(response)) setSuppliers(response.data)
	}

	async function getCategories() {
		const response = await GET('/helper/categories')
		if (httpOk(response)) setCategories(response.data)
	}

	useEffect(() => {
		getPoses()
		getSuppliers()
		getCategories()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('calculation')} [{data.length}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex flex-wrap gap-2 mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('pos')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.pos_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('supplier')}</label>
											<Select
												options={suppliers}
												value={suppliers.find(option => option.id === filterData.supplier_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'supplier_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('category')}</label>
											<Select
												options={categories}
												value={categories.find(option => option.id === filterData.category_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'category_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('date')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.date_balance ? new Date(filterData.date_balance) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'date_balance': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						{/* <div>
								<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
							</div> */}
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.supplier_id &&
									<span className="me-2">
										<b>{t('supplier')}: </b>
										{findKeyFromArrayByValue(suppliers, 'id', filterData.supplier_id, 'name')}
									</span>
								}
								{filterData.category_id &&
									<span className="me-2">
										<b>{t('category')}: </b>
										{findKeyFromArrayByValue(categories, 'id', filterData.category_id, 'name')}
									</span>
								}
								{filterData.date_balance &&
									<span className="me-2">
										<b>{t('date')}: </b>
										<span>{formatDate(filterData.date_balance)}</span>
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('name2')}</th>
									<th style={{ 'width': '20%' }}>{t('menu')}</th>
									<th style={{ 'width': '40%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td>{item.menu_name}</td>
										<td></td>
										<td className="text-end">{formatMoney(item.balance)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default Calculation
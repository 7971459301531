export let smiles = [
	'🍏', '🍎', '🍐', '🍊', '🍋', '🍌', '🍉', '🍇', '🍓', '🍒', '🥝',

	'🍆', '🥬', '🥦', '🥒', '🌶', '🍅', '🥕', '🫒', '🧄', '🧅', '🥔',

	'🥚', '🥨', '🥯', '🥐', '🥞', '🧇',

	'🍗', '🍖', '🥩', '🍔', '🌭', '🍟', '🍕', '🥪', '🥙', '🧆', '🌮',
	'🌯', '🫔', '🥗', '🫕', '🥘', '🍝', '🍜', '🍲', '🍛', '🍣', '🍱',
	'🍤', '🍙', '🍚', '🍘', '🍥',

	'🥮', '🍢', '🍡', '🍧', '🍨', '🍦', '🥧', '🧁', '🍰', '🎂', '🍮',
	'🍭', '🍬', '🍫', '🍿', '🍩', '🍪', '🌰',

	'🥫', '🫖', '☕️', '🍵', '🧃', '🥤', '🧋', '🍶', '🍺', '🍻', '🥂',
	'🍷', '🥃', '🍸', '🍹', '🧉', '🍾', '🧊',
]


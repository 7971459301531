import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function DeleteModalModified({ deleteModalParams, setDeleteModalParams, deleteFromDB }) {
	const { t } = useTranslation()

	return (
		<>
			<Modal show={deleteModalParams.bool} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setDeleteModalParams({ ...deleteModalParams, 'bool': false })}>
				<Modal.Header closeButton>
					<Modal.Title>{deleteModalParams.title ? deleteModalParams.title : t('delete')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{deleteModalParams.text ? deleteModalParams.text : t('are_you_sure_you_want_delete')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100 gap-2">
						<button className="btn btn-warning w-100"
							onClick={() => setDeleteModalParams({ ...deleteModalParams, 'bool': false })}>
							{t('cancel')}
						</button>
						<button className="btn btn-danger w-100" onClick={() => deleteFromDB()}>{t('delete')}</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default DeleteModalModified

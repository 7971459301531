import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx';

import DeleteModal from 'pages/parts/DeleteModal'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { DELETE, GET, POST, httpOk } from 'helpers/api'
import { formatDate, returnSign, findKeyFromArrayByValue, formatMoney, arrayJoin } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const reduxAccount = useSelector(state => state.account)

	const statuses = [
		{ 'id': 1, 'name': t('success') },
		{ 'id': -2, 'name': t('expected') },
		{ 'id': -1, 'name': t('deleted') },
	]
	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [iposes, setIPoses] = useState([])
	const [suppliers, setSuppliers] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
		status: null,
		ids: [],
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/service/wms-transfer-pos',
		response: null
	})
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })
	const [modalTemplate, setModalTemplate] = useState({ 'bool': false, 'id': '', 'name': '' })

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/service/wms-transfer-pos"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.to_pos_id)
			urlParams += returnSign(urlParams) + 'to_pos_id=' + filterDataCopy.to_pos_id
		if (filterDataCopy.supplier_id)
			urlParams += returnSign(urlParams) + 'supplier_id=' + filterDataCopy.supplier_id
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		filterDataCopy.urlParams = urlParams

		if (`/service/wms-transfer-pos${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setFilterData(filterDataCopy)
		setDropdownIsOpen(false)
	}

	async function exportToExcel() {
		var filterDataCopy = { ...filterData }
		var filterUrl = `/service/wms-transfer-pos-excel${filterData.urlParams}`
		var urlParams = ""

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy?.ids?.length)
			urlParams += returnSign(urlParams) + 'ids=' + arrayJoin(filterDataCopy.ids, false)

		filterUrl = filterUrl + urlParams
		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			response = response.data
			var excelHeaders = [[
				t('pos'), t('external_pos'),
				t('supplier'),
				t('date'), t('name2'),
				t('type'), t('quantity'), t('unit'),
				t('price'),
				t('sale_price'), t('total_amount')
			]]

			for (let i = 0; i < response?.data?.length; i++) {
				var item = response.data[i]
				var excelItems = []
				excelItems.push(item['pos_name'])
				excelItems.push(item['to_pos_name'])
				excelItems.push(item['supplier_name'])
				excelItems.push(item['created_at'])
				excelItems.push(item['name'])
				if (item['in_product']) {
					excelItems.push(t('product'))
				}
				if (!item['in_product']) {
					excelItems.push(t('element'))
				}
				excelItems.push(Number(item['quantity']))
				excelItems.push(item['unit_name'])
				excelItems.push(Number(item['price']))
				excelItems.push(Number(item['sale_price']))
				excelItems.push(Number(item['total_sale_price']))
				excelHeaders.push(excelItems)
			}
			excelHeaders.push([t('total'), '', '', '', '', '', '', '', '', '', response?.overall_sale_price])

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('transfer') + ".xlsx");
		}
	}

	function toggleModalTemplate(bool, id = '') {
		setModalTemplate({ ...modalTemplate, 'bool': bool, 'id': id, 'name': '' })
	}

	async function storeTemplate() {
		await POST(
			`/service/wms-transfer-pos-templates`,
			{
				id: modalTemplate.id,
				name: modalTemplate.name
			},
			{ loader: true }
		)

		toggleModalTemplate(false)
	}

	async function deleteItem() {
		var response = await DELETE(`/service/wms-transfer-pos/${deleteModalParams.id}`, {}, { loader: true })
		if (httpOk(response)) {
			setModalDelete(false)
			searchWithFilter()
		}
	}

	function handleCheckboxChangeAll(e) {
		var filterDataCopy = { ...filterData }

		filterDataCopy.ids = []
		if (e.target.checked) {
			for (let i = 0; i < data.data.length; i++) {
				filterDataCopy.ids.push(data.data[i]['id'])
			}
		}

		setFilterData(filterDataCopy)
	}

	function handleCheckboxChange(e, id) {
		var filterDataCopy = { ...filterData }

		if (e.target.checked) {
			filterDataCopy.ids.push(id)
		} else {
			filterDataCopy.ids = filterDataCopy.ids.filter(item => item !== id)
		}

		setFilterData(filterDataCopy)
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: null,
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getSupplirs() {
		const response = await GET('/helper/suppliers', {}, { loader: true })
		if (httpOk(response)) setSuppliers(response.data)
	}

	async function getIntegrationPoses() {
		const response = await GET('/helper/integration-access-poses', {}, { loader: true })
		if (httpOk(response)) setIPoses(response.data)
	}

	useEffect(() => {
		getPoses()
		getSupplirs()
		getIntegrationPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('moving_another_store')} [{data.total}]
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary"
						to="/service/wms-transfer-pos/create">
						{t('create')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('external_pos')}</label>
												<Select
													isClearable
													options={iposes}
													value={iposes.find(option => option.id === filterData.to_pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'to_pos_id': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													isClearable
													options={statuses}
													value={statuses.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('supplier')}</label>
												<Select
													isClearable
													options={suppliers}
													value={suppliers.find(option => option.id === filterData.supplier_id)}
													onChange={(option) => setFilterData({ ...filterData, 'supplier_id': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(false)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button type="button" className="btn btn-outline-primary btn-wide-2"
										onClick={() => exportToExcel()}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
						<div className="vertical-center">
							<h5 className="text-end" style={{ 'lineHeight': '18px' }}>
								<b>{t('sales')}</b>: {formatMoney(data.total_sale_price)} <br />
								<b>{t('income')}</b>: {formatMoney(data.total_income)}
							</h5>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.to_pos_id &&
									<span className="me-2">
										<b>{t('external_pos')}: </b>
										{findKeyFromArrayByValue(iposes, 'id', filterData.to_pos_id, 'name')}
									</span>
								}
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '10px' }}>
										<input type="checkbox"
											onChange={(e) => handleCheckboxChangeAll(e)} />
									</th>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }} className="td-ellipsis">{t('pos')}</th>
									<th style={{ 'width': '20%' }} className="td-ellipsis">{t('external_pos')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('document')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis">{t('note')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('sent_need_send')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('status')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td>
											<input type="checkbox"
												checked={filterData?.ids?.includes(item.id)}
												onChange={(e) => handleCheckboxChange(e, item.id)} />
										</td>
										<td className="text-center">{item.row_number}</td>
										<td className="td-ellipsis">{item.pos_name}</td>
										<td className="td-ellipsis">{item.to_pos_name}</td>
										<td className="text-center">{item.document_number}</td>
										<td className="td-ellipsis" title={item.note}>{item.note}</td>
										<td className="text-center">
											{item.need_send > 0 &&
												<span>{item.sent} / {item.need_send}</span>
											}
										</td>
										<td className="text-center">
											{item.status === 1 &&
												<span className="bg-success bg-border text-white">{t('success')}</span>
											}
											{item.status === -1 &&
												<span className="bg-danger bg-border text-white">{t('deleted')}</span>
											}
											{item.status === -2 &&
												<span className="bg-secondary bg-border text-white">{t('expected')}</span>
											}
										</td>
										<td className="text-nowrap text-center">
											{formatDate(item.created_at, 'dd.MM.yy HH:mm')}
										</td>
										<td className="text-nowrap text-end">
											{formatMoney(item.total_sale_price)}
										</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<Link className="table-dropdown-item"
															to={`/service/wms-transfer-pos/preview/${item.id}`}>
															<i className="uil-eye"></i>
															{t('preview')}
														</Link>
														{/* {reduxAccount.login === item.from_login &&
															<Link className="table-dropdown-item"
																to={`/service/wms-transfer-pos/update/${item.id}`}>
																<i className="uil-edit-alt"></i>
																{t('edit')}
															</Link>
														} */}
														<div className="table-dropdown-item"
															onClick={() => toggleModalTemplate(true, item.id)}>
															<i className="uil-bookmark-full"></i>
															{t('templates')}
														</div>
														{item.status === -2 &&
															<div className="table-dropdown-item text-danger"
																onClick={() => {
																	setDeleteModalParams({ id: item.id, index: index });
																	setModalDelete(true)
																}}>
																<i className="uil-times"></i>
																{t('delete')}
															</div>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL TEMPLATE */}
			<Modal show={modalTemplate.bool} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => toggleModalTemplate(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('save')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group">
						<label>{t('template_name')}</label>
						<div className="position-relative">
							<input type="text" className="form-control" autoFocus
								value={modalTemplate.name}
								onChange={(e) => setModalTemplate({ ...modalTemplate, 'name': e.target.value })} />
						</div>
					</div>
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => toggleModalTemplate(false)}>
							{t('cancel')}
						</button>
						<button type="button" className="btn btn-primary w-100"
							onClick={() => storeTemplate()}>
							{t('save')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL TEMPLATE */}

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index
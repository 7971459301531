import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown, DropdownButton } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, PUT, httpOk } from 'helpers/api'
import { findKeyFromArrayByValue, formatMoney, returnSign } from "helpers/helpers"

function PriceСhange() {
	const { t } = useTranslation()
	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState([])
	const [dataDuplicate, setDataDuplicate] = useState({})
	const [selectedItem, setSelectedItem] = useState({})
	const [menu, setMenu] = useState([])
	const [poses, setPoses] = useState([])
	const [fiscalCodes, setFiscalCodes] = useState([])
	const [fiscalPackageCodes, setFiscalPackageCodes] = useState([])
	const [preparationPlaces, setPreparationPlaces] = useState([])
	const [changedItem, setChangedItem] = useState({
		all: false,
		pos_id: reduxAccount.pos_id,
	})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [modalFiscal, setModalFiscal] = useState(false)
	const [modalPreparationPlace, setModalPreparationPlace] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: reduxAccount.pos_id,
		search: '',
	})
	const [pagination, setPagination] = useState({})

	async function update() {
		var copyChangedItem = { ...changedItem }
		copyChangedItem.pos_id = filterData.pos_id
		const response = await PUT('/products-price', copyChangedItem, { loader: true })
		if (httpOk(response)) {
			setPagination({ ...pagination, increment: pagination.increment + 1 })
			await searchWithFilter()
			setShowModal(false)
		}
	}

	async function updateFiscalCodes() {
		const response = await PUT('/products-fiscalization', selectedItem, { loader: true })
		if (httpOk(response)) toggleModalFiscal()
	}

	async function updatePreparationPlace() {
		const response = await PUT('/products-preparation-places', selectedItem, { loader: true })
		if (httpOk(response)) toggleModalPreparationPlace()
	}

	function selectedItemEdit(index) {
		var dataCopy = [...data]
		setDataDuplicate({ ...dataCopy[index] })

		dataCopy[index]['showInput'] = true
		for (let i = 0; i < dataCopy.length; i++) {
			if (!dataCopy[i]['showInput']) {
				dataCopy[i]['visible'] = false
			}
		}
	}

	function cancelSelectedItemEdit(index) {
		var dataCopy = [...data]
		dataCopy[index] = { ...dataDuplicate }
		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['visible'] = true
			dataCopy[i]['showInput'] = false
		}

		setData(dataCopy)
	}

	function handleInputChange(e, index) {
		var dataCopy = [...data]
		dataCopy[index][e.target.name] = e.target.value
		setData(dataCopy)
	}

	function confirmModalShow(index) {
		var dataCopy = [...data]
		if ((Number(dataCopy[index]['sale_price']) >= Number(dataCopy[index]['cost_price']))) {
			setChangedItem({ ...dataCopy[index] })
			setShowModal(true)
		} else {
			toast.dismiss();
			toast.error(t('check_filled_inputs'))
		}
	}

	async function toggleModalFiscal(bool = false) {
		if (bool && !fiscalCodes.length) {
			var response = await GET(`/helper/fiscal-codes`, {}, { loader: true })
			if (httpOk(response)) setFiscalCodes(response.data)
		}

		if (!bool) setSelectedItem({})

		setModalFiscal(bool)
	}

	async function toggleModalPreparationPlace(bool = false) {
		if (!bool) setSelectedItem({})

		setModalPreparationPlace(bool)
	}

	function handleSelectFiscalCode(option) {
		if (!option) {
			setSelectedItem({ ...selectedItem, 'fiscal_code': null, 'fiscal_package_code': null })
			return
		}

		setSelectedItem({ ...selectedItem, 'fiscal_code': option.id, 'fiscal_package_code': null })
		setFiscalPackageCodes(option.units)
	}

	async function handleSelectPos(option) {
		if (!option) {
			setSelectedItem({ ...selectedItem, 'pos_id': null, 'preparation_place_id': null })
			return
		}

		var response = await GET(`/helper/preparation-places?pos_id=${option.id}`, {}, { loader: true })
		if (httpOk(response)) setPreparationPlaces(response.data)

		setSelectedItem({ ...selectedItem, 'pos_id': option.id })
	}

	async function searchWithFilter(filterParams = { tableSort: "" }) {
		var filterDataCopy = { ...filterData }

		var filterUrl = "/products-list"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.menu_id)
			urlParams += returnSign(urlParams) + 'menu_id=' + filterDataCopy.menu_id
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		if (`/products-list${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		if (filterParams.tableSort) {
			if (filterDataCopy.tableSortKey === filterParams.tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = filterParams?.tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + filterParams.tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			response = response.data
			for (let i = 0; i < response.length; i++) {
				response[i]['showInput'] = false
				response[i]['visible'] = true
			}
			setData(response)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			pos_id: filterData.pos_id,
			menu_id: null,
			search: '',
		})
		searchWithFilter()
		setFilterDataExist(false)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.menu_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		var response = await GET('/helper/poses', {}, { loader: true })
		if (httpOk(response)) {
			setPoses(response.data)
			searchWithFilter()
		}
	}

	async function getMenu() {
		const response = await GET('/helper/menu')
		if (httpOk(response)) setMenu(response.data)
	}

	function renderArrows(tableSortDirection, tableSortKey, type) {
		if (tableSortDirection === ',asc' && tableSortKey === type) {
			return (
				<i className="uil uil-angle-up sort-active"></i>
			)
		} else if (tableSortDirection === ',desc' && tableSortKey === type) {
			return (
				<i className="uil uil-angle-down sort-active"></i>
			)
		} else {
			return (
				<i className="uil uil-angle-down"></i>
			)
		}
	}

	useEffect(() => {
		getPoses()
		getMenu()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('price_change')} [{data?.length}]
				</h4>

				<DropdownButton title={t('action')}>
					<Dropdown.Item onClick={() => toggleModalFiscal(true)}>
						{t('fiscalization')}
					</Dropdown.Item>
					<Dropdown.Item onClick={() => toggleModalPreparationPlace(true)}>
						{t('preparation_places')}
					</Dropdown.Item>
				</DropdownButton>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('menu')}</label>
												<Select
													options={menu}
													value={menu.find(option => option.id === filterData.menu_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'menu_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter()}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.menu_id &&
									<span className="me-2">
										<b>{t('menu')}: </b>
										{findKeyFromArrayByValue(menu, 'id', filterData.menu_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '50%' }} className="th-sortable"
										onClick={() => searchWithFilter({ tableSort: 'name' })}>
										{t('name2')}
										<div className="table-sort">
											{renderArrows(filterData.tableSortDirection, filterData.tableSortKey, 'name')}
										</div>
									</th>
									<th style={{ 'width': '15%' }} className="text-end">{t('cost_price')}</th>
									<th style={{ 'width': '15%' }} className="text-end">{t('sale_price')}</th>
									<th style={{ 'width': '15%' }} className="text-end">{t('profit')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('status')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td className="text-end">{formatMoney(item.cost_price)}</td>
										<td className="text-end">
											{!item.showInput ?
												<span>{formatMoney(item.sale_price)}</span>
												:
												<input type="number" step="0.01" min="0" className={"auto-width-input table-input " + ((Number(item.price) > Number(item.sale_price)) && "required-border")}
													name="sale_price" value={item.sale_price} onChange={(e) => handleInputChange(e, index)} />
											}
										</td>
										<td className="text-end">{formatMoney(item.sale_price - item.cost_price)}</td>
										<td className="text-center">
											{(Number(item.cost_price) > Number(item.sale_price)) &&
												<span className="bg-danger bg-border text-white"></span>
											}
											{(Number(item.cost_price) <= Number(item.sale_price)) &&
												<span className="bg-primary bg-border text-white"></span>
											}
										</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														{(!item.showInput && item.visible) &&
															<div className="table-dropdown-item" onClick={() => selectedItemEdit(index)}>
																<i className="uil-edit-alt"></i>
																{t('edit')}
															</div>
														}
														{item.showInput &&
															<>
																<div className="table-dropdown-item text-success"
																	onClick={() => confirmModalShow(index)}>
																	<i className="uil-check"></i>
																	{t('save')}
																</div>
																<div className="table-dropdown-item text-danger"
																	onClick={() => cancelSelectedItemEdit(index)}>
																	<i className="uil-times"></i>
																	{t('delete')}
																</div>
															</>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL CONFIRM */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('confirm')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th></th>
									<th className="text-center">{t('before')}</th>
									<th className="text-center">{t('after')}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="fw-700">{t('sale_price')}</td>
									<td className="text-center">{formatMoney(dataDuplicate.sale_price)}</td>
									<td className="text-center">{formatMoney(changedItem.sale_price)}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setShowModal(false)}>
							{t('cancel')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => update()}>{t('save')}</button>
					</div>
				</Modal.Footer>
			</Modal>
			{/* MODAL CONFIRM */}

			{/* MODAL FISCAL */}
			<Modal show={modalFiscal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModalFiscal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('confirm')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group">
						<label>{t("menu")}</label>
						<Select
							isMulti
							options={menu}
							value={selectedItem.menu}
							onChange={(options) => setSelectedItem({ ...selectedItem, 'menu': options })}
							placeholder=""
							noOptionsMessage={() => t('list_empty')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>
					<div className="form-group w-100">
						<div className="form-group">
							<label>ИКПУ</label>
							<Select
								isClearable
								options={fiscalCodes}
								value={fiscalCodes.find(option => option.id === data.fiscal_code)}
								onChange={(option) => handleSelectFiscalCode(option)}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
					</div>
					<div className="form-group w-100">
						<div className="form-group">
							<label>{t("unit")}</label>
							<Select
								isClearable
								options={fiscalPackageCodes}
								value={fiscalPackageCodes.find(option => option.id === selectedItem.fiscal_package_code)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'fiscal_package_code': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
					</div>
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => toggleModalFiscal()}>
							{t('cancel')}
						</button>
						<button className="btn btn-primary w-100"
							onClick={() => updateFiscalCodes()}>
							{t('save')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL FISCAL */}

			{/* MODAL PREPARATION PLACE */}
			<Modal show={modalPreparationPlace} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModalPreparationPlace(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('confirm')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group w-100">
						<div className="form-group">
							<label>{t("poses")}</label>
							<Select
								options={poses}
								value={poses.find(option => option.id === selectedItem.pos_id)}
								onChange={(option) => handleSelectPos(option)}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
					</div>
					<div className="form-group">
						<label>{t("menu")}</label>
						<Select
							isMulti
							options={menu}
							value={selectedItem.menu}
							onChange={(options) => setSelectedItem({ ...selectedItem, 'menu': options })}
							placeholder=""
							noOptionsMessage={() => t('list_empty')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>
					<div className="form-group w-100">
						<div className="form-group">
							<label>{t("preparation_places")}</label>
							<Select
								isClearable
								options={preparationPlaces}
								value={preparationPlaces.find(option => option.id === selectedItem.preparation_place_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'preparation_place_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
					</div>
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => toggleModalPreparationPlace()}>
							{t('cancel')}
						</button>
						<button className="btn btn-primary w-100"
							onClick={() => updatePreparationPlace()}>
							{t('save')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL PREPARATION PLACE */}
		</>
	)
}

export default PriceСhange
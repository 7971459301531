import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { POST, PUT, GET, httpOk } from 'helpers/api'
import { findKeyFromArrayByValue, regexNumeric, regexPhoneNumber, returnSign } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const statuses = [
		{ 'id': 1, 'name': t('success') },
		{ 'id': -1, 'name': t('deleted') }
	]

	const [showModal, setShowModal] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		status: 1,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/suppliers',
		response: null
	})
	const [modalDelete, setModalDelete] = useState(false)
	const [modalRestore, setModalRestore] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [data, setData] = useState([])

	async function createUpdate(e) {
		e.preventDefault()
		var selectedItemCopy = { ...selectedItem }
		if (selectedItemCopy.phone)
			selectedItemCopy.phone = '998' + regexNumeric(selectedItemCopy.phone)

		var response;
		if (selectedItemCopy.id) {
			response = await PUT('/suppliers', selectedItemCopy, { loader: true })
		} else {
			response = await POST('/suppliers', selectedItemCopy, { loader: true })
		}

		if (httpOk(response)) {
			searchWithFilter()
			toggleModal(false)
		}
	}

	async function deleteSupplier(e) {
		e.preventDefault()

		var sendData = { ...selectedItem }
		sendData.status = -1
		var response = await PUT('/suppliers-status', sendData, { loader: true })

		if (httpOk(response)) {
			searchWithFilter()
			toggleModalDelete(false)
		}
	}

	async function restore(e) {
		e.preventDefault();
		var sendData = { ...selectedItem }

		sendData.status = 1
		var response = await PUT('/suppliers-status', sendData, { loader: true })

		if (httpOk(response)) {
			searchWithFilter()
			toggleModalRestore(false)
		}
	}

	async function searchWithFilter() {
		var filterUrl = "/suppliers"
		var urlParams = ""
		checkFilter()

		if (filterData.status)
			urlParams += returnSign(urlParams) + 'status=' + filterData.status
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/suppliers${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function toggleModal(bool, item) {
		if (bool) {
			var itemCopy = { ...item }
			itemCopy.phone = itemCopy?.phone?.substring(3, 12)
			setSelectedItem(itemCopy)
			setShowModal(true)
		} else {
			setSelectedItem({})
			setShowModal(false)
		}
	}

	function toggleModalDelete(bool = false, item = {}) {
		setSelectedItem(item)
		setModalDelete(bool)
	}

	function toggleModalRestore(bool = false, item = {}) {
		setSelectedItem(item)
		setModalRestore(bool)
	}

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('suppliers')} [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => setShowModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													options={statuses}
													value={statuses.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>

										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(false)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('name')}</th>
									<th style={{ 'minWidth': '130px' }}>{t('phone')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis">{t('internal_supplier')}</th>
									<th style={{ 'width': '10%' }}>{t('deleted_by')}</th>
									<th style={{ 'width': '45%' }}></th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">
											{item.row_number}
										</td>
										<td className="td-ellipsis" title={item.name}>{item.name}</td>
										<td>{regexPhoneNumber(item.phone)}</td>
										<td className="text-center">
											{item.is_internal ?
												<span className="bg-primary bg-border text-white">{t('yes')}</span>
												:
												<span className="bg-danger bg-border text-white">{t('no')}</span>
											}
										</td>
										<td title={item.deleted_by}>{item.deleted_name}</td>
										<th></th>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
														{item.status === 1 &&
															<div className="table-dropdown-item text-danger"
																onClick={() => toggleModalDelete(true, item)}>
																<i className="uil-times"></i>
																{t('delete')}
															</div>
														}
														{item.status === -1 &&
															<div className="table-dropdown-item text-success"
																onClick={() => toggleModalRestore(true, item)}>
																<i className="uil-history-alt"></i>
																{t('restore')}
															</div>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('edit')}</>
							:
							<>{t('create')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('title')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('phone')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone"
									value={selectedItem.phone ?? ''}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'phone': e.target.value })} />
							</div>
						</div>
						<div className="d-flex justify-content-between mb-2">
							<p className="fw-600 vertical-center">{t('internal_supplier')}</p>
							<div className="form-check form-switch form-switch-lg">
								<input type="checkbox" className="form-check-input" name="is_internal"
									checked={selectedItem?.is_internal ?? false}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'is_internal': e.target.checked })} />
							</div>
						</div>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL DELETE */}
			<Modal show={modalDelete} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModalDelete(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('delete')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={deleteSupplier} autoComplete="off">
						<h4>
							{t('are_you_sure_you_want_delete')}
						</h4>
						<h6 className="text-danger">
							{t('delete_text')}.
						</h6>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalDelete(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL DELETE */}

			{/* MODAL restore */}
			<Modal show={modalRestore} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModalRestore(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('restore')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => restore(e)} autoComplete="off">
						<h5>
							{t('are_you_sure_you_want')} <b>{t('restore')}</b>
						</h5>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalRestore(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-success w-100">{t('confirm')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL restore */}
		</>
	)
}

export default Index

import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import XLSX from 'xlsx'

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import DeleteModal from 'pages/parts/DeleteModal'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { httpOk, GET, DELETE, FILE_D } from 'helpers/api'
import { findKeyFromArrayByValue, formatMoney, returnSign } from "helpers/helpers"
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const productTypes = [
		{ 'id': 0, 'name': t('element') },
		{ 'id': 1, 'name': t('product') },
		{ 'id': 2, 'name': t('dish_preparation') },
		{ 'id': 3, 'name': t('modifiers') },
	]

	const statuses = [
		{ "id": 1, "name": t('success') },
		{ "id": -1, "name": t('deleted') },
	]

	const columnFilers = [
		{ "id": 'fiscal', "name": t('fiscalization') },
		//	{ "id": 'preparation_place', "name": t('preparation_places') },
		{ "id": 'menu', "name": t('menu') },
	]

	const [data, setData] = useState([])
	const [menu, setMenu] = useState([])
	const [poses, setPoses] = useState([])
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: reduxAccount.pos_id,
		in_product: 1,
		status: 1,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/products',
		response: null
	})

	async function searchWithFilter(filterParams = {
		exportToExcel: false,
		tableSort: "",
	}) {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/products"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.product_without)
			urlParams += returnSign(urlParams) + 'product_without=' + filterDataCopy.product_without
		if (filterDataCopy.menu_id >= 0)
			urlParams += returnSign(urlParams) + 'menu_id=' + filterDataCopy.menu_id
		if (filterDataCopy.in_product >= 0)
			urlParams += returnSign(urlParams) + 'in_product=' + filterDataCopy.in_product
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		if (`/products${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		if (filterParams.tableSort) {
			if (filterDataCopy.tableSortKey === filterParams.tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = filterParams?.tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + filterParams.tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams
		if (filterParams.exportToExcel) {
			filterUrl = "/products-excel" + urlParams + returnSign(filterUrl)

			var excelHeaders = [[
				t('name2'), t('cost_price'), t('price'),
				t('sale_price'), '%', t('profit'), t('margin')
			]]

			var response = await GET(filterUrl)
			if (httpOk(response)) {
				response = response.data
				for (let i = 0; i < response.length; i++) {
					var excelItems = []
					excelItems.push(response[i]['name'])
					excelItems.push(response[i]['cost_price'])
					excelItems.push(response[i]['price'])
					excelItems.push(response[i]['sale_price'])
					excelItems.push(response[i]['fc_percentage'])
					excelItems.push(response[i]['sale_price'] - response[i]['cost_price'])
					excelItems.push(response[i]['margin'])
					excelHeaders.push(excelItems)
				}

				const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
				XLSX.writeFile(wb, t('products') + ".xlsx");
				return
			}
		}

		response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function downloadExcelRecipeFile() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/products-ingredients-excel"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.menu_id >= 0)
			urlParams += returnSign(urlParams) + 'menu_id=' + filterDataCopy.menu_id
		if (filterDataCopy.in_product >= 0)
			urlParams += returnSign(urlParams) + 'in_product=' + filterDataCopy.in_product
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		filterUrl += urlParams

		var response = await FILE_D(filterUrl)
		if (httpOk(response)) {
			// Создаем объект Blob из полученного файла
			const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Создаем ссылку для скачивания файла
			const fileUrl = URL.createObjectURL(file);

			// Создаем ссылку для скачивания файла в браузере
			const link = document.createElement('a');
			link.href = fileUrl;
			link.setAttribute('download', `${t('recipe')}.xlsx`);
			document.body.appendChild(link);
			link.click();

			// Удаляем ссылку после скачивания файла
			URL.revokeObjectURL(fileUrl);
		}
	};

	function resetFilter() {
		setFilterData({
			pos_id: null,
			search: '',
		})
		searchWithFilter()
		setFilterDataExist(false)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function deleteItem() {
		const response = await DELETE('/products', { 'id': deleteModalParams.id }, { loader: true })
		if (httpOk(response)) {
			searchWithFilter()
			setModalDelete(false)
		}
	}

	async function getPoses() {
		const response = await GET('/helper/poses', {}, { loader: true })
		if (httpOk(response)) {
			setPoses(response.data)
			searchWithFilter()
		}
	}

	async function getMenu() {
		var response = await GET('/helper/menu')
		if (httpOk(response)) {
			response.data.unshift({ 'id': 0, 'name': t('hidden_menu') })
			setMenu(response.data)
		}
	}

	function renderArrows(tableSortDirection, tableSortKey, type) {
		if (tableSortDirection === ',asc' && tableSortKey === type) {
			return (
				<i className="uil uil-angle-up sort-active"></i>
			)
		} else if (tableSortDirection === ',desc' && tableSortKey === type) {
			return (
				<i className="uil uil-angle-down sort-active"></i>
			)
		} else {
			return (
				<i className="uil uil-angle-down"></i>
			)
		}
	}

	useEffect(() => {
		getPoses()
		getMenu()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('products')} [{pagination?.response?.total}]
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary"
						to={`/products/create/${filterData.pos_id}`}>
						{t('create')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('menu')}</label>
												<Select
													options={menu}
													value={menu.find(option => option.id === filterData.menu_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'menu_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('type')}</label>
												<Select
													options={productTypes}
													value={productTypes.find(option => option.id === filterData.in_product) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'in_product': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													options={statuses}
													value={statuses.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('products_without')}</label>
												<Select
													options={columnFilers}
													value={columnFilers.find(option => option.id === filterData.product_without) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'product_without': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="d-flex justify-content-end gap-2">
											<button className="btn btn-secondary" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
											<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="d-flex gap-2">
								<button className="btn btn-outline-primary btn-wide"
									onClick={() => downloadExcelRecipeFile()}>
									{t('recipes')}
								</button>
								<button className="btn btn-outline-primary btn-wide-2"
									onClick={() => searchWithFilter({ exportToExcel: true })}>
									<CustomTooltip text={t('EXCEL')}>
										<i className="uil uil-file-download-alt"></i>
									</CustomTooltip>
								</button>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.menu_id &&
									<span className="me-2">
										<b>{t('menu')}: </b>
										{findKeyFromArrayByValue(menu, 'id', filterData.menu_id, 'name')}
									</span>
								}
								{filterData.in_product >= 0 &&
									<span className="me-2">
										<b>{t('type')}: </b>
										{findKeyFromArrayByValue(productTypes, 'id', filterData.in_product, 'name')}
									</span>
								}
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '50%' }} className="th-sortable"
										onClick={() => searchWithFilter({ tableSort: 'name' })}>
										{t('name2')}
										<div className="table-sort">
											{renderArrows(filterData.tableSortDirection, filterData.tableSortKey, 'name')}
										</div>
									</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('cost_price')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('sale_price')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('profit')}</th>
									<th style={{ 'width': '5%' }} className="text-end">
										<CustomTooltip text={`100 / ${t('sale_price')} * 100`}>
											Фудкост%
										</CustomTooltip>
									</th>
									<th style={{ 'width': '10%' }} className="text-end">
										<CustomTooltip text={`(${t('sale_price')} - ${t('cost_price')}) / ${t('sale_price')} * 100`}>
											{t('margin')}
										</CustomTooltip>
									</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('status')}</th>
									<th style={{ 'width': '35px', 'minWidth': '35px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">
											<CustomTooltip text={item.id}>
												{item.row_number}
											</CustomTooltip>
										</td>
										<td>{item.name}</td>
										<td className="text-end">{formatMoney(item.cost_price)}</td>
										<td className="text-end">{formatMoney(item.sale_price)}</td>
										<td className="text-end">{formatMoney(item.income)}</td>
										<td className="text-end">
											{formatMoney(item.fc_percentage, 1)}%
										</td>
										<td className="text-end">{formatMoney(item.margin, 1)}%</td>
										<td className="text-center">
											<CustomTooltip text={`${t('cost_price')} > ${t('sale_price')}`}>
												{(Number(item.cost_price) > Number(item.sale_price)) &&
													<span className="bg-danger bg-border"></span>
												}
											</CustomTooltip>
											<CustomTooltip text={`${t('cost_price')} <= ${t('sale_price')}`}>
												{(Number(item.cost_price) <= Number(item.sale_price)) &&
													<span className="bg-primary bg-border"></span>
												}
											</CustomTooltip>
										</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<Link to={`/products/update/${filterData.pos_id}/${item.id}`} className="table-dropdown-item">
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</Link>
														<div className="table-dropdown-item text-danger"
															onClick={() => {
																setDeleteModalParams({ id: item.id, index: index });
																setModalDelete(true)
															}}>
															<i className="uil-times"></i>
															{t('delete')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { toast } from 'react-toastify'

import ReactSelect from 'pages/parts/ReactSelect'
import { GET, POST, httpOk, PUT } from 'helpers/api'
import { formatDate, formatMoney, returnMinDate } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState({
		'items': [],
		'pos_id': reduxAccount.pos_id,
		'to_pos_id': null,
		'supplier_id': null,
		'note': '',
		'created_at': formatDate(new Date(), 'yyyy-MM-dd'),
	})
	const [addQuantity, setAddQuantity] = useState(1)
	const [poses, setPoses] = useState([])
	const [externalPoses, setExternalPoses] = useState([])
	const [suppliers, setSuppliers] = useState([])
	const [items, setItems] = useState([])
	const [itemsDuplicate, setItemsDuplicate] = useState([])

	async function createUpdate() {
		var dataCopy = { ...data }
		dataCopy.error = false

		if (dataCopy.items.length === 0) {
			toast.error(t('add_elements'))
			return
		}

		for (let i = 0; i < dataCopy.items.length; i++) {
			if (!dataCopy.items[i]['quantity']) {
				dataCopy.error = true
			}
		}

		var valueArr = dataCopy.items.map(function (item) { return item.product_id });
		var isDuplicate = valueArr.some(function (item, idx) {
			return valueArr.indexOf(item) !== idx
		});

		if (isDuplicate) {
			toast.error(t('identical_elements'))
			return
		}

		if (dataCopy.error) {
			toast.error(t('check_filled_inputs'))
			return
		}

		if (id) {
			const response = await PUT('/wms-transfer-branch', dataCopy, { loader: true })

			if (httpOk(response)) {
				navigate('/wms-transfer-branch')
			}
			return
		}

		if (!id) {
			const response = await POST('/wms-transfer-branch', dataCopy, { loader: true })

			if (httpOk(response)) {
				navigate('/wms-transfer-branch')
			}
		}
	}

	function addToList(length = 1) {
		var dataCopy = JSON.parse(JSON.stringify(data))

		for (let i = 0; i < length; i++) {
			dataCopy.items.push({
				'id': '',
				'sale_price': '',
				'quantity': '',
			})
		}
		countTotalAmount(dataCopy)

		setTimeout(() => {
			const byId = document.getElementById(dataCopy.items.length)
			byId.select()
		}, 200);

	}

	async function deleteItem(index) {
		var dataCopy = JSON.parse(JSON.stringify(data))
		dataCopy.items.splice(index, 1)

		countTotalAmount(dataCopy)
	}

	function countTotalAmount(dataCopy) {
		dataCopy.total_quantity = 0
		for (let i = 0; i < dataCopy.items.length; i++) {
			dataCopy.total_quantity += Number(dataCopy.items[i]['quantity'])
		}

		setData(dataCopy)
	}

	function handleSelectPos(option) {
		getProducts(option.id)
	}

	function handleInputChange(e, index) {
		var dataCopy = { ...data }

		dataCopy.items[index][e.target.name] = e.target.value
		countTotalAmount(dataCopy)
	}

	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (!value) {
			setItems([...itemsDuplicate])
			return
		}
		var elementsDuplicateCopy = [...itemsDuplicate]

		var filteredItems = elementsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setItems(filteredItems)
	}

	function handleSelectElement(option, index) {
		var dataCopy = { ...data }
		dataCopy.items[index] = option
		setData(dataCopy)
	}

	function closeReactSelect() {
		setTimeout(() => {
			var elementsCopy = [...items]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true
			setItems(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = items.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...items]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[items.length - 1].selected = true
						currentIndex = items.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === items.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setItems(elementsCopy)
		}
	}
	// REACTSELECT LOGIC

	async function getPoses() {
		const response = await GET('/helper/poses', {}, { loader: true })
		if (httpOk(response)) setPoses(response.data)
	}

	async function getChainPoses() {
		const response = await GET('/helper/poses-chain-transfer', {}, { loader: true })
		if (httpOk(response)) setExternalPoses(response.data)
	}

	async function getProducts(pos_id = reduxAccount.pos_id) {
		var response = await GET(`/wms-transfer-branch-items/${pos_id}`, {}, { loader: true })
		if (httpOk(response)) {
			setItems(response.data)
			setItemsDuplicate(response.data)

			var dataCopy = { ...data }
			dataCopy.items = []
			dataCopy.pos_id = pos_id

			countTotalAmount(dataCopy)
		}
	}

	async function getInternalSuppliers() {
		const response = await GET('/helper/internal-suppliers', {}, { loader: true })
		if (httpOk(response)) setSuppliers(response.data)
	}

	async function getData() {
		const response = await GET(`/wms-transfer-branch/${id}`, {}, { loader: true })

		if (httpOk(response)) {
			countTotalAmount(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		getChainPoses()
		getInternalSuppliers()
		if (id) {
			getData()
		} else {
			getProducts()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('transfer')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
			</div>

			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('date')}<span className="required-mark">*</span></label>
									<div className="calendar-input">
										<DatePicker
											disabled={id}
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={data.created_at ? new Date(data.created_at) : ''}
											onChange={(date) => setData({ ...data, 'created_at': formatDate(date, 'yyyy-MM-dd') })}
											minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
											maxDate={new Date()} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('pos')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={id}
										options={poses}
										value={poses.find(option => option.id === data.pos_id)}
										onChange={(option) => handleSelectPos(option)}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('branch')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={id}
										options={externalPoses}
										value={externalPoses.find(option => option.id === data.to_pos_id)}
										onChange={(option) => setData({ ...data, 'to_pos_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('supplier')}<span className="required-mark">*</span></label>
									<Select
										options={suppliers}
										value={suppliers.find(option => option.id === data.supplier_id)}
										onChange={(option) => setData({ ...data, 'supplier_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('note')}</label>
									<input type="text" className="form-control" name="note"
										value={data.note ?? ''}
										onChange={(e) => setData({ ...data, 'note': e.target.value })} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<div className="table-responsive">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '50%' }}>{t('name3')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('balance2')}</th>
										<th style={{ 'width': '10%' }} className="text-start">{t('unit')}</th>
										<th style={{ 'width': '10%' }} className="text-center">{t('quantity')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('cost_price')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
										<th style={{ 'minWidth': '33px' }}></th>
									</tr>
								</thead>
								<tbody>
									{data?.items?.map((item, index) => (
										<tr key={index}>
											<td className="text-center">{index + 1}</td>
											<td>
												<ReactSelect
													search={handleSelectSearch}
													value={item.name}
													index={index}
													scrollRef={scrollRef}
													options={items}
													onClose={() => closeReactSelect()}
													selectItem={(option) => handleSelectElement(option, index)}
													onKeyUp={(e) => {
														if (e.keyCode === 40 || e.keyCode === 38) {
															handleTableKeyDown(e)
														}
													}}
													table={
														<table className="table mb-0">
															<tbody ref={tbodyRef}>
																{items.map((selectItem, selectIndex) => (
																	<tr
																		id={selectItem.id}
																		className={`cursor ${((selectItem.selected || selectItem.product_id === item.element_id) && 'selected')}`}
																		tabIndex={-1}
																		key={selectIndex}
																		onClick={() => handleSelectElement(selectItem, index)}
																		onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																		<td>{selectItem.name}</td>
																		<td>{selectItem.product_type_name}</td>
																		<td className="text-end text-nowrap">{formatMoney(selectItem.cost_price)}</td>
																	</tr>
																))}
															</tbody>
														</table>
													}
												/>
											</td>
											<td className="text-end vertical-middle">
												{formatMoney(item.balance, 2)}
											</td>
											<td className="text-start vertical-middle">
												{item.unit_name}
											</td>
											<td className="td-input" style={!item.quantity ? { 'borderBottom': '2px solid red' } : {}}>
												<input
													type="text"
													name="quantity"
													className="form-control text-end"
													placeholder="0"
													value={item.quantity ?? ''}
													onChange={(e) => handleInputChange(e, index)} />
											</td>
											<td className="text-end vertical-middle">
												{formatMoney(item.cost_price)}
											</td>
											<td className="vertical-middle text-end text-nowrap">
												{formatMoney(item.quantity * item.cost_price)}
											</td>
											<td className="vertical-middle">
												{!id &&
													<div className="d-flex justify-content-center">
														<div className="table-action-button table-action-danger-button"
															onClick={() => deleteItem(index)}>
															<i className="uil uil-times"></i>
														</div>
													</div>
												}
											</td>
										</tr>
									))}
								</tbody>
								<tfoot>
									<tr>
										<td className="td-input">
											<input
												type="text"
												className="form-control text-center"
												style={{ 'padding': '5px 0' }}
												placeholder="xxx"
												value={addQuantity}
												onChange={(e) => setAddQuantity(e.target.value.replace(/[^0-9.]/g, ''))}
											/>
										</td>
										<td colSpan={1} style={{ 'padding': 0 }}>
											<div className="d-flex">
												<div className="w-100">
													<button type="button" className="btn-sm btn-primary w-100"
														disabled={id}
														onClick={() => {
															setAddQuantity(1)
															addToList(addQuantity)
														}}>
														{t('add')}
													</button>
												</div>
											</div>
										</td>
										<td></td>
										<td></td>
										<td className="text-end"><b>{formatMoney(data.total_quantity, 2)}</b></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>

						<div className="btn-wrapper gap-2">
							<button type="button" className="btn btn-warning btn-rounded btn-wide" tabIndex="-1"
								onClick={() => navigate(-1)}>
								{t('cancel')}
							</button>
							<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
								disabled={!(data.pos_id && data.to_pos_id && data.supplier_id && data.items.length)}
								onClick={() => createUpdate(1)}>
								{t('save')}
							</button>
						</div>
					</div>
				</div>
			</form >
		</>
	)
}

export default CreateUpdate
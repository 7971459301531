import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown, Modal } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import { GET, POST, PUT, httpOk } from 'helpers/api'
import { findKeyFromArrayByValue, returnSign } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const statuses = [
		{ 'id': 1, 'name': t('success') },
		{ 'id': -1, 'name': t('delete') },
	]

	const [data, setData] = useState([])
	const [accountsChef, setAccountsChef] = useState([])
	const [accounts, setAccounts] = useState([])
	const [cashFlowTypes, setCashFlowTypes] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [selectedItem, setSelectedItem] = useState({
		'transaction_type': 'minus',
		'account_chef_id': 6,
		'account_core_id': 18,
		'show_in_cashbox': true,
	})
	const [filterData, setFilterData] = useState({ 'status': 1 })
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

	async function createUpdate(e) {
		e.preventDefault()

		var response;
		if (selectedItem.id) {
			response = await PUT('/payment-purpose-types', selectedItem, { loader: true })
		} else {
			response = await POST('/payment-purpose-types', selectedItem, { loader: true })
		}

		if (httpOk(response)) {
			searchWithFilter()
			setShowModal(false)
			setSelectedItem({})
		}
	}

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/payment-purpose-types"
		var urlParams = ""

		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
		}

		setDropdownIsOpen(false)
	}

	function toggleModal(
		bool = false,
		item = {
			'transaction_type': 'minus',
			'account_chef_id': 6,
			'account_core_id': 18,
			'show_in_cashbox': true
		}
	) {
		setShowModal(bool)
		setSelectedItem(item)

		if (bool) {
			if (!accounts.length) {
				getAccounts()
				getAccountsChef()
			}

			if (!cashFlowTypes.length)
				getCashFlowTypes()
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getAccountsChef() {
		var response = await GET('/helper/accounts-chef')
		if (httpOk(response)) {
			response.data.unshift({id: 6, name: "Касса"})
			setAccountsChef(response.data)
		}
	}

	async function getAccounts() {
		const response = await GET('/helper/accounts')
		if (httpOk(response)) setAccounts(response.data)
	}

	async function getCashFlowTypes() {
		const response = await GET('/helper/cash-flow-types')
		if (httpOk(response)) setCashFlowTypes(response.data)
	}

	function returnEntityType(item) {
		var name = t('no')
		if (item?.counterparty === 'supplier') {
			name = t('supplier')
		}
		if (item?.counterparty === 'employee' || item?.counterparty === 'employee_salary') {
			name = t('employee')
		}
		if (item?.counterparty === 'agent') {
			name = t('agent')
		}
		if (item?.counterparty === 'organization') {
			name = t('organization')
		}
		if (item?.counterparty === 'client') {
			name = t('client')
		}

		return name
	}

	useEffect(() => {
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('expenses')} [{data.length}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													isClearable
													options={statuses}
													value={statuses.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					<div className="filter-block">
						<div className="filter">
							<strong>{t('filter')}</strong>
							<br />
							{filterData.search &&
								<span className="me-2">
									<b>{t('search')}: </b>
									<span>{filterData.search}</span>
								</span>
							}
							{filterData.status &&
								<span className="me-2">
									<b>{t('status')}: </b>
									{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
								</span>
							}
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('name2')}</th>
									<th style={{ 'width': '20%' }}>{t('operation_account')}</th>
									<th style={{ 'width': '20%' }}>{t('report_account')}</th>
									<th style={{ 'width': '10%' }}>{t('limit')}</th>
									<th style={{ 'width': '10%' }}>{t('type')}</th>
									<th style={{ 'width': '10%' }}>{t('counterparty')}</th>
									<th style={{ 'width': '10%' }} className="text-nowrap td-ellipsis"
										title={t('show_in_cashbox')}>
										{t('show_in_cashbox')}
									</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td className="td-ellipsis" title={item.name}>{item.name}</td>
										<td className="td-ellipsis" title={item.account_chef_name}>{item.account_chef_name}</td>
										<td className="td-ellipsis" title={item.account_core_name}>{item.account_core_name}</td>
										<td></td>
										<td>
											{item.transaction_type === 'minus' &&
												<span>{t('expense')}</span>
											}
											{item.transaction_type === 'plus' &&
												<span>{t('income')}</span>
											}
										</td>
										<td>{returnEntityType(item)}</td>
										<td>
											{item.show_in_cashbox &&
												<span>{t('yes')}</span>
											}
											{!item.show_in_cashbox &&
												<span>{t('no')}</span>
											}
										</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}</label>
							<input type="text" className="form-control" name="name" value={selectedItem.name ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('type')}</label>
							<div className="d-flex justify-content-between mb-2">
								<div className="d-flex gap-3">
									<div className="form-check p-0">
										<div className="radio-round"
											onClick={() => setSelectedItem({ ...selectedItem, 'transaction_type': 'minus' })}
											style={{ 'backgroundColor': selectedItem.transaction_type === 'minus' ? 'black' : '' }}>
										</div>
										<span onClick={() => setSelectedItem({ ...selectedItem, 'transaction_type': 'minus' })}>
											{t('expense')}
										</span>
									</div>

									<div className="form-check p-0">
										<div className="radio-round"
											onClick={() => setSelectedItem({ ...selectedItem, 'transaction_type': 'plus' })}
											style={{ 'backgroundColor': selectedItem.transaction_type === 'plus' ? 'black' : '' }}>
										</div>
										<span onClick={() => setSelectedItem({ ...selectedItem, 'transaction_type': 'plus' })}>
											{t('income')}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className="form-group">
							<label>{t('operation_account')}</label>
							<Select
								isClearable
								options={accountsChef}
								value={accountsChef.find(option => option.id === selectedItem.account_chef_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'account_chef_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('report_account')}</label>
							<Select
								options={accounts}
								value={accounts.find(option => option.id === selectedItem.account_core_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'account_core_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('cash_flow_type')}</label>
							<Select
								options={cashFlowTypes}
								value={cashFlowTypes.find(option => option.id === selectedItem.cash_flow_type_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'cash_flow_type_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('show_in_cashbox')}</label>
							<div className="d-flex justify-content-between mb-2">
								<div className="d-flex gap-3">
									<div className="form-check p-0">
										<div className="radio-round"
											onClick={() => setSelectedItem({ ...selectedItem, 'show_in_cashbox': true })}
											style={{ 'backgroundColor': selectedItem.show_in_cashbox ? 'black' : '' }}>
										</div>
										<span onClick={() => setSelectedItem({ ...selectedItem, 'show_in_cashbox': true })}>
											{t('yes')}
										</span>
									</div>

									<div className="form-check p-0">
										<div className="radio-round"
											onClick={() => setSelectedItem({ ...selectedItem, 'show_in_cashbox': false })}
											style={{ 'backgroundColor': !selectedItem.show_in_cashbox ? 'black' : '' }}>
										</div>
										<span onClick={() => setSelectedItem({ ...selectedItem, 'show_in_cashbox': false })}>
											{t('no')}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
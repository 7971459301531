
import React from 'react'

import ReactApexChart from 'react-apexcharts'

import { formatMoney } from 'helpers/helpers'

function ContourMarket() {

	const chartData = {
		series: [
			{
				name: 'Net Profit',
				data: [20, 50, 70, 40, 20, 31, 24, 57, 80, 60, 48, 60]
			}
		],
		options: {
			chart: {
				type: 'bar',
				height: 350
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '45%',
					endingShape: 'rounded',
					dataLabels: {
						position: 'top', // top, center, bottom
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (val) {
					return val + "%";
				},
				offsetY: -20,
				style: {
					fontSize: '12px',
					colors: ["#304758"]
				}
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent']
			},
			xaxis: {
				categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
			},
			yaxis: {
				title: {
					text: '$ (thousands)'
				}
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return "$ " + val + " thousands"
					}
				}
			}
		},
	}

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					Дашбоард Контур.Маркет
				</h4>
			</div>

			<div className="setting-tabs second mb-3">
				<div className="setting-tab active">
					Продажи
				</div>
				<div className="setting-tab">
					Списания
				</div>
				<div className="setting-tab">
					Остатки
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4>Таблица - План vs Факт</h4>

					<table className="table">
						<thead>
							<tr>
								<th style={{ width: '50%' }}>Товар</th>
								<th className="text-end">Кол-во товара</th>
								<th className="text-end">Сумма продажи</th>
								<th className="text-end">Доля сумма продажи</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Марс</td>
								<td className="text-end">{formatMoney(7558071)}</td>
								<td className="text-end" style={{ height: '1px' }}>
									<div class="progress-container">
										<div class="progress-bar bg-success" style={{ width: `51%`, opacity: '0.3' }}></div>
										<b style={{ position: 'absolute', right: '5px', top: 0 }}>
											{51}%
										</b>
									</div>
								</td>
								<td className="text-end">{formatMoney(6837262)}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div className="d-flex gap-3">
				<div className="card w-100">
					<div className="card-body">
						<h4>Количество проданного товара за 365 дней</h4>
					</div>
					<ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
				</div>
				<div className="card w-100">
					<div className="card-body">
						<h4>Количество продаж за 365 дней</h4>
					</div>
					<ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
				</div>
			</div>
		</>
	)
}

export default ContourMarket
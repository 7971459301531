import React, { Fragment, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'

import { SET_ACCOUNT } from 'store/account/action'
import { GUESTPOST, GUESTPUT, httpOk } from 'helpers/api'
import { clearTemporaryStorage, formatMoney, formatMoneyInput } from 'helpers/helpers'

import ru from 'assets/images/flags/ru.png'
import uzLatn from 'assets/images/flags/uz_latn.png'
import uzCyrl from 'assets/images/flags/uz_cyrl.png'

function Login() {
	const { i18n, t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [user, setUser] = useState({ "login": "", "password": "" })
	const [modalPayment, setModalPayment] = useState({ "bool": false, "data": {} })

	async function handleSubmit(e = null) {
		if (e) e.preventDefault();

		var sendData = {
			login: user.login.toLowerCase(),
			password: user.password
		}
		clearTemporaryStorage()
		var response = await GUESTPOST('/login', sendData, { loader: true })

		if (!httpOk(response)) {
			if (response.data.code === -1) {
				response.data.amount = response?.data.tariff
				setModalPayment({ ...modalPayment, 'bool': true, 'data': response?.data })
			}
		}

		if (response?.status >= 200) {
			response = response.data

			dispatch(SET_ACCOUNT(response))
			localStorage.setItem("login", user.login.toLowerCase());
			localStorage.setItem("role", response.role);
			localStorage.setItem('token', response.token)

			switch (response.role) {
				case 'ROLE_ADMIN':
					navigate("/admin/users")
					break;
				case 'ROLE_OWNER':
					navigate("/dashboard-main")
					break;
				case 'ROLE_DIRECTOR':
					navigate("/dashboard-main")
					break;
				case 'ROLE_CALL_CENTER':
					navigate("/delivery/orders")
					break;
				case 'ROLE_PARTNER':
					navigate("/partner/dashboard")
					break;
				default:
					//toast.error(t('you_do_not_have_access'))
					break;
			}
		}
	}

	async function createTransaction(type) {
		var sendData = {
			login: user.login.toLowerCase(),
			pos_id: modalPayment.data.pos_id,
			amount: modalPayment.data.amount,
		}

		var response = await GUESTPOST('/payment/uzum-transaction', sendData, { loader: true })

		if (!httpOk(response)) {
			if (response.data.code === -1) {
				setModalPayment({ ...modalPayment, 'bool': true, 'data': response?.data })
			}
		}
	}

	async function activateCredit() {
		var sendData = {
			login: modalPayment.data.login,
			pos_id: modalPayment.data.pos_id
		}

		var response = await GUESTPUT('/pos/activate-credit', sendData, { loader: true })
		if (httpOk(response)) {
			handleSubmit()
		}
	}

	function handleChange(e) {
		if (Number(e.target.value.replace(/[^0-9.]/g, '')) > 50000000) return

		var modalPaymentCopy = { ...modalPayment }
		modalPayment.data.amount = e.target.value.replace(/[^0-9.]/g, '')

		setModalPayment(modalPaymentCopy)
	}

	function toggleModal(bool) {
		setModalPayment({ ...modalPayment, 'bool': bool })
	}

	function changeLanguage(language = 'uz_latn') {
		i18n.changeLanguage(language);

		if (language === 'ru') {
			localStorage.setItem('lang', 'ru');
		}
		if (language === 'uz_latn') {
			localStorage.setItem('lang', 'uz_latn');
		}
		if (language === 'uz_cyrl') {
			localStorage.setItem('lang', 'uz_cyrl');
		}
	};

	function getCurrentLocale() {
		const locale = i18n.language
		if (locale === 'ru') return { flag: ru, lang: 'russian' }
		else if (locale === 'uz_latn') return { flag: uzLatn, lang: 'uzbek_latn' }
		else if (locale === 'uz_cyrl') return { flag: uzCyrl, lang: 'uzbek_cyrl' }
	}

	const LanguageToggle = React.forwardRef(({ children, onClick }, ref) => (
		<span ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
			{children}
		</span>
	));

	return (
		<>
			<div className="authentication-bg">
				<div className="auth-card">
					<div className="d-flex justify-content-center">
						<div className="form-wrapper">
							<form className="login-form"
								autoComplete="off"
								onSubmit={(e) => handleSubmit(e)}>
								<div style={{ fontSize: '35px', color: '#fff' }}>
									{t('logging_in')}
								</div>

								<div className="form-group w-100">
									<label style={{ color: '#fff' }}>{t('login')}</label>
									<input className="form-control"
										name="login"
										autoFocus
										value={user.login}
										onChange={(e) => setUser({ ...user, 'login': e.target.value.toLowerCase() })} />
								</div>

								<div className="form-group w-100">
									<label style={{ color: '#fff' }}>{t('password')}</label>
									<input className="form-control"
										name="password"
										type="password"
										value={user.password}
										onChange={(e) => setUser({ ...user, 'password': e.target.value })} />
								</div>

								<div className="w-100 text-center">
									<button type="submit" className="btn btn-dark w-100">
										{t('enter')}
									</button>
								</div>

								<div className="mt-4 text-center">
									<Link to="/auth/forgot-password" className="fw-medium text-white">{t('forgot_password')}</Link>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div className="position-absolute lang-position">
					<Dropdown className="cursor-pointer d-flex cursor">
						<Dropdown.Toggle as={LanguageToggle}>
							<div className="d-flex">
								<div className="vertical-center">
									<img src={getCurrentLocale().flag} className="me-2" alt="ru" width="24" height="16" />
								</div>
								<span>{t(getCurrentLocale().lang)}</span>
							</div>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{i18n.language !== 'ru' &&
								<Dropdown.Item onClick={() => changeLanguage("ru")}>
									<div className="d-flex">
										<div className="vertical-center"><img src={ru} className="me-2" alt="ru" width="24" height="16" /></div>
										<span>{t('russian')}</span>
									</div>
								</Dropdown.Item>
							}
							{i18n.language !== 'uz_latn' &&
								<Dropdown.Item onClick={() => changeLanguage("uz_latn")}>
									<div className="d-flex">
										<div className="vertical-center"><img src={uzLatn} className="me-2" alt="uz_latn" width="24" height="16" /></div>
										<span>{t('uzbek_latn')}</span>
									</div>
								</Dropdown.Item>
							}
							{i18n.language !== 'uz_cyrl' &&
								<Dropdown.Item onClick={() => changeLanguage("uz_cyrl")}>
									<div className="d-flex">
										<div className="vertical-center"><img src={uzCyrl} className="me-2" alt="uz_cyrl" width="24" height="16" /></div>
										<span>{t('uzbek_cyrl')}</span>
									</div>
								</Dropdown.Item>
							}
						</Dropdown.Menu>
					</Dropdown>
				</div>

				<div className="login-about">
					{t('contact_centre')}: +998 55 500-00-89 <br />
					{t('contact_centre')}: +998 99 803-50-34 <br />
					{t('made_by_as')} <br />
					{t('made_by_as2')}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={modalPayment.bool} animation={false} size="xl"
				dialogClassName="update-modal-width" centered backdrop="static"
				onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between gap-3">
						<div style={{ 'width': '33.33%' }}>
							<div className="auth-payment-card fz-16 mb-3" style={{ 'height': '75px' }}>
								<div className="d-flex justify-content-between">
									<div>{t('login')}:</div>
									<b>{modalPayment.data.login}</b>
								</div>
								<div className="d-flex justify-content-between">
									<div>ID</div>
									<b>{modalPayment.data.pos_id}</b>
								</div>
							</div>

							<div className="auth-payment-card mb-3" style={{ 'height': '260px' }}>
								<div>
									<div className="text-center text-primary fz-20">
										<b>{t('transfer_card')}</b>
									</div>
									<div className="d-flex flex-column" style={{ 'lineHeight': '18px' }}>
										<label className="fz-18">{t('telegram')}</label>
										<b className="fz-22">+998998035034</b>
									</div>
									<hr style={{ 'margin': '8px' }} />
									<div className="d-flex flex-column" style={{ 'lineHeight': '18px' }}>
										<label className="fz-18">Карта для оплаты</label>
										<b className="fz-22">5614682210225340</b>
									</div>
								</div>

								<p className="fz-16 mt-4">
									*После оплаты на карту пожалуйста напишите
									мне в <a href="https://t.me/AkhmedovSh" target="_blank" rel="noreferrer">телеграм</a>
									прикрепите чек оплаты и свой ID
								</p>
							</div>
						</div>
						<div style={{ 'width': '33.33%' }}>
							<div className="text-center text-primary mb-3" style={{ 'height': '75px' }}>
								<b style={{ 'fontSize': '40px' }}>
									{t('payment')}
								</b>
							</div>

							<div className="auth-payment-card mb-3" style={{ 'height': '260px' }}>
								<div>
									<div className="text-center text-primary fz-20 mb-3">
										<b>Онлайн оплата</b>
									</div>

									<div className="d-flex justify-content-center">
										<input type="text" className="form-control w-75"
											autoComplete="off"
											name="amount"
											value={formatMoneyInput(modalPayment.data.amount)}
											onChange={(e) => handleChange(e)} />
									</div>
									<div className="d-flex justify-content-center">
										<button type="button" className="btn btn-primary mt-2 w-75"
											onClick={() => createTransaction('uzum')}>
											{t('pay')}
										</button>
									</div>
								</div>

								<p className="fz-16">
									*Ваша учетная запись автоматически активируется
									после оплаты. Войдите в систему заново
								</p>
							</div>
						</div>
						<div style={{ 'width': '33.33%' }}>
							<div className="auth-payment-card fz-16 mb-3" style={{ 'height': '75px' }}>
								<div className="d-flex justify-content-between">
									<div>{t('tariff')}</div>
									<b>{formatMoney(modalPayment.data.tariff)}</b>
								</div>
								<div className="d-flex justify-content-between">
									<div>{t('balance2')}</div>
									<b>{formatMoney(modalPayment.data.balance)}</b>
								</div>
							</div>

							<div className="auth-payment-card mb-3" style={{ 'height': '260px' }}>
								<div>
									<div className="text-center text-primary fz-20 mb-2">
										<b>Доверительный платеж</b>
									</div>
									<p className="fz-16">
										*После активации “доверительный платеж”
										не забудьте оплатить абоненскую плату, во избежания блокировки входа в систему.
									</p>
									{(modalPayment.data.code === -1 && modalPayment.data.show_credit_button) &&
										<div className="d-flex mt-2">
											<div className="form-check">
												<input type="checkbox" className="form-check-input"
													onClick={(e) => setModalPayment({ ...modalPayment, 'agree': e.target.checked })}
													checked={modalPayment.agree} />
											</div>
											<span className="text-primary fz-16 fw-500">Я согласен</span>
										</div>
									}
								</div>
								{(modalPayment.data.code === -1 && !modalPayment.data.show_credit_button) &&
									<p className="text-center text-danger">Эту функцию можно активировать с 1 по 10 числа каждого календарного месяца</p>
								}
								{(modalPayment.data.code === -1 && modalPayment.data.show_credit_button) &&
									<Fragment>
										<div className="text-center mt-2">
											<button type="button" className="btn btn-primary w-75"
												disabled={(modalPayment.credit_date || !modalPayment.agree)}
												onClick={activateCredit}>
												Активировать
											</button>
										</div>
										<p className="text-center">Активировать доверительный <br />платеж на 7 дней</p>
									</Fragment>
								}

							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Login;

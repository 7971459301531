import React, { useState, useRef } from "react";
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'

// Import menuDropdown
import LanguageDropdown from "../../LanguageDropdown";

import { clearTemporaryStorage } from 'helpers/helpers'

function Header() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const wrapperRef = useRef(null);

	const reduxAccount = useSelector(state => state.account)

	const [showUserDropdown, setShowUserDropdown] = useState(false)
	const [modalLogout, setModalLogout] = useState(false)

	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
			!document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	async function logout() {
		dispatch({ type: 'USER_LOGGED_OUT', payload: null })
		clearTemporaryStorage()
		navigate('/auth/login')
	}

	return (
		<>
			<header id="page-topbar" className="sidebar-left">
				<div className="navbar-header">
					<div></div>
					<div className="d-flex">
						<LanguageDropdown />

						<Dropdown className="d-none d-lg-inline-block ms-1">
							<button type="button" className="btn header-item noti-icon waves-effect" data-toggle="fullscreen"
								onClick={toggleFullscreen}>
								<i className="uil-minus-path"></i>
							</button>
						</Dropdown>
						<div className="d-lg-inline-block ms-1 dropdown" >
							<button type="button" className="btn header-item noti-icon waves-effect" ref={wrapperRef}>
								<div className={`profile-dropdown`}>
									<div className={`profile-dropdown-toggle`}
										onClick={() => setShowUserDropdown(!showUserDropdown)}>
										<i className="uil uil-user" color="#fff"></i>
									</div>
									{showUserDropdown &&
										<div className="profile-dropdown-menu">
											<div className="profile-dropdown-item">
												<div>{t('name')}:</div>
												<div>{reduxAccount.first_name}</div>
											</div>
											<div className="profile-dropdown-item">
												<div>{t('login')}:</div>
												<div>{reduxAccount.login}</div>
											</div>
											<div className="profile-dropdown-item second"
												onClick={() => setModalLogout(true)}>
												<div>
													{t('logout')}
												</div>
												<i className="uil-signin"></i>
											</div>
										</div>
									}
								</div>
							</button>
						</div>
					</div>
				</div>
			</header>

			<Modal show={modalLogout} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalLogout(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('logout')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('are_you_sure_you_want_logout')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => setModalLogout(false)}>
							{t('cancel')}
						</button>
						<button className="btn btn-primary w-100"
							onClick={() => logout()}>
							{t('confirm')}
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Header

import React, { useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { fabric } from 'fabric'
import { SketchPicker } from 'react-color'

import { GET, PUT, httpOk } from 'helpers/api'
import CustomTooltip from 'pages/parts/CustomTooltip'

function Scheme() {
	const { t } = useTranslation()
	const { id } = useParams()
	const canvas = useRef(null);
	const wrapperRef = useRef(null);
	const inputFile = useRef(null)
	const inputBgFile = useRef(null)

	const [canvasData, setCanvasData] = useState({
		name: '',
		height: (486).toFixed(2),
		width: (773).toFixed(2),
		imageUrl: ''
	});
	const [hall, setHall] = useState({
		name: '',
		id: '',
		bg_image: ''
	});
	const [selectedItem, setSelectedItem] = useState({});
	const [selectedItemCopy, setSelectedItemCopy] = useState({});
	const [selectedColor, setSelectedColor] = useState({});

	const [showPalette, setShowPalette] = useState(false);

	const [items, setItems] = useState([]);
	const [itemsGroup, setItemsGroup] = useState([]);
	const [itemsUnGroup, setItemsUnGroup] = useState([]);

	async function saveCanvas() {
		var href = canvas.current.toDataURL({
			format: 'png',
			quality: 0.8
		});
		var hallCopy = { ...hall }
		hallCopy.schema = items;
		hallCopy.image = href;
		const response = await PUT('/halls', hallCopy, { loader: true })
		if (httpOk(response)) {
			// navigate('/halls')
		}
	}

	function changeActiveItem(item) {
		canvas.current.setActiveObject(item)
		canvas.current.renderAll()
		if (item.type === 'group' || item.type === 'table') {
			item.active = !item.active
		}
		setSelectedItem(item)
		changeSelectedItemCopy()
	}

	function changeColor(e) {
		selectedItem.set('fill', e.hex)
		setSelectedColor(e.hex)
		if (selectedItem.type === 'table') {
			selectedItem._objects[0].set('fill', e.hex)
		}
		canvas.current.renderAll();
	}

	function removeObject() {
		canvas.current.remove(canvas.current.getActiveObject());
		setSelectedItem({})
		setSelectedItemCopy({})
		canvas.current.renderAll();
	}

	function uploadImage(e, isBackgroung) {
		var reader = new FileReader();
		reader.onload = function (event) {
			var imgObj = new Image();
			imgObj.src = event.target.result;
			imgObj.onload = function () {
				var image = new fabric.Image(imgObj);
				if (!isBackgroung) {
					canvas.current.centerObject(image);
					image.set({
						name: 'image',
					});
					canvas.current.add(image);
					canvas.current.renderAll();
				} else {
					let scaleX = canvas.current.width / image.width;
					let scaleY = canvas.current.height / image.height;
					image.set({
						scaleX: scaleX,
						scaleY: scaleY
					});
					canvas.current.setBackgroundImage(image, canvas.current.renderAll.bind(canvas.current), {});
					setHall({ ...hall, bg_image: imgObj.src })
				}
			}
		}
		reader.readAsDataURL(e.target.files[0]);
	}

	function setBackgroundImage(src) {
		var imgObj = new Image();
		imgObj.src = src;
		var image = new fabric.Image(imgObj);
		let scaleX = canvas.current.width / image.width;
		let scaleY = canvas.current.height / image.height;
		image.set({
			scaleX: scaleX,
			scaleY: scaleY
		});
		canvas.current.setBackgroundImage(image, canvas.current.renderAll.bind(canvas.current), {});
	}

	function handleDropText(object) {
		return new fabric.IText(object.text, object);
	}

	function handleDropRect(object) {
		return new fabric.Rect(object);
	}

	function handleDropCircle(object) {
		return new fabric.Circle(object);
	}

	function handleDropTable(object, circle) {
		if (object._objects) {
			object.objects = object._objects
		}
		var groupCircle;
		var groupRect = new fabric.Rect(object.objects[0]);
		var groupText = new fabric.IText(object.objects[1].text, object.objects[1]);
		if (circle) {
			var obj = { ...object.objects[0] }
			obj.type = 'circle'
			obj.name = 'Круг'
			obj.radius = 50
			groupCircle = handleDropCircle(obj);
			removeObject()
			return new fabric.Group([groupCircle, groupText], {
				type: 'table',
				id: object.id,
				index: object.id,
				name: object.name,
				left: object.left,
				top: object.top,
			});

		} else if (circle === false) removeObject()
		return new fabric.Group([groupRect, groupText], {
			type: 'table',
			name: object.name,
			index: object.id,
			id: object.id,
			left: object.left,
			top: object.top,
		});
	}

	function handleDrop(type, object = {}, tableCircle = false) {
		if (type === 'text') {
			var text;
			if (!object.type) {
				text = handleDropText({
					type: 'text', name: 'Текст', text: 'Текст', left: 50, top: 50, fontSize: 20,
					fontWeight: '700', angle: 0, index: canvas.current.getActiveObjects().length + 1,
				})
			} else {
				text = handleDropText(object)
			}
			canvas.current.add(text);
		}
		if (type === 'rect') {
			var rect;
			if (!object.type) {
				rect = handleDropRect({
					type: 'rect', name: 'Прямоугольник', height: 80, width: 80, fill: 'yellow',
					index: canvas.current.getActiveObjects().length + 1, left: 100, top: 50,
				})
			} else {
				rect = handleDropRect(object);
			}
			object.name = 'Прямоугольник'
			canvas.current.add(rect);
		}
		if (type === 'circle') {
			var circle
			if (!object.type) {
				circle = handleDropCircle({
					type: 'circle', name: 'Круг', radius: 50, fill: 'yellow', stroke: 'transparent', strokeWidth: 0,
					index: canvas.current.getActiveObjects().length + 1, left: 100, top: 50,
				})
			} else {
				object.name = 'Круг'
				circle = handleDropCircle(object);
			}
			canvas.current.add(circle);
		}
		if (type === 'table') {
			var group;
			if (!object.type) {
				group = handleDropTable(
					{
						top: 100, left: 100, name: object.name, id: object.id, index: object.id, objects: [
							{
								type: 'rect', name: 'Стол', height: 99, width: 99, fill: 'yellow',
								index: object.name, originX: "center", originY: "center",
							},
							{
								type: 'text', name: 'Текст', fontSize: 20, fontWeight: '700', angle: 0, text: object.name,
								index: canvas.current.getActiveObjects().length + 1, originX: "center", originY: "center",
							},
						]
					}
				)
			} else {
				group = handleDropTable(object, tableCircle)
			}
			canvas.current.add(group);
			if (tableCircle) {
				changeActiveItem(group)
			}
		}
		if (type === 'group') {
			var arr = [];
			for (let i = 0; i < object.objects.length; i++) {
				if (object.objects[i]['type'] === 'text') {
					arr.push(handleDropText(object.objects[i]))
				}
				if (object.objects[i]['type'] === 'rect') {
					arr.push(handleDropRect(object.objects[i]))
				}
				if (object.objects[i]['type'] === 'circle') {
					arr.push(handleDropCircle(object.objects[i]))
				}
				if (object.objects[i]['type'] === 'table') {
					arr.push(handleDropTable(object.objects[i]))
				}
			}
			canvas.current.add(new fabric.Group(arr, {
				type: 'group',
				name: 'Группа',
				left: object.left,
				top: object.top,
			}));
		}
		setItems(canvas?.current?.getObjects())
		canvas.current.renderAll();
	}

	function groupItems() {
		if (canvas?.current?.getActiveObject()) {
			canvas.current.getActiveObject().toGroup()
			var obj = canvas.current.getActiveObject();
			obj.set('name', 'Группа')
			obj.set('type', 'group')
			setItems(canvas.current.getObjects())
		}
		if (canvas.current?.getActiveObjects()[0]?._objects?.length > 0) {
			setItemsUnGroup(canvas.current?.getActiveObjects()[0]?._objects)
		}
		setItemsGroup([])
		canvas.current.renderAll();
	}

	function unGroupItems() {
		if (canvas?.current?.getActiveObject()) {
			canvas?.current?.getActiveObject().toActiveSelection()
			setItemsUnGroup([])
			setItems(canvas?.current?.getObjects())
		}
		canvas.current.renderAll();
	}

	function selectHandler(e) {
		if (e.target) {
			setSelectedItem(e.target)
			changeSelectedItemCopy()
		} else {
			setSelectedItem({})
			setSelectedItemCopy({})
			setItemsUnGroup([])
		}
	}

	function sendObject(type) {
		if (type === 'inrcement') {
			canvas.current.moveTo(selectedItem, selectedItem.index + 1);
			selectedItem.index += 1
		}
		if (type === 'decrement') {
			canvas.current.moveTo(selectedItem, selectedItem.index - 2);
			selectedItem.index -= 1
		}
		canvas.current.renderAll();
	}

	function handleSelection(obj) {
		if (obj?.selected) {
			if (obj?.selected[0]._objects?.length > 1) {
				setSelectedItem(obj?.selected[0])
				setItemsUnGroup(obj?.selected[0]._objects)
			} else {
				setItemsUnGroup([])
			}
		}
		if (canvas?.current?.getActiveObjects()?.length > 1) {
			setItemsGroup(canvas.current.getActiveObjects())
		} else {
			setItemsGroup([])
		}
	}

	function changeSelectedItemCopy(e) {
		var object = { ...canvas.current.getActiveObject() }
		if (e === undefined || e.action === 'drag') {
			object.top = Math.ceil(Number(object.top))
			object.left = Math.ceil(Number(object.left))
		}
		if (e === undefined || e.action === 'scale') {
			object.width = Math.ceil(Number(object.width * (object.scaleX ?? 1)))
			object.height = Math.ceil(Number(object.height * (object.scaleY ?? 1)))
		}
		setSelectedItemCopy(object)
	}

	function changeSelectedItemValue(e, isNumber = true) {
		if (!e.target.value) {
			return;
		}
		selectedItem.set(e.target.name, isNumber ? Number(e.target.value) : e.target.value)
		var copy = { ...selectedItemCopy }
		copy[e.target.name] = e.target.value
		changeSelectedItemCopy()
		if (selectedItem.type === 'table') {
			if (e.target.name === 'height' || e.target.name === 'width') {
				selectedItem._objects[0].set(e.target.name, Number(e.target.value))
			}
		}
		canvas.current.renderAll();
	}

	function changeCanvasData(e) {
		var copy = { ...canvasData }
		copy[e.target.name] = e.target.value
		if (e.target.name !== 'name') {
			if (e.target.name === 'width') {
				canvas.current.setWidth(Number(e.target.value));
			}
			if (e.target.name === 'height') {
				canvas.current.setHeight(Number(e.target.value));
			}
		}
		setCanvasData(copy)
		canvas.current.renderAll();
	}

	function initCanvas() {
		canvas.current = new fabric.Canvas('canvas', {
			height: canvasData.height,
			width: canvasData.width,
			backgroundColor: '#eee',
			selection: true,
			renderOnAddRemove: true,
		})
		canvas.current.on({
			// 'drop': handleDrop,
			// 'selected': selectHandler,
			// 'text:editing:entered': selectHandler,
			'mouse:down': selectHandler,
			'selection:created': handleSelection,
			'selection:updated': handleSelection,
			'selection:cleared': handleSelection,
			'object:modified': changeSelectedItemCopy,
		});
	};

	async function getTables() {
		const response = await GET(`/helper/tables/${id}`)
		if (httpOk(response)) {
			for (let i = 0; i < response.data.length; i++) {
				console.log(response.data)
				response.data[i]['type'] = null
				handleDrop('table', response.data[i])
			}
		}
	}

	async function getHall() {
		const response = await GET('/halls/' + id)
		if (httpOk(response)) {
			setHall(response.data)
			if (response.data.bg_image) {
				setBackgroundImage(response.data.bg_image)
			}
			if (response.data?.schema) {
				setItems(response.data?.schema)
				for (let i = 0; i < response.data?.schema.length; i++) {
					if (response.data?.schema[i]['type'] === 'table') {
						response.data.schema[i].name = 'Стол'
					}
					handleDrop(response.data?.schema[i]['type'], response.data?.schema[i])
				}
				setItems(canvas?.current?.getObjects())
				canvas.current.renderAll();
			} else {
				getTables()
			}
		}
	}

	useEffect(() => {
		initCanvas()
		getHall()
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		function handleClickOutside(event) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setShowPalette(false)
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef]);

	return (
		<>
			<div className="p-3">
				<div className="canvas-input-buttons">
					{/* <i title="Добавить стол" className="uil uil-table canvas-input-button" onClick={() => handleDrop('table')}></i> */}
					<CustomTooltip text={t('Добавить прямоугольник')}>
						<i className="uil uil-square canvas-input-button" onClick={() => handleDrop('rect')}></i>
					</CustomTooltip>
					<CustomTooltip text={t('Добавить круг')}>
						<i className="uil uil-circle canvas-input-button" onClick={() => handleDrop('circle')}></i>
					</CustomTooltip>
					<CustomTooltip text={t('Добавить текст')}>
						<i className="uil uil-font canvas-input-button" onClick={() => handleDrop('text')}></i>
					</CustomTooltip>

					<div>
						<CustomTooltip text={t('Добавить фотографию')}>
							<i className="uil uil-image-v canvas-input-button" onClick={() => inputFile.current.click()}></i>
						</CustomTooltip>
						<input type='file' id='file' style={{ display: 'none' }}
							ref={inputFile} onChange={(e) => uploadImage(e, false)}
							accept="image/png, image/jpeg" />
					</div>
					<div>
						<CustomTooltip text={t('Добавить задний фон')}>
							<i className="uil uil-inbox canvas-input-button" onClick={() => inputBgFile.current.click()}></i>
						</CustomTooltip>
						<input type='file' id='file' style={{ display: 'none' }}
							ref={inputBgFile} onChange={(e) => uploadImage(e, true)}
							accept="image/png, image/jpeg" />
					</div>
					<CustomTooltip text={t('Сгруппировать')}>
						{itemsGroup.length > 0 &&
							<i className="uil uil-layer-group canvas-input-button" onClick={() => groupItems()}></i>
						}
					</CustomTooltip>
					<CustomTooltip text={t('Разгруппировать')}>
						{(itemsUnGroup.length > 0 && selectedItem.type !== 'table') &&
							<i className="uil uil-object-ungroup canvas-input-button" onClick={() => unGroupItems()}></i>
						}
					</CustomTooltip>
				</div>
				<div id="canvas-container">
					<canvas id="canvas" ref={canvas}></canvas>
				</div>
				<div className="canvas-right-menu">
					<div>
						{selectedItem.name ?
							<div className="canvas-settings">
								<div className="canvas-right-menu-buttons">
									<div ref={wrapperRef}>
										<i className="uil uil-palette canvas-input-button" onClick={() => setShowPalette(!showPalette)}></i>
										{showPalette &&
											<div className="color-picker" >
												<SketchPicker onChange={(e) => changeColor(e)} color={selectedColor} />
											</div>
										}
									</div>
									{selectedItem.type !== 'table' &&
										<i className="uil uil-times canvas-input-button" onClick={() => removeObject()}></i>
									}
									<i className="uil uil-arrow-up canvas-input-button" onClick={() => sendObject('inrcement')}></i>
									<i className="uil uil-arrow-down canvas-input-button" onClick={() => sendObject('decrement')}></i>
									{(selectedItem.type === 'table' && selectedItem._objects[0].type === 'rect') &&
										<i className="uil uil-circle canvas-input-button" onClick={() => handleDrop('table', selectedItem, true)}></i>
									}
									{(selectedItem.type === 'table' && selectedItem._objects[0].type === 'circle') &&
										<i className="uil uil-square canvas-input-button" onClick={() => handleDrop('table', selectedItem)}></i>
									}
								</div>
								<div>
									{selectedItem.type === 'text' ?
										<div className="canvas-input">
											<label htmlFor="text">Название</label>
											<input type="text" name="text"
												autoComplete="off"
												onChange={(e) => changeSelectedItemValue(e, false)} value={selectedItemCopy.text} />
										</div>
										:
										<div className="canvas-input">
											<label htmlFor="name">Название</label>
											<input type="text" name="name"
												autoComplete="off"
												onChange={(e) => changeSelectedItemValue(e, false)} value={selectedItemCopy.name} disabled />
										</div>
									}
									{selectedItem.type === 'circle' &&
										<>
											<div className="canvas-input">
												<label htmlFor="name">Радиус</label>
												<input type="number" name="radius"
													onChange={(e) => changeSelectedItemValue(e)} value={selectedItemCopy.radius} />
											</div>
											<div className="d-flex">
												<div className="canvas-input">
													<label htmlFor="strokeWidth">Ширина границ</label>
													<input type="number" name="strokeWidth"
														onChange={(e) => changeSelectedItemValue(e)} value={selectedItemCopy.strokeWidth} />
												</div>
												<div className="canvas-input">
													<label htmlFor="stroke">Цвет границ</label>
													<input type="text" name="stroke"
														onChange={(e) => changeSelectedItemValue(e, false)} value={selectedItemCopy.stroke} />
												</div>
											</div>
										</>
									}
									{selectedItem.type === 'text' &&
										<div className="d-flex">
											<div className="canvas-input me-2">
												<label htmlFor="name">Размер шрифта</label>
												<input type="number" name="fontSize"
													onChange={(e) => changeSelectedItemValue(e)} value={selectedItemCopy.fontSize} />
											</div>
											<div className="canvas-input">
												<label htmlFor="name">Толщина шрифта</label>
												<input type="number" name="fontWeight"
													onChange={(e) => changeSelectedItemValue(e)} value={selectedItemCopy.fontWeight} />
											</div>
										</div>
									}
									{(selectedItem.type === 'rect' || selectedItem.type === 'table') &&
										<div className="d-flex">
											<div className="canvas-input me-2">
												<label htmlFor="name">Ширина</label>
												<input type="number" name="width"
													onChange={(e) => changeSelectedItemValue(e)} value={selectedItemCopy.width} />
											</div>
											<div className="canvas-input">
												<label htmlFor="name">Высота</label>
												<input type="number" name="height"
													onChange={(e) => changeSelectedItemValue(e)} value={selectedItemCopy.height} />
											</div>
										</div>
									}
									<div className="d-flex">
										<div className="canvas-input me-2">
											<label htmlFor="name">Отступ сверху</label>
											<input type="number" name="top"
												value={selectedItemCopy.top ?? ''}
												onChange={(e) => changeSelectedItemValue(e)} />
										</div>
										<div className="canvas-input">
											<label htmlFor="name">Отступ слева</label>
											<input type="number" name="left"
												value={selectedItemCopy.left ?? ''}
												onChange={(e) => changeSelectedItemValue(e)} />
										</div>
									</div>
									<div className="canvas-input">
										<label htmlFor="name">Поворот(градусы)</label>
										<input type="number" name="angle"
											value={selectedItemCopy.angle ?? ''}
											onChange={(e) => changeSelectedItemValue(e)} />
									</div>
								</div>
							</div>
							:
							<div className="canvas-settings">
								<div className="canvas-input">
									<label htmlFor="name">Название</label>
									<input type="text" name="name"
										onChange={(e) => changeCanvasData(e)} value={canvasData.name} />
								</div>
							</div>
						}
						<div className="canvas-list">
							{items?.map((item, index) => (
								<div className={"canvas-item " + (item.active ? 'active' : '')} key={index}>
									<div className="d-flex align-items-center" onClick={() => changeActiveItem(item)}>
										<div>
											{item?.name}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					<button type="button" className="btn btn-primary w-100 mt-3" onClick={() => saveCanvas()}>
						{t('save')}
					</button>
				</div>
			</div>
		</>
	)
}

export default Scheme
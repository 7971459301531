import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { SketchPicker } from "@hello-pangea/color-picker";

import { GET, PUT, httpOk } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const colorRef = useRef(null);
	const bgColorRef = useRef(null);

	const [menu, setMenu] = useState([])
	const [products, setProducts] = useState()
	const [selectedItem, setSelectedItem] = useState({
		color: '',
		bg_color: '',
		order_number: '',
	})

	async function update() {
		var menuCopy = [...menu]
		if (selectedItem.type === "menu") {
			await PUT('/menu-order-color', selectedItem, { loader: true })
			menuCopy[selectedItem.index] = selectedItem
			var sortedMenu = menuCopy.sort(compareAge)

			setMenu(sortedMenu)
		}

		if (selectedItem.type === "product") {
			var productsCopy = [...products]

			await PUT('/products-order-color', selectedItem, { loader: true })
			productsCopy[selectedItem.index] = selectedItem
			var sortedProducts = productsCopy.sort(compareAge)

			setProducts(sortedProducts)

			for (let i = 0; i < menuCopy.length; i++) {
				for (let j = 0; j < menuCopy[i]['products'].length; j++) {
					if (menuCopy[i]['products'][j]['id'] === selectedItem.id) {
						menuCopy[i]['products'][j] = selectedItem
					}
				}
			}

			setMenu(menuCopy)
		}
	}

	function compareAge(a, b) {
		return a.order_number - b.order_number;
	}

	function selectMenu(item, index) {
		var menuCopy = [...menu]
		for (let i = 0; i < menuCopy.length; i++) {
			if (i === index) {
				menuCopy[i]['active'] = true
				setProducts(menuCopy[i]['products'])
			} else {
				menuCopy[i]['active'] = false
			}
		}
		menuCopy[index]['products'] = menuCopy[index]['products'].sort(compareAge)

		setMenu(menuCopy)
		var itemCopy = { ...item }
		itemCopy.color_bool = false
		itemCopy.bg_color_bool = false
		itemCopy.color = itemCopy.color ? itemCopy.color : ''
		itemCopy.bg_color = itemCopy.bg_color ? itemCopy.bg_color : ''
		itemCopy.order_number = itemCopy.order_number ? itemCopy.order_number : ''
		itemCopy.index = index
		itemCopy.type = 'menu'
		setSelectedItem(itemCopy)
	}

	function selectProduct(item, index) {
		var itemCopy = { ...item }
		itemCopy.color_bool = false
		itemCopy.bg_color_bool = false
		itemCopy.color = itemCopy.color ? itemCopy.color : ''
		itemCopy.bg_color = itemCopy.bg_color ? itemCopy.bg_color : ''
		itemCopy.order_number = itemCopy.order_number ? itemCopy.order_number : ''
		itemCopy.index = index
		itemCopy.type = 'product'
		setSelectedItem(itemCopy)
	}

	async function getMenu() {
		const response = await GET('/helper/menu-products', {}, { loader: true })
		if (httpOk(response)) {
			setMenu(response.data)
		}
	}

	function handleInputChange(key, value) {
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy[key] = value
		setSelectedItem(selectedItemCopy)
	}

	function handleChangeBgColor(color) {
		handleInputChange('bg_color', color.hex)
	};

	function handleChangeColor(color) {
		handleInputChange('color', color.hex)
	};

	useEffect(() => {
		getMenu()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = () => {
		setSelectedItem({ ...selectedItem, 'color_bool': false, 'bg_color_bool': false })
	};

	useEffect(() => {
		function handleClickOutside(event) {
			if (colorRef.current && !colorRef.current.contains(event.target)) {
				handleClose()
			}
			if (bgColorRef.current && !bgColorRef.current.contains(event.target)) {
				handleClose()
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [selectedItem, colorRef, bgColorRef]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					{true &&
						<>
							<div className="d-flex">
								<div className="menu-box"
									style={{
										'backgroundColor': (selectedItem.bg_color ? selectedItem.bg_color : '#eee'),
										'color': (selectedItem.color ? selectedItem.color : '#1e1d1d')
									}}>
									{selectedItem.name}
								</div>
								<div>
									<div className="d-flex gap-2">
										<div className="form-group">
											<div className="position-relative">
												<input type="text" className="form-control"
													placeholder={t('color')}
													value={selectedItem.color} onChange={(e) => handleInputChange('color', e.target.value)} />
												<div className="color-picker-icon" style={{ backgroundColor: selectedItem.color }}
													onClick={() => setSelectedItem({ ...selectedItem, 'color_bool': !selectedItem.color_bool })}
												/>
											</div>
											{selectedItem.color_bool &&
												<div ref={colorRef}>
													<SketchPicker
														className="position-absolute"
														onChangeComplete={handleChangeColor}
													/>
												</div>
											}
										</div>
										<div className="form-group me-2">
											<div className="position-relative">
												<input type="text" className="form-control"
													placeholder={t('bg_color')}
													value={selectedItem.bg_color} onChange={(e) => handleInputChange('bg_color', e.target.value)} />
												<div className="color-picker-icon" style={{ backgroundColor: selectedItem.bg_color }}
													onClick={() => setSelectedItem({ ...selectedItem, 'bg_color_bool': !selectedItem.bg_color_bool })}
												/>
											</div>
											{selectedItem.bg_color_bool &&
												<div ref={bgColorRef}>
													<SketchPicker
														className="position-absolute"
														onChangeComplete={handleChangeBgColor}
														color={selectedItem.bg_color}
													/>
												</div>
											}
										</div>
									</div>
									<div className="d-flex">
										<div className="form-group me-2">
											<input type="number" className="form-control"
												placeholder={t('order2')}
												value={selectedItem.order_number}
												onChange={(e) => handleInputChange('order_number', e.target.value)} />
										</div>
										<div className="form-group">
											<button className="btn btn-success" onClick={() => update()}>{t('save')}</button>
										</div>
									</div>
								</div>
							</div>
							<hr />
						</>
					}

					<div className="d-flex">
						<div className="menu-wrapper">
							{menu.map((item, index) => (
								<div className="menu-box"
									key={index}
									onClick={() => selectMenu(item, index)}
									style={{
										'backgroundColor': (item.bg_color ? item.bg_color : '#eee'),
										'color': (item.color ? item.color : '#1e1d1d')
									}}>
									{item.name}
								</div>
							))
							}
						</div>

						<div className="menu-wrapper">
							{products?.map((item, index) => (
								<div className="menu-box"
									key={index}
									onClick={() => selectProduct(item, index)}
									style={{
										'backgroundColor': (item.bg_color ? item.bg_color : '#8A61FF'),
										'color': (item.color ? item.color : '#fff')
									}}>
									{item.name}
								</div>
							))
							}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
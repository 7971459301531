import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { GET, POST, DELETE, httpOk } from 'helpers/api'

function Modifiers({
	showModifierModal,
	setShowModifierModal,
	showAddModifierModal,
	setShowAddModifierModal,
	addModifierToProduct,
	activeTab,
}) {
	const { t } = useTranslation()

	const [data, setData] = useState({})
	const [modifiers, setModifiers] = useState([])
	const [modifierProducts, setModifierProducts] = useState([])
	const [activeSection, setActiveSection] = useState(0)
	const [selectedModifier, setSelectedModifier] = useState({})
	const [productModifiers, setProductModifiers] = useState({
		'parent': false,
		'modifiers': []
	})

	async function createUpdate() {
		await POST('/modifiers', data, { loader: true })
		setData({ ...data, 'name': '' })
		getModifiers()
	}

	async function addModifierToProductHOC() {
		addModifierToProduct(productModifiers)
		setProductModifiers({
			'parent': false,
			'modifiers': []
		})
	}

	function selectModifierToProduct(item) {
		var productModifiersCopy = { ...productModifiers }

		var error = !!productModifiers?.modifiers?.find((element) => element.id === item.id)
		if (error) {
			toast.error('Одинаковые модификаторы')
			return
		}

		productModifiersCopy.modifiers.push(item)
		setProductModifiers(productModifiersCopy)
	}

	function selectModifier(item) {
		setSelectedModifier(item)
		setActiveSection(2)
	}

	async function selectProductToModifier(item) {
		var selectedModifierCopy = { ...selectedModifier }
		var sendData = {
			'parent_id': selectedModifierCopy.id,
			'product_id': item.id,
		}
		await POST('/modifiers-product', sendData, { loader: true })
		getModifiers()
	}

	async function deleteModifier(item) {
		await DELETE('/modifiers', { 'id': item.id }, { loader: true })
		getModifiers()
	}

	function addModifierModal(bool) {
		if (!bool) {
			setShowAddModifierModal(false)
			setProductModifiers({
				'parent': false,
				'modifiers': []
			})
		}
	}

	function existInSelected(item) {
		var bool = false
		bool = !!productModifiers?.modifiers?.find((element) => element.id === item.id)
		return bool
	}

	async function getModifiers() {
		const response = await GET('/modifiers')
		if (httpOk(response)) setModifiers(response.data)
	}

	async function getModifierProducts() {
		const response = await GET('/helper/product-modifiers')
		if (httpOk(response)) setModifierProducts(response.data)
	}

	useEffect(() => {
		if (activeTab === 2) {
			getModifiers()
			getModifierProducts()
		}
	}, [activeTab]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{/* MODAL MODIFIER */}
			<Modal show={showModifierModal} animation={false} centered size="lg"
				backdrop="static" onHide={() => setShowModifierModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						<div className="d-flex">
							<div className="vertical-center me-2">{t('modifiers')}</div>
							<button type="button" className="btn-sm btn-primary"
								onClick={() => setActiveSection(1)}>
								{t('create')}
							</button>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-md-6">
							{modifiers.map((item, index) => (
								<div key={index}
									style={{ 'borderBottom': '1px solid #eee' }}
									onClick={() => selectModifier(item)}>
									<div className={'cursor ' + (selectedModifier.id === item.id ? 'table-tr-active' : '')}>
										<div className="vertical-center">
											<b className="py-1">{index + 1}. {item.name}</b>
										</div>
									</div>
									{item?.products?.map((product, productIndex) => (
										<div className="ms-4 py-1" key={productIndex}>
											<div className="d-flex justify-content-between">
												<div className="vertical-center">
													- {product.name}
												</div>
												<div className="d-flex justify-content-center" onClick={() => deleteModifier(product)}>
													<div className="table-action-button table-action-danger-button">
														<i className="uil uil-times"></i>
													</div>
												</div>
											</div>
										</div>
									))
									}
								</div>
							))}
						</div>
						<div className="col-md-6">
							{activeSection === 1 &&
								<div>
									<input type="text" className="form-control mb-2"
										placeholder={t('name2')}
										value={data.name}
										onChange={(e) => setData({ ...data, 'name': e.target.value })} />
									<button type="button" className="btn btn-primary w-100"
										onClick={() => createUpdate()}>
										{t('save')}
									</button>
								</div>
							}
							{activeSection === 2 &&
								<>
									{modifierProducts.map((item, index) => (
										<div className="py-1" key={index}>
											<div className="d-flex justify-content-between">
												<div className="vertical-center">
													{item.name}
												</div>
												<div className="d-flex justify-content-center" onClick={() => selectProductToModifier(item)}>
													<div className="table-action-button table-action-primary-button">
														<i className="uil uil-plus"></i>
													</div>
												</div>
											</div>
										</div>
									))
									}
								</>
							}
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL MODIFIER */}

			{/* MODAL ADD MODIFIER PRODUCT */}
			<Modal show={showAddModifierModal} animation={false} centered
				backdrop="static" onHide={() => addModifierModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						<div>{t('modifiers')}</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between mb-2">
						<p className="fw-600 vertical-center">{t('main')}</p>
						<div className="form-check form-switch form-switch-lg">
							<input type="checkbox" className="form-check-input"
								checked={productModifiers?.parent}
								onChange={(e) => setProductModifiers({ ...productModifiers, 'parent': e.target.checked, 'modifiers': [] })} />
						</div>
					</div>
					{modifiers.map((item, index) => (
						<div key={index} style={{ 'borderBottom': '1px solid #000' }}>
							<div className="vertical-center" onClick={() => selectModifierToProduct(item)}>
								<div className={
									(!productModifiers?.parent ? 'modifier-inactive ' : 'modifier-active ') +
									(existInSelected(item) ? 'selected ' : '')}>
									{index + 1}. {item.name}
								</div>
							</div>
							{item?.products?.map((product, productIndex) => (
								<div key={productIndex}
									onClick={() => selectModifierToProduct(product)}
									className={'ms-4 ' +
										(productModifiers?.parent ? 'modifier-inactive ' : 'modifier-active ') +
										(existInSelected(product) ? 'selected ' : '')
									}>
									- {product.name}
								</div>
							))
							}
						</div>
					))
					}
					<button type="button" className="btn btn-primary w-100 mt-3"
						onClick={() => addModifierToProductHOC()}>
						{t('save')}
					</button>
				</Modal.Body>
			</Modal>
			{/* MODAL ADD MODIFIER PRODUCT */}
		</>
	)
}

export default Modifiers
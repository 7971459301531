import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next';

function ReactCustomSelect({
	table,
	value,
	scrollRef,
	options,
	index,
	search,
	onKeyUp,
	selectItem,
	onClose,
	isDisabled = false,
}) {
	const { t } = useTranslation()

	const wrapperRef = useRef(null);
	const inputRef = useRef(null);

	const [searchValue, setSearchValue] = useState('')
	const [showMenu, setShowMenu] = useState(false)

	const closeReactSelect = useCallback(() => {
		setSearchValue('')
		setShowMenu(false)
		if (onClose) onClose()
	}, [onClose]);

	function onKeyUpMiddleWare(e) {
		if (showMenu) {
			if (e.keyCode === 27) {
				closeReactSelect()
			}
			if (e.keyCode === 13) {
				closeReactSelect()
				selectItem(options.find(el => el.selected))
			}
			onKeyUp(e)
		}
	}

	function searchMiddleWare(value) {
		setSearchValue(value)
		search(value)
		setShowMenu(!!value)

		setTimeout(() => {
			const selectMenu = document.getElementById(`test${index}`);
			const parentContainer = document.getElementById(`${index + 1}`);
			const parentRect = parentContainer.getBoundingClientRect();
			if (selectMenu) {
				selectMenu.style.top = `${parentRect.top + parentRect.height}px`;
				selectMenu.style.left = `${parentRect.left}px`;
				selectMenu.style.width = `${parentContainer.offsetWidth}px`;
			}
		}, 10);
	}

	function openReactSelect() {
		inputRef?.current?.focus()
	}

	useEffect(() => {
		closeReactSelect()
	}, [value]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		function handleClickOutside(event) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target) && closeReactSelect) {
				closeReactSelect();
			}
		}

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef, closeReactSelect]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="custom-select" ref={wrapperRef}>
			<div className="custom-select-field"
				onClick={() => openReactSelect()}>
				<span className="value">
					{value}
				</span>
				<input
					disabled={isDisabled}
					id={index + 1}
					type="text"
					ref={inputRef}
					className={` ${showMenu ? 'active' : ''}`}
					value={searchValue ?? ''}
					onChange={(e) => searchMiddleWare(e.target.value)}
					size={searchValue.length}
					onKeyUp={(e) => onKeyUpMiddleWare(e)}
					autoComplete="off"
				/>
				<div className="icon" onClick={() => setShowMenu(!showMenu)}>
					<i className="uil uil-angle-down"></i>
				</div>
			</div>

			{showMenu &&
				<div className={`custom-select-menu ${showMenu ? 'active' : ''}`}
					id={`test${index}`}
					ref={scrollRef}>
					{options?.length ?
						<div>{table}</div>
						:
						<h6 className="text-center py-3">
							{t('list_empty')}
						</h6>
					}
				</div>
			}
		</div>
	)
}

export default ReactCustomSelect
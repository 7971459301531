import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import DeleteModal from 'pages/parts/DeleteModal'

import { GET, DELETE, httpOk } from "helpers/api"
import { formatMoney, formatDate } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Preview() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const [data, setData] = useState({})
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })

	async function deleteItem() {
		const response = await DELETE(`/documents-preparation-element/${deleteModalParams.id}`, {}, { loader: true })
		if (httpOk(response)) {
			getData()
			setModalDelete(false)
		}
	}

	function returnIngredients() {
		if (
			data.activeIndex !== null &&
			data.activeIndex >= 0 &&
			data.items[data.activeIndex]['ingredients'] &&
			data.items[data.activeIndex]['ingredients'].length
		) {
			return data.items[data.activeIndex]['ingredients']
		} else {
			return []
		}
	}

	async function getData() {
		const response = await GET(`/documents-preparation/${id}`, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('preparation')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('preview')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4 className="fw-600">{t('document_from')} {formatDate(data.created_at)}</h4>
					<div className="flex-column preview-info">
						<div className="d-flex gap-2">
							<div className="fw-600">{t('pos')}:</div>
							<div>{data.pos_name}</div>
						</div>
						<div className="d-flex gap-2">
							<div className="fw-600">{t('document')}:</div>
							<div>{data.document_number}</div>
						</div>
						<div className="d-flex gap-2">
							<div className="fw-600">{t('note')}:</div>
							<div>{data.note}</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card" style={{ 'height': 'calc(100vh - 240px)' }}>
				<div className="card-body">
					<div style={{ 'height': '45%', 'overflow': 'auto' }}>
						<div className="table-responsive h-100">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '20%' }}>{t('name3')}</th>
										<th style={{ 'width': '10%' }}>{t('type')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
										<th style={{ 'width': '5%' }} className="text-nowrap">{t('unit')}</th>
										<th style={{ 'width': '25%' }} className="text-end">
											{t('unit_cost')}
										</th>
										<th style={{ 'width': '25%' }} className="text-end">
											{t('total_cost_price')}
										</th>
										<th style={{ 'minWidth': '33px' }}></th>
									</tr>
								</thead>
								<tbody>
									{data?.items?.map((item, index) => (
										<tr key={index}>
											<td className="text-center"
												style={{ 'background': data.activeIndex === index ? '#c1c1c1' : '' }}
												onClick={() => setData({ ...data, 'activeIndex': index })}>
												<CustomTooltip text={item.id}>
													{index + 1}
												</CustomTooltip>
											</td>
											<td onClick={() => setData({ ...data, 'activeIndex': index })}>
												{item.name}
											</td>
											<td onClick={() => setData({ ...data, 'activeIndex': index })}>
												{item.product_type_name}
											</td>
											<td className="text-end"
												onClick={() => setData({ ...data, 'activeIndex': index })}>
												{item.quantity}
											</td>
											<td className="text-start">{item.unit_name}</td>
											<td className="text-end">{formatMoney(item.cost_price, 2)}</td>
											<td className="text-end">{formatMoney(item.total_cost_price, 2)}</td>
											<td>
												<span onClick={setDeleteModalParams}></span>
											</td>
										</tr>
									))}
									<tr>
										<td></td>
										<td><b>{t('total')}</b></td>
										<td></td>
										<td className="text-end">
											<b>{data.total_quantity}</b>
										</td>
										<td></td>
										<td></td>
										<td className="text-end">
											<b>{data.total_amount}</b>
										</td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className="position-relative" style={{ 'height': '3%' }}>
						<span style={{ 'position': 'absolute', 'top': '-10px' }}>{t('ingredients')}</span>
						<hr />
					</div>
					<div style={{ 'height': '45%', 'overflow': 'auto' }}>
						<div className="table-responsive h-100">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '20%' }}>{t('name3')}</th>
										<th style={{ 'width': '10%' }}>{t('type')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
										<th style={{ 'width': '5%' }}>{t('unit')}</th>
										<th style={{ 'width': '25%' }} className="text-end">{t('unit_cost')}</th>
										<th style={{ 'width': '25%' }} className="text-end">{t('total_cost_price')}</th>
										<th style={{ 'minWidth': '33px' }}></th>
									</tr>
								</thead>
								<tbody>
									{returnIngredients().map((item, index) => (
										<tr key={index}>
											<td className="text-center">{index + 1}</td>
											<td>{item.name}</td>
											<td>{item.product_type_name}</td>
											<td className="text-end">{formatMoney(item.quantity, 2)}</td>
											<td>{item.unit_name}</td>
											<td className="text-end">{formatMoney(item.cost_price, 2)}</td>
											<td className="text-end">{formatMoney(item.cost_price * item.quantity, 2)}</td>
											<td></td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>

					<div className="d-flex justify-content-end">
						<button type="button" className="btn btn-warning btn-rounded btn-wide"
							onClick={() => navigate(-1)}>
							{t('cancel')}
						</button>
					</div>
				</div>
			</div>

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Preview
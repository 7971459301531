import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'; // eslint-disable-line no-unused-vars
import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { findKeyFromArrayByValue, formatDate, formatMoney, returnSign } from 'helpers/helpers';
import { GET, httpOk, POST } from 'helpers/api';

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const socketRef = useRef(null)

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		'start_date': formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		'end_date': formatDate(new Date(), 'yyyy-MM-dd'),
		'pos_id': reduxAccount.pos_id,
	})
	const [pagination, setPagination] = useState({
		url: '/synchronizations',
		response: null
	})

	async function searchWithFilter() {
		var filterUrl = "/synchronizations"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/synchronizations${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function sync() {
		var response = await POST('/synchronizations', { 'pos_id': filterData.pos_id }, { loader: true })

		if (httpOk(response)) searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function resetFilter() {
		setFilterData({
			pos_id: reduxAccount.pos_id,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function connectSocket() {
		var wsHost;
		var authEndpoint;
		var isProd;

		if (process.env.NODE_ENV === 'production') {
			wsHost = process.env.REACT_APP_PROD_WSHOST
			authEndpoint = process.env.REACT_APP_PROD_BROADCAST_AUTH
			isProd = true
		} else {
			wsHost = process.env.REACT_APP_DEV_WSHOST
			authEndpoint = process.env.REACT_APP_DEV_BROADCAST_AUTH
			isProd = false
		}

		if (socketRef.current) {
			socketRef.current.leave(`user.${filterData.pos_id}`);
		}

		try {
			socketRef.current = new Echo({
				broadcaster: 'pusher',
				key: "mison_key",
				wsHost: wsHost,
				wsPort: 6001,
				wssPort: 6001,
				enabledTransports: ['ws', 'wss'],
				disableStats: true,
				encrypted: isProd ? true : false,
				useTLS: isProd ? true : false,
				forceTLS: isProd ? true : false,
				authEndpoint: authEndpoint,
				auth: {
					headers: {
						'Authorization': `Bearer ${reduxAccount.token}`
					}
				},
			});
		} catch (error) {
			console.log(error)
		}

		socketRef.current.private(`user.${filterData.pos_id}`)
			.listen('Connection', (payload) => {
				console.log('payload', payload)

				if (payload.type === 'synchronizationResult') {
					searchWithFilter()
				}
			});
	}

	function returnClassStatus(status) {
		if (status === -1) {
			return "bg-danger"
		}
		if (status === 1) {
			return "bg-success"
		}
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		getPoses()
		connectSocket()

		return () => {
			if (socketRef.current) {
				socketRef.current.disconnect()
			}
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('synchronizations')} [{formatMoney(data?.total)}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => sync()}>
						{t('synchronize')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex flex-wrap mb-3 gap-2">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('pos')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.pos_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end gap-2">
												<button className="btn btn-secondary"
													onClick={() => setDropdownIsOpen(false)}>
													{t('cancel')}
												</button>
												<button className="btn btn-primary"
													onClick={() => searchWithFilter()}>
													{t('apply')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '15%' }}>{t('pos')}</th>
									<th style={{ 'width': '50%' }}>{t('note')}</th>
									<th style={{ 'width': '10%' }} className="text-center">ID</th>
									<th style={{ 'width': '15%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('status')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td>{item.pos_name}</td>
										<td className="td-ellipsis" title={item.message}>
											{item.message}
										</td>
										<td className="text-center">{item.id}</td>
										<td className="text-center">{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</td>
										<td className={returnClassStatus(item.status)}></td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>
		</>
	)
}

export default Index
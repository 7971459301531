import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, returnSign, findKeyFromArrayByValue, formatDate } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const reduxAccount = useSelector(state => state.account)

	const [poses, setPoses] = useState([])
	const [suppliers, setSuppliers] = useState([])
	const [elementTypes, setElementTypes] = useState([])
	const [categories, setCategories] = useState([])
	const [totalAmount, setTotalAmont] = useState(0)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
		supplier_id: null,
		category_id: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/report-incomes',
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = "/report-incomes"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.supplier_id)
			urlParams += returnSign(urlParams) + 'supplier_id=' + filterData.supplier_id
		if (filterData.category_id)
			urlParams += returnSign(urlParams) + 'category_id=' + filterData.category_id
		if (filterData.element_type)
			urlParams += returnSign(urlParams) + 'element_type=' + filterData.element_type
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/report-incomes${urlParams}` !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		if (tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = "/report-incomes-excel" + urlParams + returnSign(filterUrl)

			var excelHeaders = [[t('pos'), t('name2'), t('supplier'), t('quantity'), t('loss'),
			t('unit'), t('price'), t('receipt_date'), t('total_amount')]]
			var response = await GET(filterUrl)

			if (httpOk(response)) {
				response = response.data
				for (let i = 0; i < response.length; i++) {
					var excelItems = []
					excelItems.push(response[i].pos_name)
					excelItems.push(response[i].element_name)
					excelItems.push(response[i].supplier_name)
					excelItems.push(response[i].quantity)
					excelItems.push(response[i].loss)
					excelItems.push(response[i].unit_name)
					excelItems.push(response[i].price)
					excelItems.push(formatDate(response[i].created_at, 'dd.MM.yy HH:mm'))
					excelItems.push(response[i].price * response[i].quantity)
					excelHeaders.push(excelItems)
				}

				const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
				XLSX.writeFile(wb, t('income_report') + ".xlsx");
			}


		} else {
			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setData(response.data.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
				setTotalAmont(response.data.total_amount)
			}
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			start_date: null,
			end_date: null,
			pos_id: null,
			supplier_id: null,
			category_id: null,
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await GET("/report-incomes", {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data.data)
			setPagination({ ...pagination, 'url': "/report-incomes", 'response': response.data })
			setTotalAmont(response.data.total_amount)
		}
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.supplier_id === null &&
			filterData.category_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getSuppliers() {
		const response = await GET('/helper/suppliers')
		if (httpOk(response)) setSuppliers(response.data)
	}

	async function getCategories() {
		const response = await GET('/helper/categories')
		if (httpOk(response)) setCategories(response.data)
	}

	async function getElementTypes() {
		const response = await GET('/helper/products-element-types')
		if (httpOk(response)) setElementTypes(response.data)
	}

	function renderArrows(tableSortDirection, tableSortKey, type) {
		if (tableSortDirection === ',asc' && tableSortKey === type) {
			return (
				<i className="uil uil-angle-up sort-active"></i>
			)
		} else if (tableSortDirection === ',desc' && tableSortKey === type) {
			return (
				<i className="uil uil-angle-down sort-active"></i>
			)
		} else {
			return (
				<i className="uil uil-angle-down"></i>
			)
		}
	}

	useEffect(() => {
		getPoses()
		getSuppliers()
		getCategories()
		getElementTypes()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('good_reception')} [{pagination?.response?.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('supplier')}</label>
												<Select
													options={suppliers}
													value={suppliers.find(option => option.id === filterData.supplier_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'supplier_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('type')}</label>
												<Select
													isClearable
													options={elementTypes}
													value={elementTypes.find(option => option.id === filterData.element_type) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'element_type': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row d-none">
											<div className="form-group col-md-6">
												<label>{t('category')}</label>
												<Select
													options={categories}
													value={categories.find(option => option.id === filterData.category_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'category_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2" onClick={() => searchWithFilter(true)}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
						<div className="vertical-center">
							<h4>
								<b>{t('amount')}</b>: {formatMoney(totalAmount)}
							</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.supplier_id &&
									<span className="me-2">
										<b>{t('supplier')}: </b>
										{findKeyFromArrayByValue(suppliers, 'id', filterData.supplier_id, 'name')}
									</span>
								}
								{filterData.category_id &&
									<span className="me-2">
										<b>{t('category')}: </b>
										{findKeyFromArrayByValue(categories, 'id', filterData.category_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '33px' }} className="text-center">#</th>
									<th>{t('pos')}</th>
									<th>{t('name2')}</th>
									<th>{t('supplier')}</th>
									<th className="text-end">{t('quantity')}</th>
									<th className="text-end">{t('loss')}</th>
									<th className="text-center">{t('unit')}</th>
									<th className="th-sortable text-end" onClick={() => searchWithFilter(false, 'price')}>
										{t('price')}
										<div className="table-sort">
											{renderArrows(filterData.tableSortDirection, filterData.tableSortKey, 'price')}
										</div>
									</th>
									<th className="th-sortable text-center" onClick={() => searchWithFilter(false, 'created_at')}>
										{t('receipt_date')}
										<div className="table-sort">
											{renderArrows(filterData.tableSortDirection, filterData.tableSortKey, 'created_at')}
										</div>
									</th>
									<th className="th-sortable text-end" onClick={() => searchWithFilter(false, 'created_at')}>
										{t('total_amount')}
										<div className="table-sort">
											{renderArrows(filterData.tableSortDirection, filterData.tableSortKey, 'amount_out')}
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td className="text-center">
											<CustomTooltip text={`document_in_id: ${item.document_in_id}`}>
												{item.row_number}
											</CustomTooltip>
										</td>
										<td>{item.pos_name}</td>
										<td>
											<CustomTooltip text={`element_id: ${item.element_id}`}>
												{item.element_name}
											</CustomTooltip>
										</td>
										<td>{item.supplier_name}</td>
										<td className="text-end">{item.quantity}</td>
										<td className="text-end">{formatMoney(item.loss)}</td>
										<td className="text-center">{item.unit_name}</td>
										<td className="text-end">{formatMoney(item.price)}</td>
										<td className="text-center">{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-end">{formatMoney(item.price * item.quantity)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>
		</>
	)
}

export default Index
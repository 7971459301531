import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import ReactApexChart from "react-apexcharts"
import XLSX from 'xlsx';

import { GET, httpOk } from 'helpers/api'
import { formatMoney, returnSign, formatDate, findKeyFromArrayByValue } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Elements() {
	const { t } = useTranslation()
	const reduxAccount = useSelector(state => state.account)

	const date = new Date()
	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [modal, setModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
	})
	const [chartData, setChartData] = useState({
		series: [{
			name: t('price'),
			data: []
		}],
		options: {
			colors: ['#34c38f'],
			chart: {
				height: 350,
				type: 'area',
				toolbar: {
					show: false
				},
				background: '#fff',
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			yaxis: {
				// labels: {
				// 	formatter: function (value) {
				// 		return formatMoney(value, 0);
				// 	}
				// },
			},
			xaxis: {
				labels: {
					show: true,
				},
				tooltip: {
					enabled: false
				},
				categories: []
			},
			tooltip: {},
		},
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/dashboard-elements"
		var urlParams = ""

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		if (`/dashboard-elements${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl += urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)

			setDropdownIsOpen(false)
		}

	}

	async function exportToExcel() {
		var excelHeaders = [[
			t('pos'), t('name2'), t('current_price'), t('min_price'),
			t('max_price'), t('average_price'), '%'
		]]
		for (let i = 0; i < data.length; i++) {
			var item = data[i]
			var excelItems = []
			excelItems.push(item['pos_name'])
			excelItems.push(item['name'])
			excelItems.push(Number(item['current_price']))
			excelItems.push(Number(item['min_price']))
			excelItems.push(Number(item['max_price']))
			excelItems.push(Number(item['avg_price']))
			excelItems.push(Number(item['percentage']))
			excelHeaders.push(excelItems)
		}
		excelHeaders.push([])
		excelHeaders.push([])
		excelHeaders.push([`${t('date')}`, filterData.start_date, filterData.end_date])

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('element_analysis') + ".xlsx");
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: null,
		})
		searchWithFilter()
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function toggleModal(bool, item) {
		if (bool) {
			detail(item.id)
		} else {
			setModal(bool)
		}
	}

	async function detail(item) {
		var filterUrl = "/dashboard-elements-detail"
		var urlParams = ""

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (item.id)
			urlParams += returnSign(urlParams) + 'id=' + item.id

		filterUrl += urlParams

		const response = await GET(filterUrl, {}, { loader: true })

		if (httpOk(response)) {
			var chartDataCopy = { ...chartData }
			chartDataCopy.series[0]['data'] = response.data['series']
			chartDataCopy.options.xaxis.categories = response.data['categories']
			setChartData(chartDataCopy)

			setModal(true)
		}
	}

	function returnPercentageDiv(item) {
		if (Number(item.percentage) === 0) {
			return <div>{formatMoney(item.percentage)} %</div>
		}
		if (Number(item.percentage) > 0) {
			return <div className="nowrap text-danger">
				<i className="uil uil-arrow-growth"></i>
				{formatMoney(item.percentage)} %
			</div>
		}
		if (Number(item.percentage) < 0) {
			return <div className="nowrap text-success">
				<i className="uil uil-chart-down"></i>
				{formatMoney(item.percentage)} %
			</div>
		}

	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('element_analysis')} [{data.length}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide-2"
									onClick={exportToExcel}>
									<CustomTooltip text={t('EXCEL')}>
										<i className="uil uil-file-download-alt"></i>
									</CustomTooltip>
								</button>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '10px' }}>#</th>
									<th style={{ 'width': '55%' }}>{t('name2')}</th>
									<th className="text-end text-nowrap" style={{ 'width': '10%' }}>{t('current_price')}</th>
									<th className="text-end text-nowrap" style={{ 'width': '10%' }}>{t('min_price')}</th>
									<th className="text-end text-nowrap" style={{ 'width': '10%' }}>{t('max_price')}</th>
									<th className="text-end text-nowrap" style={{ 'width': '10%' }}>{t('average_price')}</th>
									<th className="text-end text-nowrap" style={{ 'width': '5%' }}>%</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}</td>
										<td className="text-primary cursor table-first-column-clickable"
											onClick={() => detail(item)}>
											{item.name}
										</td>
										<td className="text-end">{formatMoney(item.current_price)}</td>
										<td className="text-end">{formatMoney(item.min_price)}</td>
										<td className="text-end">{formatMoney(item.max_price)}</td>
										<td className="text-end">{formatMoney(item.avg_price)}</td>
										<td className="text-end">{returnPercentageDiv(item)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={modal} animation={false} centered size="xl"
				onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('price')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="card-body">
						<ReactApexChart
							type="area"
							height={300}
							options={chartData.options}
							series={chartData.series}
						/>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Elements
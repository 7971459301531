import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactApexChart from "react-apexcharts"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { GET, httpOk } from 'helpers/api'
import { formatMoney } from 'helpers/helpers'
//import { formatMoney } from 'helpers/helpers';

function Cheques() {
	const { t } = useTranslation()

	const [loader, setLoader] = useState(false)
	const [chartAreaCheques, setChartAreaCheques] = useState({
		series: [{
			name: t('cheques'),
			data: []
		}],
		options: {
			colors: ['#34c38f'],
			chart: {
				height: 350,
				type: 'area',
				toolbar: {
					show: false
				},
				background: '#fff',
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			yaxis: {
				// labels: {
				// 	formatter: function (value) {
				// 		return formatMoney(value, 0);
				// 	}
				// },
			},
			xaxis: {
				labels: {
					show: true,
				},
				tooltip: {
					enabled: false
				},
				categories: []
			},
			tooltip: {},
		},
	})
	const [chartPieOrderTypes, setChartPieOrderTypes] = useState({
		series: [1, 1, 1, 1, 1, 1, 1],
		options: {
			colors: ['#34c38f', '#f1b44c', '#f46a6a', '#5b73e8', '#6f42c1', '#343a40', '#74788d'],
			chart: {
				width: 380,
				type: 'pie',
				toolbar: {
					show: false
				},
			},
			labels: [t('hall'), t('takeaway'), t('manager'), t('call_center'), t('telegram'), t('website'), 'Android & IOS'],
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
		},
	})
	const [chartLineHall, setChartLineHall] = useState({
		series: [{
			data: []
		}],
		options: {
			colors: ['#34c38f'],
			chart: {
				height: 350,
				type: 'line',
				toolbar: {
					show: false
				},
				background: '#fff',
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			yaxis: {
				labels: {
					show: false,
				},
			},
			xaxis: {
				type: "numeric",
				labels: {
					show: false,
				},
				tooltip: {
					enabled: false
				},
				categories: []
			},
			tooltip: {
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
					console.log(data);
					return '<ul class="p-2">' +
						'<li><b>Дата</b>: ' + data.x + '</li>' +
						'<li><b>Зал</b>: ' + data.y + '</li>' +
						'<li><b>Гости</b>: ' + data.people_number + '</li>' +
						'<li><b>Средний чек</b>: ' + data.avg_cheque + '</li>' +
						'</ul>';
				}
			}
		},
	})
	const [chartLineDelivery, setChartLineaDelivery] = useState({
		series: [{
			data: []
		}],
		options: {
			colors: ['#34c38f'],
			chart: {
				height: 350,
				type: 'line',
				toolbar: {
					show: false
				},
				background: '#fff',
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			yaxis: {
				labels: {
					show: false,
				},
			},
			xaxis: {
				type: "numeric",
				labels: {
					show: false,
				},
				tooltip: {
					enabled: false
				},
				categories: []
			},
			tooltip: {
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
					return '<ul class="p-2">' +
						'<li><b>Дата</b>: ' + data.x + '</li>' +
						'<li><b>Доставка</b>: ' + data.y + '</li>' +
						'<li><b>Средний чек</b>: ' + data.avg_cheque + '</li>' +
						'</ul>';
				}
			}
		},
	})
	const [chartLineTakeaway, setChartLineaTakeaway] = useState({
		series: [{
			data: []
		}],
		options: {
			colors: ['#34c38f'],
			chart: {
				height: 350,
				type: 'line',
				toolbar: {
					show: false
				},
				background: '#fff',
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			yaxis: {
				labels: {
					show: false,
				},
			},
			xaxis: {
				type: "numeric",
				labels: {
					show: false,
				},
				tooltip: {
					enabled: false
				},
				categories: []
			},
			tooltip: {
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
					console.log(data);
					return '<ul class="p-2">' +
						'<li><b>Дата</b>: ' + data.x + '</li>' +
						'<li><b>Собой</b>: ' + data.y + '</li>' +
						'<li><b>Средний чек</b>: ' + data.avg_cheque + '</li>' +
						'</ul>';
				}
			}
		},
	})
	const [chartPieChequeTypes, setChartPieChequeTypes] = useState({
		series: [1, 1, 1, 1, 1, 1, 1],
		options: {
			colors: ['#34c38f', '#f1b44c', '#f46a6a', '#5b73e8', '#6f42c1', '#343a40', '#74788d'],
			chart: {
				width: 380,
				type: 'pie',
				toolbar: {
					show: false
				},
			},
			labels: [t('loyalty'), t('discount'), t('shortage'), t('no_profit'), t('debt'), t('agents')],
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
		},
	})
	const [chartPieOrderTypesData, setChartPieOrderTypesData] = useState({});
	const [chartPieChequeTypesData, setChartPieChequeTypesData] = useState({});

	async function getData(type = 'today') {
		setLoader(true)
		const response = await GET('/dashboard-cheques', {
			'type': type
		})

		if (httpOk(response)) {
			var chartAreaChequesCopy = { ...chartAreaCheques }
			chartAreaChequesCopy.series[0]['data'] = response.data['chart_area_cheques']['series']
			chartAreaChequesCopy.options.xaxis.categories = response.data['chart_area_cheques']['categories']

			setChartAreaCheques(chartAreaChequesCopy)
			setChartPieOrderTypes({ ...chartPieOrderTypes, 'series': response.data['chart_pie_order_types']['series'] })

			var chartLineHallCopy = { ...chartLineHall }
			chartLineHallCopy.series[0]['data'] = response.data['chart_line_cheque_types']['hall']
			setChartLineHall(chartLineHallCopy)

			var chartLineDeliveryCopy = { ...chartLineDelivery }
			chartLineDeliveryCopy.series[0]['data'] = response.data['chart_line_cheque_types']['delivery']
			setChartLineaDelivery(chartLineDeliveryCopy)

			var chartLineTakeawayCopy = { ...chartLineTakeaway }
			chartLineTakeawayCopy.series[0]['data'] = response.data['chart_line_cheque_types']['takeaway']
			setChartLineaTakeaway(chartLineTakeawayCopy)

			setChartPieOrderTypesData(response.data['chart_pie_order_types']['data'])

			setChartPieChequeTypes({ ...chartPieChequeTypes, 'series': response.data['chart_pie_cheque_types']['series'] })
			setChartPieChequeTypesData(response.data['chart_pie_cheque_types']['data'])

			setLoader(false)
		}


	}

	useEffect(() => {
		getData()
		//setLoader(false)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="mb-2">
				<button onClick={() => getData('today')} className="btn btn-sm btn-outline-success">{t('today')}</button>
				<button onClick={() => getData('yesterday')} className="btn btn-sm btn-outline-success ms-1">{t('yesterday')}</button>
				<button onClick={() => getData('week')} className="btn btn-sm btn-outline-success ms-1">{t('week')}</button>
				<button onClick={() => getData('month')} className="btn btn-sm btn-outline-success ms-1">{t('month')}</button>
				<button onClick={() => getData('quarter')} className="btn btn-sm btn-outline-success ms-1">{t('quarter')}</button>
				<button onClick={() => getData('year')} className="btn btn-sm btn-outline-success ms-1">{t('year')}</button>
			</div>

			{loader ?
				<Skeleton height={315} />
				:
				<>
					<div className="card mb-2">
						<div className="d-flex p-2">
							<h4>{t('cheques')} [{formatMoney(chartPieOrderTypesData.total_cheques)}]</h4>
						</div>
						<ReactApexChart type="area" height={300}
							options={chartAreaCheques.options}
							series={chartAreaCheques.series}
						/>
					</div>
				</>
			}

			<div className="row mb-5">
				<div className="col-md-4">
					{loader ?
						<Skeleton height={450} />
						:
						<div className="card h-100">
							<div className="card-body">
								<div>
									<h4>{t('order_types')}</h4>
									<span>{t('quantity')}</span>
								</div>
								<div className="d-flex justify-content-center">
									<ReactApexChart
										type="pie" width={250} height={250}
										options={chartPieOrderTypes.options}
										series={chartPieOrderTypes.series}
									/>
								</div>
								<div className="d-flex justify-content-between">
									<div className="d-flex">
										<div className="chart-legend-color bg-success"></div>
										{t('hall')}
									</div>
									<div>{formatMoney(chartPieOrderTypesData.total_hall_percentage)} %</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="d-flex">
										<div className="chart-legend-color bg-warning"></div>
										{t('takeaway')}
									</div>
									<div>{formatMoney(chartPieOrderTypesData.total_takeaway_percentage)} %</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="d-flex">
										<div className="chart-legend-color bg-danger"></div>
										{t('manager')}
									</div>
									<div>{formatMoney(chartPieOrderTypesData.total_delivery_manager_percentage)} %</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="d-flex">
										<div className="chart-legend-color bg-primary"></div>
										{t('call_center')}
									</div>
									<div>{formatMoney(chartPieOrderTypesData.total_delivery_callcenter_percentage)} %</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="d-flex">
										<div className="chart-legend-color bg-purple"></div>
										{t('telegram')}
									</div>
									<div>{formatMoney(chartPieOrderTypesData.total_delivery_telegram_percentage)} %</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="d-flex">
										<div className="chart-legend-color bg-dark"></div>
										{t('website')}
									</div>
									<div>{formatMoney(chartPieOrderTypesData.total_delivery_website_percentage)} %</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="d-flex">
										<div className="chart-legend-color bg-secondary"></div>
										Android & IOS
									</div>
									<div>{formatMoney(chartPieOrderTypesData.total_delivery_mobile_percentage)} %</div>
								</div>
							</div>
						</div>
					}
				</div>
				<div className="col-md-4">
					{loader ?
						<>
							<Skeleton height={150} />
							<Skeleton height={150} />
							<Skeleton height={150} />
						</>
						:
						<>
							<div className="card mb-2">
								<div className="card-body">
									<div>
										<h4>{t('hall')} [{formatMoney(chartPieOrderTypesData.total_hall)}]</h4>
									</div>
									<ReactApexChart type="line" height={100}
										options={chartLineHall.options}
										series={chartLineHall.series}
									/>
								</div>
							</div>
							<div className="card mb-2">
								<div className="card-body">
									<div>
										<h4>{t('takeaway')} [{formatMoney(chartPieOrderTypesData.total_takeaway)}]</h4>
									</div>
									<ReactApexChart type="line" height={100}
										options={chartLineTakeaway.options}
										series={chartLineTakeaway.series}
									/>
								</div>
							</div>
							<div className="card">
								<div className="card-body">
									<div>
										<h4>{t('delivery')} [{formatMoney(chartPieOrderTypesData.total_delivery)}]</h4>
									</div>
									<ReactApexChart type="line" height={100}
										options={chartLineDelivery.options}
										series={chartLineDelivery.series}
									/>
								</div>
							</div>
						</>
					}
				</div>
				<div className="col-md-4">
					{loader ?
						<Skeleton height={450} />
						:
						<div className="card h-100">
							<div className="card-body">
								<div>
									<h4>{t('cheque_types')}</h4>
									<span>{t('quantity')}</span>
								</div>
								<div className="d-flex justify-content-center">
									<ReactApexChart
										type="pie" width={250} height={250}
										options={chartPieChequeTypes.options}
										series={chartPieChequeTypes.series}
									/>
								</div>
								<div className="d-flex justify-content-between">
									<div className="d-flex">
										<div className="chart-legend-color bg-success"></div>
										{t('loyalty')}
									</div>
									<div>{formatMoney(chartPieChequeTypesData.total_loyalty_percentage)} %</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="d-flex">
										<div className="chart-legend-color bg-warning"></div>
										{t('discount')}
									</div>
									<div>{formatMoney(chartPieChequeTypesData.total_discount_percentage)} %</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="d-flex">
										<div className="chart-legend-color bg-primary"></div>
										{t('no_profit')}
									</div>
									<div>{formatMoney(chartPieChequeTypesData.total_no_profit_percentage)} %</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="d-flex">
										<div className="chart-legend-color bg-purple"></div>
										{t('debt')}
									</div>
									<div>{formatMoney(chartPieChequeTypesData.total_debt_percentage)} %</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="d-flex">
										<div className="chart-legend-color bg-dark"></div>
										{t('agents')}
									</div>
									<div>{formatMoney(chartPieChequeTypesData.total_agent_percentage)} %</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="d-flex">
										<div className="chart-legend-color bg-secondary"></div>
										{t('no_service_percentage')}
									</div>
									<div>{formatMoney(chartPieChequeTypesData.total_no_service_percentage)} %</div>
								</div>
							</div>
						</div>
					}
				</div>
			</div>

		</>
	)
}

export default Cheques
import React from 'react'

function Courier({ data, handleSettingChange }) {
	return (
		<form autoComplete="off">
			<div className="row">
				<div className="col-md-6">
					<div className="d-flex justify-content-between mb-2">
						<div className="fw-500 vertical-center">
							Время принятие заказа
						</div>
						<div className="w-50">
							<input type="number" className="form-control" disabled />
						</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<div className="fw-500 vertical-center">
							Радиус принятие заказа (км)
						</div>
						<div className="w-50">
							<input type="number" className="form-control" name="courier_accept_order_limit"
								value={data?.settings.courier_accept_order_limit ?? ''}
								onChange={(e) => handleSettingChange(e, 'number')} />
						</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<div className="fw-500 vertical-center">
							Радиус для заверщение заказа
						</div>
						<div className="w-50">
							<input type="number" className="form-control" disabled />
						</div>
					</div>
				</div>
			</div>
		</form>
	)
}

export default Courier
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { GET, POST, PUT, httpOk } from 'helpers/api'
import { regexNumeric, regexLogin } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const [poses, setPoses] = useState([])
	const [userRoles, setUserRoles] = useState([])
	const [activeTab, setActiveTab] = useState(null)
	const [permissions, setPermissions] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [employeePositions, setEmployeePositions] = useState([])
	const [mainActiveTab, setMainActiveTab] = useState(1)

	async function createUpdate(e) {
		e.preventDefault()

		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.permissions = []
		if (selectedItemCopy.phone && selectedItemCopy.phone.length === 9)
			selectedItemCopy.phone = '998' + regexNumeric(selectedItemCopy.phone)

		for (let i = 0; i < permissions.length; i++) {
			for (let j = 0; j < permissions[i]['permissions'].length; j++) {
				if (permissions[i]['permissions'][j]['checked']) {
					selectedItemCopy.permissions.push(permissions[i]['permissions'][j])
				}
			}
		}

		var response;
		if (selectedItemCopy.id) {
			response = await PUT('/employees', selectedItemCopy, { loader: true })
		} else {
			response = await POST('/employees', selectedItemCopy, { loader: true })
		}

		if (httpOk(response)) navigate('/employees')
	}

	function handleInputChange(e) {
		var selectedItemCopy = { ...selectedItem }
		switch (e.target.name) {
			case 'login':
				selectedItemCopy[e.target.name] = regexLogin(e.target.value)
				break;
			case 'car_model':
				selectedItemCopy.additional_info[e.target.name] = e.target.value
				break;
			case 'car_number':
				selectedItemCopy.additional_info[e.target.name] = e.target.value
				break;
			default:
				selectedItemCopy[e.target.name] = e.target.value
				break;
		}
		setSelectedItem(selectedItemCopy)
	}

	function handleSelectChange(option) {
		setSelectedItem({ ...selectedItem, 'pos_id': option.id })
		getEmployeePositions(option.id)
	}

	function selectAll(checked) {
		var permissionsCopy = [...permissions]

		for (let i = 0; i < permissionsCopy[activeTab]['permissions'].length; i++) {
			permissionsCopy[activeTab]['permissions'][i]['checked'] = checked
		}
		permissionsCopy[activeTab]['checked'] = checked

		setPermissions(permissionsCopy)
	}

	function selectOne(checked, index) {
		var permissionsCopy = [...permissions]

		permissionsCopy[activeTab]['permissions'][index]['checked'] = checked

		setPermissions(permissionsCopy)
	}

	function returnStyle(item) {
		var style = {}
		style.width = '90%'
		style.borderBottom = '1px solid #eee'
		if (item.parent_id) {
			style.marginLeft = '20px'
			return style
		}
		if (!item.parent_id) {
			style.fontWeight = '600'
			return style
		}
	}

	async function getPoses() {
		const response = await GET('/helper/poses-head')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getUserRoles() {
		const response = await GET('/helper/user-roles')
		if (httpOk(response)) setUserRoles(response.data)
	}

	async function getEmployeePositions(posId) {
		const response = await GET(`/helper/employee-positions/${posId}`)
		if (httpOk(response)) setEmployeePositions(response.data)
	}

	async function getPermissions(user_login = null) {
		var response = await GET(`/helper/permissions?user_login=${user_login}`)
		if (httpOk(response)) {
			response = response.data
			setActiveTab(0)
			setPermissions(response)
		}
	}

	async function getData() {
		if (id) {
			var response = await GET(`/employees/${id}`, {}, { loader: true })

			if (httpOk(response)) {
				if (response?.data?.phone)
					response.data.phone = response?.data.phone.substring(3, 12)
				setSelectedItem(response.data)
			}
			getPermissions(response.data.login)
			getEmployeePositions(response.data.pos_id)
		} else {
			getPermissions()
		}
	}

	useEffect(() => {
		getData()
		getPoses()
		getUserRoles()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('staff')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						{!id ?
							<h6>{t('create')}</h6>
							:
							<h6>{t('edit')}</h6>
						}
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="tabs tabs-sm mb-2">
						<ul>
							<li className={mainActiveTab === 1 ? 'active' : ''}
								onClick={() => setMainActiveTab(1)}>
								<span className="link">{t('main')}</span>
							</li>
							<li className={mainActiveTab === 2 ? 'active ' : ''}
								onClick={() => setMainActiveTab(2)}>
								<span className="link">{t('additional_info')}</span>
							</li>
						</ul>
					</div>
					<form autoComplete="off">
						<div className={`${mainActiveTab === 1 ? '' : 'd-none'}`}>
							<div className="d-flex gap-5 w-100">
								<div style={{ 'width': '30%' }}>
									<div className="form-group">
										<label>{t('pos')}<span className="required-mark">*</span></label>
										<Select
											isDisabled={selectedItem.id}
											options={poses}
											value={poses.find(option => option.id === selectedItem.pos_id)}
											onChange={(option) => handleSelectChange(option)}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
									<div className="form-group">
										<label>{t('preview')}</label>
										<Select
											isMulti
											options={poses}
											value={selectedItem.poses}
											onChange={(options) => setSelectedItem({ ...selectedItem, 'poses': options })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
									<div className="form-group">
										<label>{t('roles')}<span className="required-mark">*</span></label>
										<Select
											options={userRoles}
											value={userRoles.find(option => option.role === selectedItem.role)}
											onChange={(option) => setSelectedItem({ ...selectedItem, 'role': option.role })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.role}
										/>
									</div>
									<div className="form-group">
										<label>{t('position')}</label>
										<Select
											isClearable
											options={employeePositions}
											value={employeePositions.find(option => option.id === selectedItem.employee_position_id)}
											onChange={(option) => setSelectedItem({ ...selectedItem, 'employee_position_id': option ? option.id : null })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>

									<div className="form-group">
										<label>{t('system_name')}<span className="required-mark">*</span></label>
										<input type="text" className="form-control" name="first_name"
											value={selectedItem.first_name ?? ''}
											onChange={(e) => handleInputChange(e)} />
									</div>
									<div className="form-group">
										<label>{t('phone')}</label>
										<div className="input-group">
											<span className="input-group-text">998</span>
											<input type="number" className="form-control" name="phone"
												value={selectedItem.phone ?? ''}
												onChange={(e) => handleInputChange(e)} />
										</div>
									</div>
									<div className="form-group">
										<label>{t('note')}</label>
										<input type="text" className="form-control" name="note"
											value={selectedItem.note ?? ''}
											onChange={(e) => handleInputChange(e)} />
									</div>
									<div className="form-group">
										<label>{t('login')}</label>
										<input type="text" className="form-control" name="login"
											disabled={id}
											value={selectedItem.login ?? ''}
											onChange={(e) => handleInputChange(e)} />
									</div>
									<div className="form-group">
										<label>{t('password')}</label>
										<input type="text" className="form-control" name="password"
											value={selectedItem.password ?? ''}
											onChange={(e) => handleInputChange(e)} />
									</div>
									{!!(
										selectedItem.role &&
										selectedItem.role !== 'ROLE_DIRECTOR' &&
										selectedItem.role !== 'ROLE_CALL_CENTER' &&
										selectedItem.role !== 'ROLE_COURIER'
									) &&
										<div className="form-group">
											<label>{t('pin_code')}</label>
											<input type="number" className="form-control" name="pin_code"
												value={selectedItem.pin_code ?? ''}
												onChange={(e) => handleInputChange(e)} />
										</div>
									}
								</div>
								<div style={{ 'width': '70%' }}>
									<div className="tabs tabs-sm">
										<ul>
											{permissions?.map((item, index) => (
												<li key={index} className={activeTab === index ? 'active' : ''}
													onClick={() => setActiveTab(index)}>
													<span className="link">{item.name_ru}</span>
												</li>
											))}
										</ul>
									</div>

									{activeTab >= 0 &&
										<div style={{ 'height': '500px', 'overflow': 'auto' }}>
											{permissions.map((item, index) => (
												index === activeTab &&
												<div className="d-flex justify-content-between w-50 mt-2" key={index}>
													<h4>{t('all')}</h4>
													<div className="form-check" key={index}>
														<input className="form-check-input" type="checkbox" id="flexCheckChecked"
															checked={item.checked ? true : false}
															onChange={(e) => selectAll(e.target.checked)} />
													</div>
												</div>
											))}

											{permissions[activeTab]?.permissions?.map((item, index) => (
												<div className="d-flex justify-content-between w-50" key={index}>
													<div style={returnStyle(item)}>
														{item.name_ru}
													</div>
													<div className="form-check" key={index} style={{ 'justifyContent': "flex-end", 'width': '10%' }}>
														<input className="form-check-input" type="checkbox" id="flexCheckChecked"
															checked={item.checked ? true : false}
															onChange={(e) => selectOne(e.target.checked, index)} />
													</div>
												</div>
											))}
										</div>
									}
								</div>
							</div>
						</div>

						<div className={`${mainActiveTab === 2 ? '' : 'd-none'}`}>
							<div style={{ 'width': '30%' }}>
								<div className="form-group">
									<label>{t('car_model')}</label>
									<input type="text" className="form-control" name="car_model"
										value={selectedItem?.additional_info?.car_model ?? ''}
										onChange={(e) => handleInputChange(e)} />
								</div>
								<div className="form-group">
									<label>{t('car_number')}</label>
									<input type="text" className="form-control" name="car_number"
										value={selectedItem?.additional_info?.car_number ?? ''}
										onChange={(e) => handleInputChange(e)} />
								</div>
								<div className="form-group">
									<label>{t('backdate')}</label>
									<input type="number" className="form-control" name="backdate"
										value={selectedItem?.backdate ?? ''}
										onChange={(e) => handleInputChange(e)} />
								</div>
							</div>
						</div>
					</form>
					<div className="d-flex justify-content-end gap-2 mt-5">
						<button type="button" className="btn btn-warning btn-rounded btn-wide"
							onClick={() => navigate(-1)}>
							{t('cancel')}
						</button>
						<button type="button" className="btn btn-primary btn-rounded btn-wide"
							onClick={createUpdate}>
							{t('save')}
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
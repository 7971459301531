import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown, Modal } from 'react-bootstrap'
import Select from 'react-select'

import DeleteModal from 'pages/parts/DeleteModal'
import { DELETE, GET, POST, PUT, httpOk } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [inputs, setInputs] = useState([])
	const [serviceRatingTypes, setServiceRatingTypes] = useState([])
	const [selectedItem, setSelectedItem] = useState({
		'status': 1,
		'value': '',
		'options': [],
	})
	const [showModal, setShowModal] = useState(false)
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })

	async function createUpdate(e) {
		e.preventDefault()

		var response;
		if (selectedItem.id) {
			response = await PUT('/service-rating-templates', selectedItem, { loader: true })
		} else {
			response = await POST('/service-rating-templates', selectedItem, { loader: true })
		}

		if (httpOk(response)) {
			getData()
			toggleModal(false)
		}
	}

	async function deleteItem() {
		const response = await DELETE(`/service-rating-templates-option?id=${deleteModalParams.id}`, {}, { loader: true })
		if (httpOk(response)) {
			var selectedItemCopy = { ...selectedItem }
			selectedItemCopy.options.splice(deleteModalParams.index, 1)
			setSelectedItem(selectedItemCopy)
			getData()
			setModalDelete(false)
		}
	}

	function toggleModal(bool, item = { 'name': '', 'options': [] }) {
		setSelectedItem(item)
		setShowModal(bool)
	}

	function addOption() {
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.options.push({ 'value': '' })

		setSelectedItem(selectedItemCopy)
	}

	function handleChangeOption(e, index) {
		var selectedItemCopy = { ...selectedItem }

		selectedItemCopy.options[index]['value'] = e.target.value
		setSelectedItem(selectedItemCopy)
	}

	async function getData() {
		const response = await GET('/service-rating-templates', {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
		}
	}

	async function getInputTypes() {
		const response = await GET('/helper/input-types')
		if (httpOk(response)) {
			setInputs(response.data)
		}
	}

	async function getServiceRatingTypes() {
		const response = await GET('/helper/service-rating-types')
		if (httpOk(response)) {
			setServiceRatingTypes(response.data)
		}
	}

	useEffect(() => {
		getData()
		getInputTypes()
		getServiceRatingTypes()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('service_rating_templates')} [{data.total}]
				</h4>
				<div className="d-flex gap-2">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '40%' }}>{t('name3')}</th>
									<th style={{ 'width': '10%' }}>{t('type')}</th>
									<th style={{ 'width': '10%' }}>{t('cheque_type')}</th>
									<th style={{ 'width': '30%' }}>{t('options')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('status')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td>{item.input_type_name}</td>
										<td>{item.service_type_name}</td>
										<td>
											{item?.options?.map((item, index) => (
												<div key={index}>
													{item.value}
												</div>
											))}
										</td>
										<td className="text-center">
											{item.status === 1 &&
												<span className="bg-primary bg-border text-white">{t('activated')}</span>
											}
											{item.status === -1 &&
												<span className="bg-danger bg-border text-white">{t('disconnected')}</span>
											}
										</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL CREATE UPDATE */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group w-100">
							<label>{t('question')}</label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>

						<div className="d-flex gap-2">
							<div className="form-group w-100">
								<label>{t('status')}</label>
								<Select
									options={[{ 'id': 1, 'name': t('activated') }, { 'id': -1, 'name': t('disconnected') }]}
									value={[{ 'id': 1, 'name': t('activated') }, { 'id': -1, 'name': t('disconnected') }].find(option => option.id === selectedItem.status)}
									onChange={(option) => setSelectedItem({ ...selectedItem, 'status': option.id })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>

							<div className="form-group w-100">
								<label>{t('cheque_type')}</label>
								<Select
									isClearable
									options={serviceRatingTypes}
									value={serviceRatingTypes.find(option => option.id === selectedItem.cheque_order_type_id)}
									onChange={(option) => setSelectedItem({ ...selectedItem, 'cheque_order_type_id': option ? option.id : null })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						</div>

						<div className="d-flex gap-2">
							<div className="form-group w-100">
								<label>{t('type')}</label>
								<Select
									isDisabled={selectedItem.id}
									options={inputs}
									value={inputs.find(option => option.id === selectedItem.input_type_id)}
									onChange={(option) => setSelectedItem({ ...selectedItem, 'input_type_id': option.id, 'optionable': option.optionable })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>

							<div className="form-group w-100">
								<label>{t('order2')}</label>
								<input type="number" className="form-control" name="order_number"
									value={selectedItem.order_number}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'order_number': e.target.value })} />
							</div>
						</div>
						{selectedItem.optionable &&
							<>
								<button type="button" className="btn btn-primary w-100 mb-2"
									onClick={addOption}>
									{t('add')}
								</button>
								{selectedItem?.options?.map((item, index) => (
									<div className="d-flex gap-2">
										<input type="text" key={index}
											className="form-control mb-2"
											placeholder="Значение"
											name="value"
											value={item.value}
											onChange={(e) => handleChangeOption(e, index)} />
										<div className="table-action-button table-action-danger-button"
											onClick={() => {
												setDeleteModalParams({ id: item.id, index: index });
												setModalDelete(true)
											}}>
											<i className="uil-times"></i>
										</div>
									</div>
								))}
							</>
						}

						<label>Обзятельное поле?</label>
						<div className="d-flex justify-content-between mb-2">
							<div className="d-flex gap-3">
								<div className="form-check p-0">
									<div className="radio-round"
										onClick={() => setSelectedItem({ ...selectedItem, 'required': 1 })}
										style={{ 'backgroundColor': selectedItem.required === 1 ? 'black' : '' }}>
									</div>
									<span onClick={() => setSelectedItem({ ...selectedItem, 'required': 1 })}>
										{t('yes')}
									</span>
								</div>

								<div className="form-check p-0">
									<div className="radio-round"
										onClick={() => setSelectedItem({ ...selectedItem, 'required': 2 })}
										style={{ 'backgroundColor': selectedItem.required === 2 ? 'black' : '' }}>
									</div>
									<span onClick={() => setSelectedItem({ ...selectedItem, 'required': 2 })}>
										{t('no')}
									</span>
								</div>
							</div>
						</div>

						<div className="d-flex gap-2 w-100">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL CREATE UPDATE */}

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index
import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx'

import FilterSearch from 'pages/parts/FilterSearch'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, returnSign, formatDate, findKeyFromArrayByValue } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Products() {
	const { t } = useTranslation()
	const date = new Date()
	const reduxAccount = useSelector(state => state.account)

	const takeaways = [
		{ "id": 0, "name": t('hall') },
		{ "id": 1, "name": t('takeaway') },
		{ "id": 2, "name": t('delivery') },
		{ "id": 3, "name": t('telegram') }
	]

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
		search: '',
	})
	const [data, setData] = useState({
		'data': []
	})
	const [poses, setPoses] = useState([])

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = "/report-gross-income"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.order_type)
			urlParams += returnSign(urlParams) + 'order_type=' + filterData.order_type
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (`/report-gross-income${urlParams}` !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			var excelHeaders = [[t('name2'), t('quantity'), t('price'), t('sale_price'), t('gross_income'), '%']]

			for (let i = 0; i < data?.data?.length; i++) {
				var excelItems = []
				excelItems.push(data.data[i]['name'])
				excelItems.push(data.data[i]['quantity'])
				excelItems.push(data.data[i]['price'])
				excelItems.push(data.data[i]['sale_price'])
				excelItems.push(data.data[i]['income'])
				excelItems.push(data.data[i]['percent'])
				excelHeaders.push(excelItems)
			}

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('gross_income') + ".xlsx");
		} else {
			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setData(response.data)
			}
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: null,
			search: '',
		})
		searchWithFilter()
		setFilterDataExist(false)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.start_date === null &&
			filterData.end_date === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('gross_income')} [{data?.data?.length}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													options={takeaways}
													value={takeaways.find(option => option.id === filterData.order_type) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'order_type': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2" onClick={() => searchWithFilter(true)}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
						<div className="vertical-center">
							<h6 className="d-flex justify-content-between gap-3">
								<b>{t('amount')}:</b> {formatMoney(data?.total_income)}
							</h6>
							<h6 className="d-flex justify-content-between gap-3">
								<b>{t('allowances')}:</b> {formatMoney(data?.total_allowances)}
							</h6>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{(filterData.order_type) &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(takeaways, 'id', filterData.order_type, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '50%' }}>{t('name2')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('price')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('sale_price')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('gross_income')}</th>
									<th style={{ 'width': '10%' }} className="text-end">%</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td className="text-end">{formatMoney(item.quantity)}</td>
										<td className="text-end">{formatMoney(item.price)}</td>
										<td className="text-end">{formatMoney(item.sale_price)}</td>
										<td className="text-end">{formatMoney(item.income)}</td>
										<td className="text-end">{formatMoney(item.percent, 2)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

				</div>
			</div>
		</>
	)
}

export default Products
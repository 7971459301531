export let exportMenu = [
	{
		icon: "uil-setting sidebar-icon",
		children: [
			{
				title: true,
				i18n: "home",
			},
			{
				url: "/admin/users",
				i18n: "users",
				icon: "uil-users-alt",
				active: false,
			},
			{
				url: "/admin/poses",
				i18n: "pos",
				icon: "uil-shop",
				active: false,
			},
			{
				url: "/admin/payments-history",
				i18n: "payment_history",
				icon: "uil-history",
				active: false,
			},
			{
				url: "/admin/online",
				i18n: "online",
				icon: "uil-users-alt",
				active: false,
			},
			{
				url: "/admin/duplicate",
				i18n: "duplicate",
				icon: "uil-pathfinder",
				active: false,
			},
			{
				url: "/admin/sql",
				i18n: "SQL",
				icon: "uil-coins",
				active: false,
			},
			{
				url: "/admin/elements",
				i18n: "elements",
				icon: "uil-cell",
				active: false,
			},
			{
				url: "/admin/permissions",
				i18n: "permissions",
				icon: "uil-lock",
				active: false,
			},
		]
	},
]
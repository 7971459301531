import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Modal } from 'react-bootstrap'
import Select from 'react-select'

import ReactSelect from 'pages/parts/ReactSelect'
import { httpOk, GET, POST } from 'helpers/api'
import { formatMoney, findKeyFromArrayByValue, returnSign } from 'helpers/helpers'

function Index({
	data,
	poses,
	parentItems,
	modalExterntalBalance,
	setModalExterntalBalance,
	handlePassed,
	toggleModalExternalBalance
}) {
	const { t } = useTranslation()

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const [items, setItems] = useState([...parentItems])
	const [itemsDuplicate] = useState([...parentItems])
	const [filterData, setFilterData] = useState({
		'external_pos_id': null,
		'external_wms_id': null,
		'avg_sales': 10,
		'only_connected': true,
	})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [externalWms, setExternalWms] = useState([])

	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (!value) {
			setItems([...itemsDuplicate])
			return
		}
		var elementsDuplicateCopy = [...itemsDuplicate]

		var filteredItems = elementsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setItems(filteredItems)
	}

	async function handleSelectElement(option, index, item) {
		var modalExterntalBalanceCopy = { ...modalExterntalBalance }
		modalExterntalBalanceCopy.products[index]['name'] = option.name
		modalExterntalBalanceCopy.products[index]['product_id'] = option.id

		if (!item?.product_id) {
			await POST(`/service/documents-orders-wtppc`, {
				'element_id': option.product_id,
				'external_pos_id': filterData.external_pos_id,
				'external_element_id': item.external_product_id,
			}, {})
		}

		setModalExterntalBalance(modalExterntalBalanceCopy)
	}

	function closeReactSelect() {
		setTimeout(() => {
			var elementsCopy = [...items]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true
			setItems(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = items.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...items]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[items.length - 1].selected = true
						currentIndex = items.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === items.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setItems(elementsCopy)
		}
	}
	// REACTSELECT LOGIC

	async function handleSelectPos(option) {
		getExternalWms(option.id)
		setFilterData({ ...filterData, 'external_pos_id': option.id })
	}

	function handleInputChange(e, index) {
		var modalExterntalBalanceCopy = { ...modalExterntalBalance }

		let value = e.target.value;
		value = value.replace(/,/g, '.')
		modalExterntalBalanceCopy.products[index][e.target.name] = value.replace(/[^\d.]/g, '')

		setModalExterntalBalance(modalExterntalBalanceCopy)
	}

	function addToList() {
		const filteredProducts = modalExterntalBalance.products.filter(product => product.quantity > 0);

		var arrayToPass = []
		for (let i = 0; i < filteredProducts.length; i++) {
			for (let j = 0; j < parentItems.length; j++) {
				if (filteredProducts[i]['product_id'] === parentItems[j]['product_id']) {
					parentItems[j]['quantity'] = filteredProducts[i]['quantity']
					arrayToPass.push(parentItems[j])
				}
			}
		}

		handlePassed(arrayToPass)
		toggleModalExternalBalance(false)
	}

	function fillQuantity(index) {
		var modalExterntalBalanceCopy = { ...modalExterntalBalance }

		if (Number(modalExterntalBalanceCopy.products[index]['recommended']) <= 0) return

		modalExterntalBalanceCopy.products[index]['quantity'] = modalExterntalBalanceCopy.products[index]['recommended'].toFixed(2)
		setModalExterntalBalance(modalExterntalBalanceCopy)
	}

	async function getExternalPosProducts() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/service/wms-transfer-external-pos-products"
		var urlParams = ""

		if (data.from_wms_id)
			urlParams += returnSign(urlParams) + 'wms_id=' + data.from_wms_id
		if (filterDataCopy.external_pos_id)
			urlParams += returnSign(urlParams) + 'external_pos_id=' + filterDataCopy.external_pos_id
		if (filterDataCopy.external_wms_id)
			urlParams += returnSign(urlParams) + 'external_wms_id=' + filterDataCopy.external_wms_id
		if (filterDataCopy.only_connected)
			urlParams += returnSign(urlParams) + 'only_connected=' + filterDataCopy.only_connected
		if (filterDataCopy.avg_sales)
			urlParams += returnSign(urlParams) + 'avg_sales=' + filterDataCopy.avg_sales
		if (filterDataCopy.recommended)
			urlParams += returnSign(urlParams) + 'recommended=' + filterDataCopy.recommended

		filterUrl = filterUrl + urlParams

		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setModalExterntalBalance({ ...modalExterntalBalance, 'products': response.data })
			setDropdownIsOpen(false)
		}
	}

	async function getExternalWms(external_pos_id) {
		const response = await GET(`/helper/external-wms?external_pos_id=${external_pos_id}`, {}, { loader: true })
		if (httpOk(response)) setExternalWms(response.data)
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	return (
		<>
			{/* MODAL EXTERNAL BALANCE */}
			<Modal show={modalExterntalBalance.bool} animation={false} centered size="xl"
				dialogClassName="update-modal-width" onHide={() => toggleModalExternalBalance(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('external_balance')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="mb-2">
						<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
							<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
								{t('filter')}
							</Dropdown.Toggle>

							<Dropdown.Menu className="dropdown-filter">
								<div className="row">
									<div className="form-group col-md-6">
										<label>{t('pos')}</label>
										<Select
											options={poses}
											value={poses.find(option => option.id === filterData.external_pos_id) || ''}
											onChange={(option) => handleSelectPos(option)}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
									<div className="form-group col-md-6">
										<label>{t('warehouse')}</label>
										<Select
											options={externalWms}
											value={externalWms.find(option => option.id === filterData.external_wms_id) || ''}
											onChange={(option) => setFilterData({ ...filterData, 'external_wms_id': option ? option.id : null })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
								</div>
								<div className="row">
									<div className="form-group col-md-6">
										<label>{t('average_sale_days')}</label>
										<input type="text" className="form-control"
											onChange={(e) => setFilterData({ ...filterData, 'avg_sales': e.target.value.replace(/[^0-9]/g, '') })} />
									</div>
									<div className="form-group col-md-6">
										<label>{t('recommend_days')}</label>
										<input type="text" className="form-control"
											onChange={(e) => setFilterData({ ...filterData, 'recommended': e.target.value.replace(/[^0-9]/g, '') })} />
									</div>
								</div>
								<div className="row">
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('only_connected')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="only_connected"
												checked={filterData?.only_connected}
												onChange={(e) => setFilterData({ ...filterData, 'only_connected': e.target.checked })} />
										</div>
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-md-12">
										<div className="d-flex justify-content-end gap-2">
											<button className="btn btn-secondary"
												onClick={() => setDropdownIsOpen(false)}>
												{t('cancel')}
											</button>
											<button className="btn btn-primary"
												onClick={() => getExternalPosProducts()}>
												{t('apply')}
											</button>
										</div>
									</div>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>

					{filterData.external_pos_id &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.external_pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.external_pos_id, 'name')}
									</span>
								}
								{filterData.external_wms_id &&
									<span className="me-2">
										<b>{t('warehouse')}: </b>
										{findKeyFromArrayByValue(externalWms, 'id', filterData.external_wms_id, 'name')}
									</span>
								}
							</div>
						</div>
					}

					<div className="table-responsive table-fixed" style={{ 'height': '500px', 'overflow': 'auto' }}>
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('name2')}</th>
									<th style={{ 'width': '20%' }}>{t('external_name')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('balance2')}</th>
									<th style={{ 'width': '10%' }} className="text-end text-nowrap td-ellipsis"
										title={t('external_balance')}>
										{t('external_balance')}
									</th>
									<th style={{ 'width': '5%' }} className="text-nowrap">{t('unit')}</th>
									<th style={{ 'width': '5%' }} className="text-end text-nowrap"
										title={`Средная продажа за ${filterData.avg_sales} дней`}>
										{t('sales')}
									</th>
									<th style={{ 'width': '10%' }} className="text-end td-ellipsis"
										title={t('recommended_quantity')}>{t('recommended_quantity')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
								</tr>
							</thead>
							<tbody>
								{modalExterntalBalance?.products?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>
											<ReactSelect
												search={handleSelectSearch}
												value={item.name}
												index={index}
												scrollRef={scrollRef}
												options={items}
												onClose={() => closeReactSelect()}
												selectItem={(option) => handleSelectElement(option, index, item)}
												onKeyUp={(e) => {
													if (e.keyCode === 40 || e.keyCode === 38) {
														handleTableKeyDown(e)
													}
												}}
												table={
													<table className="table mb-0">
														<tbody ref={tbodyRef}>
															{items.map((selectItem, selectIndex) => (
																<tr
																	id={selectItem.id}
																	className={`cursor ${((selectItem.selected || selectItem.product_id === item.element_id) && 'selected')}`}
																	tabIndex={-1}
																	key={selectIndex}
																	onClick={() => handleSelectElement(selectItem, index, item)}
																	onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																	<td>{selectItem.name}</td>
																	<td>{selectItem.product_type_name}</td>
																	<td className="text-end text-nowrap">{formatMoney(selectItem.sale_price)}</td>
																</tr>
															))}
														</tbody>
													</table>
												}
											/>
										</td>
										<td className="td-ellipsis">{item.external_product_name}</td>
										<td className="text-end">
											{item.balance < 0 ?
												<b className={item.balance < 0 ? 'text-danger' : ''}>
													{formatMoney(item.balance, 2)}
												</b>
												:
												<span className={item.balance < 0 ? 'text-danger' : ''}>
													{formatMoney(item.balance, 2)}
												</span>
											}
										</td>
										<td className="text-end">
											{item.external_balance < 0 ?
												<b className={item.external_balance < 0 ? 'text-danger' : ''}>
													{formatMoney(item.external_balance, 2)}
												</b>
												:
												<span className={item.external_balance < 0 ? 'text-danger' : ''}>
													{formatMoney(item.external_balance, 2)}
												</span>
											}
										</td>
										<td>{item.unit_name}</td>
										<td className="text-end">{formatMoney(item.avg_sales, 2)}</td>
										<td className="text-end hover"
											onClick={() => fillQuantity(index)}>
											{formatMoney(item.recommended, 2)}
										</td>
										<td className="td-input">
											<input
												autoComplete="off"
												type="text"
												name="quantity"
												className="form-control text-end"
												placeholder="0"
												value={item.quantity ?? ''}
												onChange={(e) => handleInputChange(e, index)} />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<div className="btn-wrapper gap-2">
						<button type="button" className="btn btn-warning btn-rounded btn-wide" tabIndex="-1"
							onClick={() => {
								toggleModalExternalBalance(false)
								setFilterData({
									'external_pos_id': null,
									'external_wms_id': null,
									'avg_sales': 10,
									'only_connected': true,
								})
							}}>
							{t('cancel')}
						</button>
						<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
							onClick={() => addToList()}>
							{t('save')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL EXTERNAL BALANCE */}
		</>
	)
}

export default Index
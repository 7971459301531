import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useParams } from "react-router-dom"
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, POST, PUT, httpOk } from 'helpers/api'
import { formatDate, formatMoney, returnSign, findKeyFromArrayByValue } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const { supplier_id } = useParams()

	const paymentTypes = [{ 'id': 1, 'name': t('cash') }, { 'id': 3, 'name': t('money_transfer') }]
	const amountTypes = [{ 'id': 1, 'name': t('amortization') }, { 'id': 2, 'name': t('debt') }]

	const [modalPayment, setModalPayment] = useState(false)
	const [modalChangePayment, setModalChangePayment] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [poses, setPoses] = useState([])

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: `/report-suppliers-history/${supplier_id}`,
		response: null
	})
	const [data, setData] = useState({})

	async function searchWithFilter() {
		var filterUrl = `/report-suppliers-history/${supplier_id}`
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/report-suppliers-history/${supplier_id}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function payment(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }

		if (selectedItemCopy.amount_type_id === 2) {
			selectedItemCopy.amount_out = selectedItemCopy.amount_in
			selectedItemCopy.amount_in = 0
		}

		var sendData = {}
		sendData.amount_in = selectedItemCopy.amount_in
		sendData.amount_out = selectedItemCopy.amount_out
		sendData.supplier_id = selectedItemCopy.supplier_id
		sendData.payment_type_id = selectedItemCopy.payment_type_id
		sendData.pos_id = selectedItemCopy.pos_id
		sendData.note = selectedItemCopy.note

		var response = await POST('/suppliers-payment', sendData, { loader: true })

		if (httpOk(response)) {
			showPaymentModal(false)
			searchWithFilter()
		}
	}

	function showPaymentModal(bool) {
		var selectedItemCopy = { ...data.supplier }
		if (!bool) {
			selectedItemCopy.payment_type_id = 1
			selectedItemCopy.amount_type_id = 1
			selectedItemCopy.amount_in = ''
			selectedItemCopy.amount_out = 0
		} else {
			selectedItemCopy.payment_type_id = 1
			selectedItemCopy.amount_type_id = 1
			selectedItemCopy.amount_in = ''
			selectedItemCopy.amount_out = 0
			setSelectedItem(selectedItemCopy)
		}

		setModalPayment(bool)
	}

	function toggleModalChangePayment(bool, item = {}) {
		if (bool) {
			var itemCopy = { ...item }
			itemCopy.changed_amount_in = itemCopy.amount_in

			setSelectedItem(itemCopy)
			setModalChangePayment(true)
		} else {
			setModalChangePayment(false)
		}
	}

	async function changePayment(e) {
		e.preventDefault();

		var response = await PUT('/suppliers-payment', selectedItem, { loader: true })

		if (httpOk(response)) {
			toggleModalChangePayment(false)
			searchWithFilter()
		}
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex text-truncate">
				<h4 className="vertical-center h-100">
					{t('supplier')}
				</h4>
				<div className="vertical-center">
					<i className="uil uil-angle-double-right fz-20"></i>
				</div>
				<div className="vertical-center">
					<h6>{data?.supplier?.name} [{data?.supplier?.supplier_id}]</h6>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="d-none">
								<button className="btn btn-outline-primary btn-wide me-2" onClick={() => showPaymentModal(true)}>
									{t('pay')}
								</button>
							</div>
						</div>
						<div className="d-flex gap-2">
							<div className="vertical-center">
								<h4><b>{t('amount')}</b>:</h4>
							</div>
							<div className="vertical-center">
								<h5 className="text-end">
									<span className={`${data.total_amount > 0 ? 'text-success' : 'text-danger'}`}>
										{formatMoney(data.total_amount)}
									</span>
								</h5>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '15%' }}>{t('pos')}</th>
									<th style={{ 'width': '10%' }}>{t('payment_method')}</th>
									<th style={{ 'width': '30%' }}>{t('note')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('debt')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('paid')}</th>
									<th style={{ 'width': '15%' }} className="text-end">{t('total')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td>{item.pos_name}</td>
										<td>{item.payment_type_name}</td>
										<td>{item.note}</td>
										<td className="text-center text-nowrap">
											{item.status === 1 &&
												<span>{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</span>
											}
											{item.status === -1 &&
												<span className="text-danger">{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</span>
											}
										</td>
										<td className="text-end text-nowrap text-danger">{formatMoney(item.amount_minus)}</td>
										<td className="text-end text-nowrap text-success">{formatMoney(item.amount_plus)}</td>
										<td className={`text-end text-nowrap ${item.balance >= 0 ? 'text-success' : 'text-danger'}`}>
											{formatMoney(item.balance)}
										</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														{item.document_in_id &&
															<Link to={`/documents-in/preview/${item.document_in_id}`} className="table-dropdown-item">
																<i className="uil-eye"></i>
																{t('preview')}
															</Link>
														}
														{item.deleatable &&
															<div className="table-dropdown-item"
																onClick={() => toggleModalChangePayment(true, item)}>
																<i className="uil-edit-alt"></i>
																{t('delete')}
															</div>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width"
				onHide={() => showPaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('pay')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<span>{t('pos')}</span>
						<span>{selectedItem.pos_name}</span>
					</div>
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<span>{t('organization')}</span>
						<span>{selectedItem.name}</span>
					</div>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('payment_method')}</label>
							<Select
								options={paymentTypes}
								value={paymentTypes.find(option => option.id === selectedItem.payment_type_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'payment_type_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('choose_payment_type')}</label>
							<Select
								options={amountTypes}
								value={amountTypes.find(option => option.id === selectedItem.amount_type_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'amount_type_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('amount')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="amount_in" autoFocus
								value={selectedItem.amount_in ? formatMoney(selectedItem.amount_in) : ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount_in': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => showPaymentModal(false)} tabIndex="-1">{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100" disabled={!selectedItem.amount_in && !selectedItem.amount_out}>
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}

			{/* MODAL CHANGE PAYMENT */}
			<Modal show={modalChangePayment} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModalChangePayment(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('pay')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={changePayment} autoComplete="off">
						<div className="form-group">
							<label>{t('amortization')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="amount_in" autoFocus
								value={selectedItem.amount_in ? formatMoney(selectedItem.amount_in) : ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount_in': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => toggleModalChangePayment(false)} tabIndex="-1">{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100"
								disabled={!selectedItem.amount_in || !(Number(selectedItem.amount_in) < Number(selectedItem.changed_amount_in))}>
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL CHANGE PAYMENT */}
		</>
	)
}

export default Index

export let DayOfTheWeek = [
	{
		id: 1,
		nameru: 'Понедельник',
		nameuz_latn: 'Dushanba',
		nameuz_cyrl: 'Душанба',
	},
	{
		id: 2,
		nameru: 'Вторник',
		nameuz_latn: 'Seshanba',
		nameuz_cyrl: 'Сешанба',
	},
	{
		id: 3,
		nameru: 'Среда',
		nameuz_latn: 'Chorshanba',
		nameuz_cyrl: 'Чоршанба',
	},
	{
		id: 4,
		nameru: 'Четверг',
		nameuz_latn: 'Payshanba',
		nameuz_cyrl: 'Пайшанба',
	},
	{
		id: 5,
		nameru: 'Пятница',
		nameuz_latn: 'Juma',
		nameuz_cyrl: 'Жума',
	},
	{
		id: 6,
		nameru: 'Суббота',
		nameuz_latn: 'Shanba',
		nameuz_cyrl: 'Шанба',
	},
	{
		id: 7,
		nameru: 'Воскресенье',
		nameuz_latn: 'Yakshanba',
		nameuz_cyrl: 'Якшанба',
	}
]
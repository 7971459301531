import React, { useState, useEffect, useRef, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { YMaps, Map, Placemark, SearchControl } from '@pbe/react-yandex-maps';

import Rightbar from 'pages/main/cheques/Rightbar'
import CustomTooltip from "pages/parts/CustomTooltip"
import { DELETE, GET, POST, PUT, httpOk } from 'helpers/api'
import { newDate, formatDate, formatMoney, generateTransactionId, regexNumeric, returnSign } from 'helpers/helpers'

const numbers = [
	{ 'id': '1', 'name': 1 }, { 'id': '2', 'name': 2 }, { 'id': '3', 'name': 3 },
	{ 'id': '4', 'name': 4 }, { 'id': '5', 'name': 5 }, { 'id': '6', 'name': 6 },
	{ 'id': '7', 'name': 7 }, { 'id': '8', 'name': 8 }, { 'id': '9', 'name': 9 },
]

function OrderCreate({ cheque_id = null }) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const date = new Date()

	const scrollToBottomRef = useRef(null)
	const disableRef = useRef(false)
	const ymaps = React.useRef(null);
	const pointRef = useRef();
	const refPhoneInput = useRef();

	const reduxAccount = useSelector(state => state.account)
	const reduxDelivery = useSelector(state => state.delivery)

	const [orderType, setOrderType] = useState(3); // 2 - Собой 3 - Доставка
	const [data, setData] = useState({
		"operator_login": reduxAccount.login,
		"active_tab": 1,
		"pos_id": null,
		"total_amount": 0,
		"people_number": 1,
		"service_percentage": 0,
		"payment_type_id": 1,
		"eating_kit": 1,
		"products": [],
		'transaction_id': generateTransactionId(reduxAccount.pos_id, reduxAccount.id),
	});
	const [menu, setMenu] = useState([]);
	const [fastMenu, setFastMenu] = useState([]);
	const [comments, setComments] = useState([]);
	const [selectedMenu, setSelectedMenu] = useState({
		'products': []
	});
	// change table
	const [couriers] = useState([]);
	const [modifiers, setModifiers] = useState([]);
	const [sizes, setSizes] = useState([]);
	const [modalModifiers, setModalModifiers] = useState(false);
	const [modalSizes, setModalSizes] = useState({
		'bool': false,
		'replace': false,
		'index': '',
	});
	const [modalModification, setModalModification] = useState({
		'bool': false,
		'index': '',
		'tabNumber': 1,
		'product': {
			'quantity': ''
		},
	});
	const [client, setClient] = useState({
		'phone1': '',
		'phone2': '',
		'first_name': '',
		'address': '',
		'new_address': t('new_address'),
		'note': '',
		'addresses': [],
		'gps': [41.311081, 69.240562],
		'latitude': 41.311081,
		'longitude': 69.240562
	});
	const [selectedItem, setSelectedItem] = useState({});
	const [newAddress, setNewAddress] = useState({
		'latitude': '',
		'longitude': '',
		'new_address': '',
		'address': '',
		'pos_name': '',
		'distance': '',
	});
	const [modalSelectClient, setModalSelectClient] = useState(false);
	const [smsTemplates, setSmsTemplates] = useState([]);
	const [promocodes, setPromocodes] = useState([]);
	const [defaultState, setDefaultState] = useState({
		center: [41.311081, 69.240562],
		zoom: 11,
		controls: ["zoomControl"]
	});
	const [modalAddressDelete, setModalAddressDelete] = useState({ 'bool': false, 'address_id': null });
	const [modalClient, setModalClient] = useState(false);
	const [cheques, setCheques] = useState([]);
	const [modalCheques, setModalCheques] = useState(false);
	const [showRightBar, setShowRightBar] = useState(false);
	const [filterData] = useState({
		start_date: formatDate(date.setDate(date.getDate() - 60), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		status: 10,
		search: '',
	})

	async function createUpdate(params = {}) {
		if (disableRef.current) return
		disableRef.current = true

		var dataCopy = { ...data }

		if (!dataCopy.id) {
			// order_type_delivery 1-Менеджер 2-Телеграм 3-Колл центр 4-Сайт 5-Android 6-IOS
			dataCopy.order_type = orderType
			dataCopy.order_type_delivery = 3
			dataCopy.operator_name = reduxAccount?.first_name
			if (params?.status === 0) {
				dataCopy.status = 0
			}
			var clientCopy = { ...client }

			if (clientCopy?.phone1?.length === 9) {
				clientCopy.phone1 = '998' + clientCopy.phone1
			}
			if (clientCopy?.phone2?.length === 9) {
				clientCopy.phone2 = '998' + clientCopy.phone2
			}

			if (!clientCopy.id) {
				clientCopy.pos_id = reduxAccount?.pos?.id
				var clientResponse
				var clientAddressResponse

				clientResponse = await POST('/clients', clientCopy, { loader: true })
				if (httpOk(clientResponse)) {
					clientResponse = clientResponse.data
				} else {
					return;
				}
				if (newAddress?.latitude) {
					clientAddressResponse = await POST('/clients-create-address', {
						'client_id': clientResponse.data.id,
						'longitude': newAddress?.longitude,
						'latitude': newAddress?.longitude,
					}, { loader: true })
					if (httpOk(clientAddressResponse)) dataCopy.address_id = clientAddressResponse.data?.id
				}

				if (!clientResponse) {
					disableRef.current = false
					return
				}

				dataCopy.client_id = clientResponse?.data?.id
				dataCopy.address_id = clientAddressResponse.data?.id
				dataCopy.pos_id = newAddress?.pos_id
			} else {
				dataCopy.client_id = clientCopy.id
				dataCopy.address_id = clientCopy.address_id
			}

			const response = await POST('/delivery/cheque', dataCopy, { notification: true, loader: true })
			if (httpOk(response)) {

				clearData()
				if (refPhoneInput.current) refPhoneInput.current.select()
			}

			disableRef.current = false
		}
	}

	function clearData() {
		setData({
			"order_type": 3,
			"operator_login": reduxAccount.login,
			"active_tab": 1,
			"pos_id": null,
			"total_amount": 0,
			"people_number": 1,
			"service_percentage": 0,
			"payment_type_id": 1,
			"eating_kit": 1,
			"products": [],
			'transaction_id': generateTransactionId(reduxAccount.pos_id, reduxAccount.id),
		})
		setClient({
			'phone1': '',
			'phone2': '',
			'first_name': '',
			'address': '',
			'new_address': t('new_address'),
			'note': '',
			'addresses': [],
			'gps': [41.311081, 69.240562],
			'latitude': 41.311081,
			'longitude': 69.240562
		})
	}

	async function handleChangeOrderType(type) {
		setOrderType(type)
		if (type === 2) {
			setData({ ...data, 'delivery_price': 0, 'address_id': '' })
		}
		if (type === 3) {
			var dataCopy = { ...data }
			const responseClosestPos = await POST('/delivery/closest-pos', {}, { loader: true })
			if (httpOk(responseClosestPos)) {
				dataCopy.delivery_amount_until_km = responseClosestPos.data.delivery_amount_until_km
				dataCopy.distance = responseClosestPos.data.distance
				dataCopy.max_delivery_distance = responseClosestPos.data.max_delivery_distance
				if (type === 3) dataCopy.delivery_price = responseClosestPos.data.delivery_price
				dataCopy.error = false
				calculateTotalAmount(dataCopy)
			} else {
				dataCopy.error = true
				setData(dataCopy)
			}
		}
	}

	async function createUserAddress() {
		var clientCopy = { ...client }
		if (clientCopy.id) {
			if (!clientCopy.address) {
				toast.error(t('address_required'))
				return
			}
			await POST('/clients-create-address', {
				'client_id': clientCopy.id,
				'longitude': newAddress?.longitude,
				'latitude': newAddress?.latitude,
			}, { loader: true })
			setNewAddress({
				'latitude': '',
				'longitude': '',
				'new_address': '',
				'address': '',
				'pos_name': '',
				'distance': '',
			})
			searchClient()
		}
		setModalSelectClient(false)
	}

	function addToList(item, params = { 'instantlySetQuantity': false }) {
		var dataCopy = { ...data }

		var index = dataCopy.products.findIndex(x => (x.product_id === item.product_id && !x.status))
		if (index === -1 && index !== undefined) {

			item.status = 0 // 0 потому что новый и нету еще в базе если есть в базе то 1
			if (!params.instantlySetQuantity) {
				item.quantity = 1
			}

			item.total_price = item.sale_price * item.quantity
			if (item.in_product !== 3) { // Выделить продукт если тип продукт
				for (let i = 0; i < dataCopy.products.length; i++) { // last added item selected TRUE
					dataCopy.products[i]['selected'] = false
				}
				item.selected = true
				dataCopy.selectedProduct = item
				dataCopy.selectedIndex = dataCopy.products.length
			}

			dataCopy.products.push(item)
		} else {
			if (params.instantlySetQuantity) {
				dataCopy.products[index]['quantity'] = item.quantity
			} else {
				dataCopy.products[index]['quantity'] += 1
			}

			dataCopy.products[index]['total_price'] =
				dataCopy.products[index]['sale_price'] *
				dataCopy.products[index]['quantity']

			for (let i = 0; i < dataCopy.products.length; i++) { // last added item selected TRUE
				dataCopy.products[i]['selected'] = false
			}
			dataCopy.products[index]['selected'] = true
			dataCopy.selectedProduct = dataCopy.products[index]
		}

		calculateTotalAmount(dataCopy)

		setTimeout(() => {
			scrollToBottomRef.current.scrollTop = scrollToBottomRef.current.scrollHeight
		}, 100);
	}

	function selectSize(item) {
		var product = item.sizes.find(x => x.bool_size_default === 1)

		if (!product) {
			setSizes(item.sizes)
			setModalSizes({
				'bool': true,
				'replace': false,
				'index': '',
			})
			return
		}

		addToList(product)
	}

	function changeProductSize() {
		var index = data.products.findIndex(x => x.selected === true)
		setSizes(data.products[index]['sizes'])
		setModalSizes({
			'bool': true,
			'replace': true,
			'index': index,
		})
	}

	function replaceSize(item) {
		var product = data.products.find(x => x.selected === true)
		item.sizes = product.sizes
		deleteSelectedProduct()
		addToList(item)
		setModalSizes({
			'bool': false,
			'replace': false,
			'index': '',
		})
	}

	function clickProductQuantity(value) {
		var modalModificationCopy = { ...modalModification }
		if (!modalModificationCopy.product.quantity) {
			modalModificationCopy.product.quantity = ''
		}

		if (value === 'C') {
			modalModificationCopy.product.quantity = ''
			setModalModification(modalModificationCopy)
			return
		}

		modalModificationCopy.product.quantity = modalModificationCopy.product.quantity + value
		setModalModification(modalModificationCopy)
	}

	function incrementDouble() {
		var modalModificationCopy = { ...modalModification }
		if (!modalModificationCopy.product.initial_quantity) {
			modalModificationCopy.product.initial_quantity = modalModificationCopy.product.quantity
		}

		modalModificationCopy.product.quantity =
			(
				Number(modalModificationCopy.product.quantity) +
				Number(modalModificationCopy.product.initial_quantity)
			).toFixed(1).toString()
		setModalModification(modalModificationCopy)
	}

	function instantChangeSelectedProductQuantity(type) {
		var product = data.products.find(x => x.selected === true)
		if (type === '+') {
			if (product) {
				product.quantity = (Number(product.quantity) + 1).toString()
				addToList(product, { 'instantlySetQuantity': true })
			}
		}
		if (type === '-') {
			if (product) {
				product.quantity = (Number(product.quantity) - 1).toString()
				if (Number(product.quantity) === 0) {
					deleteSelectedProduct()
					return
				}
				addToList(product, { 'instantlySetQuantity': true })
			}
		}
	}

	function changeSelectedProductQuantity() {
		var product = data.products.find(x => x.selected === true)
		var index = data.products.findIndex(x => x.selected === true)
		product.quantity = product.quantity.toString()

		if (!product.cheque_id) {
			setModalModification({
				...modalModification,
				'bool': true,
				'product': { ...product },
				'index': index
			})
		}
	}

	function deleteSelectedProduct() {
		var dataCopy = { ...data }
		var index = dataCopy.products.findIndex(x => x.selected === true)
		if (index !== -1 && index !== undefined) {
			dataCopy.products.splice(index, 1)
			dataCopy.selectedProduct = undefined
			dataCopy.selectedIndex = undefined
			setData(dataCopy)
			calculateTotalAmount(dataCopy)
		}
	}

	function selectProduct(index) {
		var dataCopy = { ...data }
		for (let i = 0; i < dataCopy.products.length; i++) { // last added item selected TRUE
			dataCopy.products[i]['selected'] = false
		}
		dataCopy.products[index]['selected'] = true
		dataCopy.selectedProduct = dataCopy.products[index]
		dataCopy.selectedIndex = index
		setData(dataCopy)
	}

	function acceptModificationChange() {
		var dataCopy = { ...data }
		dataCopy.products[modalModification.index] = modalModification.product
		calculateTotalAmount(dataCopy)
		setModalModification({
			'tabNumber': 1,
			'bool': false,
			'product': {},
			'index': ''
		})
	}

	function selectMenu(index, type = 'menu') {
		var menuCopy = []
		if (type === 'menu') {
			menuCopy = [...menu]
		} else {
			menuCopy = [...fastMenu]
		}

		for (let i = 0; i < menuCopy.length; i++) {
			if (i === index) {
				menuCopy[i]['active'] = true
				setSelectedMenu(menuCopy[i])
			} else {
				menuCopy[i]['active'] = false
			}
		}

		if (type === 'menu') {
			setMenu(menuCopy)
		} else {
			setFastMenu(menuCopy)
		}
	}

	function goTo(page) {
		if (page === "back") {
			navigate('/delivery/orders')
			return
		}
	}

	function calculateTotalAmount(dataCopy) {
		dataCopy['total_amount'] = 0
		dataCopy['total_amount_service'] = 0
		for (let i = 0; i < dataCopy.products.length; i++) {
			if (dataCopy.products[i]['status'] >= 0) {
				dataCopy['total_amount'] += Number(dataCopy.products[i]['sale_price']) * Number(dataCopy.products[i]['quantity'])
				dataCopy['total_amount_service'] = Number(dataCopy['total_amount']) + (Number(dataCopy['total_amount']) * Number(dataCopy['service_percentage']) / 100)
				dataCopy.products[i]['total_price'] = Number(dataCopy.products[i]['sale_price']) * Number(dataCopy.products[i]['quantity'])
			}
		}

		if (dataCopy['delivery_price']) {
			dataCopy['total_amount_service'] += Number(dataCopy['delivery_price'])
		}

		setData(dataCopy)
	}

	async function searchClient() {
		var dataCopy = { ...data }

		if (client.phone1.length === 9) {
			var response = await GET(`/search-client/998${client.phone1}`, {}, { loader: true })
			if (httpOk(response)) {
				response = response.data
				if (response.id) {
					if (response?.phone2) {
						response.phone2 = response?.phone2?.substring(3, 12)
					}
					setClient({
						...client,
						'id': response.id,
						'phone1': client.phone1,
						'phone2': response.phone2,
						'first_name': response.first_name,
						'note': response.note,
						'gender': response.gender,
						'birthday': response.birthday,
						'address_id': null,
						'addresses': response?.addresses,
						'loyalty_balance': response.loyalty_balance,
						'telegram_user_id': response.telegram_user_id,
						'count_cheques': response.count_cheques,
						'exist': true,
					})

					const responseClosestPos = await POST('/delivery/closest-pos', {}, { loader: true })
					if (httpOk(responseClosestPos)) {
						dataCopy.delivery_amount_until_km = responseClosestPos.data.delivery_amount_until_km
						dataCopy.distance = responseClosestPos.data.distance
						dataCopy.max_delivery_distance = responseClosestPos.data.max_delivery_distance
						if (orderType === 3)
							dataCopy.delivery_price = responseClosestPos.data.delivery_price
						dataCopy.error = false
						calculateTotalAmount(dataCopy)
					} else {
						dataCopy.error = true
						setData(dataCopy)
					}
				} else {
					setClient({
						...client,
						'id': '',
						'client_id': '',
						'address_id': '',
						'phone2': '',
						'first_name': '',
						'address': '',
						'note': '',
						'addresses': [],
						'exist': false,
					})
				}
			}
		}
	}

	function addComment(name) {
		var dataCopy = { ...data }
		dataCopy.products[modalModification.index]['comment'] = name
		setData(dataCopy)
		setModalModification({
			'bool': false,
			'index': '',
			'tabNumber': 1,
			'product': {
				'quantity': ''
			},
		})
	}

	function deleteIngredient(index) {
		var modalModificationCopy = { ...modalModification }
		modalModificationCopy.product.ingredients[index]['del'] =
			!modalModificationCopy.product.ingredients[index]['del']

		setModalModification(modalModificationCopy)
	}

	function toggleModal(bool, type = 'map') {
		if (type === 'map') {
			if (bool) {
				setModalSelectClient(true)
			} else {
				setNewAddress({
					...newAddress,
					'latitude': '',
					'longitude': '',
					'new_address': '',
					'address': '',
					'pos_name': '',
					'distance': '',
				})
				setModalSelectClient(false)
			}
		}
	}

	async function handleSelectAddress(clientCopy = {}, item, index) {
		var dataCopy = { ...data }
		var responseClosestPos = await POST('/delivery/closest-pos', {
			"latitude": clientCopy?.addresses[index]['latitude'],
			"longitude": clientCopy?.addresses[index]['longitude'],
			"total_amount_service": data?.total_amount_service ?? 0,
		}, { loader: true })
		if (httpOk(responseClosestPos)) {
			responseClosestPos = responseClosestPos.data
			clientCopy.addresses[index]['pos_name'] = responseClosestPos.name
			clientCopy.addresses[index]['distance'] = responseClosestPos.distance

			clientCopy.address_id = item.id
			clientCopy.address = item.name

			dataCopy.apartment = item.apartment ?? null
			dataCopy.floor = item.floor ?? null
			dataCopy.entrance = item.entrance ?? null
			dataCopy.intercom = item.intercom ?? null
			dataCopy.navigate = item.navigate ?? null

			dataCopy.pos_id = responseClosestPos.id
			dataCopy.distance = responseClosestPos.distance
			dataCopy.delivery_price = responseClosestPos.delivery_price
			dataCopy.max_delivery_distance = responseClosestPos.max_delivery_distance

			setClient(clientCopy)
			calculateTotalAmount(dataCopy)
		} else {
			responseClosestPos = responseClosestPos.data
			clientCopy.addresses[index]['pos_name'] = responseClosestPos.pos.name
			clientCopy.addresses[index]['distance'] = responseClosestPos.pos.distance

			setClient(clientCopy)
		}
	}

	async function handleSelectPos(option) {
		var dataCopy = { ...data }
		dataCopy.pos_id = option ? option.id : null;
		dataCopy.distance = 0;

		setClient({ ...client, 'address_id': '', 'address': '' })
		calculateTotalAmount(dataCopy)
	}

	function clearSelectedAddress() {
		var dataCopy = { ...data }
		dataCopy.distance = 0
		dataCopy.apartment = null
		dataCopy.floor = null
		dataCopy.entrance = null
		dataCopy.intercom = null
		if (dataCopy?.delivery_amount_until_km) {
			dataCopy.delivery_price = Number(dataCopy.delivery_amount_until_km)
		}
		setClient({ ...client, address_id: null, address: '' })
		calculateTotalAmount(dataCopy)
	}

	async function findClosestPos(firstGeoObject, longitude, latitude) {
		const response = await POST('/delivery/closest-pos', {
			"latitude": latitude,
			"longitude": longitude,
		}, { loader: true })
		if (httpOk(response)) {
			setNewAddress({
				...newAddress,
				'latitude': latitude,
				'longitude': longitude,
				'new_address': firstGeoObject.getAddressLine(),
				'address': firstGeoObject.getAddressLine(),
				'pos_id': response.data.id,
				'pos_name': response.data.name,
				'distance': response.data.distance,
			})
		}
	}

	async function parseCoordinates(value) {
		setData({ ...data, 'coordinates': value })
		if (!client.id) {
			toast.error(t('please_create_client'))
			return
		}

		var numbers = value.replace(/\s/g, '').split(",")
		var lattitude = numbers[0];
		var longitude = numbers[1];

		if (longitude && lattitude) {
			var response = await POST('/clients-create-address', {
				'client_id': client.id,
				'latitude': lattitude,
				'longitude': longitude,
			}, { loader: true })
			if (httpOk(response)) {
				response = response.data
				setNewAddress({
					'latitude': '',
					'longitude': '',
					'new_address': '',
					'address': '',
					'pos_name': '',
					'distance': '',
				})
				var clientCopy = { ...client }
				if (clientCopy.addresses.length) {
					clientCopy.addresses.unshift(response)
				} else {
					clientCopy.addresses = []
					clientCopy.addresses.push(response)
				}

				setClient(clientCopy)
				handleSelectAddress(clientCopy, response, 0)
			}
		}
	}

	function isDisable() {
		if (!data.products.length) {
			return true;
		}
		if (!data.pos_id) {
			return true;
		}
		if (Number(data.distance) > Number(reduxAccount.max_delivery_distance)) {
			return true;
		}
		return false
	}

	async function getComments() {
		var comments = await GET('/comments')
		if (httpOk(comments)) setComments(comments.data)
	}

	async function getData() {
		var menu = []
		var fastMenu = []
		var poses = []

		if (reduxDelivery?.menu?.length) {
			menu = reduxDelivery.menu
		}

		if (reduxDelivery?.fastMenu?.length) {
			fastMenu = reduxDelivery.fastMenu
		}

		if (reduxDelivery?.poses?.length) {
			poses = reduxDelivery.poses
		}

		if (poses.length > 0 && poses[0]['longitude']) {
			setDefaultState({
				center: [poses[0]['longitude'], poses[0]['latitude']],
				zoom: 11,
				controls: ["zoomControl"]
			})
		}

		if (typeof fastMenu !== 'object') {
			fastMenu = []
		}

		setMenu(menu)
		setFastMenu(fastMenu)
		setSelectedMenu(menu[0])

		setData({
			...data,
			'people_number': 1,
			'service_percentage': 0,
		})

		if (cheque_id) {
			var response = await GET(`/cheque/cheque/${cheque_id}`)
			if (httpOk(response)) {
				setData(response.data)
			}
		}
	}

	async function generatePromocode(item) {
		var sendData = {
			'id': item.id,
			'client_id': client.id,
		}

		await POST('/discount/promo-codes/generate', sendData, { 'loader': true })
	}

	async function sendSMS(item) {
		var sendData = {
			'id': item.id,
			'client_id': client.id,
		}

		await POST('/sms/send-template', sendData, { 'loader': true })
	}

	function returnRole(role) {
		if (role === 'ROLE_OPERATOR')
			return t('call_center')

		if (role === 'ROLE_COURIER')
			return t('courier')

		if (role === 'ROLE_CASHIER')
			return t('cashier')
	}

	function toggleModalClient(bool = false, item = {}) {
		setModalClient(bool)
		setSelectedItem(item)
	}

	function toggleModalAddressDelete(bool = false, address_id = null) {
		setModalAddressDelete({ 'bool': bool, 'address_id': address_id })
	}

	function handleClientChange(e) {
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy[e.target.name] = e.target.value
		setSelectedItem(selectedItemCopy)
	}

	async function updateClient(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.phone1 = '998' + regexNumeric(selectedItemCopy.phone1)
		if (selectedItemCopy.phone2)
			selectedItemCopy.phone2 = '998' + regexNumeric(selectedItemCopy.phone2)

		var response = await PUT('/clients', selectedItemCopy, { loader: true });

		if (httpOk(response)) {
			toggleModalClient(false)
			if (selectedItemCopy?.phone2) {
				selectedItemCopy.phone2 = selectedItemCopy?.phone2?.substring(3, 12)
			}
			setClient({
				...client,
				'phone2': selectedItemCopy.phone2,
				'first_name': selectedItemCopy.first_name,
				'gender': selectedItemCopy.gender,
				'birthday': selectedItemCopy.birthday,
				'note': selectedItemCopy.note,
			})
		}
	}

	async function deleteClientAddress() {
		var sendData = {
			'client_id': client.id,
			'address_id': modalAddressDelete.address_id,
		}

		var response = await DELETE('/clients-address', sendData, { 'loader': true })
		if (httpOk(response)) {
			toggleModalAddressDelete(false)

			var clientCopy = { ...client }
			var index = clientCopy.addresses.findIndex(x => x.id !== modalAddressDelete.id)
			if (index !== -1 && index !== undefined) {
				clientCopy.addresses.splice(index, 1)
				setClient(clientCopy)
			}
		}
	}

	async function toggleModalCheques(bool = false) {
		setModalCheques(bool)
		if (bool) {
			var filterDataCopy = { ...filterData }
			var filterUrl = "/cheques"
			var urlParams = ""

			if (client.phone1)
				urlParams += returnSign(urlParams) + 'client_phone=998' + client.phone1
			if (filterDataCopy.status)
				urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
			if (filterDataCopy.start_date)
				urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
			if (filterDataCopy.end_date)
				urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
			filterUrl = filterUrl + urlParams

			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setCheques(response.data)
			}
		} else {
			setCheques([])
		}
	}

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...cheques.data]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}
		if (boolean) {
			const response = await GET(`/cheque-params/${item.id}`, {}, { loader: true })
			if (httpOk(response)) {
				dataCopy[index]['products'] = response.data.products
				dataCopy[index]['payments'] = response.data.payments
				dataCopy[index]['loyalty'] = response.data.loyalty
				dataCopy[index]['showSidebar'] = boolean

				setSelectedItem(dataCopy[index])
				setShowRightBar(boolean)
			}

		} else {
			setShowRightBar(boolean)
			setSelectedItem({})
		}
	}

	function returnClassStatus(order_type) {
		if (order_type === 1) {
			return "bg-primary"
		}
		if (order_type === 2) {
			return "bg-success"
		}
		if (order_type === 3) {
			return "bg-warning"
		}
	}

	async function handleSelectTab(tabNumber) {
		var response
		if (tabNumber === 2) {
			response = await POST('/delivery/client/cheques', {
				'client_id': client.id
			}, { loader: true });

			if (httpOk(response)) {
				setCheques(response.data)
			}
		}

		if (tabNumber === 6) {
			response = await GET('/helper/discount-promocodes')
			if (httpOk(response)) setPromocodes(response.data)
		}

		if (tabNumber === 7) {
			response = await GET('/helper/sms-templates?sms_type_id=3')
			if (httpOk(response)) setSmsTemplates(response.data)
		}


		setData({ ...data, 'active_tab': tabNumber })
	}

	function returnActiveTabStyle(number) {
		if (number === 1) {
			if (data.active_tab === 1) {
				return 'active'
			}
		}

		if (number === 2) {
			if (data.active_tab === 2 && client.id) {
				return 'active'
			}
			if (data.active_tab !== 2 && client.id) {
				return ''
			}
			if (data.active_tab !== 2 && !client.id) {
				return 'disabled'
			}
		}

	}

	function repeatOrder(item) {
		var dataCopy = { ...data }

		dataCopy.products = []
		for (let i = 0; i < item?.products?.length; i++) {
			var found = reduxDelivery.products.find(p => p.product_id === item.products[i]['product_id']);
			found.status = 0
			found.quantity = item.products[i]['quantity']
			dataCopy.products.push(found)
		}

		dataCopy.active_tab = 1
		calculateTotalAmount(dataCopy)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (client?.phone1?.length === 9) {
			searchClient()
		}
	}, [client.phone1]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="waiter-footer w-100">
				<div className="waiter-footer-item"
					onClick={() => goTo('back')}>
					<i className="uil uil-arrow-left fz-30"></i>
				</div>
				<div className={`waiter-footer-item ${returnActiveTabStyle(1)}`}
					onClick={() => handleSelectTab(1)}>
					{t('menu')}
				</div>
				<div className={`waiter-footer-item ${returnActiveTabStyle(2)}`}
					onClick={() => handleSelectTab(2)}>
					{t('history')}
				</div>
				<div className={`waiter-footer-item disabled`}>
					{t('discounts')}
				</div>
				<div className={`waiter-footer-item disabled`}>
					{t('payment')}
				</div>
				<div className={`waiter-footer-item disabled`}>
					{t('Черновик')}
				</div>
				<div className={`waiter-footer-item ${client.id ? '' : 'disabled'}`}
					onClick={() => handleSelectTab(6)}>
					{t('promo_code')}
				</div>
				<div className={`waiter-footer-item ${client.id ? '' : 'disabled'}`}
					onClick={() => handleSelectTab(7)}>
					SMS
				</div>
				<div className={`waiter-footer-item ${client.id ? '' : 'disabled'}`}
					onClick={() => toggleModalCheques(true)}>
					{t('cheques')} <div className="waiter-footer-count">{formatMoney(client.count_cheques)}</div>
				</div>
			</div>

			<div className="d-flex justify-content-center">
				{data.active_tab === 1 &&
					<>
						<div className="w-100">
							<div className="waiter-header">
								<div className="header-action-wrapper">
									{(
										data?.selectedProduct?.bool_modifiers ||
										data?.selectedProduct?.size_parent_id
									) ?
										<>
											{data?.selectedProduct?.bool_modifiers &&
												<div className="header-action-button"
													onClick={() => {
														setModifiers([...data?.selectedProduct?.modifiers])
														setModalModifiers(true)
													}}>
													<i className="uil uil-cell"></i>
													<div className="text-center">{t('modification')}</div>
												</div>
											}
											{data?.selectedProduct?.size_parent_id &&
												<div className="header-action-button"
													onClick={() => changeProductSize()}>
													<i className="uil uil-sync"></i>
													<div className="text-center">{t('sizes')}</div>
												</div>
											}
										</>
										:
										<div className="vertical-center">
											<h4>{t('new_order')}</h4>
											{client.note &&
												<h5 className="d-flex">
													<b>{t('note')}: <span className="text-danger">{client.note}</span></b>
												</h5>
											}
										</div>
									}
								</div>
							</div>

							<div className="waiter-body">
								<div className="w-33 position-relative">
									<div className="order-info">
										<div className="vertical-center order-info-left">{t('order')}</div>
										<div className="d-flex" style={{ 'width': '40px' }}>
											<input type="number" className="form-control text-center"
												value={data.people_number}
												onChange={(e) => {
													if (e.target.value <= 50) {
														setData({ ...data, 'people_number': e.target.value })
													}
												}} />
										</div>
										<div className="d-flex d-none">
											<div className="order-info-left-time">
												{data.created_at ? newDate(data.created_at, 'HH:mm') : ''}
											</div>
										</div>
									</div>
									<div className="order-left" ref={scrollToBottomRef}>
										{data?.products?.map((item, index) => (
											<div className={`order-product${item.status === -1 ? ' del' : ''}${item.selected ? ' active' : ''}`} key={index}
												onClick={() => {
													if (!item.cheque_id) {
														selectProduct(index)
													}
												}}>
												<div className="d-flex">
													<div className="order-product-quantity"
														style={item.in_product === 3 ? { 'fontStyle': 'italic' } : {}}>
														<div className="d-flex">
															{item.in_product === 3 &&
																<span>x</span>
															}
															<span>{item.quantity}</span>
														</div>
													</div>
													<div className="order-product-info">
														<div className="order-product-name">
															{item.name}
														</div>
														<div className="order-product-comment">
															{item.comment}
														</div>
													</div>
												</div>
												<div className="order-product-price">
													{formatMoney(item.total_price)} {t('sum')}
												</div>
											</div>
										))}
									</div>
									<div className="order-info-total w-100">
										<div className="d-flex justify-content-between fz-12">
											<div className="vertical-center order-info-left">{t('amount')}</div>
											<div className="vertical-center order-info-left">{formatMoney(data.total_amount)} {t('sum')}</div>
										</div>
										<div className="d-flex justify-content-between fz-12">
											<div className="vertical-center order-info-left">{t('delivery')}</div>
											<div className="vertical-center order-info-left">{formatMoney(data.delivery_price)} {t('sum')}</div>
										</div>

										<div className="d-flex justify-content-between fz-16">
											<div className="vertical-center order-info-left">{t('total')}</div>
											<div className="vertical-center order-info-left">{formatMoney(data.total_amount_service)} {t('sum')}</div>
										</div>
									</div>
									<div className="order-action-bar w-100">
										<button className="btn-footer"
											disabled
											onClick={() => { }}>
											<i className="uil uil-ellipsis-v"></i>
										</button>
										<button className="btn-footer"
											disabled={!(data.selectedIndex >= 0)}
											onClick={() => deleteSelectedProduct()}>
											<i className="uil uil-times"></i>
										</button>
										<button className="btn-footer"
											disabled={!(data.selectedIndex >= 0)}
											onClick={() => changeSelectedProductQuantity()}>
											123
										</button>
										<button className="btn-footer"
											disabled={!(data.selectedIndex >= 0)}
											onClick={() => instantChangeSelectedProductQuantity('-')}>
											<i className="uil uil-minus"></i>
										</button>
										<button className="btn-footer"
											disabled={!(data.selectedIndex >= 0)}
											onClick={() => instantChangeSelectedProductQuantity('+')}>
											<i className="uil uil-plus"></i>
										</button>
									</div>
								</div>

								<div className="order-right no-select">
									<div className="w-50 order-products-wrapper no-scroll">
										<div className="d-flex flex-wrap gap-2 pb-2">
											{selectedMenu?.products?.map((item, index) => (
												<div className="order-item-box" key={index}
													style={{
														'backgroundColor': (item.bg_color ? item.bg_color : '#8A61FF'),
														'color': (item.color ? item.color : '#fff')
													}}
													onClick={() => {
														if (item?.sizes?.length > 0) {
															selectSize(item)
														} else {
															addToList(item)
														}
													}}>
													<div className="text-center ellipse-3">
														{item.name}
													</div>
												</div>
											))}
										</div>
									</div>
									<div className="w-50 order-products-wrapper no-scroll">
										{fastMenu?.length > 0 &&
											<div className="d-flex gap-2 mb-2">
												{fastMenu?.map((item, index) => (
													<div className="order-item-box bg-success" key={index}
														onClick={() => selectMenu(index, 'fastMenu')}>
														<div className="text-center">
															{item.name}
														</div>
													</div>
												))}
											</div>
										}
										<div className="d-flex flex-wrap gap-2">
											{menu.map((item, index) => (
												<div className="order-item-box-category"
													style={{
														'backgroundColor': (item.bg_color ? item.bg_color : '#fff'),
														'color': (item.color ? item.color : '#1e1d1d')
													}}
													key={index}
													onClick={() => selectMenu(index)}>
													<div className="text-center ellipse-3">{item.name}</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				}
				{data.active_tab === 2 &&
					<>
						<div className="w-100">
							<div className="table-responsive">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th style={{ 'width': '10%' }}>ID</th>
											<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
											<th style={{ 'width': '30%' }}>{t('products')}</th>
											<th style={{ 'width': '30%' }}>{t('address')}</th>
											<th style={{ 'width': '10%' }} className="text-end">{t('total')}</th>
										</tr>
									</thead>
									<tbody>
										{cheques.map((item, index) => (
											<tr key={index}>
												<td>{item.id}</td>
												<td className="text-center">{formatDate(item.cheque_opened_at)}</td>
												<td>
													{item.products.map((item, index) => (
														<div key={index}>
															x{item.quantity} {item.product_name}
														</div>
													))}
												</td>
												<td>
													<div>{item.name}</div>
													<div>
														{item.apartment &&
															<span>{t('apartment')}: {item.apartment},</span>
														}
														{item.floor &&
															<span>{t('floor')}: {item.floor},</span>
														}
														{item.entrance &&
															<span>{t('entrance')}: {item.entrance},</span>
														}
														{item.intercom &&
															<span>{t('intercom')}: {item.intercom},</span>
														}
													</div>
												</td>
												<td className="text-end position-relative">
													<div style={{ 'height': '70px' }}>
														{formatMoney(item.total_amount_service)}
													</div>
													<button className="btn btn-success btn-sm w-100"
														style={{ 'position': 'absolute', 'left': 0, 'bottom': 0 }}
														onClick={() => repeatOrder(item)}>
														{t('repeat')}
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</>
				}
				{data.active_tab === 6 &&
					<div className="w-100 p-2">
						<div className="card" style={{ 'height': 'calc(100vh - 70px)' }}>
							<div className="card-body">
								{promocodes?.map((item, index) => (
									<button type="button" className="btn-footer" key={index}
										style={{ 'width': '140px', 'height': '55px' }}
										onClick={() => generatePromocode(item)}>
										{item.name}
									</button>
								))}
							</div>
						</div>
					</div>
				}
				{data.active_tab === 7 &&
					<div className="w-100 p-2">
						<div className="card" style={{ 'height': 'calc(100vh - 70px)' }}>
							<div className="card-body">
								{smsTemplates?.map((item, index) => (
									<button type="button" className="btn-footer" key={index}
										style={{ 'width': '140px', 'height': '55px' }}
										onClick={() => sendSMS(item)}>
										{item.name}
									</button>
								))}
							</div>
						</div>
					</div>
				}
				<div className="order-client-wrapper bg-white px-2" style={{ minWidth: '500px' }}>
					<div>
						<div className="d-flex gap-2">
							<div className="w-50">
								<div className="form-group">
									<label>{t('phone')}<span className="required-mark">*</span></label>
									<div className="input-group">
										<span className="input-group-text">998</span>
										<input type="number" className="form-control" name="phone1" autoFocus
											ref={refPhoneInput}
											value={client.phone1 ?? ''}
											onChange={(e) => {
												if (e.target.value.length < 10) {
													setClient({ ...client, 'phone1': e.target.value })
												}
											}}
										/>
										<button className="input-button-right delivery"
											disabled={!client.id}
											onClick={() => toggleModalClient(true, client)}>
											<i className="uil uil-edit-alt input-icon"></i>
										</button>
									</div>
								</div>

								<div className="d-flex mb-1">
									<div className="form-check">
										<div className="radio-round"
											onClick={() => handleChangeOrderType(3)}
											style={{ 'backgroundColor': orderType === 3 ? 'black' : '' }}>
										</div>
										<span onClick={() => handleChangeOrderType(3)}>
											{t('delivery')}
										</span>
									</div>
									<div className="form-check">
										<div className="radio-round"
											onClick={() => handleChangeOrderType(2)}
											style={{ 'backgroundColor': orderType === 2 ? 'black' : '' }}>
										</div>
										<span onClick={() => handleChangeOrderType(2)}>
											{t('takeaway')}
										</span>
									</div>
								</div>

							</div>
							<div className="w-50 d-flex flex-column">
								<div className="d-flex justify-content-between">
									<div>{t('name')}</div>
									<div>
										{client.first_name ?
											<span>{client.first_name}</span>
											:
											<span>-</span>
										}
									</div>
								</div>

								<div className="d-flex justify-content-between">
									<div>{t('loyalty')}</div>
									<div>
										{client.loyalty_balance ?
											<span>{formatMoney(client.loyalty_balance)}</span>
											:
											<span>-</span>
										}
									</div>
								</div>

								<div className="d-flex justify-content-between align-items-center">
									<div>{t('telegram')}</div>
									<div>
										{client.id ?
											<i className={`uil uil-telegram fz-20 ${client.telegram_user_id ? 'text-primary' : 'text-danger'}`}
												title="Клиент не Зарегистрирован в боте">
											</i>
											:
											<span>-</span>
										}
									</div>
								</div>
							</div>
						</div>

						<hr className="my-2" />

						<div className="d-flex">
							<div className="w-50 pe-2">
								<div className="form-group">
									<label>{t('poses')}</label>
									<Select
										isDisabled={!(client.id)}
										isClearable
										options={reduxDelivery?.poses}
										value={reduxDelivery?.poses.find(option => option.id === data.pos_id) ?? null}
										onChange={(option) => handleSelectPos(option)}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>

								{!!reduxAccount?.settings?.bool_courier_select &&
									<div className="form-group">
										<label>{t('courier')}</label>
										<Select
											menuPosition="fixed"
											value={couriers.find(option => option.id === data.courier_id)}
											options={couriers}
											onChange={(option) => setData({ ...data, 'courier_id': option.id })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
								}

								{(!client.id && newAddress.address) &&
									<div className={`client_address ${newAddress?.new_address !== t('new_address') ? 'active' : ''}`}
										onClick={() => setModalSelectClient(true)}>
										<div>{newAddress.new_address}</div>
										<b className="fz-12">{newAddress.pos_name} [{newAddress.distance}]km</b>
									</div>
								}

								{client?.addresses?.length > 0 &&
									<>
										{client?.addresses?.map((item, index) => (
											<div key={index}
												className={`client_address ${client?.address_id === item.id ? 'active' : ''}`}>
												<div className="max-lines-3"
													onClick={() => {
														if (client.address_id === item.id) {
															clearSelectedAddress()
															return;
														}
														if (!item.id) {
															setClient({ ...client, 'address_id': item.id, 'address': '' })
														} else {
															handleSelectAddress(client, item, index)
														}
													}}>
													{item.name}
												</div>

												<div className="client_address_btn">
													<div className="table-action-button table-action-danger-button"
														style={{ 'width': '20px', 'height': '20px' }}
														onClick={() => toggleModalAddressDelete(true, item.id)}>
														<i className="uil-times"></i>
													</div>
												</div>
												<div className="client_address_km">
													{((client?.address_id === item.id) && item.pos_name) &&
														<b className="fz-12">{item.distance} km</b>
													}
												</div>
												{item.created_by &&
													<div className="position-absolute text-warning fz-12"
														style={{ 'bottom': '2px', 'right': '3px' }}
														title={item.created_by}>
														<b>{returnRole(item.role)}</b>
													</div>
												}
											</div>
										))}
									</>
								}
								{client?.addresses?.length === 0 &&
									<>
										{[{}, {}, {}, {}].map((item, index) => (
											<div key={index} className="client_address" style={{ 'backgroundColor': '#f2f2f2' }}>
											</div>
										))}
									</>
								}
							</div>

							<div className="w-50">
								{orderType === 3 &&
									<>
										<div className="form-group">
											<label>{t('coordinates')}</label>
											<div className="position-relative">
												<input type="text" className="form-control" name="coordinates"
													disabled={!(client.id)}
													autoComplete="off"
													value={data.coordinates ?? ''}
													onChange={(e) => {
														parseCoordinates(e.target.value)
													}}
												/>
												<button className="input-button-right delivery"
													onClick={() => setModalSelectClient(true)}>
													<i className="uil uil-map-marker input-icon"></i>
												</button>
											</div>
										</div>

										<div className="d-flex gap-2">
											<div className="form-group w-50">
												<label>{t('apartment')}</label>
												<input type="text" className="form-control" name="apartment"
													disabled={!(client.address_id)}
													autoComplete="off"
													value={data.apartment ?? ''}
													onChange={(e) => setData({ ...data, 'apartment': e.target.value })}
												/>
											</div>
											<div className="form-group w-50">
												<label>{t('floor')}</label>
												<input type="text" className="form-control" name="floor"
													disabled={!(client.address_id)}
													autoComplete="off"
													value={data.floor ?? ''}
													onChange={(e) => setData({ ...data, 'floor': e.target.value })}
												/>
											</div>
										</div>

										<div className="d-flex gap-2">
											<div className="form-group w-50">
												<label>{t('entrance')}</label>
												<input type="text" className="form-control" name="entrance"
													disabled={!(client.address_id)}
													autoComplete="off"
													value={data.entrance ?? ''}
													onChange={(e) => setData({ ...data, 'entrance': e.target.value })}
												/>
											</div>
											<div className="form-group w-50">
												<label>{t('intercom')}</label>
												<input type="text" className="form-control" name="intercom"
													disabled={!(client.address_id)}
													autoComplete="off"
													value={data.intercom ?? ''}
													onChange={(e) => setData({ ...data, 'intercom': e.target.value })}
												/>
											</div>
										</div>
										<div className="form-group">
											<label>{t('navigate')}</label>
											<textarea type="text" className="form-control" name="navigate"
												style={{ minHeight: '68px' }}
												disabled={!(client.address_id)}
												autoComplete="off"
												value={data.navigate ?? ''}
												onChange={(e) => setData({ ...data, 'navigate': e.target.value })}
											/>
										</div>
									</>
								}
							</div>
						</div>

						<div className="d-flex justify-content-center w-100">
							<div className="form-check delivery">
								<div className="radio-round"
									onClick={() => setData({ ...data, 'payment_type_id': 1 })}
									style={{ 'backgroundColor': data.payment_type_id === 1 ? 'black' : '' }}>
								</div>
								<span onClick={() => setData({ ...data, 'payment_type_id': 1 })}>
									{t('cash')}
								</span>
							</div>

							<div className="form-check delivery">
								<div className="radio-round"
									onClick={() => setData({ ...data, 'payment_type_id': 2 })}
									style={{ 'backgroundColor': data.payment_type_id === 2 ? 'black' : '' }}>
								</div>
								<span onClick={() => setData({ ...data, 'payment_type_id': 2 })}>
									{t('terminal')}
								</span>
							</div>

							<div className="form-check delivery">
								<div className="radio-round"
									onClick={() => setData({ ...data, 'payment_type_id': 4 })}
									style={{ 'backgroundColor': data.payment_type_id === 4 ? 'black' : '' }}>
								</div>
								<span onClick={() => setData({ ...data, 'payment_type_id': 4 })}>
									{t('loyalty')}
								</span>
							</div>
						</div>

						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								autoComplete="off"
								value={data.note ?? ''}
								onChange={(e) => setData({ ...data, 'note': e.target.value })}
								rows={5}
							/>
						</div>

						{Number(data.distance) > Number(reduxAccount.max_delivery_distance) &&
							<div className="text-danger text-center">
								{t('maximum_delivery_distance')} {reduxAccount.max_delivery_distance} km
							</div>
						}
					</div>

					<div>
						<div className="d-flex gap-2">
							<div className="d-flex align-items-center">
								<i className="uil uil-restaurant me-3 fz-20"></i>
								<i className="uil uil-minus cursor"
									onClick={() => {
										if (data.eating_kit > 1) {
											setData({ ...data, 'eating_kit': data.eating_kit - 1 })
										}
									}}>
								</i>
								<input type="text" className="delivery-small-input"
									value={data.eating_kit ?? ''}
									onChange={() => { }} />
								<i className="uil uil-plus cursor"
									onClick={() => setData({ ...data, 'eating_kit': data.eating_kit + 1 })}>
								</i>
							</div>

							<button className="delivery-small-btn" disabled>
								<i className="uil uil-comment-alt-message"></i>
							</button>
							<button className="delivery-small-btn" disabled>
								<i className="uil uil-percentage"></i>
							</button>
							<button className="delivery-small-btn" disabled>
								<i className="uil uil-copy"></i>
							</button>
							<button className="delivery-small-btn"
								onClick={() => clearData()}>
								<i className="uil uil-trash-alt"></i>
							</button>
						</div>

						<div className="d-flex">
							{data.id &&
								<div className="position-relative h-100">
									<button className="btn-footer h-100">
										{t('print')}
									</button>
								</div>
							}
							<button className="delivery-btn"
								disabled={isDisable()}
								onClick={() => createUpdate()}>
								{t('save')}
							</button>
						</div>
					</div>
				</div>
			</div>

			{/* MODAL CHEQUES */}
			<Modal show={modalCheques} animation={false} centered size="xl"
				dialogClassName="update-modal-width"
				onHide={() => toggleModalCheques(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('cheques')} [{cheques.total}]</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="filter-block">
						<div className="filter">
							<strong>{t('filter')}</strong>
							<br />
							{(filterData.start_date || filterData.end_date) &&
								<span className="me-2">
									<b>{t('period')}: </b>
									{filterData.start_date &&
										<span>{formatDate(filterData.start_date)}</span>
									}
									{(filterData.start_date && filterData.end_date) &&
										<span>|</span>
									}
									{filterData.end_date &&
										<span>{formatDate(filterData.end_date)}</span>
									}
								</span>
							}
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-bordered table-hover mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('pos')}</th>
									<th style={{ 'width': '20%' }}>{t('cashier')}</th>
									<th style={{ 'width': '20%' }}>{t('waiter')}</th>
									<th style={{ 'width': '10%' }} className=" text-center">
										{t('cheque')} №
									</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('open_date')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('total')}</th>
								</tr>
							</thead>
							<tbody>
								{cheques?.data?.map((item, index) => (
									<tr className={`cursor ${item.showSidebar ? 'table-tr-active' : ''}`} key={index}>
										<td className="text-center">
											<CustomTooltip text={item.id}>
												{item.row_number}
											</CustomTooltip>
										</td>
										<td className="td-ellipsis" onClick={() => toggleRightbar(true, item, index)}>
											{item.pos_short_name}
										</td>
										<td className="td-ellipsis" onClick={() => toggleRightbar(true, item, index)}>
											{item.cashier_first_name}
										</td>
										<td className="td-ellipsis" onClick={() => toggleRightbar(true, item, index)}>
											{item.waiter_first_name}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)}
											className={`text-center text-white ${returnClassStatus(item.order_type)}`}>
											{item.cheque_number}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)} className="text-center">
											{formatDate(item.cheque_opened_at, 'dd.MM.yy HH:mm')}
										</td>
										<td className={`text-end text-nowrap ${item.client_debtor_id ? 'bg-danger text-white' : ''}`}
											onClick={() => toggleRightbar(true, item, index)}>
											{formatMoney(item.total_amount_service)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL CHEQUES */}

			{/* MODAL MODIFICATION */}
			<Modal show={modalModification.bool} animation={false} centered
				dialogClassName="modal-width-product-change" size="lg"
				onHide={() => setModalModification({ ...modalModification, 'bool': false, 'index': '', 'tabNumber': 1 })}>
				<Modal.Body style={{ 'height': '550px' }}>
					<div className="product-detail-tabs mb-3">
						<div className={`item ${modalModification.tabNumber === 1 ? 'active' : ''}`}
							onClick={() => setModalModification({ ...modalModification, 'tabNumber': 1 })}>
							<i className="uil uil-edit-alt"></i>
							{t('quantity')}
						</div>
						<div className={`item ${modalModification.tabNumber === 2 ? 'active' : ''}`}
							onClick={() => setModalModification({ ...modalModification, 'tabNumber': 2 })}>
							<i className="uil uil-clipboard-notes"></i>
							{t('ingredients')}
						</div>
						<div className={`item ${modalModification.tabNumber === 3 ? 'active' : ''}`}
							onClick={() => {
								getComments()
								setModalModification({ ...modalModification, 'tabNumber': 3 })
							}}>
							<i className="uil uil-comment"></i>
							{t('comments')}
						</div>
					</div>
					{modalModification.tabNumber === 1 &&
						<div className="d-flex justify-content-center">
							<div style={{ 'width': '345px' }}>
								<>
									<div className="d-flex mb-2" style={{ 'gap': '12px' }}>
										<div className="numbers-item v-hidden"></div>
										<input type="text" className="order-payment-input"
											autoFocus
											value={modalModification.product.quantity ? modalModification.product.quantity : ''}
										/>
										{modalModification?.product?.quantity?.includes('.') ?
											<div className="numbers-item"
												onClick={incrementDouble}>
												<div className="numbers-item-inner">
													x1
												</div>
											</div>
											:
											<div className="numbers-item v-hidden"></div>
										}
									</div>
									<div className="numbers-wrapper">
										{numbers.map((item, index) => (
											<div className="numbers-item" key={index}
												onClick={() => clickProductQuantity(item.id)}>
												<div className="numbers-item-inner">
													{item.name}
												</div>
											</div>
										))}
									</div>
									<div className="numbers-wrapper" style={{ 'marginTop': '12px' }}>
										<button className="numbers-item"
											disabled={!modalModification?.product?.bool_devisible}
											onClick={() => clickProductQuantity('.')}>
											<div className="numbers-item-inner">
												.
											</div>
										</button>
										<div className="numbers-item" onClick={() => clickProductQuantity('0')}>
											<div className="numbers-item-inner">
												0
											</div>
										</div>
										<div className="numbers-item" onClick={() => clickProductQuantity('C')}>
											<div className="numbers-item-inner">
												C
											</div>
										</div>
									</div>
								</>
								<div className="d-flex mt-3">
									<button
										disabled={!modalModification.product.quantity}
										style={{ 'height': '60px' }}
										className="btn btn-primary w-100"
										onClick={acceptModificationChange}>
										{t('confirm')}
									</button>
								</div>
							</div>
						</div>
					}
					{modalModification.tabNumber === 2 &&
						<div className="">
							<div style={{ 'height': '390px', 'overflow': 'auto' }}>
								{modalModification?.product?.ingredients?.map((item, index) => (
									<Fragment key={index}>
										{item.changeable &&
											<>
												<div className={`product-ingredients-wrapper mb-4 ${item.del ? 'del' : ''}`}
													style={{ 'borderBottom': '1px solid #000' }}
													onClick={() => deleteIngredient(index)}>
													<div className="product-ingredients-left">{item.name}</div>
													<div className="product-ingredients-right">
														<div className="me-1">{item.quantity}</div>
														<div className="me-3">{item.unit_name}</div>
													</div>
												</div>
											</>
										}
									</Fragment>
								))}
							</div>
							<div className="d-flex mt-3">
								<button
									style={{ 'height': '60px' }}
									className="btn btn-danger w-100 me-3"
									onClick={() => setModalModification({ ...modalModification, 'bool': false, 'index': '', 'tabNumber': 1 })}>
									{t('cancel')}
								</button>
								<button
									disabled={!modalModification.product.quantity}
									style={{ 'height': '60px' }}
									className="btn btn-primary w-100"
									onClick={acceptModificationChange}>
									{t('confirm')}
								</button>
							</div>
						</div>
					}
					{modalModification.tabNumber === 3 &&
						<div className="row">
							{comments?.map((item, index) => (
								<div className="col-md-6" key={index}>
									<button className="btn btn-success w-100 mb-3" style={{ 'height': '60px' }}
										onClick={() => addComment(item.name)}>
										{item.name}
									</button>
								</div>
							))}
						</div>
					}
				</Modal.Body>
			</Modal>
			{/* MODAL MODIFICATION */}

			{/* MODAL MODIFIER */}
			<Modal show={modalModifiers} animation={false} centered size="sm"
				onHide={() => setModalModifiers(false)}>
				<Modal.Body>
					<div className="addition-wrapper no-scroll">
						{modifiers?.map((item, index) => (
							<div key={index}>
								{item.product_id ?
									<div className="addition-wrapper-item" style={{ 'backgroundColor': '#CE34AC' }}
										onClick={() => addToList(item)}>
										{item.name}
									</div>
									:
									<h5>{item.name}</h5>
								}
								{item?.products?.map((product, index2) => (
									<div key={index2}>
										<div className="addition-wrapper-item" style={{ 'backgroundColor': '#CE34AC' }}
											onClick={() => addToList(product)}>
											{product.name}
										</div>
									</div>
								))}
							</div>
						))}
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL MODIFIER */}

			{/* MODAL SIZES */}
			<Modal show={modalSizes.bool} animation={false} centered size="sm"
				onHide={() => setModalSizes({
					'bool': false,
					'replace': false,
					'index': '',
				})}>
				<Modal.Body>
					<div className="addition-wrapper">
						{sizes?.map((item, index) => (
							<div key={index}>
								<div className="addition-wrapper-item" style={{ 'backgroundColor': '#26AEFB' }}
									onClick={() => {
										if (modalSizes.replace) {
											replaceSize(item)
										} else {
											addToList(item)
										}
									}}>
									{item.name}
								</div>
							</div>
						))}
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL SIZES */}

			{/* MODAL MAP */}
			<Modal show={modalSelectClient} animation={false} dialogClassName="custom-delivery-modal"
				backdrop="static" onHide={() => toggleModal(false, 'map')}>
				<Modal.Body>
					<div className="row">
						<div className="col-md-12" style={{ height: '80vh' }} >
							<YMaps
								style={{ height: '80vh' }}
								query={{
									load: "package.full",
									apikey: '1c08a7da-6cda-4a21-a1e7-a6f7d9ba19e6'
								}}>
								<Map
									defaultOptions={{
										copyrightUaVisible: false,
										yandexMapDisablePoiInteractivity: true,
									}}
									style={{ height: '80vh' }}
									defaultState={defaultState}
									onLoad={(e) => {
										ymaps.current = e;
									}}
									onClick={(event) => {
										const coords = event.get("coords");
										ymaps.current.geocode(coords).then((res) => {
											const firstGeoObject = res.geoObjects.get(0);
											findClosestPos(firstGeoObject, coords[0], coords[1]);
										});
									}}>
									<Placemark instanceRef={pointRef} geometry={[newAddress.longitude, newAddress.latitude]} />
									{reduxDelivery?.poses.map((item, index) => (
										<Placemark key={index} geometry={[Number(item.longitude), Number(item.latitude)]} />
									))}
									<SearchControl
										instanceRef={(initRef) => {
											if (initRef) {
												initRef.events.add('resultselect', (e) => {
													var index = initRef.getSelectedIndex(e);
													var item = initRef.getResult(index)
													setClient({
														...client, 'latitude': item._value.geometry._coordinates[0], 'longitude': item._value.geometry._coordinates[1],
														'new_address': initRef.getRequestString(), 'address': initRef.getRequestString(),
													})
												})
											}
										}}
										options={{
											position: {
												top: '10px',
												left: '34%',
											},
											fitMaxWidth: true,
											noPlacemark: true,
											maxWidth: '500px'
										}}
										style={{
											height: '50px',
										}}
									/>
								</Map>
							</YMaps>
						</div>
					</div>
					<div className="d-flex w-100 mt-3">
						<button type="button" className="btn btn-outline-warning w-100 me-2" style={{ 'flex': 1 }}
							onClick={() => toggleModal(false, 'map')}>
							{t('cancel')}
						</button>
						{/* <button type="button" className="btn btn-secondary w-100 me-2" style={{ 'flex': 1 }}
							disabled={disable || data.products.length === 0}
							onClick={() => createUpdate({ 'status': 0 })}>
							<i className="uil uil-clock"></i>
						</button> */}
						<button type="submit" className="btn btn-primary w-100" style={{ 'flex': 3 }}
							onClick={() => createUserAddress()}>
							{newAddress?.distance ?
								<span className="ms-3">{newAddress?.pos_name} {newAddress?.distance}km </span>
								:
								<span>{t('save')}</span>
							}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL MAP */}

			{/* MODAL CLIENT */}
			<Modal show={modalClient} animation={false} centered backdrop="static"
				dialogClassName="update-modal-width" onHide={() => toggleModalClient(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('edit')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => updateClient(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('name')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="first_name"
								value={selectedItem.first_name ?? ''}
								onChange={(e) => handleClientChange(e)} />
						</div>
						<div className="form-group">
							<label>{t('additional_phone')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone2"
									value={selectedItem.phone2 ?? ''}
									onChange={(e) => handleClientChange(e)} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('birthday')}</label>
							<input type="date" className="form-control" name="birthday"
								value={selectedItem.birthday ?? ''}
								onChange={(e) => handleClientChange(e)} />
						</div>
						<div className="d-flex justify-content-between mb-2">
							<div className="d-flex gap-3">
								<div className="form-check p-0">
									<div className="radio-round"
										onClick={() => setSelectedItem({ ...selectedItem, 'gender': 1 })}
										style={{ 'backgroundColor': selectedItem.gender === 1 ? 'black' : '' }}>
									</div>
									<span onClick={() => setSelectedItem({ ...selectedItem, 'gender': 1 })}>
										{t('man')}
									</span>
								</div>

								<div className="form-check p-0">
									<div className="radio-round"
										onClick={() => setSelectedItem({ ...selectedItem, 'gender': 2 })}
										style={{ 'backgroundColor': selectedItem.gender === 2 ? 'black' : '' }}>
									</div>
									<span onClick={() => setSelectedItem({ ...selectedItem, 'gender': 2 })}>
										{t('woman')}
									</span>
								</div>
							</div>
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								value={selectedItem.note ?? ''}
								onChange={(e) => handleClientChange(e)} />
						</div>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL CLIENT */}

			{/* MODAL ADDRESS DELETE */}
			<Modal show={modalAddressDelete.bool} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModalAddressDelete(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('delete')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('are_you_sure_you_want_delete')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => toggleModalAddressDelete(false)}>
							{t('cancel')}
						</button>
						<button className="btn btn-danger w-100"
							onClick={() => deleteClientAddress()}>
							{t('delete')}
						</button>
					</div>
				</Modal.Footer>
			</Modal>
			{/* MODAL ADDRESS DELETE */}

			<Rightbar
				showRightBar={showRightBar}
				setShowRightBar={setShowRightBar}
				selectedItem={selectedItem}>
			</Rightbar>
		</>
	)
}

export default OrderCreate
import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from "react-datepicker"

import { GET, POST, httpOk } from 'helpers/api'
import { findKeyFromArrayByValue, formatMoneyInput, returnSign } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		date: new Date(),
		year: new Date().getFullYear(),
		pos_id: reduxAccount.pos_id,
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/payment-purpose-plan"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.year)
			urlParams += returnSign(urlParams) + 'year=' + filterDataCopy.year

		if (`/payment-purpose-plan${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })

		if (httpOk(response)) {
			setData(response.data)

			setDropdownIsOpen(false)
		}
	}

	async function createUpdate() {
		await POST('/payment-purpose-plan', data, { loader: true })
	}

	function resetFilter() {
		setFilterData({
			pos_id: null
		})
		searchWithFilter()
		setFilterDataExist(false)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	function handleChangePlan(e, index, planIndex) {
		if (Number(e.target.value.replace(/[^0-9.]/g, '')) > 99999999999) return

		var dataCopy = [...data]
		dataCopy[index]['plans'][planIndex][e.target.name] = e.target.value.replace(/[^0-9.]/g, '')

		setData(dataCopy)
	}

	async function getPoses() {
		const response = await GET('/helper/poses', {}, { loader: true })
		if (httpOk(response)) {
			setPoses(response.data)
			searchWithFilter()
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center h-100">
					{t('expense_plan')} [{data.length}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => createUpdate()}>
						{t('save')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('date')}</label>
												<DatePicker
													className="form-control text-center"
													selected={filterData.date}
													onChange={(date) => setFilterData({ ...filterData, 'date': date, 'year': date.getFullYear() })}
													showYearPicker
													dateFormat="yyyy"
													yearItemNumber={6}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b> {findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.year &&
									<span className="me-2">
										<b>{t('year')}: </b> {filterData.year}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div style={{ 'overflow': 'auto' }}>
						<div className="table-resposive">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th className="text-nowrap">{t('type')}</th>
										<th>{t('january')}</th>
										<th>{t('february')}</th>
										<th>{t('march')}</th>
										<th>{t('april')}</th>
										<th>{t('may')}</th>
										<th>{t('june')}</th>
										<th>{t('july')}</th>
										<th>{t('august')}</th>
										<th>{t('september')}</th>
										<th>{t('october')}</th>
										<th>{t('november')}</th>
										<th>{t('december')}</th>
									</tr>
								</thead>
								<tbody>
									{data.map((item, index) => (
										<tr key={index}>
											<td className="text-nowrap">
												<b>{item.name}</b>
											</td>
											{item?.plans?.map((plan, planIndex) => (
												<td className="td-input" style={{ 'width': '115px' }} key={planIndex}>
													<input type="text" className="form-control"
														style={{ 'width': '115px' }}
														name="plan"
														autoComplete="off"
														value={formatMoneyInput(plan.plan) ?? ''}
														onChange={(e) => handleChangePlan(e, index, planIndex)} />
												</td>
											))}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
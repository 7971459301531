import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import CustomTooltip from 'pages/parts/CustomTooltip';
import { GET, POST, PUT, httpOk } from 'helpers/api'
import { copyText, formatDate, formatMoney } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const symbolTypes = [
		{ 'id': 1, 'name': 'Цифры' },
		{ 'id': 2, 'name': 'Латинские буквы' },
		{ 'id': 3, 'name': 'Цифры и латинские буквы' },
	]
	const [data, setData] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [showModalPromoCodeStatus, setShowModalPromoCodeStatus] = useState(false)
	const [selectedItem, setSelectedItem] = useState({ 'discount_payment_type': 2 })
	const [discountTypes, setDiscountTypes] = useState([])
	const [smsTemplates, setSmsTemplates] = useState([])

	async function createUpdate(e) {
		e.preventDefault()

		var response
		if (selectedItem.id) {
			response = await PUT('/discount/promo-codes', selectedItem, { loader: true })
		} else {
			response = await POST('/discount/promo-codes', selectedItem, { loader: true })
		}

		if (httpOk(response)) {
			toggleModal(false)
			getData()
		}
	}

	async function updatePromoCodeStatus(e) {
		e.preventDefault()

		var response = await PUT('/discount/promo-codes/status', selectedItem, { loader: true })

		if (httpOk(response)) {
			toggleModalPromoCodeStatus(false)
			getData()
		}
	}

	function toggleModal(bool, item = { 'discount_payment_type': 2 }) {
		setSelectedItem(item)
		setShowModal(bool)
	}

	function toggleModalPromoCodeStatus(bool, item = {}) {
		if (bool) {
			var sendData = {}
			sendData.id = item.id
			sendData.status = -1
			sendData.delete_discount_promo_codes_statuses = false
			setSelectedItem(sendData)
		} else {
			setSelectedItem(item)
		}
		setShowModalPromoCodeStatus(bool)
	}

	function handleChangeInput(e) {
		var selectedItemCopy = { ...selectedItem }

		if (e.target.name === 'prefix') {
			if (e.target.value.length <= 5) {
				selectedItemCopy[e.target.name] = e.target.value
			}
		}
		if (e.target.name === 'discount_payment_value') {
			selectedItemCopy[e.target.name] = e.target.value
		}
		if (e.target.name === 'suffix_length') {
			if (Number(e.target.value) <= 7) {
				selectedItemCopy[e.target.name] = e.target.value
			}
		}

		setSelectedItem(selectedItemCopy)
	}

	async function getData() {
		const response = await GET('/discount/promo-codes', {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	async function getDiscountTypes() {
		const response = await GET(`/helper/discount-types?type=promo_code`, {}, { loader: true })
		if (httpOk(response)) setDiscountTypes(response.data)
	}

	async function getSmsTemplates() {
		const response = await GET(`/helper/sms-templates?sms_type_id=2`, {}, { loader: true })
		if (httpOk(response)) setSmsTemplates(response.data)
	}

	useEffect(() => {
		getData()
		getDiscountTypes()
		getSmsTemplates()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('promocodes')} [{data.length}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '25%' }}>{t('type')}</th>
									<th style={{ 'width': '10%' }}>{t('name3')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('prefix')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('promo_code')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('discount')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('user')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('template')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-center text-nowrap">{t('status')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('created')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('activated')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('deleted')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center"
											onClick={() => copyText(item.id)}>
											<CustomTooltip text={item.id}>
												{index + 1}
											</CustomTooltip>
										</td>
										<td>{item.discount_type_name}</td>
										<td>{item.name}</td>
										<td className="text-center">{item.prefix}</td>
										<td className="text-center">{item.promo_code}</td>
										<td className="text-end">{item.discount_payment_value}</td>
										<td className="text-center">{item.created_by}</td>
										<td className={`${item.sms_template_id ? 'bg-primary' : 'bg-danger'}`}></td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										<td className="text-center">
											{item.status === 1 &&
												<span className="bg-primary bg-border text-white">{t('activated')}</span>
											}
											{item.status === -1 &&
												<span className="bg-danger bg-border text-white">{t('disconnected')}</span>
											}
										</td>
										<td className="text-end">{formatMoney(item.total_created)}</td>
										<td className="text-end">{formatMoney(item.total_activated)}</td>
										<td className="text-end">{formatMoney(item.total_deleted)}</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
														<Link className="table-dropdown-item" to={`/discount/promo-codes/${item.id}`}>
															<i className="uil-panel-add"></i>
															{t('report')}
														</Link>
														{item.status === 1 &&
															<div className="table-dropdown-item text-danger"
																onClick={() => toggleModalPromoCodeStatus(true, item)}>
																<i className="uil-times"></i>
																Выключить
															</div>
														}
														{/* {item.status === -1 &&
															<div className="table-dropdown-item text-success"
																onClick={() => setShowModalPromoCodeStatus(true)}>
																<i className="uil-times"></i>
																Включить
															</div>
														} */}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="d-flex justify-content-between mb-2">
							<p className="fw-600 vertical-center w-50">{t('type')}</p>
							<Select
								isDisabled={selectedItem.id}
								className="w-50"
								options={discountTypes}
								value={discountTypes.find(option => option.id === selectedItem.discount_type_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'discount_type_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>

						<div className="d-flex justify-content-between mb-2">
							<p className="fw-600 vertical-center w-50">{t('name3')}</p>
							<input type="text" className="form-control w-50" name="name"
								value={selectedItem.name ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>

						{selectedItem.discount_type_id === 6 &&
							<>
								<div className="d-flex justify-content-between mb-2">
									<div className="vertical-center w-50" style={{ 'lineHeight': '14px' }}>
										<p className="fw-600">{t('prefix')}</p>
										<div className="ms-2" style={{ 'fontSize': '12px' }}>Максимальная длина 5</div>
									</div>
									<input type="text" className="form-control w-50" name="prefix"
										disabled={selectedItem.id}
										value={selectedItem.prefix ?? ''}
										onChange={(e) => handleChangeInput(e)} />
								</div>

								<div className="d-flex justify-content-between mb-2">
									<div className="vertical-center w-50" style={{ 'lineHeight': '14px' }}>
										<p className="fw-600">{t('random_length')}</p>
										<div className="ms-2" style={{ 'fontSize': '12px' }}>Максимальная значение 7</div>
									</div>
									<input type="number" className="form-control w-50" name="suffix_length"
										disabled={selectedItem.id}
										value={selectedItem.suffix_length ?? ''}
										onChange={(e) => handleChangeInput(e)} />
								</div>

								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center w-50">{t('random_type')}</p>
									<Select
										className="w-50"
										options={symbolTypes}
										value={symbolTypes.find(option => option.id === selectedItem.symbol_type_id)}
										onChange={(option) => setSelectedItem({ ...selectedItem, 'symbol_type_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</>
						}

						{selectedItem.discount_type_id === 7 &&
							<>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center w-50">{t('promo_code')}</p>
									<input type="text" className="form-control w-50" name="promo_code"
										disabled={selectedItem.id}
										value={selectedItem.promo_code ?? ''}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'promo_code': e.target.value.toLocaleUpperCase() })} />
								</div>
							</>
						}
						<div className="d-flex justify-content-between mb-2">
							<div className="d-flex gap-3">
								<div className="form-check p-0">
									<div className="radio-round"
										onClick={() => setSelectedItem({ ...selectedItem, 'discount_payment_type': 1 })}
										style={{ 'backgroundColor': selectedItem.discount_payment_type === 1 ? 'black' : '' }}>
									</div>
									<span onClick={() => {
										if (selectedItem.id) return
										setSelectedItem({ ...selectedItem, 'discount_payment_type': 1 })
									}}>
										{t('amount')}
									</span>
								</div>

								<div className="form-check p-0">
									<div className="radio-round"
										onClick={() => setSelectedItem({ ...selectedItem, 'discount_payment_type': 2 })}
										style={{ 'backgroundColor': selectedItem.discount_payment_type === 2 ? 'black' : '' }}>
									</div>
									<span onClick={() => {
										if (selectedItem.id) return
										setSelectedItem({ ...selectedItem, 'discount_payment_type': 2 })
									}}>
										{t('percentage')}
									</span>
								</div>
							</div>
							<input type="number" className="form-control w-50"
								name="discount_payment_value"
								disabled={selectedItem.id}
								value={selectedItem.discount_payment_value ?? ''}
								onChange={(e) => handleChangeInput(e)} />
						</div>
						{selectedItem.discount_type_id === 6 &&
							<>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center w-50">SMS {t('templates')}</p>
									<Select
										className="w-50"
										options={smsTemplates}
										value={smsTemplates.find(option => option.id === selectedItem.sms_template_id)}
										onChange={(option) => setSelectedItem({ ...selectedItem, 'sms_template_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>

								<div className="form-check">
									<input id="create_once_client" className="form-check-input" type="checkbox"
										disabled={selectedItem.id}
										checked={selectedItem.create_once_client ?? false}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'create_once_client': e.target.checked })}
									/>
									<label className="form-check-label" htmlFor="create_once_client">
										{t('promo_code_rule')}
									</label>
								</div>
							</>
						}

						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL PROMO CODE STATUS*/}
			<Modal show={showModalPromoCodeStatus} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static" onHide={() => toggleModalPromoCodeStatus(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Выключить</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={updatePromoCodeStatus} autoComplete="off">
						<p className="fz-16">
							Удалить не активированные промокоды этого сервиса
						</p>
						<div className="d-flex mt-2">
							<div className="form-check">
								<input type="checkbox" className="form-check-input"
									onClick={(e) => setSelectedItem({ ...selectedItem, 'delete_discount_promo_codes_statuses': e.target.checked })}
									checked={selectedItem.delete_discount_promo_codes_statuses} />
							</div>
							<span className="text-danger fz-16 fw-500">Я согласен</span>
						</div>

						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalPromoCodeStatus(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PROMO CODE STATUS*/}
		</>
	)
}

export default Index
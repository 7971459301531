import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, regexPhoneNumber } from "helpers/helpers"
import { Dropdown } from "react-bootstrap"

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])

	async function getData() {
		const response = await GET('/pos', {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	function returnClassStatus(status) {
		if (status === 1) {
			return "bg-primary"
		}
		if (status === -1) {
			return "bg-danger"
		}
		if (status === -2) {
			return "bg-secondary"
		}
		if (status === -3) {
			return "bg-danger"
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('pos')} [{data.length}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('name3')}</th>
									<th style={{ 'width': '10%' }} className="text-nowrap">{t('phone')}</th>
									<th style={{ 'width': '50%' }}>{t('address')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('status')}</th>
									<th style={{ 'width': '10%' }} className="text-end text-nowrap">{t('balance')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.id}</td>
										<td className="td-ellipsis">{item.name}</td>
										<td className="text-nowrap">{regexPhoneNumber(item.phone)}</td>
										<td className="td-ellipsis">{item.address}</td>
										<td className={`text-center text-white ${returnClassStatus(item.status)}`}>
											{item.status === 1 &&
												<span>{t('activated')}</span>
											}
											{item.status === -1 &&
												<span>{t('disconnected')}</span>
											}
											{item.status === -2 &&
												<span>{t('frozen')}</span>
											}
											{item.status === -3 &&
												<span>{t('deleted')}</span>
											}
										</td>
										<td className={`text-end text-nowrap ${item.balance >= 0 ? 'bg-primary' : 'bg-danger'}`}>
											<span className="text-white text-nowrap">
												{formatMoney(item.balance)}
											</span>
										</td>
										<td className="d-flex justify-content-center">
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<Link to={`/pos/update/${item.id}`} className="table-dropdown-item">
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</Link>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
import React, { useEffect, useState, useRef } from "react"
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import XLSX from 'xlsx'

import ReactSelect from 'pages/parts/ReactSelect'
import { GET, POST, PUT, httpOk } from 'helpers/api'
import { formatMoney, findKeyFromArrayByValue, formatMoneyInput } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const reduxAccount = useSelector(state => state.account)

	const [poses, setPoses] = useState([])
	const [elements, setElements] = useState([])
	const [elementsDuplicate, setElementsDuplicate] = useState([])
	const [modalConfirm, setModalConfirm] = useState(false)
	const [modalNotInventored, setModalNotInventored] = useState(false)
	const [addQuantity, setAddQuantity] = useState(1)
	const [annual, setAnnual] = useState(false)
	const [notInventoredProducts, setNotInventoredProducts] = useState([])
	const [data, setData] = useState({
		pos_id: null,
		elements: [],
	})

	async function inventoryCompleted(completed = false) {
		var sendData = { ...data }

		for (let i = 0; i < sendData.elements.length; i++) {
			if (sendData.elements[i]['actual_balance'] < 0 || sendData.elements[i]['actual_balance'] === "") {
				sendData.error = true
			}

			sendData.elements[i]['difference_amount'] = (sendData.elements[i]['actual_balance'] - sendData.elements[i]['balance']) * sendData.elements[i]['price']
			sendData.elements[i]['divergence'] = sendData.elements[i]['actual_balance'] - sendData.elements[i]['balance']
		}

		if (sendData.error) {
			toast.error(t('check_filled_inputs'))
			return
		}

		var response;
		if (id) {
			if (completed) {
				response = await PUT('/inventory-completed', sendData, { loader: true })
				if (httpOk(response)) navigate('/inventory-wms')
			} else {
				response = await PUT('/inventory', sendData, { loader: true })
				if (httpOk(response)) navigate('/inventory-wms')
			}
		} else {
			if (completed) {
				response = await PUT('/inventory-completed', sendData, { loader: true })
				if (httpOk(response)) navigate('/inventory-wms')
			} else {
				response = await POST('/inventory', sendData, { loader: true })
				if (httpOk(response)) navigate('/inventory-wms')
			}
		}
	}

	function handleProductsChange(e, index) {
		var dataCopy = { ...data }
		if (Number(e.target.value) > 999999999) {
			toast.error(t('exceeds_maximum'))
			dataCopy.elements[index][e.target.name] = ""
			setData(dataCopy)
			return;
		}

		dataCopy.elements[index][e.target.name] = e.target.value.replace(/[^\d.,]/g, '')
		calculate(dataCopy)
	}

	function deleteProduct(index) {
		var dataCopy = { ...data }
		dataCopy.elements.splice(index, 1)
		setData(dataCopy)
	}

	function addToList(length = 1) {
		var dataCopy = JSON.parse(JSON.stringify(data))

		for (let i = 0; i < length; i++) {
			dataCopy.elements.push({
				"price": "",
				"element_id": "",
				"actual_balance": "",
				"name": "",
				"unit_name": "",
				"balance": "",
				"difference_amount": '',
				"divergence": ''
			})
		}

		setData(dataCopy)

		setTimeout(() => {
			const byId = document.getElementById(1)
			byId?.select()
		}, 200);
	}

	function calculate(dataCopy) {
		dataCopy.balance = 0
		dataCopy.divergence = 0
		dataCopy.difference_amount = 0

		for (let i = 0; i < dataCopy.elements.length; i++) {
			dataCopy.elements[i]['divergence'] = dataCopy.elements[i]['actual_balance'] - dataCopy.elements[i]['balance']
			dataCopy.elements[i]['difference_amount'] = (dataCopy.elements[i]['actual_balance'] - dataCopy.elements[i]['balance']) * dataCopy.elements[i]['price']
		}
		setData(dataCopy)
	}

	async function notInventoredList() {
		var arrayIds = []
		for (let i = 0; i < data.elements.length; i++) {
			arrayIds.push(data.elements[i]['element_id'])
		}
		var prepareIds = { "balance_ids": [], "pos_id": data.pos_id }
		prepareIds.balance_ids = arrayIds

		const response = await POST('/inventory/not-inventored', prepareIds, {})
		if (httpOk(response)) {
			var list = [...response.data]
			for (let i = 0; i < list.length; i++) {
				list[i]['selected'] = false
				list[i]['actual_balance'] = list[i]['balance']
			}

			setNotInventoredProducts(list)
			setModalNotInventored(true)
		}
	}

	function annualActualBalance() {
		var notInventoredProductsCopy = [...notInventoredProducts]
		if (annual) {
			var selectedExist = false

			for (let i = 0; i < notInventoredProductsCopy.length; i++) {
				if (notInventoredProductsCopy[i]['selected']) {
					selectedExist = true
					break;
				}
			}

			if (selectedExist) {
				for (let i = 0; i < notInventoredProductsCopy.length; i++) {
					if (notInventoredProductsCopy[i]['selected']) {
						notInventoredProductsCopy[i]['actual_balance'] = 0
					}
				}
			} else {
				for (let i = 0; i < notInventoredProductsCopy.length; i++) {
					notInventoredProductsCopy[i]['actual_balance'] = 0
				}
			}
		} else {
			for (let i = 0; i < notInventoredProductsCopy.length; i++) {
				notInventoredProductsCopy[i]['actual_balance'] = notInventoredProductsCopy[i]['balance']
			}
		}
		setNotInventoredProducts(notInventoredProductsCopy)
	}

	function setActualBalanceModal(e, index) {
		var notInventoredProductsCopy = [...notInventoredProducts]
		notInventoredProductsCopy[index]['actual_balance'] = e.target.value
		setNotInventoredProducts(notInventoredProductsCopy)
	}

	function addToListSelectedProducts() {
		var notInventoredProductsCopy = [...notInventoredProducts]
		var dataCopy = { ...data }

		for (let i = 0; i < notInventoredProductsCopy.length; i++) {
			if (notInventoredProductsCopy[i]['selected']) {
				dataCopy.elements.push(notInventoredProductsCopy[i])
			}
		}

		setData(dataCopy)
		setModalNotInventored(false)
	}

	function addToListMinusBalanceProducts() {
		var notInventoredProductsCopy = [...notInventoredProducts]
		var dataCopy = { ...data }

		var minusProducts = notInventoredProductsCopy.filter(element => element.balance < 0);
		dataCopy.elements = minusProducts

		setData(dataCopy)
		setModalNotInventored(false)
	}

	function addToListNotInventoredProduct(index) {
		var notInventoredProductsCopy = [...notInventoredProducts]
		var dataCopy = { ...data }

		dataCopy.elements.push(notInventoredProductsCopy[index])
		notInventoredProductsCopy.splice(index, 1)

		setNotInventoredProducts(notInventoredProductsCopy)

		if (notInventoredProductsCopy.length === 0) {
			setModalNotInventored(false)
		}
	}

	function addToListNotInventoredProducts() {
		var notInventoredProductsCopy = [...notInventoredProducts]
		var dataCopy = { ...data }

		dataCopy.elements = dataCopy.elements.concat(notInventoredProductsCopy)

		setData(dataCopy)
		setModalNotInventored(false)
	}

	function markAsSelected(bool, index) {
		var notInventoredProductsCopy = [...notInventoredProducts]
		notInventoredProductsCopy[index]['selected'] = bool

		setNotInventoredProducts(notInventoredProductsCopy)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
			if (!id) {
				setData({ ...data, 'pos_id': reduxAccount.pos_id })

				const responseElements = await GET(`/inventory-elements/${reduxAccount.pos_id}`)
				if (httpOk(responseElements)) {
					setElements(responseElements.data)
					setElementsDuplicate(responseElements.data)
				}
			}

			if (id) {
				const temporaryData = await GET(`/inventory/${id}`)
				if (httpOk(temporaryData)) {
					setData(temporaryData.data)

					const responseElements = await GET(`/inventory-elements/${temporaryData.data.pos_id}`)
					if (httpOk(responseElements)) {
						setElements(responseElements.data)
						setElementsDuplicate(responseElements.data)
					}
				}

			}
		}
	}

	async function getElements(pos_id) {
		const response = await GET(`/inventory-elements/${pos_id}`)
		if (httpOk(response)) {
			setElements(response.data)
			setElementsDuplicate(response.data)
		}
	}

	function sortBy(key, orderBy) {
		var notInventoredProductsCopy = [...notInventoredProducts]
		if (key === 'balance' && orderBy === 'asc') {
			notInventoredProductsCopy.sort((a, b) => parseFloat(a.balance) - parseFloat(b.balance));
		}
		if (key === 'balance' && orderBy === 'desc') {
			notInventoredProductsCopy.sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance));
		}
		if (key === 'name' && orderBy === 'asc') {
			notInventoredProductsCopy.sort((a, b) => a.productName.localeCompare(b.productName))
		}
		if (key === 'name' && orderBy === 'desc') {
			notInventoredProductsCopy.sort((a, b) => b.productName.localeCompare(a.productName))
		}

		setNotInventoredProducts(notInventoredProductsCopy)
	}

	async function exportToExcel() {
		var excelHeaders = [[t('product_name'), t('price'), t('expected_balance')]]
		for (let i = 0; i < notInventoredProducts.length; i++) {
			var excelItems = []
			excelItems.push(notInventoredProducts[i]['name'])
			excelItems.push(notInventoredProducts[i]['price'])
			excelItems.push(notInventoredProducts[i]['balance'])
			excelHeaders.push(excelItems)
		}

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('not_inventored') + ".xlsx");
	}

	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (!value) {
			setElements([...elementsDuplicate])
			return
		}
		var elementsDuplicateCopy = [...elementsDuplicate]

		var filteredItems = elementsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setElements(filteredItems)
	}

	function handleSelectElement(option, index) {
		var dataCopy = { ...data }

		dataCopy.elements[index]['balance_id'] = option.balance_id
		dataCopy.elements[index]['element_id'] = option.element_id
		dataCopy.elements[index]['name'] = option.name
		dataCopy.elements[index]['unit_name'] = option.unit_name
		dataCopy.elements[index]['price'] = option.price
		dataCopy.elements[index]['balance'] = option.balance
		dataCopy.elements[index]['actual_balance'] = ''
		dataCopy.elements[index]['divergence'] = ''
		dataCopy.elements[index]['difference_amount'] = ''

		setData(dataCopy)
	}

	function closeReactSelect() {
		setTimeout(() => {
			var elementsCopy = [...elements]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true

			setElements(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = elements.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...elements]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[elements.length - 1].selected = true
						currentIndex = elements.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === elements.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setElements(elementsCopy)
		}
	}
	// REACTSELECT LOGIC

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		annualActualBalance()
	}, [annual]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between flex-wrap mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('inventory')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
				<div>
					<button className="btn btn-primary"
						onClick={() => notInventoredList()}>
						{t('not_inventored')}
					</button>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<div className="row">
						<div className="col-md-2">
							<div className="form-group">
								<label>{t('pos')}<span className="required-mark">*</span></label>
								<Select
									isDisabled={data.elements.length > 0}
									value={poses.find(option => option.id === data.pos_id)}
									options={poses}
									onChange={(option) => {
										setData({ ...data, 'pos_id': option.id })
										getElements(option.id)
									}}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<label>{t('note')}</label>
								<input name="note" className="form-control"
									value={data.note ?? ''}
									onChange={(e) => setData({ ...data, 'note': e.target.value })} />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<table className="table table-bordered" style={{ 'overflow': 'unset' }}>
						<thead>
							<tr>
								<th style={{ 'minWidth': '40px' }} className="text-center">#</th>
								<th style={{ 'width': '30%' }}>{t('name2')}</th>
								<th style={{ 'width': '10%' }} className="text-center">{t('unit')}</th>
								<th style={{ 'width': '10%' }} className="text-end">{t('price')}</th>
								<th style={{ 'width': '10%' }} className="text-end">{t('balance2')}</th>
								<th style={{ 'width': '10%' }} className="text-end">{t('counted')}</th>
								<th style={{ 'width': '10%' }} className="text-end">{t('difference')}</th>
								<th style={{ 'width': '20%' }} className="text-end">{t('amount')}</th>
								<th style={{ 'minWidth': '35px' }} className="text-end"></th>
							</tr>
						</thead>
						<tbody>
							{data.elements.map((item, index) => (
								<tr key={index}>
									<td className="text-center">{index + 1}</td>
									<td>
										<ReactSelect
											search={handleSelectSearch}
											value={item.name}
											index={index}
											scrollRef={scrollRef}
											options={elements}
											onClose={() => closeReactSelect()}
											selectItem={(option) => handleSelectElement(option, index)}
											onKeyUp={(e) => {
												if (e.keyCode === 40 || e.keyCode === 38) {
													handleTableKeyDown(e)
												}
											}}
											table={
												<table className="table mb-0">
													<tbody ref={tbodyRef}>
														{elements.map((selectItem, selectIndex) => (
															<tr
																id={selectItem.id}
																className={`cursor ${((selectItem.selected || (selectItem.id === item.element_id)) ? 'selected' : '')}`}
																tabIndex={-1}
																key={selectIndex}
																onClick={() => handleSelectElement(selectItem, index)}
																onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																<td>{selectItem.name}</td>
																<td className="text-end text-nowrap">{formatMoney(selectItem.price)}</td>
															</tr>
														))}
													</tbody>
												</table>
											}
										/>
									</td>
									<td className="vertical-middle text-center">{item.unit_name}</td>
									<td className="vertical-middle text-end text-nowrap">{formatMoney(item.price)}</td>
									<td className="vertical-middle text-end text-nowrap">{formatMoney(item.balance, 2)}</td>
									<td className="td-input" style={!item.actual_balance ? { 'borderBottom': '2px solid red' } : {}}>
										<input
											type="text"
											name="actual_balance"
											autoComplete="off"
											className={`form-control text-end ${item.actual_balance < 0 ? 'required-border' : ''}`}
											placeholder="0"
											value={formatMoneyInput(item.actual_balance) ?? ''}
											onChange={(e) => handleProductsChange(e, index)}
											onKeyUp={(event) => {
												if (event.key === 'Enter') {
													addToList()
												}
											}} />
									</td>
									<td className="text-end text-nowrap">
										<span className={`${item.divergence < 0 ? 'text-danger' : ''}`}>
											{formatMoney(item.divergence)}
										</span>
									</td>
									<td className="text-end text-nowrap">
										<span className={`${item.difference_amount < 0 ? 'text-danger' : ''}`}>
											{formatMoney(item.difference_amount)}
										</span>
									</td>
									<td className="vertical-middle">
										<div className="d-flex justify-content-center"
											onClick={() => deleteProduct(index)}>
											<div className="table-action-button table-action-danger-button">
												<i className="uil uil-times"></i>
											</div>
										</div>
									</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<td className="td-input">
									<input
										type="text"
										className="form-control text-center"
										style={{ 'padding': '5px 0' }}
										tabIndex={-1}
										value={addQuantity}
										onChange={(e) => setAddQuantity(e.target.value.replace(/[^\d]/g, ''))}
									/>
								</td>
								<td colSpan={1} style={{ 'padding': 0 }}>
									<div className="d-flex">
										<div className="w-100">
											<button type="button" className="btn-sm btn-primary w-100"
												disabled={!data.pos_id}
												onClick={() => {
													setAddQuantity(1)
													addToList(addQuantity)
												}}>
												{t('add')}
											</button>
										</div>
									</div>
								</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tfoot>
					</table>

					<div className="btn-wrapper gap-2 mt-5">
						<Link to="/inventory-wms" className="btn btn-warning btn-rounded btn-wide">
							{t('back')}
						</Link>
						<button type="button" className="btn btn-success btn-rounded btn-wide"
							disabled={!data.elements.length > 0}
							onClick={() => setModalConfirm(true)}>
							{t('complete')}
						</button>
						<button type="button" className="btn btn-primary btn-rounded btn-wide"
							disabled={!data.elements.length > 0}
							onClick={() => inventoryCompleted()}>
							{t('save_to_draft')}
						</button>
					</div>
				</div>
			</div>

			{/* MODAL NOT INVENTORED PRODUCTS */}
			<Modal show={modalNotInventored} animation={false} centered size="lg" backdrop="static"
				onHide={() => setModalNotInventored(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						<div className="d-flex gap-2">
							<div className="me-4">{t('annual')}</div>
							<div className="form-check form-switch form-switch-lg">
								<input type="checkbox" className="form-check-input"
									defaultValue={false} onChange={(e) => setAnnual(e.target.checked)} />
							</div>
							<div>{t('sort_by')}</div>
							<button className="btn btn-sm btn-success" onClick={() => sortBy('balance', 'asc')}>[0-9]</button>
							<button className="btn btn-sm btn-success" onClick={() => sortBy('balance', 'desc')}>[9-0]</button>
							<button className="btn btn-sm btn-success" onClick={() => sortBy('name', 'asc')}>[A-Z]</button>
							<button className="btn btn-sm btn-success" onClick={() => sortBy('name', 'desc')}>[Z-A]</button>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive inventory-modal-height">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th>#</th>
									<th style={{ 'width': '60%' }}>{t('name2')}</th>
									<th style={{ 'width': '20%' }} className="text-end">{t('balance2')}</th>
									<th style={{ 'width': '20%' }} className="text-end">{t('counted')}</th>
									<th style={{ 'minWidth': '35px' }} className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{notInventoredProducts.map((item, index) => (
									<tr key={index}>
										<td>
											<input type="checkbox"
												id={`control${index}`}
												onChange={(e) => markAsSelected(e.target.checked, index)} />
										</td>
										<td>{item.name}</td>
										<td className="text-end">{formatMoney(item.balance, 2)}</td>
										<td className="td-input">
											<input
												type="number"
												name="actual_balance"
												autoComplete="off"
												className="form-control text-end"
												placeholder="0"
												value={item.actual_balance ?? ''}
												onChange={(e) => setActualBalanceModal(e, index)} />
										</td>
										<td>
											<div className="table-action-button table-action-primary-button"
												onClick={() => addToListNotInventoredProduct(index)}>
												<i className="uil-plus"></i>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-secondary" tabIndex="-1"
						onClick={() => exportToExcel()}>
						EXCEL</button>
					<button className="btn btn-info" tabIndex="-1"
						onClick={() => addToListMinusBalanceProducts()}>
						{t('add_negative_balance')}
					</button>
					<button className="btn btn-success" tabIndex="-1"
						onClick={() => addToListSelectedProducts()}>
						{t('add_selected')}
					</button>
					<button className="btn btn-primary" tabIndex="-1"
						onClick={() => addToListNotInventoredProducts()}>
						{t('add_all')}
					</button>
				</Modal.Footer>
			</Modal>
			{/* MODAL NOT INVENTORED PRODUCTS */}

			{/* MODAL CONFIRM */}
			<Modal show={modalConfirm} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => setModalConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('confirm')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between mb-2">
						<div>{t('pos')}</div>
						<div className="fw-700">{findKeyFromArrayByValue(poses, 'id', data.pos_id, 'name')}</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => setModalConfirm(false)}>
							{t('cancel')}
						</button>
						<button className="btn btn-primary w-100"
							onClick={() => inventoryCompleted(true)}>
							{t('confirm')}
						</button>
					</div>
				</Modal.Footer>
			</Modal>
			{/* MODAL CONFIRM */}
		</>
	)
}

export default CreateUpdate


const initialState = {
	showleftMenu: true,
};

const layout = function (state = initialState, action) {
	switch (action.type) {
		case "TOGGLE_LEFT_MENU":
			return {
				...state,
				showleftMenu: action.payload,
			};
		default:
			return state;
	}
};

export default layout;

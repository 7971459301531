import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { httpOk, GET, POST, PUT } from 'helpers/api'
import { formatMoneyInput } from 'helpers/helpers'

function CreateUpdateCheque() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const [poses, setPoses] = useState([])
	const [data, setData] = useState({
		"name": "",
		"amount": "",
		"discount_payment_type": 2,
		"discount_payment_value": "",
		"discount_hall": true,
		"discount_takeaway": false,
		"discount_delivery": false,
		"poses": [],
	})

	async function createUpdate() {
		var dataCopy = { ...data }

		var response
		if (!dataCopy.id) {
			response = await POST('/discount/services-cheque-manual', dataCopy, { loader: true })
		} else {
			response = await PUT('/discount/services-cheque-manual', dataCopy, { loader: true })
		}

		if (httpOk(response)) navigate(-1)
	}

	async function getData() {
		const response = await GET('/helper/poses', {}, { loader: true })
		if (httpOk(response)) setPoses(response.data)
		if (id) {
			const response = await GET(`/discount/services-dish/${id}`, {}, { loader: true })
			if (httpOk(response)) setData(response.data)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('discounts')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<div className="row">
						<div className="col-md-4 border-right">
							<div className="form-group">
								<label>{t('name3')}</label>
								<input type="text" className="form-control" name="name" autoComplete="off"
									value={data.name ?? ''}
									onChange={(e) => setData({ ...data, 'name': e.target.value })} />
							</div>
							<div className="form-group">
								<label>{t('poses')}<span className="required-mark">*</span></label>
								<Select
									isMulti
									value={data.poses}
									options={poses}
									onChange={(options) => setData({ ...data, 'poses': options })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>

							<div className="d-flex gap-3">
								<div className="form-check p-0">
									<div className="radio-round"
										onClick={() => setData({ ...data, 'discount_payment_type': 2 })}
										style={{ 'backgroundColor': data.discount_payment_type === 2 ? 'black' : '' }}>
									</div>
									<span onClick={() => setData({ ...data, 'discount_payment_type': 2 })}>
										{t('percentage')}
									</span>
								</div>

								<div className="form-check p-0">
									<div className="radio-round"
										onClick={() => setData({ ...data, 'discount_payment_type': 1 })}
										style={{ 'backgroundColor': data.discount_payment_type === 1 ? 'black' : '' }}>
									</div>
									<span onClick={() => setData({ ...data, 'discount_payment_type': 1 })}>
										{t('amount')}
									</span>
								</div>
							</div>

							<div className="form-group w-100">
								<label>{t('discount_value')}</label>
								<input type="text" className="form-control" name="discount_payment_value" autoComplete="off"
									value={formatMoneyInput(data.discount_payment_value) ?? ''}
									onChange={(e) => setData({ ...data, 'discount_payment_value': e.target.value.replace(/[^0-9.]/g, '') })} />
							</div>
							<hr />
							<h5 className="mb-2">{t('order_type')}</h5>
							<div className="d-flex gap-3">
								<div className="form-check">
									<input type="checkbox" id="order_type_1" className="form-check-input"
										checked={data.discount_hall ?? false}
										onChange={(e) => setData({ ...data, 'discount_hall': e.target.checked })} />
									<label htmlFor="order_type_1" className="form-check-label">{t('hall')}</label>
								</div>
								<div className="form-check">
									<input type="checkbox" id="order_type_2" className="form-check-input"
										checked={data.discount_takeaway ?? false}
										onChange={(e) => setData({ ...data, 'discount_takeaway': e.target.checked })} />
									<label htmlFor="order_type_2" className="form-check-label">{t('takeaway')}</label>
								</div>
								<div className="form-check">
									<input type="checkbox" id="order_type_3" className="form-check-input"
										checked={data.discount_delivery ?? false}
										onChange={(e) => setData({ ...data, 'discount_delivery': e.target.checked })} />
									<label htmlFor="order_type_3" className="form-check-label">{t('delivery')}</label>
								</div>
							</div>
						</div>
					</div>

					<div className="btn-wrapper gap-2">
						<Link className="btn btn-warning btn-rounded btn-wide" to="/discount/services" tabIndex="-1">
							{t('cancel')}
						</Link>
						<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
							onClick={() => createUpdate()}>
							{t('save')}
						</button>
					</div>

				</div>
			</div>
		</>
	)
}

export default CreateUpdateCheque
import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import { GET, httpOk } from 'helpers/api'
import { findKeyFromArrayByValue, formatDate } from 'helpers/helpers'

import ByWaiters from './ByWaiters'
import Kitchen from './Kitchen'
import Cashier from './Cashier'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const date = new Date()
	const [poses, setPoses] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
	})
	const [passingFilterData, setPassingFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
	})

	async function searchWithFilter() {
		setFilterData(passingFilterData)
		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setPassingFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: reduxAccount.pos_id,
		})
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('employees')}
				</h4>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === passingFilterData.pos_id) || ''}
													onChange={(option) => setPassingFilterData({ ...passingFilterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={passingFilterData.start_date ? new Date(passingFilterData.start_date) : ''}
														onChange={(date) => setPassingFilterData({ ...passingFilterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={passingFilterData.end_date ? new Date(passingFilterData.end_date) : ''}
														onChange={(date) => setPassingFilterData({ ...passingFilterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					<div className="filter-block">
						<div className="filter">
							<strong>{t('filter')}</strong>
							<br />
							{(filterData.start_date || filterData.end_date) &&
								<span className="me-2">
									<b>{t('period')}: </b>
									{filterData.start_date &&
										<span>{formatDate(filterData.start_date)}</span>
									}
									{(filterData.start_date && filterData.end_date) &&
										<span>|</span>
									}
									{filterData.end_date &&
										<span>{formatDate(filterData.end_date)}</span>
									}
								</span>
							}
							{filterData.pos_id &&
								<span className="me-2">
									<b>{t('pos')}: </b>
									{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
								</span>
							}
							{filterData.search &&
								<span className="me-2">
									<b>{t('search')}: </b>
									<span>{filterData.search}</span>
								</span>
							}
						</div>
						<div className="vertical-center">
							<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
						</div>
					</div>
				</div>
			</div>
			<div className="mb-3">
				<ByWaiters filterData={filterData}></ByWaiters>
			</div>
			<div className="mb-3">
				<Kitchen filterData={filterData}></Kitchen>
			</div>
			<div className="mb-3">
				<Cashier filterData={filterData}></Cashier>
			</div>
		</>
	)
}

export default Index
import React from 'react'
import { useSelector } from 'react-redux'

function FAQ() {
	const reduxAccount = useSelector(state => state.account)

	return (
		<div>
			<b>1 - Как создать бота</b>
			<div>1.1 - В телеграмме в поиске напишите <a href="https://t.me/Botfather" target="_blank" rel="noreferrer">Botfather</a></div>
			<div>1.2 - Напишите /newbot</div>
			<div>1.3 - Напишите названи бота "Ваш бренд"</div>
			<div>1.4 - Напишите ник бота пример mison_bot</div>
			<div>1.5 - Получите токен и вставьте его в раздел "Токен телеграм бота"</div>

			<hr />

			<b>2 - Контекстное меню</b>
			<div>2.1 - Напишите /mybots выберите раздел Bot Settings » Menu Button » Configure menu button</div>
			<div>2.2 - Отправьте ссылку https://telegram.mison.uz/check/{reduxAccount.login}/{reduxAccount.pos_id}</div>

			<hr />

			<b>3 - Получать заказы в телеграм группу</b>
			<div>3.1 - Создайте приватную группу</div>
			<div>3.2 - Получите ID телеграм группы добавив бота в группу
				<a href="https://t.me/username_to_id_bot" target="_blank" rel="noreferrer" className="mx-1">username_to_id_bot</a>
			</div>
			<div>3.3 - Скопируйте айди группы он будет минусовым значением пример <b>id чата -1002400598445</b></div>
			<div>3.4 - Вставьте его
				<a href="https://cabinet.mison.uz/pos/update/1" target="_blank" rel="noreferrer" className="mx-1">тут</a>
				в разделе <b>Телеграм » ID Телеграм группы</b>
			</div>

			<hr />

			<b>4 - Получать жалобы и предложение</b>
			<div>4.1 - Создайте приватную группу</div>
			<div>4.2 - Получите ID телеграм группы добавив бота в группу
				<a href="https://t.me/username_to_id_bot" target="_blank" rel="noreferrer" className="mx-1">username_to_id_bot</a>
			</div>
			<div>4.3 - Скопируйте айди группы он будет минусовым значением пример <b>id чата -1002400598445</b></div>
			<div>4.4 - Вставьте его в разделе ID телеграм группы для жалоб</div>
			<div>4.5 - Добавьте в группу бота
				<a href="https://t.me/mison_statistics_bot" target="_blank" rel="noreferrer" className="mx-1">mison_statistics_bot</a>
			</div>
		</div>
	)
}

export default FAQ
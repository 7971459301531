import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import Rightbar from '../cheques/Rightbar'
import { GET, httpOk } from 'helpers/api'
import { formatDate, formatMoney } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()

	const settings = useSelector(state => state.settings)
	const reduxReport = useSelector(state => state.report)

	const URL = "/cheques-by-agent-id/" + reduxReport.reportSelectedAgent.agent_id

	const [showRightBar, setShowRightBar] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...data]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}

		if (item.id !== 0) {
			if (boolean) {
				const response = await GET("/cheques/" + item.id, {}, { loader: true })
				if (httpOk(response)) {
					dataCopy[index]['showSidebar'] = boolean
					setSelectedItem(response.data)
					setShowRightBar(boolean)
				}
			} else {
				setShowRightBar(boolean)
				setSelectedItem({})
			}
		}
	}

	async function getData() {
		const response = await GET("/cheques-by-agent-id/" + reduxReport.reportSelectedAgent.agent_id, {}, { loader: true })
		if (httpOk(response)) {
			setPageCount(Math.ceil(response.data.total / settings.size))
			setData(response.data);
		}
	}

	async function exportToExcel() {
		await GET('/services/web/api/report-client-history-excel/' + reduxReport.reportSelectedAgent.agent_id)
	}

	async function paginate(data) {
		const response = await GET(URL, { page: data.selected, size: settings.size })
		if (httpOk(response)) setData(response.data.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex text-truncate">
				<h4 className="vertical-center h-100">
					{t('agents')}
				</h4>
				<div className="vertical-center">
					<i className="uil uil-angle-double-right fz-20"></i>
				</div>
				<div className="vertical-center">
					<h6>{reduxReport.reportSelectedAgent.first_name}</h6>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<Search url={URL} search={search} getData={getData}></Search>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2" onClick={exportToExcel}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('cashier')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('waiter')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('check_number')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('cheque_date')}</th>
									<th style={{ 'width': '20%' }} className="text-center">{t('sync_date')}</th>
									<th style={{ 'width': '20%' }} className="text-end">{t('total')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index} onClick={() => toggleRightbar(true, item, index)}>
										<td className="text-center">{item.row_number}</td>
										<td>{item.pos_name}</td>
										<td className="text-center">{item.cashier_first_name}</td>
										<td className="text-center">{item.waiter_first_name}</td>
										<td className="text-center">
											<CustomTooltip text={t('hall')}>
												{item.order_type === 1 &&
													<span className="bg-primary bg-border text-white">
														<span>{item.cheque_number}</span>
													</span>
												}
											</CustomTooltip>
											<CustomTooltip text={t('takeaway')}>
												{item.order_type === 2 &&
													<span className="bg-success bg-border text-white">
														<span>{item.cheque_number}</span>
													</span>
												}
											</CustomTooltip>
											<CustomTooltip text={t('delivery')}>
												{item.order_type === 3 &&
													<span className="bg-warning bg-border text-white">
														<span>{item.cheque_number}</span>
													</span>
												}
											</CustomTooltip>
										</td>
										<td className="text-center">
											{formatDate(item.cheque_closed_at, 'dd.MM.yy HH:mm')}
										</td>
										<td className="text-center text-nowrap">
											{item.status < 0 ?
												<span className="bg-danger bg-border text-white">
													<span>{formatDate(item.cheque_sync_at, 'dd.MM.yy HH:mm')}</span>
												</span>
												:
												<span>{formatDate(item.cheque_sync_at, 'dd.MM.yy HH:mm')}</span>
											}
										</td>
										<td className="text-end text-nowrap">
											<span className={`${item.client_debtor_id ? 'bg-danger bg-border text-white' : ''}`}>
												{formatMoney(item.total_amount_service)}
											</span>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			<Rightbar showRightBar={showRightBar} setShowRightBar={setShowRightBar} selectedItem={selectedItem}></Rightbar>
		</>
	)
}

export default Index

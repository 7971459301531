import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom"
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { toast } from 'react-toastify'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, POST, httpOk } from 'helpers/api'
import {
	formatDate, formatMoney, returnSign,
	findKeyFromArrayByValue, returnMinDate, regexPhoneNumber, formatMoneyInput
} from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const reduxAccount = useSelector(state => state.account)
	const date = new Date()

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [accounts, setAccounts] = useState([])
	const [accountsChef, setAccountsChef] = useState([])
	const [paymentPurposeTypes, setPaymentPurposeTypes] = useState([])
	const [modalPayment, setModalPayment] = useState(false)
	const [selectedItem, setSelectedItem] = useState({ 'created_at': date })
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({ pos_id: reduxAccount.pos_id })
	const [pagination, setPagination] = useState({
		url: `/report-suppliers`,
		response: null
	})

	async function searchWithFilter() {
		var filterUrl = `/report-suppliers`
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/report-suppliers${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	function selectItem(item) {
		var itemCopy = { ...item }
		itemCopy.amount = ''
		setSelectedItem(itemCopy)
	}

	async function payment(e) {
		e.preventDefault();
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.created_at = formatDate(selectedItemCopy.created_at, 'yyyy-MM-dd')

		var response = await POST('/suppliers-payment', selectedItemCopy, { loader: true })

		if (httpOk(response)) {
			toggleModalPayment(false)
			searchWithFilter()
		}
	}

	function toggleModalPayment(bool) {
		if (!selectedItem?.supplier_id) {
			toast.warning(t('no_supplier_selected'))
			return
		}

		var selectedItemCopy = { ...selectedItem }
		if (bool) {
			selectedItemCopy.created_at = new Date()
			setSelectedItem(selectedItemCopy)
		} else {
			selectedItemCopy.amount = ''
			selectedItemCopy.note = ""
			selectedItemCopy.created_at = new Date()
			setSelectedItem(selectedItemCopy)
		}

		setModalPayment(bool)
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getAccountsChef() {
		const response = await GET('/helper/accounts-chef')
		if (httpOk(response)) setAccountsChef(response.data)
	}

	async function getAccounts() {
		const response = await GET('/helper/accounts')
		if (httpOk(response)) setAccounts(response.data)
	}

	async function getPaymentPurposeTypes() {
		const response = await GET('/helper/ppt-expense?counterparty=supplier')
		if (httpOk(response)) setPaymentPurposeTypes(response.data)
	}

	useEffect(() => {
		getPoses()
		getAccounts()
		getAccountsChef()
		getPaymentPurposeTypes()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('suppliers')} [{data.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('pos')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.pos_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end gap-2">
												<button className="btn btn-secondary"
													onClick={() => setDropdownIsOpen(false)}>
													{t('cancel')}
												</button>
												<button className="btn btn-primary"
													onClick={() => searchWithFilter(false)}>
													{t('apply')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
							<div>
								<button className="btn btn-outline-primary btn-wide"
									onClick={() => toggleModalPayment(true)}>
									{t('pay')}
								</button>
							</div>
						</div>
						<div className="d-flex gap-2">
							<div className="vertical-center">
								<h4><b>{t('amount')}</b>:</h4>
							</div>
							<div className="vertical-center">
								<h5 className="text-end">
									<span className={`${data.total_amount >= 0 ? 'text-success' : 'text-danger'}`}>
										{formatMoney(data.total_amount)}
									</span>
								</h5>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('supplier')}</th>
									<th style={{ 'width': '10%' }}>{t('phone')}</th>
									<th style={{ 'width': '60%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end">{t('debt')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr className={'tr-middle ' + (
										(
											selectedItem &&
											selectedItem.pos_id === item.pos_id &&
											selectedItem.supplier_id === item.supplier_id
										) ? 'table-tr-active' : '')}
										key={index}
										onClick={() => selectItem(item)}>
										<td className="text-center">{item.row_number}</td>
										<td className="td-ellipsis">
											<Link className="text-primary cursor table-first-column-clickable"
												to={`/report-suppliers-debts/${item.supplier_id}`}>
												{item.name}
											</Link>
										</td>
										<td className="text-nowrap">{regexPhoneNumber(item.phone)}</td>
										<td></td>
										<td className="text-end text-nowrap">
											<div className={item.balance >= 0 ? 'text-primary' : 'text-danger'}>
												<span>{formatMoney(item.balance)}</span>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModalPayment(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('amortization')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={payment} autoComplete="off">
						<div className="d-flex justify-content-between flex-wrap">
							<span>{t('pos')}</span>
							<span>{selectedItem.pos_name}</span>
						</div>
						<div className="d-flex justify-content-between flex-wrap">
							<span>{t('supplier')}</span>
							<span>{selectedItem.name}</span>
						</div>
						<div className="form-group">
							<label>{t('date')}</label>
							<div className="calendar-input">
								<DatePicker
									className="form-control"
									dateFormat="dd.MM.yyyy"
									selected={selectedItem.created_at ? new Date(selectedItem.created_at) : ''}
									onChange={(date) => setSelectedItem({ ...selectedItem, 'created_at': formatDate(date, 'yyyy-MM-dd') })}
									minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
									maxDate={new Date()}
								/>
								<i className="uil uil-calendar-alt"></i>
							</div>
						</div>
						<div className="form-group">
							<label>{t('expense_type')}</label>
							<Select
								isDisabled={selectedItem.update}
								options={paymentPurposeTypes}
								value={paymentPurposeTypes.find(option => option.id === selectedItem.payment_purpose_id)}
								onChange={(option) => setSelectedItem({
									...selectedItem,
									'payment_purpose_id': option.id,
									'account_chef_id': option.account_chef_id,
									'account_core_id': option.account_core_id,
								})}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('operation_account')}</label>
							<Select
								isClearable
								options={accountsChef}
								value={accountsChef.find(option => option.id === selectedItem.account_chef_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'account_chef_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('report_account')}</label>
							<Select
								isDisabled
								options={accounts}
								value={accounts.find(option => option.id === selectedItem.account_core_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'account_core_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('amount')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="amount" autoFocus
								value={selectedItem.amount ? formatMoneyInput(selectedItem.amount) : ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								value={selectedItem.note}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => toggleModalPayment(false)} tabIndex="-1">{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100" disabled={!selectedItem.amount}>
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}
		</>
	)
}

export default Index

import React, { useEffect, useRef, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'

import { TOGGLE_LEFT_MENU } from 'store/layout/actions'
import { store } from 'store/index'
//Import Scrollbar
import { Nav } from 'react-bootstrap'
import { exportMenu } from './menu'

import logo from "assets/images/logo.png";

function SidebarContent() {
	const { t } = useTranslation()
	const location = useLocation()
	const ref = useRef();
	const layoutSelector = useSelector(state => state.layout)

	const [menu, setMenu] = useState(exportMenu)
	const [activeItem, setActiveItem] = useState({
		children: []
	})

	function setActiveMenu() {
		var menuCopy = [...menu]
		for (let k = 0; k < menuCopy.length; k++) {
			for (let i = 0; i < menuCopy[k]['children'].length; i++) {
				if (location.pathname.includes(menuCopy[k]['children'][i]['url'])) {
					menuCopy[k]['children'][i]['active'] = true;
					setActiveItem(menuCopy[k])
				} else {
					menuCopy[k]['children'][i]['active'] = false;
				}
			}
		}
		setMenu(menuCopy);
	}

	function setActiveTab(item) {
		store.dispatch(TOGGLE_LEFT_MENU(true))
		var copy = { ...item }
		setActiveItem(copy)
	}

	function setActiveChild(item, index) {
		store.dispatch(TOGGLE_LEFT_MENU(true))
		var copyArr = [...menu]
		const i = copyArr.findIndex(x => x.icon === activeItem.icon);
		copyArr[i]['children'][index].active = !copyArr[i]['children'][index].active
		setActiveItem(copyArr[i])
	}

	useEffect(() => {
		setActiveMenu()
	}, [location]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div ref={ref}
				className={`sidebar-menu-scroll ${layoutSelector.showleftMenu ? 'box-shadow-none' : ''}`}>
				<Nav className="nav-mini" activeKey={activeItem.icon}>
					{exportMenu.map((item, index) => (
						<Nav.Item onClick={() => setActiveTab(item)} key={index}>
							<Nav.Link eventKey={item.icon}>
								<span className="fw-bold"><i className={item.icon}></i></span>
							</Nav.Link>
						</Nav.Item>
					))}
				</Nav>
				<div className="app-version">
					{t('version')}
					<br />
					<div className="text-center">
						{process.env.REACT_APP_VERSION}
					</div>
				</div>
			</div>

			<div className={"second-sidebar" + (layoutSelector.showleftMenu ? '' : ' d-none')}>
				<div className="second-sidebar-logo">
					<img src={logo} alt="" width={120} />
				</div>

				<div id="sidebar-menu">
					<ul className="metismenu list-unstyled" id="side-menu">
						{activeItem.children.map((item, index) => (
							<li className={item.active ? "mm-active" : ""} key={index}>
								{item.title == null ?
									<NavLink to={item.url}
										onClick={() => setActiveChild(item, index)} className={"waves-effect d-flex " + (item.active ? "active" : "")}>
										<i className={item.icon}></i>
										<div className="vertical-menu-div">{t(item.i18n)}</div>
									</NavLink>
									:
									<div className="second-sidebar-title">
										<div>{t(item.i18n)}</div>
									</div>
								}
							</li>
						))}
					</ul>
				</div>
			</div>
		</>
	)
}

export default SidebarContent
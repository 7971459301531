import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx'

import Rightbar from 'pages/main/cheques/Rightbar'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, returnSign, formatDate, findKeyFromArrayByValue } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const date = new Date()
	const reduxAccount = useSelector(state => state.account)

	const orderTypes = [
		{ "id": 1, "name": t('hall') },
		{ "id": 2, "name": t('takeaway') },
		{ "id": 3, "name": t('delivery') },
	]

	const orderDeliveryTypes = [
		{ "id": 1, "name": t('pos') },
		{ "id": 2, "name": t('telegram') },
		{ "id": 3, "name": t('call_center') },
		{ "id": 4, "name": t('website') },
		{ "id": 5, "name": t('ANDROID') },
		{ "id": 6, "name": t('IOS') }
	]

	const [showRightBar, setShowRightBar] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [poses, setPoses] = useState([])
	const [menu, setMenu] = useState([])
	const [cashiers, setCashiers] = useState([])
	const [waiters, setWaiters] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
		menu_id: null,
		cashier_login: null,
		waiter_login: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/report-sales',
		response: null
	})
	const [data, setData] = useState({
		'data': [],
		'total_sales': 0,
		'total_sales_with_percentage': 0,
	})

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...data.data]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}

		if (item.cheque_id !== 0) {
			if (boolean) {
				const response = await GET(`/cheque-params/${item.cheque_id}`, {}, { loader: true })
				if (httpOk(response)) {
					dataCopy[index]['products'] = response.data.products
					dataCopy[index]['payments'] = response.data.payments
					dataCopy[index]['showSidebar'] = boolean

					setSelectedItem(dataCopy[index])
					setShowRightBar(boolean)
				}
			} else {
				setShowRightBar(boolean)
				setSelectedItem({})
			}
		}
	}

	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = "/report-sales"
		var urlParams = ""

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.order_type)
			urlParams += returnSign(urlParams) + 'order_type=' + filterData.order_type
		if (filterData.order_type_delivery)
			urlParams += returnSign(urlParams) + 'order_type_delivery=' + filterData.order_type_delivery
		if (filterData.menu_id)
			urlParams += returnSign(urlParams) + 'menu_id=' + filterData.menu_id
		if (filterData.cashier_login)
			urlParams += returnSign(urlParams) + 'cashier_login=' + filterData.cashier_login
		if (filterData.waiter_login)
			urlParams += returnSign(urlParams) + 'waiter_login=' + filterData.waiter_login
		if (filterData.shift_id)
			urlParams += returnSign(urlParams) + 'shift_id=' + filterData.shift_id
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/report-sales${urlParams}` !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		filterUrl += urlParams

		if (exportToExcel) {
			filterUrl = `/report-sales-excel${urlParams}`

			var excelHeaders = [[
				'ID', t('pos'), t('cashier'), t('menu'), t('name2'), t('date'),
				t('quantity'), t('price'), t('sale_price'), t('amount'), t('gross_income'),
				t('service'), t('order_type'),
			]]
			var response = await GET(filterUrl)
			if (httpOk(response)) {
				response = response.data
				for (let i = 0; i < response.length; i++) {
					var excelItems = []
					excelItems.push(response[i]['id'])
					excelItems.push(response[i]['pos_name'])
					excelItems.push(response[i]['first_name'])
					excelItems.push(response[i]['menu_name'])
					excelItems.push(response[i]['product_name'])
					excelItems.push(formatDate(response[i]['cheque_closed_at'], 'dd.MM.yy HH:mm'))
					excelItems.push(Number(response[i]['quantity']))
					excelItems.push(Number(response[i]['price']))
					excelItems.push(Number(response[i]['sale_price']))
					excelItems.push(Number(response[i]['total_price']))
					excelItems.push(Number(response[i]['total_income']))
					excelItems.push(Number(response[i]['service_percentage']))
					if (response[i]['order_type'] === 1) {
						excelItems.push(t('hall'))
					}
					if (response[i]['order_type'] === 2) {
						excelItems.push(t('takeaway'))
					}
					if (response[i]['order_type'] === 3) {
						excelItems.push(t('delivery'))
					}
					if (response[i]['order_type'] === 4) {
						excelItems.push(t('telegram'))
					}
					excelHeaders.push(excelItems)
				}

				const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
				XLSX.writeFile(wb, t('sales_report') + ".xlsx");
			}
		}

		if (!exportToExcel) {
			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
			}
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: null,
			menu_id: null,
			cashier_login: null,
			waiter_login: null,
			search: '',
		})
		searchWithFilter()
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getMenu() {
		const response = await GET('/helper/menu')
		if (httpOk(response)) setMenu(response.data)
	}

	async function getCashiers() {
		const response = await GET('/helper/cashiers')
		if (httpOk(response)) setCashiers(response.data)
	}

	async function getWaiters() {
		const response = await GET('/helper/waiters')
		if (httpOk(response)) setWaiters(response.data)
	}

	useEffect(() => {
		getPoses()
		getMenu()
		getCashiers()
		getWaiters()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('sales')} [{data.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('order_type')}</label>
												<Select
													isClearable
													options={orderTypes}
													value={orderTypes.find(option => option.id === filterData.order_type) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'order_type': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('menu')}</label>
												<Select
													options={menu}
													value={menu.find(option => option.id === filterData.menu_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'menu_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('delivery_type')}</label>
												<Select
													isClearable
													options={orderDeliveryTypes}
													value={orderDeliveryTypes.find(option => option.id === filterData.order_type_delivery) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'order_type_delivery': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('cashier')}</label>
												<Select
													options={cashiers}
													value={cashiers.find(option => option.login === filterData.cashier_login) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'cashier_login': option.login })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.first_name}
													getOptionValue={(option) => option.login}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('waiter')}</label>
												<Select
													options={waiters}
													value={waiters.find(option => option.login === filterData.waiter_login) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'waiter_login': option.login })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.first_name}
													getOptionValue={(option) => option.login}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('shift')} ID</label>
												<input type="text" className="form-control"
													onChange={(e) => setFilterData({ ...filterData, 'shift_id': e.target.value })} />
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(false)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2"
										onClick={() => searchWithFilter(true)}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
						<div className="vertical-center">
							<h6 className="d-flex justify-content-between gap-3">
								<b>{t('amount')}:</b> {formatMoney(data.total_sales)}
							</h6>
							<h6 className="d-flex justify-content-between gap-3">
								<b>{t('allowances')}:</b> {formatMoney(data.total_allowances)}
							</h6>
							<h6 className="d-flex justify-content-between gap-3">
								<b>{t('discount')}:</b> {formatMoney(data.total_discount)}
							</h6>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.menu_id &&
									<span className="me-2">
										<b>{t('menu')}: </b>
										{findKeyFromArrayByValue(menu, 'id', filterData.menu_id, 'name')}
									</span>
								}
								{filterData.cashier_login &&
									<span className="me-2">
										<b>{t('cashier')}: </b>
										{findKeyFromArrayByValue(cashiers, 'login', filterData.cashier_login, 'first_name')}
									</span>
								}
								{filterData.waiter_login &&
									<span className="me-2">
										<b>{t('waiter')}: </b>
										{findKeyFromArrayByValue(waiters, 'login', filterData.cashier_login, 'first_name')}
									</span>
								}
								{(filterData.order_type) &&
									<span className="me-2">
										<b>{t('order_type')}: </b>
										{findKeyFromArrayByValue(orderTypes, 'id', filterData.order_type, 'name')}
									</span>
								}
								{(filterData.order_type_delivery) &&
									<span className="me-2">
										<b>{t('delivery_type')}: </b>
										{findKeyFromArrayByValue(orderDeliveryTypes, 'id', filterData.order_type_delivery, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '20%' }}>{t('name2')}</th>
									<th style={{ 'width': '20%' }}>{t('menu')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('sale_date')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('sync_date')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('quantity')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('sale_price')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data.map((item, index) => (
									<tr key={index} className={'cursor' + (item.showSidebar ? ' table-tr-active' : '')}
										onClick={() => toggleRightbar(true, item, index)}>
										<td className="text-center">
											<CustomTooltip text={item.id}>
												{item.row_number}
											</CustomTooltip>
										</td>
										<td>{item.pos_name}</td>
										<td>{item.product_name}</td>
										<td>{item.menu_name}</td>
										<td className="text-center">{formatDate(item.cheque_closed_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-center">{formatDate(item.cheque_sync_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-end">{item.quantity}</td>
										<td className="text-end">{formatMoney(item.sale_price)}</td>
										<td className="text-end">{formatMoney(item.sale_price * item.quantity)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			<Rightbar
				showRightBar={showRightBar}
				setShowRightBar={setShowRightBar}
				selectedItem={selectedItem}>
			</Rightbar>
		</>
	)
}

export default Index
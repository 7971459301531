import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { toast } from 'react-toastify'

import { GET, POST, PUT, httpOk } from 'helpers/api'
import { formatDate, formatMoney, returnMinDate } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState({
		items: [],
		note: '',
		total_cost_price: 0,
		created_at: formatDate(new Date(), 'yyyy-MM-dd'),
	})
	const [products, setProducts] = useState([])
	const [wms, setWms] = useState([])

	async function createUpdate(e, completed) {
		e.preventDefault();
		var dataCopy = { ...data }
		dataCopy.error = false
		dataCopy.completed = completed

		for (let i = 0; i < dataCopy.items.length; i++) {
			if (!dataCopy.items[i]['quantity']) {
				dataCopy.error = true
			}
			if (dataCopy.items[i]['price'] === "" || dataCopy.items[i]['price'] < 0.0001) {
				dataCopy.error = true
			}
		}

		var valueArr = dataCopy.items.map(function (item) { return item.id });
		var isDuplicate = valueArr.some(function (item, idx) {
			return valueArr.indexOf(item) !== idx
		});

		if (isDuplicate) {
			toast.error(t('identical_elements'))
			return
		}

		if (dataCopy.error) {
			toast.error(t('check_filled_inputs'))
			return
		}

		var response;
		if (dataCopy.id) {
			response = await PUT('/write-off', dataCopy, { loader: true })
		} else {
			response = await POST('/write-off', dataCopy, { loader: true })
		}

		if (httpOk(response)) {
			navigate('/write-off')
		}
	}

	// function search(value) {
	// 	if (value === '') {
	// 		setProducts(productsDuplicate)
	// 		return;
	// 	}

	// 	var dataDuplicateCopy = []
	// 	dataDuplicateCopy = [...productsDuplicate]

	// 	var result = dataDuplicateCopy.filter(item =>
	// 		item?.name?.toString().toLowerCase().includes(value.toLowerCase())
	// 	)

	// 	setProducts(result)
	// }

	function deleteItem(index) {
		var dataCopy = JSON.parse(JSON.stringify(data))
		dataCopy.items.splice(index, 1)

		setData(dataCopy)
		countTotalAmount(dataCopy)
	}

	function countTotalAmount(dataCopy) {
		dataCopy['total_cost_price'] = 0
		for (let i = 0; i < dataCopy.items.length; i++) {
			dataCopy.items[i]['total_cost_price'] = 0
			dataCopy.items[i]['total_cost_price'] +=
				Number(dataCopy.items[i]['quantity']) *
				Number(dataCopy.items[i]['cost_price'])

			dataCopy['total_cost_price'] += dataCopy.items[i]['total_cost_price']
		}
		console.log(dataCopy)
		setData(dataCopy)
	}

	function addToList() {
		var dataCopy = { ...data }

		dataCopy.items.push({
			'quantity': ''
		})
		countTotalAmount(dataCopy)
	}

	function handleInputChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.items[index][e.target.name] = e.target.value
		countTotalAmount(dataCopy)
	}

	function handleSelect(option, index) {
		var dataCopy = { ...data }

		dataCopy.items[index]['id'] = option.id
		dataCopy.items[index]['name'] = option.name
		dataCopy.items[index]['in_product'] = option.in_product
		dataCopy.items[index]['cost_price'] = option.cost_price
		dataCopy.items[index]['unit_name'] = option.unit_name

		setData(dataCopy)
		countTotalAmount(dataCopy)
	}

	async function getItems() {
		const response = await GET(`/write-off-items`, {}, { loader: true })
		if (httpOk(response)) {
			setProducts(response.data)
			//setProductsDuplicate(response)
		}
	}

	async function getWms() {
		const response = await GET(`/helper/wms`, {}, { loader: true })

		if (httpOk(response)) setWms(response.data)
	}

	useEffect(() => {
		getItems()
		getWms()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('write_off')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
			</div>

			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('date')}</label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={data.created_at ? new Date(data.created_at) : ''}
											onChange={(date) => setData({ ...data, 'created_at': formatDate(date, 'yyyy-MM-dd') })}
											minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
											maxDate={new Date()} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('warehouse')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={data.wms_id}
										options={wms}
										value={wms.find(option => option.id === data.wms_id)}
										onChange={(option) => setData({ ...data, 'wms_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('note')}</label>
									<input type="text" className="form-control" name="note"
										value={data.note ?? ''}
										onChange={(e) => setData({ ...data, 'note': e.target.value })} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<div className="d-flex  justify-content-between mb-2">
							<button type="button" className="btn btn-primary btn-rounded"
								onClick={() => addToList()}>
								{t('add')}
							</button>
						</div>

						<div className="table-responsive">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th style={{ 'width': '1%' }}>#</th>
										<th style={{ 'width': '30%' }}>{t('name2')}</th>
										<th style={{ 'width': '10%' }}>{t('quantity')}</th>
										<th style={{ 'width': '10%' }}>{t('type')}</th>
										<th style={{ 'width': '20%' }}>{t('unit')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('cost_price')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
										<th style={{ 'width': '5%' }}></th>
									</tr>
								</thead>
								<tbody>
									{data?.items?.map((item, index) => (
										<tr key={index}>
											<td>{index + 1}</td>
											<td>
												<div style={{ 'width': '300px' }}>
													<Select
														inputId={index + 1}
														options={products}
														value={products?.find(option => option.id === data.items[index]['id'])}
														onChange={(option) => handleSelect(option, index)}
														menuPosition="fixed"
														placeholder=""
														noOptionsMessage={() => t('list_empty')}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>
												</div>
											</td>
											<td>
												<input
													type="text"
													className={"auto-width-input " + (!item.quantity && "required-border")}
													name="quantity"
													value={item.quantity}
													onChange={(e) => handleInputChange(e, index)} />
											</td>
											<td className="vertical-middle">
												{item.in_product === 0 &&
													<span>{t('element')}</span>
												}
												{item.in_product === 1 &&
													<span>{t('product')}</span>
												}
												{item.in_product === 2 &&
													<span>{t('dish_preparation')}</span>
												}
												{item.in_product === 3 &&
													<span>{t('modifiers')}</span>
												}
											</td>
											<td className="vertical-middle">{item.unit_name}</td>
											<td className="vertical-middle text-end">{formatMoney(item.cost_price)}</td>
											<td className="vertical-middle text-end">{formatMoney(item.total_cost_price)}</td>
											<td className="vertical-middle">
												<div className="d-flex justify-content-end">
													<div className="table-action-button table-action-danger-button"
														onClick={() => deleteItem(index)}>
														<i className="uil uil-times"></i>
													</div>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>

						<div className="w-100 d-flex justify-content-end">
							<div className="flex-column w-25">
								<h5 className="d-flex justify-content-between">
									<strong className="me-2">{t('total_amount')}:</strong>{formatMoney(data.total_cost_price)}
								</h5>
							</div>
						</div>

						<div className="btn-wrapper">
							<Link className="btn btn-warning btn-rounded btn-wide me-2" to="/write-off">
								{t('cancel')}
							</Link>
							<button type="button" className="btn btn-success btn-rounded btn-wide me-2"
								disabled={!data.items.length > 0}
								onClick={(e) => createUpdate(e, true)}>{t('complete')}</button>
							<button type="button" className="btn btn-primary btn-rounded btn-wide"
								disabled={!data.items.length > 0}
								onClick={(e) => createUpdate(e, false)}>{t('save_to_draft')}</button>
						</div>
					</div>
				</div>
			</form>
		</>
	)
}

export default CreateUpdate
import React, { useEffect, useState, Fragment } from "react"
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { httpOk, GET } from 'helpers/api'
import { formatDate, formatMoney, formatMoneyInput } from 'helpers/helpers'

function Main() {
	const { t } = useTranslation();
	const { id } = useParams();

	const [activeTab, setActiveTab] = useState(1)
	const [data, setData] = useState({
		pos_id: null,
		wms_id: null,
		elements: [],
		products: [],
	})

	// STEP 2
	function handleGroupElementChange(e, index, index2) {
		var dataCopy = { ...data }

		let value = e.target.value;
		value = value.replace(/,/g, '.')
		dataCopy.elements[index]['groups'][index2][e.target.name] = value.replace(/[^\d.]/g, '')

		calculate(dataCopy)
	}

	function calculateGroup(item) {
		var fact = 0

		for (let i = 0; i < item.groups.length; i++) {
			fact += Number(item.groups[i]['actual_balance'])
		}

		return formatMoney(fact, 2)
	}

	function calculate(dataCopy) {
		dataCopy.balance = 0
		dataCopy.difference_quantity = 0
		dataCopy.difference_amount = 0

		for (let i = 0; i < dataCopy.elements.length; i++) {
			if (dataCopy.elements[i]['groups'] && dataCopy.elements[i]['groups'].length > 1) {
				var temporaryActualBalance = 0;
				for (let j = 0; j < dataCopy.elements[i]['groups'].length; j++) {
					temporaryActualBalance += Number(dataCopy.elements[i]['groups'][j]['actual_balance']);
				}

				dataCopy.elements[i]['divergence'] =
					temporaryActualBalance - dataCopy.elements[i]['balance']
				dataCopy.elements[i]['difference_amount'] =
					(temporaryActualBalance - dataCopy.elements[i]['balance']) * dataCopy.elements[i]['price']
			} else {
				dataCopy.elements[i]['divergence'] =
					dataCopy.elements[i]['actual_balance'] - dataCopy.elements[i]['balance']
				dataCopy.elements[i]['difference_amount'] =
					(dataCopy.elements[i]['actual_balance'] - dataCopy.elements[i]['balance']) * dataCopy.elements[i]['price']
			}
		}

		setData(dataCopy)
	}
	// STEP 2

	async function getData() {
		const response = await GET(`/inventory2/${id}`, {}, { loader: true })
		if (httpOk(response)) {
			setActiveTab(2)
			setData(response.data)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{activeTab === 1 &&
				<>
					<div className="page-header d-flex">
						<div className="d-flex text-truncate">
							<h4 className="vertical-center">
								Шаг 1: Приготовленные блюда загатовки
							</h4>
						</div>
					</div>
					<div className="card mb-3">
						<div className="card-body">
							<h4 className="fw-600">{t('inventory')} {formatDate(data.created_at)}</h4>
							<div className="d-flex flex-wrap">
								<div className="me-3">
									<div className="d-flex">
										<div className="fw-600 text-nowrap me-3">{t('pos')}:</div>
										<div className="w-100 text-end text-nowrap">{data.pos_name}</div>
									</div>
									<div className="d-flex">
										<div className="fw-600 text-nowrap me-3">{t('warehouse')}:</div>
										<div className="w-100 text-end text-nowrap">{data.wms_name}</div>
									</div>
									<div className="d-flex">
										<div className="fw-600 text-nowrap me-3">{t('note')}:</div>
										<div className="w-100 text-end text-nowrap">{data.note}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<table className="table table-bordered" style={{ 'overflow': 'unset' }}>
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '30%' }}>{t('name2')}</th>
										<th style={{ 'width': '10%' }}>{t('type')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('counted')}</th>
										<th style={{ 'width': '10%' }}>{t('unit')}</th>
										<th style={{ 'width': '40%' }}></th>
									</tr>
								</thead>
								<tbody>
									{data?.products?.map((item, index) => (
										<tr key={index}>
											<td className="text-center">{index + 1}</td>
											<td className="td-ellipsis">{item.name}</td>
											<td>{item.product_type_name}</td>
											<td className="text-end">{formatMoney(item.actual_balance, 2)}</td>
											<td>{item.unit_name}</td>
											<td></td>
										</tr>
									))}
								</tbody>
							</table>

							<div className="btn-wrapper mt-5">
								<button type="button" className="btn btn-primary btn-rounded btn-wide"
									onClick={() => setActiveTab(2)}>
									{t('continue')}
								</button>
							</div>
						</div>
					</div>
				</>
			}

			{activeTab === 2 &&
				<>
					<div className="page-header d-flex justify-content-between">
						<div className="d-flex text-truncate">
							<h4 className="vertical-center">
								Шаг 2: Подсчет элементов
							</h4>
						</div>
					</div>

					<div className="card mb-3">
						<div className="card-body">
							<h4 className="fw-600">{t('inventory')} {formatDate(data.created_at)}</h4>
							<div className="d-flex flex-wrap">
								<div className="me-3">
									<div className="d-flex">
										<div className="fw-600 text-nowrap me-3">{t('pos')}:</div>
										<div className="w-100 text-end text-nowrap">{data.pos_name}</div>
									</div>
									<div className="d-flex">
										<div className="fw-600 text-nowrap me-3">{t('warehouse')}:</div>
										<div className="w-100 text-end text-nowrap">{data.wms_name}</div>
									</div>
									<div className="d-flex">
										<div className="fw-600 text-nowrap me-3">{t('note')}:</div>
										<div className="w-100 text-end text-nowrap">{data.note}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<table className="table table-bordered" style={{ 'overflow': 'unset' }}>
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '30%' }}>{t('name2')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('counted')}</th>
										<th style={{ 'width': '10%' }}>{t('unit')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('price')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('fact')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('balance2')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('difference')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
									</tr>
								</thead>
								<tbody>
									{data.elements.map((item, index) => (
										item.groups?.length > 0 ?
											<Fragment key={index}>
												<tr className="border-none">
													<td className="vertical-middle text-center" rowSpan={item.groups.length}>
														{index + 1}
													</td>
													<td className="vertical-middle">{item.name}</td>
													<td className="vertical-middle text-end text-nowrap">{item.groups[0]['actual_balance']}</td>
													<td className="vertical-middle">{item.unit_name}</td>
													<td className="vertical-middle text-end text-nowrap" rowSpan={item.groups.length}>{formatMoney(item.price)}</td>
													<td className="vertical-middle text-end text-nowrap" rowSpan={item.groups.length}>{calculateGroup(item)}</td>
													<td className="vertical-middle text-end text-nowrap" rowSpan={item.groups.length}>{formatMoney(item.balance, 2)}</td>
													<td className="vertical-middle text-end text-nowrap" rowSpan={item.groups.length}>
														<span className={`${item.divergence < 0 ? 'text-danger' : ''}`}>
															{formatMoney(item.divergence, 2)}
														</span>
													</td>
													<td className="vertical-middle text-end text-nowrap" rowSpan={item.groups.length}>
														<span className={`${item.difference_amount < 0 ? 'text-danger' : ''}`}>
															{formatMoney(item.difference_amount)}
														</span>
													</td>
												</tr>
												{item?.groups?.map((item2, index2) => (
													index2 > 0 &&
													<tr className="border-none" key={index2}>
														<td></td>
														<td className="td-input" style={!item2.actual_balance ? { 'borderBottom': '2px solid red' } : {}}>
															<input
																type="number"
																name="actual_balance"
																className={`form-control text-end ${item2.actual_balance < 0 ? 'required-border' : ''}`}
																placeholder="0"
																value={formatMoneyInput(item2.actual_balance) ?? ''}
																onChange={(e) => handleGroupElementChange(e, index, index2)}
															/>
														</td>
														<td className="vertical-middle">{item.unit_name}</td>
													</tr>
												))
												}
											</Fragment>
											:
											<tr key={index}>
												<td className="text-center">{index + 1}</td>
												<td>{item.name}</td>
												<td className="vertical-middle text-end text-nowrap">{item.actual_balance}</td>
												<td className="vertical-middle">{item.unit_name}</td>
												<td className="vertical-middle text-end text-nowrap">{formatMoney(item.price)}</td>
												<td className="vertical-middle text-end text-nowrap">{formatMoney(item.actual_balance, 2)}</td>
												<td className="vertical-middle text-end text-nowrap">{formatMoney(item.balance, 2)}</td>
												<td className="text-end text-nowrap">
													<span className={`${item.divergence < 0 ? 'text-danger' : ''}`}>
														{formatMoney(item.divergence, 2)}
													</span>
												</td>
												<td className="vertical-middle text-end text-nowrap">
													<span className={`${item.difference_amount < 0 ? 'text-danger' : ''}`}>
														{formatMoney(item.difference_amount)}
													</span>
												</td>
											</tr>
									))}
								</tbody>
							</table>

							<div className="btn-wrapper gap-2 mt-5">
								<button type="button" className="btn btn-warning btn-rounded btn-wide"
									onClick={() => setActiveTab(1)}>
									{t('back')}
								</button>
							</div>
						</div>
					</div>
				</>
			}
		</>
	)
}

export default Main
import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import Rightbar from '../cheques/Rightbar'
import { GET, PUT, httpOk } from 'helpers/api'
import { findKeyFromArrayByValue, formatDate, formatMoney, returnSign } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const { employee_id } = useParams()
	const reduxAccount = useSelector(state => state.account)

	const statuses = [
		{ 'id': 1, 'name': t('success') },
		{ 'id': -1, 'name': t('deleted') },
	]

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [modalChangePayment, setModalChangePayment] = useState(false)
	const [showRightBar, setShowRightBar] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: reduxAccount.pos_id,
		status: 1,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: `/report-employees-history/${employee_id}`,
		response: null
	})

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = { ...data }

		for (let i = 0; i < dataCopy.data.length; i++) {
			dataCopy.data[i]['showSidebar'] = false
		}

		if (item.cheque_id !== 0) {
			if (boolean) {
				const response = await GET("/cheques/" + item.cheque_id, {}, { loader: true })
				if (httpOk(response)) {
					dataCopy.data[index]['showSidebar'] = boolean
					setSelectedItem(response.data)
					setShowRightBar(boolean)
				}

			} else {
				setShowRightBar(boolean)
				setSelectedItem({})
			}
		}
	}

	function toggleModalChangePayment(bool, item = {}) {
		if (bool) {
			var itemCopy = { ...item }
			itemCopy.changed_amount_in = itemCopy.amount_in

			setSelectedItem(itemCopy)
			setModalChangePayment(true)
		} else {
			setModalChangePayment(false)
		}
	}

	async function changePayment(e) {
		e.preventDefault();

		var response = await PUT('/clients-payment', selectedItem, { loader: true })

		if (httpOk(response)) {
			toggleModalChangePayment(false)
			searchWithFilter()
		}
	}

	async function searchWithFilter() {
		var filterUrl = `/report-employees-history/${employee_id}`
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.status)
			urlParams += returnSign(urlParams) + 'status=' + filterData.status
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/report-employees-history/${employee_id}${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function exportToExcel() {
		//
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			status: 1,
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.status === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex text-truncate">
				<h4 className="vertical-center h-100">
					{t('employee')}
				</h4>
				<div className="vertical-center">
					<i className="uil uil-angle-double-right fz-20"></i>
				</div>
				<div className="vertical-center">
					<h6>{data?.client?.first_name} [{data?.client?.employee_id}]</h6>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													options={statuses}
													value={statuses.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(false)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div title={t('EXCEL')}>
								<button className="btn btn-outline-primary btn-wide-2 d-none"
									onClick={exportToExcel}>
									<i className="uil uil-file-download-alt"></i>
								</button>
							</div>
						</div>
						<div className="d-flex gap-2">
							<div className="vertical-center">
								<h4><b>{t('amount')}</b>:</h4>
							</div>
							<div className="vertical-center">
								<h5 className="text-end">
									<span className={`${data.total_amount > 0 ? 'text-success' : 'text-danger'}`}>
										{formatMoney(data.total_amount)}
									</span>
								</h5>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '10%' }}>{t('pos')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t("payment_method")}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '40%' }}>{t('note')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('debt')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('paid')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('total')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index} className={'cursor ' + (item.showSidebar ? 'table-tr-active' : '')}
										onClick={() => {
											if (item.cheque_id) {
												toggleRightbar(true, item, index)
											}
										}}>
										<td className="text-center">{item.row_number}</td>
										<td>{item.pos_name}</td>
										<td className="text-center">{item.payment_type_name}</td>
										<td className="text-center">
											<CustomTooltip text={t('write_off')}>
												{!item.cashbox_id && // Списанный долг
													<span className="text-danger">{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</span>
												}
											</CustomTooltip>
											{item.cashbox_id &&
												<span>{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</span>
											}
										</td>
										<td className="td-ellipsis">{item.note}</td>
										<td className="text-end text-danger">{formatMoney(item.amount_minus)}</td>
										<td className="text-end text-success">{formatMoney(item.amount_plus)}</td>
										<td className={`text-end ${item.balance >= 0 ? 'text-success' : 'text-danger'}`}>
											{formatMoney(item.balance)}
										</td>
										<td>
											{(!item.changed_by && item.amount_in > 0 && item?.last_id && item?.cashbox_id) &&
												<div className="d-flex justify-content-end">
													<div className="table-action-button table-action-primary-button"
														onClick={() => toggleModalChangePayment(true, item)}>
														<i className="uil-edit-alt"></i>
													</div>
												</div>
											}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			<Rightbar
				showRightBar={showRightBar}
				setShowRightBar={setShowRightBar}
				selectedItem={selectedItem}>
			</Rightbar>

			{/* MODAL CHANGE PAYMENT */}
			<Modal show={modalChangePayment} animation={false}
				centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModalChangePayment(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('pay')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={changePayment} autoComplete="off">
						<div className="form-group">
							<label>{t('amortization')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="amount_in" autoFocus
								value={selectedItem.amount_in ? formatMoney(selectedItem.amount_in) : ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount_in': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => toggleModalChangePayment(false)} tabIndex="-1">{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100"
								disabled={!selectedItem.amount_in || !(Number(selectedItem.amount_in) < Number(selectedItem.changed_amount_in))}>
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL CHANGE PAYMENT */}
		</>
	)
}

export default Index

import React, { useEffect, useState } from "react"
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, formatDate } from 'helpers/helpers'

function Preview() {
	const { t } = useTranslation()
	const { id } = useParams();

	const [data, setData] = useState({})

	async function getData() {
		const response = await GET("/inventory-section/" + id, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('inventory')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('preview')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4 className="fw-600">{t('inventory')} {formatDate(data.created_at)}</h4>
					<div className="d-flex flex-wrap">
						<div className="me-3">
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('pos')}:</div>
								<div className="w-100 text-end text-nowrap">{data.pos_name}</div>
							</div>
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('note')}:</div>
								<div className="w-100 text-end text-nowrap">{data.note}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered">
							<thead>
								<tr>
									<th>{t('name2')}</th>
									<th className="text-center">{t('unit')}.</th>
									<th className="text-center">{t('price')}</th>
									<th className="text-center">{t('expected_balance')}</th>
									<th className="text-center">{t('counted')}</th>
									<th className="text-center">{t('divergence')}</th>
									<th className="text-end">{t('cost_difference')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.elements?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1} {item.name}</td>
										<td className="text-center">{item.unit_name}</td>
										<td className="text-center">{formatMoney(item.price)}</td>
										<td className="text-center">{item.balance}</td>
										<td className="text-center">{item.actual_balance}</td>
										<td className="text-center">{item.divergence}</td>
										<td className="text-end">{formatMoney(item.difference_amount)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<div className="w-100 d-flex justify-content-end">
						<div className="flex-column w-25">
							<h5 className="d-flex justify-content-between">
								<strong className="me-2">{t('lack_of')}:</strong>{formatMoney(data.shortage)} {data.currencyName}
							</h5>
							<h5 className="d-flex justify-content-between">
								<strong className="me-2">{t('surplus')}:</strong>{formatMoney(data.surplus)} {data.currencyName}
							</h5>
						</div>
					</div>

					<div className="d-flex justify-content-end mt-5">
						<Link className="btn btn-warning btn-rounded btn-wide" to="/inventory-section">
							{t('cancel')}
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}

export default Preview

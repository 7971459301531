import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import { TOGGLE_LEFT_MENU } from 'store/layout/actions'
import { FILE_D, GET, httpOk } from 'helpers/api'
import { returnSign, formatDate, findKeyFromArrayByValue, formatMoney } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function ProfitLoses() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const date = new Date()

	const reduxAccount = useSelector(state => state.account)

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
		search: '',
	})
	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])

	async function searchWithFilter() {
		var filterUrl = "/report-profit-loses"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date

		if (`/report-profit-loses${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams
		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	async function downloadExcelFile() {
		var filterUrl = "/report-profit-loses-excel"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		
		filterUrl = filterUrl + urlParams
		var response = await FILE_D(filterUrl)
		if (httpOk(response)) {
			// Создаем объект Blob из полученного файла
			const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Создаем ссылку для скачивания файла
			const fileUrl = URL.createObjectURL(file);

			// Создаем ссылку для скачивания файла в браузере
			const link = document.createElement('a');
			link.href = fileUrl;
			link.setAttribute('download', `P&L.xlsx`);
			document.body.appendChild(link);
			link.click();

			// Удаляем ссылку после скачивания файла
			URL.revokeObjectURL(fileUrl);
		}
	};

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: null,
			search: '',
		})
		searchWithFilter()
		setFilterDataExist(false)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.start_date === null &&
			filterData.end_date === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		getPoses()
		dispatch(TOGGLE_LEFT_MENU(false))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('date')}</label>
												<div className="calendar-input">
													<DatePicker
														showMonthYearPicker
														showFullMonthYearPicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(false)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2"
										onClick={() => downloadExcelFile()}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive" style={{ 'height': '75vh' }}>
						<table className="table table-bordered table-column-fixed mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }}></th>
									{data?.headers?.map((item, index) => (
										<th key={index}
											style={{ 'minWidth': '100px' }}
											className="text-center">
											{item}
										</th>
									))}
								</tr>
								<tr>
									<th style={{ 'minWidth': '33px' }}></th>
									<th style={{ 'minWidth': '250px' }}></th>
									<th style={{ 'minWidth': '100px' }}
										className="text-end text-nowrap">
										{t('amount')}
									</th>
									{data?.days?.map((item, index) => (
										<th key={index}
											style={{ 'minWidth': '100px' }}
											className="text-end text-nowrap">
											{formatDate(item.day, 'dd.MM.yy')}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="text-center cursor"
										onClick={() => setData({ ...data, 'bool_payments': !data.bool_payments })}>
										<i className="uil uil-plus"></i>
									</td>
									<td><b>{t('sales')}</b></td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(data?.payments_total?.overall_payments)}</b>
									</td>
									{data?.payments_total?.days?.map((item, index) => (
										<td className="text-end text-nowrap" key={index}>
											<b>{formatMoney(item.total_sales)}</b>
										</td>
									))}
								</tr>
								<tr className={`${!data.bool_payments ? 'd-none' : ''}`}>
									<td></td>
									<td>{t('safe')}</td>
									<td className="text-end text-nowrap">
										{formatMoney(data?.payments_total?.overall_safe)}
									</td>
									{data?.payments_total?.days?.map((item, index) => (
										<td className="text-end text-nowrap" key={index}>
											{formatMoney(item.total_safe)}
										</td>
									))}
								</tr>
								<tr className={`${!data.bool_payments ? 'd-none' : ''}`}>
									<td></td>
									<td>{t('bank')}</td>
									<td className="text-end text-nowrap">
										{formatMoney(data?.payments_total?.overall_bank)}
									</td>
									{data?.payments_total?.days?.map((item, index) => (
										<td className="text-end text-nowrap" key={index}>
											{formatMoney(item.total_bank)}
										</td>
									))}
								</tr>
								<tr>
									<td></td>
									<td><b>{t('goods sold')}</b></td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(data?.balance_total?.overall)}</b>
									</td>
									{data?.balance_total?.days?.map((item, index) => (
										<td className="text-end text-nowrap" key={index}>
											<b>{formatMoney(item.amount)}</b>
										</td>
									))}
								</tr>
								<tr>
									<td className="text-center cursor"
										onClick={() => setData({ ...data, 'bool_documents_in': !data.bool_documents_in })}>
										<i className="uil uil-plus"></i>
									</td>
									<td><b>{t('good_reception')}</b></td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(data?.documents_in_total?.overall)}</b>
									</td>
									{data?.documents_in_total?.days?.map((item, index) => (
										<td className="text-end text-nowrap" key={index}>
											<b>{formatMoney(item.amount)}</b>
										</td>
									))}
								</tr>
								{data?.suppliers_debt?.map((supplier, index) => (
									<tr key={index} className={`${!data.bool_documents_in ? 'd-none' : ''}`}>
										<td></td>
										<td>{supplier.name}</td>
										<td className="text-end text-nowrap">
											{formatMoney(supplier.amount)}
										</td>
										{supplier?.days?.map((supplierByDay, index) => (
											<td className="text-end text-nowrap" key={index}>
												{formatMoney(supplierByDay.amount)}
											</td>
										))}
									</tr>
								))}
								<tr>
									<td className="text-center cursor"
										onClick={() => setData({ ...data, 'bool_documents_in_payed': !data.bool_documents_in_payed })}>
										<i className="uil uil-plus"></i>
									</td>
									<td><b>{t('suppliers_payment')}</b></td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(data?.documents_in_payed?.overall)}</b>
									</td>
									{data?.documents_in_payed?.days?.map((item, index) => (
										<td className="text-end text-nowrap" key={index}>
											<b>{formatMoney(item.amount)}</b>
										</td>
									))}
								</tr>
								{data?.suppliers_payed?.map((supplier, index) => (
									<tr key={index} className={`${!data.bool_documents_in_payed ? 'd-none' : ''}`}>
										<td></td>
										<td>{supplier.name}</td>
										<td className="text-end text-nowrap">
											{formatMoney(supplier.amount)}
										</td>
										{supplier?.days?.map((supplierByDay, index) => (
											<td className="text-end text-nowrap" key={index}>
												{formatMoney(supplierByDay.amount)}
											</td>
										))}
									</tr>
								))}
								<tr>
									<td></td>
									<td><b>{t('marginal_profit')}</b></td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(data?.margin_profit?.overall)}</b>
									</td>
									{data?.margin_profit?.days?.map((item, index) => (
										<td className="text-end text-nowrap" key={index}>
											<b>{formatMoney(item.amount)}</b>
										</td>
									))}
								</tr>
								<tr>
									<td></td>
									<td><b>{t('marginal_profit')} %</b></td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(data?.margin_profitability?.avg, 2)}</b>
									</td>
									{data?.margin_profitability?.days.map((item, index) => (
										<td className="text-end text-nowrap" key={index}>
											<b>{formatMoney(item.amount, 2)}</b>
										</td>
									))}
								</tr>
								<tr>
									<td className="text-center cursor"
										onClick={() => setData({ ...data, 'bool_expenses': !data.bool_expenses })}>
										<i className="uil uil-plus"></i>
									</td>
									<td><b>{t('expenses')}</b></td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(data?.expenses_total?.overall)}</b>
									</td>
									{data?.expenses_total?.days?.map((item, index) => (
										<td className="text-end text-nowrap" key={index}>
											<b>{formatMoney(item.amount)}</b>
										</td>
									))}
								</tr>
								{data?.expense_types?.map((expense, index) => (
									<tr key={index} className={`${!data.bool_expenses ? 'd-none' : ''}`}>
										<td></td>
										<td>{expense.name}</td>
										<td className="text-end text-nowrap">
											{formatMoney(expense.amount)}
										</td>
										{expense?.days?.map((expenseByDay, index) => (
											<td className="text-end text-nowrap" key={index}>
												{formatMoney(expenseByDay.amount)}
											</td>
										))}
									</tr>
								))}
								<tr className={`${!data.bool_expenses ? 'd-none' : ''}`}>
									<td></td>
									<td>({t('discount')}, {t('bonus')}...)</td>
									<td className="text-end text-nowrap">
										{formatMoney(data?.expenses_discount?.overall)}
									</td>
									{data?.expenses_discount?.days?.map((item, index) => (
										<td className="text-end text-nowrap" key={index}>
											{formatMoney(item.amount)}
										</td>
									))}
								</tr>
								<tr className={`${!data.bool_expenses ? 'd-none' : ''}`}>
									<td></td>
									<td>{t('loyalty')}</td>
									<td className="text-end text-nowrap">
										{formatMoney(data?.expenses_loyalty?.overall)}
									</td>
									{data?.expenses_loyalty?.days?.map((item, index) => (
										<td className="text-end text-nowrap" key={index}>
											{formatMoney(item.amount)}
										</td>
									))}
								</tr>
								<tr className={`${!data.bool_expenses ? 'd-none' : ''}`}>
									<td></td>
									<td>{t('salary')}</td>
									<td className="text-end text-nowrap">
										{formatMoney(data?.expenses_salary?.overall)}
									</td>
									{data?.expenses_salary?.days?.map((item, index) => (
										<td className="text-end text-nowrap" key={index}>
											{formatMoney(item.amount)}
										</td>
									))}
								</tr>
								<tr>
									<td></td>
									<td><b>{t('profit')}</b></td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(data?.profit?.overall)}</b>
									</td>
									{data?.profit?.days.map((item, index) => (
										<td className="text-end text-nowrap" key={index}>
											<b>{formatMoney(item.amount)}</b>
										</td>
									))}
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default ProfitLoses
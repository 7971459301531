import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import DatePicker from "react-datepicker"
import CustomTooltip from 'pages/parts/CustomTooltip';
import { formatMoney } from 'helpers/helpers';

function Index() {
	const { t } = useTranslation()

	const [startDate, setStartDate] = useState(new Date());

	const [calendar, setCalendar] = useState([])

	function getDaysInMonth(year, month) {
		const daysInMonth = new Date(year, month + 1, 0).getDate();
		const firstDayOfMonth = new Date(year, month, 1).getDay();
		const days = [];

		// Определяем день недели, с которого начинается месяц (0 - воскресенье, 1 - понедельник, ..., 6 - суббота)
		// Если месяц начинается не с понедельника, добавляем дни предыдущей недели
		let daysBefore = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

		// Заполняем массив днями предыдущей недели
		const prevMonthLastDay = new Date(year, month, 0).getDate();
		for (let i = prevMonthLastDay - daysBefore + 1; i <= prevMonthLastDay; i++) {
			const date = new Date(year, month - 1, i);
			days.push({ date: date, day: i, dayOfWeek: date.getDay(), active: false });
		}

		// Заполняем массив объектами с информацией о днях текущего месяца
		for (let i = 1; i <= daysInMonth; i++) {
			const date = new Date(year, month, i);
			days.push({ date: date, day: i, dayOfWeek: date.getDay(), active: true });
		}

		// Определяем последний день месяца и его день недели
		const lastDayOfMonth = new Date(year, month, daysInMonth);
		const lastDayOfWeek = lastDayOfMonth.getDay();

		// Если последний день месяца не воскресенье, добавляем дни следующего месяца до воскресенья
		if (lastDayOfWeek !== 0) {
			const daysAfter = 7 - lastDayOfWeek;
			for (let i = 1; i <= daysAfter; i++) {
				const date = new Date(year, month + 1, i);
				days.push({ date: date, day: i, dayOfWeek: date.getDay(), active: false });
			}
		}

		setCalendar(days);
	}


	useEffect(() => {
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		const currentMonth = currentDate.getMonth();

		getDaysInMonth(currentYear, currentMonth);
	}, [])

	const handleDayChange = (date) => {
		if (date.getMonth() < startDate.getMonth() || date.getMonth() > startDate.getMonth()) {
			getDaysInMonth(date.getFullYear(), date.getMonth())
		}
		setStartDate(date)
	};

	return (
		<>
			<div>
				<h4 className="vertical-center">
					{t('payment_schedule')}
				</h4>
			</div>

			<div className="d-flex gap-3">
				<div className="card w-25">
					<div className="card-body text-center">
						<DatePicker
							selected={startDate}
							onChange={handleDayChange}
							shouldCloseOnSelect={false}
							showPreviousMonths={false}
							useImperativeHandle
							inline
							onMonthChange={(e) => getDaysInMonth(e.getFullYear(), e.getMonth())}
						/>
					</div>
				</div>
				<div className="card w-100">
					<div className="card-body">
						<div className="calendar">
							<div className="weekdays">
								<div className="weekday">{t('monday')}</div>
								<div className="weekday">{t('tuesday')}</div>
								<div className="weekday">{t('wednesday')}</div>
								<div className="weekday">{t('thursday')}</div>
								<div className="weekday">{t('friday')}</div>
								<div className="weekday">{t('saturday')}</div>
								<div className="weekday">{t('sunday')}</div>
							</div>
							<div className="days">
								{calendar.map((item, index) => (
									<div key={index} className={"day " + (item.active ? 'active ' : '') + (item.active ? 'today' : '')}>
										<div className="text-end">
											{item.day}
										</div>
										<div className="calendar-expenses">
											<CustomTooltip text='Сардор'>
												<div className="calendar-expense">
													+ {formatMoney(100000)}
												</div>
											</CustomTooltip>
											<CustomTooltip text='Сардор'>
												<div className="calendar-expense">
													+ {formatMoney(100000)}
												</div>
											</CustomTooltip>
											<CustomTooltip text='Сардор'>
												<div className="calendar-expense">
													+ {formatMoney(100000)}
												</div>
											</CustomTooltip>
											<div className="calendar-expense text-danger">
												- {formatMoney(300000)}
											</div>
											<div className="calendar-expense">
												+ {formatMoney(2310000)}
											</div>
										</div>
										<b>
											+ {formatMoney(1000000000)}
										</b>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
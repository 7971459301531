import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown, DropdownButton } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from "react-datepicker"

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { POST, PUT, GET, FILE_D, httpOk, FILE } from 'helpers/api'
import { formatDate, regexNumeric, regexPhoneNumber, returnSign, findKeyFromArrayByValue, formatMoney } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const registrationTypes = [
		{ id: 1, name: t('pos') },
		{ id: 2, name: t('telegram') },
		{ id: 3, name: t('website') },
		{ id: 4, name: t('call_center') },
		{ id: 5, name: 'ANDROID' },
		{ id: 6, name: 'IOS' },
	];

	const clientWithoutTypes = [
		{ id: 'telegram', name: t('telegram') },
		{ id: 'loyalty', name: t('loyalty') },
	];

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [discounts, setDiscounts] = useState([])
	const [showCardModal, setCardShowModal] = useState(false)
	const [smsTemplates, setSmsTemplates] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [modalSMS, setModalSMS] = useState({ 'bool': false, 'message': '' })
	const [modalDeleteClient, setModalDeleteClient] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/clients',
		response: null
	})

	async function createUpdate(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.phone1 = '998' + regexNumeric(selectedItemCopy.phone1)
		if (selectedItemCopy.phone2)
			selectedItemCopy.phone2 = '998' + regexNumeric(selectedItemCopy.phone2)

		var response;
		if (selectedItemCopy.id) {
			response = await PUT('/clients', selectedItemCopy, { loader: true })
		} else {
			response = await POST('/clients', selectedItemCopy, { loader: true })
		}

		if (httpOk(response)) {
			searchWithFilter()
			setShowModal(false)
		}
	}

	async function sendSms(e) {
		e.preventDefault();
		var filterDataCopy = { ...filterData }
		filterDataCopy.message = modalSMS.message
		filterDataCopy.sms_template_id = modalSMS.sms_template_id

		var response = await POST('/sms/send-clients', filterDataCopy, { loader: true })

		if (httpOk(response)) {
			toggleModalSMS(false)
		}
	}

	async function bindCard(e) {
		e.preventDefault();

		var response = await POST('/clients-cards', selectedItem, { loader: true })

		if (httpOk(response)) {
			setCardShowModal(false)
			searchWithFilter()
		}
	}

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/clients"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.client_without)
			urlParams += returnSign(urlParams) + 'client_without=' + filterDataCopy.client_without
		if (filterDataCopy.registration_type)
			urlParams += returnSign(urlParams) + 'registration_type=' + filterDataCopy.registration_type
		if (filterDataCopy.registration_date_start)
			urlParams += returnSign(urlParams) + 'registration_date_start=' + filterDataCopy.registration_date_start
		if (filterDataCopy.registration_date_end)
			urlParams += returnSign(urlParams) + 'registration_date_end=' + filterDataCopy.registration_date_end
		if (filterDataCopy.last_cheque_start)
			urlParams += returnSign(urlParams) + 'last_cheque_start=' + filterDataCopy.last_cheque_start
		if (filterDataCopy.last_cheque_end)
			urlParams += returnSign(urlParams) + 'last_cheque_end=' + filterDataCopy.last_cheque_end
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		filterDataCopy.urlParams = urlParams

		if (`/clients${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		if (filterDataCopy.sort) {
			if (filterDataCopy.tableSortKey === filterDataCopy.sort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = filterDataCopy.sort
			urlParams += returnSign(urlParams) + 'sort=' + filterDataCopy.sort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams


		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterData.url, 'response': response.data })
		}

		setFilterData(filterDataCopy)
		setDropdownIsOpen(false)
	}

	function toggleCardModal(bool, item = {}) {
		if (bool) getDiscounts()

		setSelectedItem(item)
		setCardShowModal(bool)
	}

	function toggleDeleteModal(item) {
		setSelectedItem(item)
		setModalDeleteClient(true)
	}

	async function toggleModalSMS(bool) {
		if (bool) {
			var response = await GET('/helper/sms-templates?sms_type_id=1')
			if (httpOk(response)) setSmsTemplates(response.data)
		}
		setModalSMS({ ...modalSMS, 'bool': bool, 'message': '' })
	}

	async function deleteClient(e) {
		e.preventDefault();

		var sendData = { ...selectedItem }
		sendData.status = -1
		var response = await PUT('/clients-status', sendData, { loader: true })

		if (httpOk(response)) {
			searchWithFilter()
			toggleDeleteModal()
		}
	}

	function resetFilter() {
		var filterDataCopy = {
			pos_id: null,
			start_date: null,
			end_date: null,
			search: '',
			random: filterData.random + 1
		}
		setFilterData(filterDataCopy)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.start_date === null &&
			filterData.end_date === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function toggleModal(bool, item = { 'gender': 1 }) {
		if (bool) {
			if (item?.id) {
				var itemCopy = { ...item }
				if (itemCopy.phone1)
					itemCopy.phone1 = itemCopy.phone1.substring(3, 12)
				if (itemCopy.phone2)
					itemCopy.phone2 = itemCopy.phone2.substring(3, 12)
				setSelectedItem(itemCopy)
			}
		}
		setShowModal(bool)
	}

	function handleInputChange(e) {
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy[e.target.name] = e.target.value
		setSelectedItem(selectedItemCopy)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	async function downloadExcelFile() {
		var response = await FILE_D('/clients-excel')
		if (httpOk(response)) {
			// Создаем объект Blob из полученного файла
			const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Создаем ссылку для скачивания файла
			const fileUrl = URL.createObjectURL(file);

			// Создаем ссылку для скачивания файла в браузере
			const link = document.createElement('a');
			link.href = fileUrl;
			link.setAttribute('download', `${t('clients')}.xlsx`);
			document.body.appendChild(link);
			link.click();

			// Удаляем ссылку после скачивания файла
			URL.revokeObjectURL(fileUrl);
		}
	};

	async function EExcelClientAddress() {
		var response = await FILE_D('/clients-address-excel')
		if (httpOk(response)) {
			// Создаем объект Blob из полученного файла
			const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Создаем ссылку для скачивания файла
			const fileUrl = URL.createObjectURL(file);

			// Создаем ссылку для скачивания файла в браузере
			const link = document.createElement('a');
			link.href = fileUrl;
			link.setAttribute('download', `${t('address')}.xlsx`);
			document.body.appendChild(link);
			link.click();

			// Удаляем ссылку после скачивания файла
			URL.revokeObjectURL(fileUrl);
		}
	};

	async function importFromExel(e) {
		let formData = new FormData();
		formData.append("file", e.target.files[0]);

		var response = await FILE('/clients-excel-import', formData, {}, { loader: true });

		if (httpOk(response)) searchWithFilter()
	}

	async function getDiscounts() {
		if (!discounts.length) {
			const response = await GET('/clients-cards-discounts')
			if (httpOk(response)) {
				setDiscounts(response.data)
			}
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page, filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('clients')} [{formatMoney(data?.total)}]
				</h4>
				<DropdownButton title={t('action')}>
					<Dropdown.Item onClick={() => toggleModal(true)}>
						{t('create')}
					</Dropdown.Item>
					<Dropdown.Item onClick={() => toggleModalSMS(true)}>
						{t('sms_sending')}
					</Dropdown.Item>
					<Dropdown.Item as="li" eventKey="1">
						<input id="actual-btn" hidden type="file" onChange={importFromExel} accept=".xlsx, application/vnd.ms-excel" />
						<label htmlFor="actual-btn" className="upload-from-excel">{t('import_clients')}</label>
					</Dropdown.Item>
					<Dropdown.Item as="li" eventKey="2">
						<a href="/clients_template.xlsx" className="upload-from-excel">
							{t('download_template')}
						</a>
					</Dropdown.Item>
					<Dropdown.Item onClick={() => downloadExcelFile()}>
						{t('export_clients')}
					</Dropdown.Item>
					<Dropdown.Item onClick={() => EExcelClientAddress()}>
						{t('export_clients_addresses')}
					</Dropdown.Item>
				</DropdownButton>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('type')}</label>
												<Select
													isClearable
													options={registrationTypes}
													value={registrationTypes.find(option => option.id === filterData.registration_type) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'registration_type': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('client_without')}</label>
												<Select
													isClearable
													options={clientWithoutTypes}
													value={clientWithoutTypes.find(option => option.id === filterData.client_without) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'client_without': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('registered')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.registration_date_start ? new Date(filterData.registration_date_start) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'registration_date_start': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.registration_date_end ? new Date(filterData.registration_date_end) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'registration_date_end': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('last_cheque')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.last_cheque_start ? new Date(filterData.last_cheque_start) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'last_cheque_start': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.last_cheque_end ? new Date(filterData.last_cheque_end) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'last_cheque_end': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>

						<div className="vertical-center">
							<h5 className="d-flex justify-content-between gap-3">
								<b>{t('telegram')}:</b>
								<div className="d-flex">
									<div style={{ 'width': '70px' }} className="text-end text-success">{formatMoney(data.total_member)}</div>
									<div style={{ 'width': '70px' }} className="text-end text-danger">{formatMoney(data.total_kicked)}</div>
								</div>
							</h5>

							<h5 className="d-flex justify-content-between gap-3">
								<b>{t('loyalty')}:</b>
								<div className="d-flex">
									<div style={{ 'width': '70px' }} className="text-end text-success">{formatMoney(data.total_loyalty_yes)}</div>
									<div style={{ 'width': '70px' }} className="text-end text-danger">{formatMoney(data.total_loyalty_no)}</div>
								</div>
							</h5>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.registration_date_start_date || filterData.registration_date_end) &&
									<span className="me-2">
										<b>{t('registered')}: </b>
										{filterData.registration_date_start_date &&
											<span>{formatDate(filterData.registration_date_start_date)}</span>
										}
										{(filterData.registration_date_start_date && filterData.registration_date_end) &&
											<span>|</span>
										}
										{filterData.registration_date_end &&
											<span>{formatDate(filterData.registration_date_end)}</span>
										}
									</span>
								}
								{(filterData.last_cheque_start || filterData.last_cheque_end) &&
									<span className="me-2">
										<b>{t('last_cheque')}: </b>
										{filterData.last_cheque_start &&
											<span>{formatDate(filterData.last_cheque_start)}</span>
										}
										{(filterData.last_cheque_start && filterData.last_cheque_end) &&
											<span>|</span>
										}
										{filterData.last_cheque_end &&
											<span>{formatDate(filterData.last_cheque_end)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.registration_type &&
									<span className="me-2">
										<b>{t('type')}: </b>
										{findKeyFromArrayByValue(registrationTypes, 'id', filterData.registration_type, 'name')}
									</span>
								}
								{filterData.client_without &&
									<span className="me-2">
										<b>{t('client_without')}: </b>
										{findKeyFromArrayByValue(clientWithoutTypes, 'id', filterData.client_without, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('phone')}</th>
									<th style={{ 'width': '30%' }}>{t('name')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('cheques')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('loyalty')}</th>
									<th style={{ 'width': '10%' }} title={t('loyalty_overall')}
										className="text-end td-ellipsis">
										{t('loyalty_overall')}
									</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('registered')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('last_cheque')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center" title={item.id}>
											{item.row_number}
										</td>
										<td>{regexPhoneNumber(item.phone1)}</td>
										<td>
											<div className="position-relative">
												{item.first_name}
												<i className={`uil uil-telegram ${item.telegram_user_id ? 'text-primary' : 'text-danger'}`}
													style={{
														'position': 'absolute',
														'top': '-2px',
														'right': '2px',
														'fontSize': '20px',
													}}>
												</i>
											</div>
										</td>
										<td className="text-end">{item.count_cheques}</td>
										<td className="text-end">{formatMoney(item.loyalty_balance)}</td>
										<td className="text-end">{formatMoney(item.loyalty_balance_up)}</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										<td className="text-center">{formatDate(item.last_cheque_date)}</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
														<div className="table-dropdown-item"
															onClick={() => toggleCardModal(true, item)}>
															<i className="uil-user-plus"></i>
															{t('link_card')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered backdrop="static"
				dialogClassName="update-modal-width" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('edit')}</>
							:
							<>{t('create')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('name')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="first_name"
								value={selectedItem.first_name ?? ''}
								onChange={(e) => handleInputChange(e)} />
						</div>
						{!selectedItem.id &&
							<div className="form-group">
								<label>{t('phone')}</label>
								<div className="input-group">
									<span className="input-group-text">998</span>
									<input type="number" className="form-control" name="phone1"
										value={selectedItem.phone1 ?? ''}
										onChange={(e) => handleInputChange(e)} />
								</div>
							</div>
						}
						<div className="form-group">
							<label>{t('additional_phone')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone2"
									value={selectedItem.phone2 ?? ''}
									onChange={(e) => handleInputChange(e)} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('birthday')}</label>
							<input type="date" className="form-control" name="birthday"
								value={selectedItem.birthday ?? ''}
								onChange={(e) => handleInputChange(e)} />
						</div>
						<div className="d-flex justify-content-between mb-2">
							<div className="d-flex gap-3">
								<div className="form-check p-0">
									<div className="radio-round"
										onClick={() => setSelectedItem({ ...selectedItem, 'gender': 1 })}
										style={{ 'backgroundColor': selectedItem.gender === 1 ? 'black' : '' }}>
									</div>
									<span onClick={() => setSelectedItem({ ...selectedItem, 'gender': 1 })}>
										{t('man')}
									</span>
								</div>

								<div className="form-check p-0">
									<div className="radio-round"
										onClick={() => setSelectedItem({ ...selectedItem, 'gender': 2 })}
										style={{ 'backgroundColor': selectedItem.gender === 2 ? 'black' : '' }}>
									</div>
									<span onClick={() => setSelectedItem({ ...selectedItem, 'gender': 2 })}>
										{t('woman')}
									</span>
								</div>
							</div>
						</div>

						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								value={selectedItem.note ?? ''}
								onChange={(e) => handleInputChange(e)} />
						</div>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL DELETE */}
			<Modal show={modalDeleteClient} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleDeleteModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('delete')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => deleteClient(e)} autoComplete="off">
						<h4>
							{t('delete')}?
						</h4>
						<h6 className="text-danger">
							{t('delete_text')}?
						</h6>
						<div className="d-flex gap-3 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleDeleteModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL DELETE */}

			{/* MODAL SMS */}
			<Modal show={modalSMS.bool} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModalSMS(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('create')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={sendSms} autoComplete="off">
						<div className="form-group">
							<label>{t('sms_templates')}</label>
							<Select
								options={smsTemplates}
								value={smsTemplates.find(option => option.id === modalSMS.sms_template_id) || ''}
								onChange={(option) => setModalSMS({ ...modalSMS, 'sms_template_id': option.id, 'message': option.text })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>

						<div className="form-group">
							<label>{t("description")}</label>
							<textarea className="form-control" rows="5"
								disabled
								value={modalSMS.message}>
							</textarea>
							<span>{t("letters")} - {modalSMS.message.length}</span>
						</div>

						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalSMS(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL SMS */}

			{/* CLIENT CARD */}
			<Modal show={showCardModal} animation={false} centered
				size="md" backdrop="static"
				onHide={() => toggleCardModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem?.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => bindCard(e)} autoComplete="off">
						<div className="form-group w-100">
							<label>{t('client')}</label>
							<input type="text" className="form-control" name="first_name"
								disabled
								value={selectedItem.first_name ?? ''}
								onChange={(e) => { }} />
						</div>

						<div className="form-group w-100">
							<label>{t('card_number')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="card_number" autoFocus
								value={selectedItem.card_number ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'card_number': e.target.value })} />
						</div>

						<div className="form-group w-100">
							<label>{t('discount')}<span className="required-mark">*</span></label>
							<Select
								options={discounts}
								value={discounts.find(option => option.id === selectedItem.discount_service_id) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'discount_service_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>

						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* CLIENT CARD */}
		</>
	)
}

export default Index

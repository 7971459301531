import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx';

import FilterSearch from 'pages/parts/FilterSearch'

import { GET, httpOk } from 'helpers/api'
import { returnSign, findKeyFromArrayByValue, formatDate, formatMoney, arrayJoin } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const reduxAccount = useSelector(state => state.account)

	const statuses = [
		{ 'id': 1, 'name': t('success') },
		{ 'id': -2, 'name': t('expected') },
	]
	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [iposes, setIPoses] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
		ids: [],
		search: '',
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/chain/external-transfer-summary"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.to_pos_id)
			urlParams += returnSign(urlParams) + 'to_pos_id=' + filterDataCopy.to_pos_id
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		filterDataCopy.urlParams = urlParams

		if (`/chain/wms-transfer-pos-report${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
		}

		setFilterData(filterDataCopy)
		setDropdownIsOpen(false)
	}

	async function exportToExcel() {
		var filterDataCopy = { ...filterData }
		var filterUrl = `/service/wms-transfer-pos-excel${filterData.urlParams}`
		var urlParams = ""
		console.log(filterDataCopy.ids)
		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy?.ids?.length)
			urlParams += returnSign(urlParams) + 'ids=' + arrayJoin(filterDataCopy.ids, false)

		filterUrl = filterUrl + urlParams
		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			var excelHeaders = [[
				t('pos'), t('date'), t('name2'),
				t('type'), t('quantity'), t('unit'),
				t('price'), t('total_amount')
			]]

			for (let i = 0; i < response.data?.data?.length; i++) {
				var item = response.data.data[i]
				var excelItems = []
				excelItems.push(item['pos_name'])
				excelItems.push(item['created_at'])
				excelItems.push(item['name'])
				if (item['in_product']) {
					excelItems.push(t('product'))
				}
				if (!item['in_product']) {
					excelItems.push(t('element'))
				}
				excelItems.push(Number(item['quantity']))
				excelItems.push(item['unit_name'])
				excelItems.push(Number(item['price']))
				excelItems.push(Number(item['total_price']))
				excelHeaders.push(excelItems)
			}
			excelHeaders.push(['', t('total'), '', '', '', '', '', response.data?.total_amount])

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('moving_another_store_amount') + ".xlsx");
		}

	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: null,
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getIntegrationPoses() {
		const response = await GET('/helper/integration-access-poses', {}, { loader: true })
		if (httpOk(response)) setIPoses(response.data)
	}

	useEffect(() => {
		getPoses()
		getIntegrationPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('moving_another_store')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('external_pos')}</label>
												<Select
													isClearable
													options={iposes}
													value={iposes.find(option => option.id === filterData.to_pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'to_pos_id': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													isClearable
													options={statuses}
													value={statuses.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(false)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button type="button" className="btn btn-outline-primary btn-wide-2 d-none"
									onClick={() => exportToExcel()}>
									<CustomTooltip text={t('EXCEL')}>
										<i className="uil uil-file-download-alt"></i>
									</CustomTooltip>
								</button>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.to_pos_id &&
									<span className="me-2">
										<b>{t('external_pos')}: </b>
										{findKeyFromArrayByValue(iposes, 'id', filterData.to_pos_id, 'name')}
									</span>
								}
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '20%' }}>{t('external_pos')}</th>
									<th style={{ 'width': '40%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('percentage')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.pos_name}</td>
										<td>{item.to_pos_name}</td>
										<td></td>
										<td className="text-nowrap text-end">{formatMoney(item.total_amount)}</td>
										<td className="text-nowrap text-end" style={{ height: '1px' }}>
											<div class="progress-container">
												<div class="progress-bar bg-success" style={{ 'width': `${item.percentage}%`, 'opacity': '0.5' }}></div>
												<b style={{ 'position': 'absolute', 'right': '5px', 'top': 0 }}>
													{formatMoney(item.percentage, 2)}%
												</b>
											</div>
										</td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td><b>{t('total')}</b></td>
									<td></td>
									<td></td>
									<td className="text-nowrap text-end"><b>{formatMoney(data.total_amount)}</b></td>
									<td className="text-nowrap text-end"><b>{formatMoney(100)}%</b></td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
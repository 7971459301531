export let themes = [
	// Зеленая
	{
		"light": {
			"id": 1,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#34B547",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 1,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#34B547",
			"button_bg_color_minus": "#F24442",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#FFF",
			"input_bg_color": "#F4F4F4",
			"input_text_color": "#000000",
			"type": "dark",
		}
	},
	// Красная
	{
		"light": {
			"id": 2,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#F24442",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 2,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#F24442",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// ЖЕЛТАЯ
	{
		"light": {
			"id": 3,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#F9CB17",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 3,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#F9CB17",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// ОРАНЖ
	{
		"light": {
			"id": 4,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#FE8E39",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 4,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#FE8E39",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// МАЛИНОВ
	{
		"light": {
			"id": 5,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#F3408E",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 5,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#F3408E",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// ГОЛУБОЙ
	{
		"light": {
			"id": 6,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#3BBEEE",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 6,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#3BBEEE",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// ФИОЛЕТ
	{
		"light": {
			"id": 7,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#9F5CFC",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 7,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#9F5CFC",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// СЕРЫЙ
	{
		"light": {
			"id": 8,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#677C97",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 8,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#677C97",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// МЕНТОЛ
	{
		"light": {
			"id": 9,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#32E1E1",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 9,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#32E1E1",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// СИНИЙ
	{
		"light": {
			"id": 10,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#4E4EF9",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 10,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#4E4EF9",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// КОРАЛ
	{
		"light": {
			"id": 11,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#FF7964",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 11,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#FF7964",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// -----
	{
		"light": {
			"id": 12,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#50C878",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 12,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#50C878",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// -----
	{
		"light": {
			"id": 13,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#F7ABE5",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 13,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#F7ABE5",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// -----
	{
		"light": {
			"id": 14,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#784DAF",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 14,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#784DAF",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// -----
	{
		"light": {
			"id": 15,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#4D6F98",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 15,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#4D6F98",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// -----
	{
		"light": {
			"id": 16,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#B0BB43",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 16,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#B0BB43",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
	// -----
	{
		"light": {
			"id": 17,
			"body_bg_color": "#FFFFFF",
			"text_color": "#222222",
			"button_bg_color_plus": "#76D193",
			"button_bg_color_minus": "#EBEBEB",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#000",
			"input_bg_color": "#EBEBEB",
			"input_text_color": "#222222",
			"type": "light",
		},
		"dark": {
			"id": 17,
			"body_bg_color": "#022A49",
			"text_color": "#FEFFFF",
			"button_bg_color_plus": "#76D193",
			"button_bg_color_minus": "#033C69",
			"button_text_color": "#FFFFFF",
			"button_minus_text_color": "#fff",
			"input_bg_color": "#CAEFF7",
			"input_text_color": "#00233A",
			"type": "dark",
		}
	},
]

// {"dark": {"text_color": "#FFFFFF", "body_bg_color": "#FFFFFF", "input_bg_color": "#F4F4F4", "input_text_color": "#9EA09E", "button_text_color": "#FFFFFF", "button_bg_color_plus": "#34B547", "button_bg_color_minus": "#F24442"}, "light": {"text_color": "#222222", "body_bg_color": "#222222", "input_bg_color": "#C5C5C5", "input_text_color": "#9EA09E", "button_text_color": "#FFFFFF", "button_bg_color_plus": "#34B547", "button_bg_color_minus": "#EBEBEB"}}
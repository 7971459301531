import React from 'react'
import { useTranslation } from 'react-i18next'

function Delivery({ data, setData, handleSettingChange }) {
	const { t } = useTranslation()

	function handleChangeRule(e, index) {
		var dataCopy = { ...data }
		dataCopy['settings']['delivery_cost_rules'][index][e.target.name] = e.target.value.replace(/[^0-9.]/g, '')

		setData(dataCopy)
	}

	function addDeliveryCostRule() {
		var dataCopy = { ...data }

		if (!dataCopy['settings'].hasOwnProperty('delivery_cost_rules')) {
			dataCopy['settings']['delivery_cost_rules'] = []
		}

		dataCopy['settings']['delivery_cost_rules'].push({ 'from': '', 'to': '', 'amount': '' })
		setData(dataCopy)
	}

	function deleteDeliveryCostRule(index) {
		var dataCopy = { ...data }

		dataCopy['settings']['delivery_cost_rules'].splice(index, 1)
		setData(dataCopy)
	}

	return (
		<form autoComplete="off">
			<div className="row">
				<div className="col-md-6">
					<div className="d-flex justify-content-between mb-2">
						<div className="fw-500 vertical-center">
							{t('delivery_type')}
						</div>
						<div className="w-50">
							<select name="delivery_cost_type" className="form-select"
								value={data?.settings.delivery_cost_type ?? ''}
								onChange={(e) => handleSettingChange(e, 'number')}>
								<option value="1">Фиксированный</option>
								<option value="2">По километражу</option>
								<option value="3">Альтернативный по км</option>
								<option value="4">Альтернативный по сумме</option>
								<option value="5">Фикса за км + за сверх лимит</option>
							</select>
						</div>
					</div>
					{Number(data?.settings?.delivery_cost_type) === 1 &&
						<div className="d-flex justify-content-between mb-2">
							<div className="fw-500 vertical-center">
								Цена доставки
							</div>
							<div className="w-50">
								<input type="number" className="form-control" name="delivery_cost"
									value={data?.settings.delivery_cost ?? ''}
									onChange={(e) => handleSettingChange(e, 'number')} />
							</div>
						</div>
					}
					{Number(data?.settings?.delivery_cost_type) === 2 &&
						<div className="d-flex justify-content-between mb-2">
							<div className="fw-500 vertical-center">
								Цена доставки
							</div>
							<div className="w-50">
								<input type="number" className="form-control" name="delivery_cost"
									value={data?.settings.delivery_cost ?? ''}
									onChange={(e) => handleSettingChange(e, 'number')} />
							</div>
						</div>
					}
					{Number(data?.settings?.delivery_cost_type) === 3 &&
						<>
							<div className="d-flex justify-content-between mb-2">
								<div className="fw-500 vertical-center">
									Базовая сумма
								</div>
								<div className="w-50">
									<input type="number" className="form-control" name="delivery_cost"
										value={data?.settings.delivery_cost ?? ''}
										onChange={(e) => handleSettingChange(e, 'number')} />
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											<th style={{ 'width': '33.3%' }}>{t('from_km')}</th>
											<th style={{ 'width': '33.3%' }}>{t('to_km')}</th>
											<th style={{ 'width': '33.3%' }}>{t('delivery_price')}</th>
											<th style={{ 'minWidth': '33px' }}>
												<div className="d-flex justify-content-center">
													<div className="d-flex justify-content-center">
														<div className="table-action-button table-action-success-button"
															onClick={() => addDeliveryCostRule()}>
															<i className="uil-plus"></i>
														</div>
													</div>
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										{data?.settings?.delivery_cost_rules?.map((item, index) => (
											<tr key={index}>
												<td className="td-input">
													<input type="number" pattern="[0-9]*" className="form-control"
														name="from"
														value={item.from ?? ''}
														onChange={(e) => handleChangeRule(e, index)} />
												</td>
												<td className="td-input">
													<input type="number" pattern="[0-9]*" className="form-control"
														name="to"
														value={item.to ?? ''}
														onChange={(e) => handleChangeRule(e, index)} />
												</td>
												<td className="td-input">
													<input type="number" pattern="[0-9]*" className="form-control"
														name="amount"
														value={item.amount ?? ''}
														onChange={(e) => handleChangeRule(e, index)} />
												</td>
												<td>
													<div className="d-flex justify-content-center">
														<div className="d-flex justify-content-center">
															<div className="table-action-button table-action-danger-button"
																onClick={() => deleteDeliveryCostRule(index)}>
																<i className="uil-times"></i>
															</div>
														</div>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</>
					}
					{Number(data?.settings?.delivery_cost_type) === 4 &&
						<>
							<div className="d-flex justify-content-between mb-2">
								<div className="fw-500 vertical-center">
									Базовая сумма
								</div>
								<div className="w-50">
									<input type="number" className="form-control" name="delivery_cost"
										value={data?.settings.delivery_cost ?? ''}
										onChange={(e) => handleSettingChange(e, 'number')} />
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											<th style={{ 'width': '33.3%' }}>{t('from_amount')}</th>
											<th style={{ 'width': '33.3%' }}>{t('to_amount')}</th>
											<th style={{ 'width': '33.3%' }}>{t('delivery_price')}</th>
											<th style={{ 'minWidth': '33px' }}>
												<div className="d-flex justify-content-center">
													<div className="d-flex justify-content-center">
														<div className="table-action-button table-action-success-button"
															onClick={() => addDeliveryCostRule()}>
															<i className="uil-plus"></i>
														</div>
													</div>
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										{data?.settings?.delivery_cost_rules?.map((item, index) => (
											<tr key={index}>
												<td className="td-input">
													<input type="number" pattern="[0-9]*" className="form-control"
														name="from"
														value={item.from ?? ''}
														onChange={(e) => handleChangeRule(e, index)} />
												</td>
												<td className="td-input">
													<input type="number" pattern="[0-9]*" className="form-control"
														name="to"
														value={item.to ?? ''}
														onChange={(e) => handleChangeRule(e, index)} />
												</td>
												<td className="td-input">
													<input type="number" pattern="[0-9]*" className="form-control"
														name="amount"
														value={item.amount ?? ''}
														onChange={(e) => handleChangeRule(e, index)} />
												</td>
												<td>
													<div className="d-flex justify-content-center">
														<div className="d-flex justify-content-center">
															<div className="table-action-button table-action-danger-button"
																onClick={() => deleteDeliveryCostRule(index)}>
																<i className="uil-times"></i>
															</div>
														</div>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</>
					}
					{Number(data?.settings?.delivery_cost_type) === 5 &&
						<>
							<div className="d-flex justify-content-between mb-2">
								<div className="fw-500 vertical-center">
									Фиксированный (км)
								</div>
								<div className="w-50">
									<input type="number" className="form-control" name="delivery_until_km"
										value={data?.settings.delivery_until_km ?? ''}
										onChange={(e) => handleSettingChange(e, 'number')} />
								</div>
							</div>
							<div className="d-flex justify-content-between mb-2">
								<div className="fw-500 vertical-center">
									Фиксированная (сумма)
								</div>
								<div className="w-50">
									<input type="number" className="form-control" name="delivery_amount_until_km"
										value={data?.settings.delivery_amount_until_km ?? ''}
										onChange={(e) => handleSettingChange(e, 'number')} />
								</div>
							</div>
							<div className="d-flex justify-content-between mb-2">
								<div className="fw-500 vertical-center">
									Цена доставки за км сверхлимита
								</div>
								<div className="w-50">
									<input type="number" className="form-control" name="delivery_cost"
										value={data?.settings.delivery_cost ?? ''}
										onChange={(e) => handleSettingChange(e, 'number')} />
								</div>
							</div>
						</>
					}
				</div>
			</div>
		</form>
	)
}

export default Delivery
import { getTime, format } from 'date-fns'
import { ru } from 'date-fns/esm/locale'
import i18n from '../i18n'

export function regexLogin(str) {
	if (str) {
		return str.toLowerCase().replace(/[^a-z0-9_]/gi, '');
	}
	return ''
}

export function deleteSymbols(str) {
	if (str) {
		return str.replace(/[^A-Z0-9]/gi, '');
	}
	return ''
}

export function regexNumeric(str) {
	if (str) {
		return str.replace(/[^0-9.]/gi, '');
	}
	return ''
}

export function returnSign(str) {
	return str.includes('?') ? '&' : '?'
}

export function arrayJoin(arr, arrayOfObjects = true) {
	if (arrayOfObjects) {
		return arr.map(u => u.id).join(',')
	}

	return arr.join()
}

export function returnMonth(number) {
	const dates = [
		{ 'id': 1, 'name': i18n.t('january') },
		{ 'id': 2, 'name': i18n.t('february') },
		{ 'id': 3, 'name': i18n.t('march') },
		{ 'id': 4, 'name': i18n.t('april') },
		{ 'id': 5, 'name': i18n.t('may') },
		{ 'id': 6, 'name': i18n.t('june') },
		{ 'id': 7, 'name': i18n.t('july') },
		{ 'id': 8, 'name': i18n.t('august') },
		{ 'id': 9, 'name': i18n.t('september') },
		{ 'id': 10, 'name': i18n.t('october') },
		{ 'id': 11, 'name': i18n.t('november') },
		{ 'id': 12, 'name': i18n.t('december') },
	]
	if (number) {
		return dates.find(item => item.id === number)['name']
	} else {
		return ''
	}
}

export function regexPhoneNumber(str) {
	if (str) {
		return str.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, "$1$2 $3-$4-$5");
	}
	return ""
}

export function clearTemporaryStorage() {
	localStorage.removeItem('login')
	localStorage.removeItem('role')
	localStorage.removeItem('token')
}

export function findKeyFromArrayByValue(array, valueName, value, key) {
	if ((array.length > 0 && value) || value === 0) {
		var found = array.find(x => x[valueName] === value)
		return found ? found[key] : '';
	}
}

export function formatMoneyInput(amount) {
	if (!amount) return ""
	return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function formatMoney(amount, decimalCount = 0, decimal = ".", thousands = " ") {
	if (!amount) {
		return 0
	}

	if (Number.isInteger(Number(amount))) {
		decimalCount = 0
	}

	try {
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 0 : decimalCount; // 0 was 2

		const negativeSign = amount < 0 ? "-" : "";

		let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
		let j = (i.length > 3) ? i.length % 3 : 0;

		return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
	} catch (e) {
		console.log(e)
	}
}

export function formatDate(date, formating = 'dd.MM.yyyy') {
	if (date) {
		var formattedDate = format(new Date(date), formating);
		return formattedDate
	}
}

export function newDate(format = 'yyyy-MM-dd') {
	return format(new Date(), format)
}

export function formatDateCalendar(date) {
	if (date) {
		var formattedDate = format(new Date(date), 'dd MMMM yyyy', { locale: ru });
		return formattedDate
	}
}

export function getUnixTime() {
	return getTime(new Date())
}

export function getUnixTimeByDate(date) {
	return getTime(date)
}

export function formatUnixTime(unixTime) {
	if (unixTime)
		return format(new Date(unixTime), 'dd.MM.yyyy HH:mm:ss')
}

export function sumFromArray(array, key) {
	var sum = 0
	if (array && array.length > 0) {
		for (let i = 0; i < array.length; i++) {
			sum += Number(array[i][key]);
		}
	}

	return sum
}

export function returnMinDate(date, days) {
	var result = new Date(date);
	result.setDate(result.getDate() - days);
	return result;
}

export function countTotalFromArray(array = [], key) {
	var count = 0

	if (array?.length > 0) {
		for (let i = 0; i < array.length; i++) {
			count += Number(array[i][key])
		}
	}
	return count
}

export function generateTransactionId(posId, userId) {
	if (posId && userId)
		return posId.toString() + userId.toString() + getUnixTime().toString() + Math.floor(Math.random() * 999999)
}

export function copyText(text) {
	navigator.clipboard.writeText(text)
}
import React, { useEffect, useState } from "react"
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import XLSX from 'xlsx'

import { GET, POST, PUT, httpOk } from 'helpers/api'
import { formatMoney, findKeyFromArrayByValue } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();

	const reduxSettings = useSelector(state => state.settings)

	const [poses, setPoses] = useState([])
	const [elements, setElements] = useState([])
	const [modalNotInventored, setModalNotInventored] = useState(false)
	const [modalConfirm, setModalConfirm] = useState(false)
	const [annual, setAnnual] = useState(false)
	const [notInventoredProducts, setNotInventoredProducts] = useState([])
	const [data, setData] = useState({
		elements: [],
		pos_id: null,
	})

	async function inventoryCompleted(completed = false) {
		var sendData = { ...data }

		for (let i = 0; i < sendData.elements.length; i++) {
			if (sendData.elements[i]['actual_balance'] < 0 || sendData.elements[i]['actual_balance'] === "") {
				sendData.error = true
			}

			sendData.elements[i]['difference_amount'] = (sendData.elements[i]['actual_balance'] - sendData.elements[i]['balance']) * sendData.elements[i]['price']
			sendData.elements[i]['divergence'] = sendData.elements[i]['actual_balance'] - sendData.elements[i]['balance']
		}

		if (sendData.error) {
			toast.error(t('check_filled_inputs'))
			return
		}

		var response;
		if (id) {
			if (completed) {
				response = await PUT('/inventory-section-completed', sendData, { loader: true })
				if (httpOk(response)) {
					navigate('/inventory-section')
				}
			} else {
				response = await PUT('/inventory-section', sendData, { loader: true })
				if (httpOk(response)) {
					navigate('/inventory-section')
				}
			}
		} else {
			if (completed) {
				response = await PUT('/inventory-section-completed', sendData, { loader: true })
				if (httpOk(response)) {
					navigate('/inventory-section')
				}
			} else {
				response = await POST('/inventory-section', sendData, { loader: true })
				if (httpOk(response)) {
					navigate('/inventory-section')
				}
			}
		}
	}

	function handleProductsChange(e, index) {
		var dataCopy = { ...data }
		if (Number(e.target.value) > 999999999) {
			toast.error(t('exceeds_maximum'))
			dataCopy.elements[index][e.target.name] = ""
			setData(dataCopy)
			return;
		}
		dataCopy.elements[index][e.target.name] = e.target.value
		setData(dataCopy)
	}

	function deleteProduct(index) {
		var dataCopy = { ...data }
		dataCopy.elements.splice(index, 1)
		setData(dataCopy)
	}

	function handleSelect(option, index) {
		var dataCopy = { ...data }

		dataCopy.elements[index]['balance_id'] = option.balance_id
		dataCopy.elements[index]['element_id'] = option.element_id
		dataCopy.elements[index]['name'] = option.name
		dataCopy.elements[index]['unit_name'] = option.unit_name
		dataCopy.elements[index]['price'] = option.price
		dataCopy.elements[index]['balance'] = option.balance
		dataCopy.elements[index]['actual_balance'] = ''
		dataCopy.elements[index]['difference_amount'] = ''
		dataCopy.elements[index]['divergence'] = ''

		setData(dataCopy)
	}

	function addToList() {
		var dataCopy = JSON.parse(JSON.stringify(data))
		var element = {
			"price": "",
			"element_id": "",
			"actual_balance": "",
			"name": "",
			"unit_name": "",
			"balance": "",
			"difference_amount": '',
			"divergence": ''
		}

		dataCopy.elements.push(element)
		setData(dataCopy)
		// setTimeout(() => {
		// 	const byId = document.getElementById(dataCopy.elements.length)
		// 	byId.select()
		// }, 200);
	}

	async function notInventoredList() {
		var arrayIds = []
		for (let i = 0; i < data.elements.length; i++) {
			arrayIds.push(data.elements[i]['id'])
		}
		var prepareIds = { "balance_ids": [], "pos_id": data.pos_id }
		prepareIds.balance_ids = arrayIds

		const response = await POST('/inventory-section/not-inventored', prepareIds, { loader: true })
		if (httpOk(response)) {
			var list = [...response.data.data]
			for (let i = 0; i < list.length; i++) {
				list[i].actual_balance = list[i].balance
				list[i]['uniqueBalanceId'] = list[i]['id'] + Math.floor(Math.random() * 999999);
				list[i].selected = false
			}
			setNotInventoredProducts(list)
			setModalNotInventored(true)
		}
	}

	function annualActualBalance() {
		var notInventoredProductsCopy = [...notInventoredProducts]
		if (annual) {
			var selectedExist = false

			for (let i = 0; i < notInventoredProductsCopy.length; i++) {
				if (notInventoredProductsCopy[i]['selected']) {
					selectedExist = true
					break;
				}
			}

			if (selectedExist) {
				for (let i = 0; i < notInventoredProductsCopy.length; i++) {
					if (notInventoredProductsCopy[i]['selected']) {
						notInventoredProductsCopy[i]['actual_balance'] = 0
					}
				}
			} else {
				for (let i = 0; i < notInventoredProductsCopy.length; i++) {
					notInventoredProductsCopy[i]['actual_balance'] = 0
				}
			}
		} else {
			for (let i = 0; i < notInventoredProductsCopy.length; i++) {
				notInventoredProductsCopy[i]['actual_balance'] = notInventoredProductsCopy[i]['balance']
			}
		}
		setNotInventoredProducts(notInventoredProductsCopy)
	}

	function setActualBalanceModal(e, index) {
		var notInventoredProductsCopy = [...notInventoredProducts]
		notInventoredProductsCopy[index]['actual_balance'] = e.target.value
		setNotInventoredProducts(notInventoredProductsCopy)
	}

	function addToListSelectedProducts() {
		var notInventoredProductsCopy = [...notInventoredProducts]
		var dataCopy = { ...data }

		for (let i = 0; i < notInventoredProductsCopy.length; i++) {
			if (notInventoredProductsCopy[i]['selected']) {
				dataCopy.elements.push(notInventoredProductsCopy[i])
			}
		}
		setData(dataCopy)
		setModalNotInventored(false)
	}

	function addToListMinusBalanceProducts() {
		var notInventoredProductsCopy = [...notInventoredProducts]
		var dataCopy = { ...data }

		var minusProducts = notInventoredProductsCopy.filter(element => element.balance < 0);
		dataCopy.elements = minusProducts
		setData(dataCopy)
		setModalNotInventored(false)
	}

	function addToListNotInventoredProduct(index) {
		var notInventoredProductsCopy = [...notInventoredProducts]
		var dataCopy = { ...data }

		dataCopy.elements.push(notInventoredProductsCopy[index])
		notInventoredProductsCopy.splice(index, 1)

		setNotInventoredProducts(notInventoredProductsCopy)

		if (notInventoredProductsCopy.length === 0) {
			setModalNotInventored(false)
		}
	}

	function addToListNotInventoredProducts() {
		var notInventoredProductsCopy = [...notInventoredProducts]
		var dataCopy = { ...data }

		dataCopy.elements = dataCopy.elements.concat(notInventoredProductsCopy)
		setData(dataCopy)
		//countTotalAmount()
		setModalNotInventored(false)
	}

	function markAsSelected(bool, index) {
		var notInventoredProductsCopy = [...notInventoredProducts]
		notInventoredProductsCopy[index]['selected'] = bool
		setNotInventoredProducts(notInventoredProductsCopy)
	}

	async function getPoses() {
		//debugger
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
			if (!id) {
				if (reduxSettings.pos_id && findKeyFromArrayByValue(response.data, 'id', reduxSettings.pos_id, 'id')) {
					setData({ ...data, 'pos_id': reduxSettings.pos_id })
				} else {
					setData({ ...data, 'pos_id': response.data[0]['id'] })

					const temporaryElements = await GET(`/inventory-section-elements/${response.data[0]['id']}`)
					if (temporaryElements) {
						setElements(temporaryElements)
					}
				}
			}

			if (id) {
				const temporaryData = await GET('/inventory-section/' + id)
				if (httpOk(temporaryData)) setData(temporaryData.data)

				const temporaryElements = await GET(`/inventory-section-elements/${temporaryData['pos_id']}`)
				if (httpOk(temporaryElements)) setElements(temporaryElements.data)
			}
		}
	}

	async function getElements(pos_id) {
		const temporaryElements = await GET(`/inventory-section-elements/${pos_id}`)
		if (httpOk(temporaryElements)) setElements(temporaryElements.data)
		
	}

	function sortBy(key, orderBy) {
		var notInventoredProductsCopy = [...notInventoredProducts]
		if (key === 'balance' && orderBy === 'asc') {
			notInventoredProductsCopy.sort((a, b) => parseFloat(a.balance) - parseFloat(b.balance));
		}
		if (key === 'balance' && orderBy === 'desc') {
			notInventoredProductsCopy.sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance));
		}
		if (key === 'name' && orderBy === 'asc') {
			notInventoredProductsCopy.sort((a, b) => a.productName.localeCompare(b.productName))
		}
		if (key === 'name' && orderBy === 'desc') {
			notInventoredProductsCopy.sort((a, b) => b.productName.localeCompare(a.productName))
		}

		setNotInventoredProducts(notInventoredProductsCopy)
	}

	async function exportToExcel(item) {
		var excelHeaders = [[t('product_name'), t('BARCODE'), t('price'), t('expected_balance')]]
		for (let i = 0; i < notInventoredProducts.length; i++) {
			var excelItems = []
			excelItems.push(notInventoredProducts[i].productName)
			excelItems.push(notInventoredProducts[i].barcode)
			excelItems.push(notInventoredProducts[i].price)
			excelItems.push(notInventoredProducts[i].balance)
			excelHeaders.push(excelItems)
		}
		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('not_inventored') + ".xlsx");
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		annualActualBalance()
	}, [annual]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between flex-wrap mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('inventory')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
				<div>
					<button className="btn btn-primary" onClick={() => notInventoredList()}>
						{t('not_inventored')}
					</button>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<div className="row">
						<div className="col-md-2">
							<div className="form-group">
								<label>{t('pos')}<span className="required-mark">*</span></label>
								<Select
									isDisabled={data.elements.length > 0}
									value={poses.find(option => option.id === data.pos_id)}
									options={poses}
									onChange={(option) => {
										setData({ ...data, 'pos_id': option.id })
										getElements(option.id)
									}}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<label>{t('inventory')} №</label>
								<input name="inventory_number" className="form-control" value={data.inventory_number}
									onChange={(e) => setData({ ...data, 'inventory_number': e.target.value })} />
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<label>{t('note')}</label>
								<input name="note" className="form-control" value={data.note}
									onChange={(e) => setData({ ...data, 'note': e.target.value })} />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<button type="button" className="btn btn-primary btn-rounded mb-2"
						onClick={() => addToList()}>
						{t('add')}
					</button>
					<div className="table-responsive mb-3">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th>{t('name2')}</th>
									<th className="text-center">{t('unit')}</th>
									<th className="text-center">{t('price')}</th>
									<th className="text-center">{t('expected_balance')}</th>
									<th className="text-center">{t('counted')}</th>
									<th className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{data.elements.map((item, index) => (
									<tr key={index}>
										<td>
											<div className="d-flex">
												<div className="vertical-center me-2">{index + 1}</div>
												<div style={{ 'width': '300px' }}>
													<Select
														inputId={index + 1}
														options={elements}
														value={data.elements.find(option => option.element_id === data.elements[index]['element_id'])}
														onChange={(option) => handleSelect(option, index)}
														menuPosition="fixed"
														placeholder=""
														noOptionsMessage={() => t('list_empty')}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.element_id}
													/>
												</div>
											</div>
										</td>
										<td className="vertical-middle text-center">{item.unit_name}</td>
										<td className="vertical-middle text-center text-nowrap">{formatMoney(item.price)}</td>
										<td className="vertical-middle text-center text-nowrap">{formatMoney(item.balance)}</td>
										<td className="d-flex justify-content-center">
											<input
												type="number"
												className={"auto-width-input " + (item.actual_balance < 0 && "required-border")}
												name="actual_balance"
												value={item.actual_balance}
												onChange={(e) => handleProductsChange(e, index)}
												onKeyUp={(event) => {
													if (event.key === 'Enter') {
														addToList()
													}
												}} />
										</td>
										<td className="vertical-middle">
											<div className="d-flex justify-content-center" onClick={() => deleteProduct(index)}>
												<div className="table-action-button table-action-danger-button">
													<i className="uil uil-times"></i>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<div className="btn-wrapper">
						<Link className="btn btn-warning btn-rounded btn-wide me-2" to="/inventory-section">
							{t('cancel')}
						</Link>
						<button type="button" className="btn btn-success btn-rounded btn-wide me-2"
							disabled={!data.elements.length > 0}
							onClick={() => setModalConfirm(true)}>{t('complete')}</button>
						<button type="button" className="btn btn-primary btn-rounded btn-wide"
							disabled={!data.elements.length > 0}
							onClick={() => inventoryCompleted()}>{t('save_to_draft')}</button>
					</div>
				</div>
			</div>

			{/* MODAL NOT INVENTORED products */}
			<Modal show={modalNotInventored} animation={false} centered size="lg" backdrop="static" onHide={() => setModalNotInventored(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						<div className="d-flex">
							<div className="me-4">{t('annual')}</div>
							<div className="form-check form-switch form-switch-lg">
								<input type="checkbox" className="form-check-input"
									defaultValue={false} onChange={(e) => setAnnual(e.target.checked)} />
							</div>
							<div className="me-2">{t('sort_by')}</div>
							<button className="btn btn-sm btn-success me-2" onClick={() => sortBy('balance', 'asc')}>[0-9]</button>
							<button className="btn btn-sm btn-success me-2" onClick={() => sortBy('balance', 'desc')}>[9-0]</button>
							<button className="btn btn-sm btn-success me-2" onClick={() => sortBy('name', 'asc')}>[A-Z]</button>
							<button className="btn btn-sm btn-success me-2" onClick={() => sortBy('name', 'desc')}>[Z-A]</button>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive inventory-modal-height">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th>{t('name2')}</th>
									<th className="text-center">{t('expected_balance')}</th>
									<th>{t('counted')}</th>
								</tr>
							</thead>
							<tbody>
								{notInventoredProducts.map((item, index) => (
									<tr key={index}>
										<td>
											<div className="d-flex">
												<div className="form-check">
													<input type="checkbox" className="form-check-input"
														id={'control' + index}
														onChange={(e) => markAsSelected(e.target.checked, index)} />
												</div>
												<label className="form-check-label vertical-center text-truncate"
													htmlFor={'control' + index} style={{ 'width': '300px' }}>
													{index + 1} {item.name}
												</label>
											</div>
										</td>
										<td className="text-center">{formatMoney(item.balance)}</td>
										<td className="d-flex justify-content-center">
											<div className="d-flex">
												<input type="number" className="auto-width-input me-2" autoComplete="off"
													name="actual_balance" value={item.actual_balance} onChange={(e) => setActualBalanceModal(e, index)} />
												<div className="table-action-button table-action-primary-button"
													onClick={() => addToListNotInventoredProduct(index)}>
													<i className="uil-plus"></i>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-secondary" tabIndex="-1" onClick={() => exportToExcel()}>EXCEL</button>
					<button className="btn btn-info" tabIndex="-1" onClick={() => addToListMinusBalanceProducts()}>{t('add_negative_balance')}</button>
					<button className="btn btn-success" tabIndex="-1" onClick={() => addToListSelectedProducts()}>{t('add_selected')}</button>
					<button className="btn btn-primary" tabIndex="-1" onClick={() => addToListNotInventoredProducts()}>{t('add_all')}</button>
				</Modal.Footer>
			</Modal>
			{/* MODAL NOT INVENTORED products */}

			{/* MODAL confirm */}
			<Modal show={modalConfirm} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('confirm')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between mb-2">
						<div>{t('pos')}</div>
						<div className="fw-700">{findKeyFromArrayByValue(poses, 'id', data.pos_id, 'name')}</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setModalConfirm(false)}>
							{t('cancel')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => inventoryCompleted(true)}>{t('confirm')}</button>
					</div>
				</Modal.Footer>
			</Modal>
			{/* MODAL confirm */}
		</>
	)
}

export default CreateUpdate


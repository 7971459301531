import { combineReducers } from "redux"

import layout from "./layout/reducer"
import loader from "./loader/reducer"
import skeleton from "./skeleton/reducer"
import settings from "./settings/reducer"
import filterData from "./filterData/reducer"
import report from "./report/reducer"
import account from "./account/reducer"
import delivery from "./delivery/reducer"
import transferData from "./transferData/reducer"

const allReducers = combineReducers({
	layout,
	loader,
	skeleton,
	settings,
	filterData,
	report,
	account,
	delivery,
	transferData,
})

export const rootReducer = (state, action) => {
	// when a logout action is dispatched it will reset redux state
	if (action.type === 'USER_LOGGED_OUT') {
		state = undefined;
	}

	return allReducers(state, action);
};

export default rootReducer

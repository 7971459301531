import React from 'react'
import { useTranslation } from 'react-i18next'

import { themes } from 'helpers/themes'

import Logo from 'assets/images/logo_icon.png'

import Menu_1 from 'assets/images/theme/menu/menu_1.png'
import Menu_2 from 'assets/images/theme/menu/menu_2.png'
import Menu_3 from 'assets/images/theme/menu/menu_3.png'
import Menu_4 from 'assets/images/theme/menu/menu_4.png'

import Product_1 from 'assets/images/theme/products/products_1.png'
import Product_2 from 'assets/images/theme/products/products_2.png'
// import Product_3 from 'assets/images/theme/products/products_3.png'
// import Product_4 from 'assets/images/theme/products/products_4.png'

import TelegramProduct_1 from 'assets/images/theme/telegram/telegram_1.png'
import TelegramProduct_2 from 'assets/images/theme/telegram/telegram_2.png'
import TelegramProduct_3 from 'assets/images/theme/telegram/telegram_3.png'

import 'assets/css/menu.css'

function Themes({ handleThemeTypeChange, handleThemeChange, data }) {
	const { t } = useTranslation()

	const menu = [
		{ name: 'Бургеры', image: Menu_1 },
		{ name: 'Тандыр', image: Menu_2 },
		{ name: 'Салаты', image: Menu_3 },
		{ name: 'Супы', image: Menu_4 },
	]

	const products = [
		{ name: 'Шакшука', image: Product_1, sale_price: '121 000' },
		{ name: 'Тост с курицей', image: Product_2, sale_price: '21 000', quantity: '3' },
	]

	const telegramProducts = [
		{ name: 'Гамбургер с овощами...', sale_price: '121 000', image: TelegramProduct_1 },
		{ name: 'Чизбургер', sale_price: '21 000', image: TelegramProduct_2 },
		{ name: 'Гамбургер с овощами...', sale_price: '45 000', image: TelegramProduct_3, quantity: '3' },
	]

	function OnlineMenu({ type }) {
		var theme = {}
		if (data?.settings?.theme === 'light') {
			theme = data?.settings?.telegram_theme.light
		} else {
			theme = data?.settings?.telegram_theme.dark
		}
		return <div className="theme-page 111" style={{ 'backgroundColor': theme?.body_bg_color }}>
			<div className="theme-menu">
				<div className="pos-header" style={{ 'backgroundColor': data?.settings?.theme === 'dark' ? '#133852' : '#f4f4f4' }}>
					<div className="pos-image">
						<img src={Logo} alt="" />
					</div>
					<div>
						<h2 className="pos-title" style={{ 'color': theme?.text_color }}>
							Mison
						</h2>
						<div className="pos-info">
							<div className="pos-info__address">
								<div className="pos-info__block" style={{ 'color': theme?.text_color }}>
									<i className="uil uil-location-point mr-10"></i>
									Ул. Боткина
								</div>
							</div>
							<div className="pos-info__block" style={{ 'color': theme?.text_color }}>
								<i className="uil uil-phone mr-10"></i>
								998 99819 77-99
							</div>
							<div className="pos-info__description"></div>
						</div>
					</div>
				</div>
				<div className="menu-list" style={{ 'backgroundColor': theme?.body_bg_color }}>
					<div className="menu-item-search">
						<div className="theme-menu-item-search-form">
							<div className="menu-item-search-form-field">
								<input
									type="text"
									className="base-form-input"
									value={t('search')}
									placeholder={t('search')}
									style={{
										'padding': '10px 16px',
										'border': '1px solid #c5c5c5',
										'color': theme?.input_text_color,
										'backgroundColor': theme?.input_bg_color,
										'::WebkitInputPlaceholder': theme?.input_text_color
									}}
									onChange={() => {}}
								/>
							</div>
						</div>
					</div>
					{type === 'menu' ?
						<div className="row">
							{menu.map((item, index) => (
								<div className="col-6" key={index}>
									<div className="theme-menu-item">
										<div className="theme-menu-item__link">
											<img src={item.image} alt={item.image} />
											<h2 style={{ 'fontSize': '24px', 'color': theme?.text_color }}>
												{item.name}
											</h2>
										</div>
									</div>
								</div>
							))}
						</div>
						:
						<div className="row">
							{products.map((item, index) => (
								<div className="col-6 mb-30" key={index}>
									<div className="menu-item-list__item">
										<div className="product-image">
											<img src={item.image} alt="" />
										</div>
										<div className="product-content">
											<div className="product-body">
												<div className="product-header">
													<div className="product-title" style={{ color: theme?.text_color }}>
														{item.name}
													</div>
												</div>
											</div>
										</div>
										<div className="product-footer">
											<div className="product-price">
												<span className="product-price__current" style={{ color: theme?.text_color }}>
													<b>{item.sale_price}</b>
													<span>{t('sum').toLowerCase()}</span>
												</span>
											</div>
											{item.quantity ?
												<div className="product-action">
													<button className="product-button  minus"
														style={{ backgroundColor: theme?.button_bg_color_minus, color: theme?.button_minus_text_color }}>
														<i className="uil uil-minus"></i>
													</button>
													<span className="product-count" style={{ color: theme?.text_color }}>
														{item.quantity}
													</span>
													<button className="product-button plus"
														style={{ backgroundColor: theme?.button_bg_color_plus, color: theme?.button_text_color }}>
														<i className="uil uil-plus"></i>
													</button>
												</div>
												:
												<button className="product-button add"
													style={{ backgroundColor: theme?.button_bg_color_plus, color: theme?.button_text_color }}>
													{t('add')}
												</button>
											}
										</div>
									</div>
								</div>
							))}
						</div>
					}
				</div>
			</div>
		</div>
	}

	const TelegramBot = ({ type }) => {
		var theme = {}
		if (data?.settings?.theme === 'light') {
			theme = data?.settings?.telegram_theme.light
		} else {
			theme = data?.settings?.telegram_theme.dark
		}
		return <div className="theme-page" style={{ 'backgroundColor': theme?.body_bg_color }}>
			<div className="theme-menu">
				<div className="telegram-header">
					<div className="telegram-back">
						<i className="uil uil-angle-left-b"></i>
						<div>{t('back')}</div>
					</div>
					<div className="telegram-name">
						Mison
					</div>
					<div className="telegram-image">
						<img src={Logo} alt="" />
					</div>
				</div>
				<div className="telegram-chat"></div>
				<div className="menu-list" style={{ 'backgroundColor': theme?.body_bg_color }}>
					<div className="menu-list-header" style={{ 'color': theme?.text_color }}>
						<div className="telegram-back">
							<i className="uil uil-angle-left-b"></i>
							<div>{t('back')}</div>
						</div>
						<div className="telegram-name">
							Бургеры
						</div>
						<div className="menu-list-header-action">
							<i className="uil uil-ellipsis-h me-2"></i>
							<i className="uil uil-times"></i>
						</div>
					</div>
					{type === 'menu' ?
						<div className="row">
							{menu.map((item, index) => (
								<div className="col-6" key={index}>
									<div className="menu-item">
										<div className="theme-menu-item__link">
											<img src={item.image} alt="" />
											<h2 style={{ 'fontSize': '24px', 'color': theme?.text_color }}>
												{item.name}
											</h2>
										</div>
									</div>
								</div>
							))}
						</div>
						:
						<div className="row">
							{telegramProducts.map((item, index) => (
								<div className="col-4 mb-30" key={index}>
									<div className="menu-item-list__item">
										<div className="product-telegram-image">
											<img src={item.image} alt="" />
											{item.quantity &&
												<span className="product-count theme"
													style={{ 'backgroundColor': theme?.button_bg_color_plus, 'color': theme?.button_text_color }}>
													{item.quantity}
												</span>
											}
										</div>
										<div className="product-telegram-title" style={{ 'color': theme?.text_color }}>
											{item.name}
										</div>
										<div className="product-footer">
											<div className="product-price w-100">
												<span className="product-telegram-price__current" style={{ 'color': theme?.text_color }}>
													<b>{item.sale_price}</b>
													<span>{t('sum').toLowerCase()}</span>
												</span>
											</div>
											{item.quantity ?
												<div className="product-action">
													<button className="product-button theme minus"
														style={{ 'backgroundColor': theme?.button_bg_color_minus, 'color': theme?.button_minus_text_color }}>
														<i className="uil uil-minus"></i>
													</button>
													<button className="product-button theme plus"
														style={{ 'backgroundColor': theme?.button_bg_color_plus, 'color': theme?.button_text_color }}>
														<i className="uil uil-plus"></i>
													</button>
												</div>
												:
												<button className="product-button add"
													style={{ 'backgroundColor': theme?.button_bg_color_plus, 'color': theme?.button_text_color }}>
													{t('add')}
												</button>
											}
										</div>
									</div>
								</div>
							))}
						</div>
					}
				</div>
			</div>
		</div>
	}

	return (
		<>
			<div className="d-flex gap-2">
				<div className="themes">
					<div className="themes-buttons">
						<button className={"themes-button light " + (data?.settings?.theme === 'light' ? 'active' : '')}
							onClick={() => handleThemeTypeChange('light')}>
							<i className="uil uil-brightness"></i>
						</button>
						<button className={"themes-button dark " + (data?.settings?.theme === 'dark' ? 'active' : '')}
							onClick={() => handleThemeTypeChange('dark')}>
							<i className="uil uil-moon"></i>
						</button>
					</div>
					<div className="themes-colors">
						{themes.map((item, index) => (
							<div className={"theme-item " + (data?.online_menu_theme?.id === item.light.id ? 'selected' : '')}
								onClick={() => handleThemeChange(item)} key={index}>
								<div className="theme-item-light">
									{Object.keys(item.light).map((key, i) => (
										<div className="theme-color" style={{ backgroundColor: item?.light[key] }} key={i}></div>
									))}
								</div>
								<div className="theme-item-dark">
									{Object.keys(item.dark).map((key, i) => (
										<div className="theme-color" style={{ backgroundColor: item?.dark[key] }} key={i}></div>
									))}
								</div>
							</div>
						))}
					</div>
				</div>

				<div className="theme-wrapper">
					<div className="theme-title">
						{t('online_menu')}
					</div>
					<div className="theme-online-menu">
						<OnlineMenu type="menu" />
						<OnlineMenu type="products" />
					</div>
					<div className="theme-title">
						{t('telegram_bot')}
					</div>
					<div className="theme-bot">
						<TelegramBot type="menu" />
						<TelegramBot type="products" />
					</div>
				</div>
			</div>
		</>
	)
}

export default Themes
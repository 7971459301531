import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import DeleteModalModified from 'pages/parts/DeleteModalModified'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, POST, PUT, DELETE, httpOk } from 'helpers/api'
import { returnSign, findKeyFromArrayByValue, formatMoney, copyText } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const bonusTypes = [
		{ id: 1, name: t('menu') },
		{ id: 2, name: t('cheques') },
		{ id: 3, name: t('products') },
		{ id: 4, name: t('cheque') },
	];

	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0, bool: false })
	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [poses, setPoses] = useState([])
	const [users, setUsers] = useState([])
	const [menu, setMenu] = useState([])
	const [products, setProducts] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '',
		response: null
	})
	const [validData, setValidData] = useState({
		'items': []
	})

	async function searchWithFilter() {
		var filterUrl = "/employee-bonus"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/employee-bonus${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function createUpdate(e) {
		e.preventDefault()
		var sendData = { ...selectedItem }
		sendData.items = validData.items
		if (sendData?.items?.length > 0) {
			for (let i = 0; i < sendData.items.length; i++) {
				sendData.items[i]['pos_id'] = sendData.pos_id
			}
		}

		var response;
		if (sendData.id) {
			response = await PUT('/employee-bonus', sendData, { loader: true })
		} else {
			response = await POST('/employee-bonus', sendData, { loader: true })
		}

		if (httpOk(response)) {
			searchWithFilter()
			setShowModal(false)
			setSelectedItem({})
		}
	}

	async function handleSelectPos(option) {
		setSelectedItem({ ...selectedItem, 'pos_id': option.id })
		getUsers(option.id)
		getProducts()
	}

	async function getProducts() {
		const response = await GET(`/helper/products`, {}, { loader: true })
		if (httpOk(response)) {
			setProducts(response.data)
		}
	}

	function handleChildChange(key, value, index) {
		var validDataCopy = { ...validData }

		validDataCopy.items[index][key] = value
		setValidData(validDataCopy)
	}

	function addToList() {
		var validDataCopy = { ...validData }
		var child = {
			'menu_id': '',
			'bonus': '',
			'pos_id': validDataCopy.pos_id,
		}

		validDataCopy.items.push(child)
		setValidData(validDataCopy)
	}

	function deleteItem(index) {
		var validDataCopy = { ...validData }
		validDataCopy.items.splice(index, 1)

		setValidData(validDataCopy)
	}

	async function deleteFromDB() {
		const response = await DELETE(`/employee-bonus`, {
			'id': deleteModalParams.id
		}, { loader: true })
		if (httpOk(response)) {
			searchWithFilter()
			setDeleteModalParams({ id: 0, index: 0, bool: false })
		}
	}

	async function openModal(item) {
		await getUsers(item.pos_id)
		await getProducts()
		setSelectedItem(item)
		setShowModal(true)
		setValidData(item)
	}

	function closeModal() {
		setShowModal(false)
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getUsers(posId) {
		const response = await GET(`/employee-bonus-users/${posId}`, {}, { loader: true })
		if (httpOk(response)) {
			setUsers(response.data)
		}

		if (menu.length === 0) {
			const responseMenu = await GET(`/helper/menu`, {}, { loader: true })
			if (httpOk(responseMenu)) {
				setMenu(responseMenu.data)
			}
		}
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		searchWithFilter()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					KPI [{data?.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => {
						setValidData({ 'items': [] })
						setShowModal(true)
					}}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex gap-3 mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('pos')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.pos_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b> {findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '20%' }}>{t('user')}</th>
									<th style={{ 'width': '10%' }}>{t('role')}</th>
									<th style={{ 'width': '5%' }}>{t('type')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('bonus')}%</th>
									<th style={{ 'width': '50%' }} ></th>
									<th style={{ 'width': '35px', 'minWidth': '35px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.pos_name}</td>
										<td onClick={() => copyText(item.user_login)}>
											{item.first_name}
										</td>
										<td onClick={() => copyText(item.user_login)}>
											{item.role}
										</td>
										<td>
											{item.employee_bonus_type_id === 1 &&
												t('menu')
											}
											{item.employee_bonus_type_id === 2 &&
												t('cheques')
											}
											{item.employee_bonus_type_id === 3 &&
												t('products')
											}
											{item.employee_bonus_type_id === 4 &&
												t('cheque')
											}
										</td>
										<td className="text-end">{formatMoney(item.bonus)}</td>
										<td></td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item" onClick={() => openModal(item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
														<div className="table-dropdown-item text-danger"
															onClick={() => setDeleteModalParams({ id: item.id, index: index, bool: true })}>
															<i className="uil-times"></i>
															{t('delete')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('edit')}</>
							:
							<>{t('create')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('pos')}<span className="required-mark">*</span></label>
							<Select
								options={poses}
								value={poses.find(option => option.id === selectedItem.pos_id)}
								onChange={(option) => handleSelectPos(option)}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('user')}<span className="required-mark">*</span></label>
							<Select
								options={users}
								value={users.find(option => option.login === selectedItem.user_login)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'user_login': option.login })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.full_name}
								getOptionValue={(option) => option.login}
							/>
						</div>
						<div className="form-group">
							<label>{t('bonus')}<span className="required-mark">*</span></label>
							<Select
								options={bonusTypes}
								value={bonusTypes.find(option => option.id === selectedItem.employee_bonus_type_id) || ''}
								onChange={(option) => {
									setSelectedItem({ ...selectedItem, 'employee_bonus_type_id': option.id })
									setValidData({ ...validData, 'employee_bonus_type_id': option.id })
								}}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						{validData.employee_bonus_type_id === 2 &&
							<div className="form-group">
								<label>{t('bonus')} %<span className="required-mark">*</span></label>
								<input type="number" className="form-control" name="bonus"
									value={selectedItem.bonus}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'bonus': e.target.value })} />
							</div>
						}
						{validData.employee_bonus_type_id === 1 &&
							<>
								<div className="d-flex justify-content-between my-3">
									<div className="vertical-center">
										<h5><b>{t('menu')}</b></h5>
									</div>
									<div className="d-flex justify-content-center">
										<div className="table-action-button table-action-primary-button" onClick={() => addToList()}>
											<i className="uil-plus"></i>
										</div>
									</div>
								</div>

								<div className="table-responsive">
									<table className="table table-bordered mb-0">
										<thead>
											<tr>
												<th>{t('menu')}</th>
												<th>{t('bonus')}</th>
												<th>{t('action')}</th>
											</tr>
										</thead>
										<tbody>
											{validData?.items.map((item, index) => (
												<tr key={index}>
													<td className="w-75">
														<div className="w-100">
															<Select
																menuPosition="fixed"
																value={menu.find(option => option.id === validData?.items[index]['menu_id']) || ''}
																options={menu}
																onChange={(option) => handleChildChange('menu_id', option.id, index)}
																placeholder=""
																noOptionsMessage={() => t('list_empty')}
																getOptionLabel={(option) => option.name}
																getOptionValue={(option) => option.id}
															/>
														</div>
													</td>
													<td className="w-25">
														<div>
															<input type="number" className="form-control" name="bonus"
																value={item.bonus}
																onChange={(e) => handleChildChange('bonus', e.target.value, index)} />
														</div>
													</td>
													<td className="vertical-middle">
														<div className="d-flex justify-content-center">
															<div className="table-action-button table-action-danger-button" onClick={() => deleteItem(index)}>
																<i className="uil uil-times"></i>
															</div>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</>
						}

						{validData.employee_bonus_type_id === 3 &&
							<>
								<div className="d-flex justify-content-between my-3">
									<div className="vertical-center">
										<h5><b>{t('products')}</b></h5>
									</div>
									<div className="d-flex justify-content-center">
										<div className="table-action-button table-action-primary-button"
											onClick={() => addToList()}>
											<i className="uil-plus"></i>
										</div>
									</div>
								</div>

								<div className="table-responsive">
									<table className="table table-bordered mb-0">
										<thead>
											<tr>
												<th>{t('product')}</th>
												{/* <th>{t('bonus')}</th> */}
												<th>{t('amount')}</th>
												<th>{t('action')}</th>
											</tr>
										</thead>
										<tbody>
											{validData?.items.map((item, index) => (
												<tr key={index}>
													<td className="w-50">
														<div className="w-100">
															<Select
																menuPosition="fixed"
																value={products.find(option => option.id === validData?.items[index]['product_id']) || ''}
																options={products}
																onChange={(option) => handleChildChange('product_id', option.id, index)}
																placeholder=""
																noOptionsMessage={() => t('list_empty')}
																getOptionLabel={(option) => option.name}
																getOptionValue={(option) => option.id}
															/>
														</div>
													</td>
													{/* <td className="w-25">
														<div>
															<input type="number" className="form-control" name="bonus"
																value={item.bonus}
																onChange={(e) => handleChildChange('bonus', e.target.value, index)} />
														</div>
													</td> */}
													<td className="w-25">
														<div>
															<input type="number" className="form-control" name="bonus_fixed"
																value={item.bonus_fixed}
																onChange={(e) => handleChildChange('bonus_fixed', e.target.value, index)} />
														</div>
													</td>
													<td className="vertical-middle">
														<div className="d-flex justify-content-center">
															<div className="table-action-button table-action-danger-button" onClick={() => deleteItem(index)}>
																<i className="uil uil-times"></i>
															</div>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</>
						}

						{validData.employee_bonus_type_id === 4 &&
							<div className="form-group">
								<label>{t('amount')}<span className="required-mark">*</span></label>
								<input type="number" className="form-control" name="bonus"
									value={selectedItem.bonus}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'bonus': e.target.value })} />
							</div>
						}
						<div className="d-flex gap-3 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100" onClick={() => closeModal()}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<DeleteModalModified
				deleteModalParams={deleteModalParams}
				setDeleteModalParams={setDeleteModalParams}
				deleteFromDB={deleteFromDB}>
			</DeleteModalModified>
		</>
	)
}

export default Index
import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { GET, POST, httpOk } from 'helpers/api'

function TransferAccess({ activeTab, id }) {
	const { t } = useTranslation()

	const statusTypes = [
		{ 'id': 1, 'name': t('on') },
		{ 'id': -1, 'name': t('off') }
	]

	const [data, setData] = useState([]);
	const [suppliers, setSuppliers] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [modal, setModal] = useState(false);

	async function createUpdate(e) {
		e.preventDefault()

		var sendData = {
			"pos_id": id,
			"to_pos_id": selectedItem.to_pos_id,
			"supplier_id": selectedItem.supplier_id,
			"telegram_user_id": selectedItem.telegram_user_id,
			"telegram_group_id": selectedItem.telegram_group_id,
			"status": selectedItem.status,
		}

		var response = await POST(`/service/pos/transfer-access`, sendData, { loader: true });
		if (httpOk(response)) {
			toggleModal(false)
			getData()
		}
	}

	async function getData() {
		if (activeTab === 3 && id) {
			var response = await GET(`/service/pos/transfer-access/${id}`, {}, { loader: true })
			if (httpOk(response)) {
				setData(response.data)
			}

			var responseSuppliers = await GET(`/helper/suppliers`, {}, {})
			if (httpOk(responseSuppliers)) {
				setSuppliers(responseSuppliers.data)
			}
		}
	}

	async function toggleModal(bool, item = {}) {
		setSelectedItem(item)
		setModal(bool)
	}

	useEffect(() => {
		getData()
	}, [activeTab]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="table-responsive my-3">
				<table className="table table-bordered mb-0">
					<thead>
						<tr>
							<th>
								<div className="d-flex gap-2">
									<span className="vertical-center">{t('external_supplier')}</span>
									<div className="table-action-button table-action-primary-button"
										onClick={() => toggleModal(true)}>
										<i className="uil uil-plus"></i>
									</div>
								</div>
							</th>
							<th>{t('supplier')}</th>
							<th>Telegram CHAT_ID</th>
							<th>{t('status')}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{data?.map((item, index) => (
							<tr key={index}>
								<td>{item.pos_id}. {item.pos_name}</td>
								<td>{item.supplier_name}</td>
								<td>{item.telegram_user_id}</td>
								<td>
									{item.status === 1 ?
										<span className="bg-primary bg-border text-white text-nowrap">{t('yes')}</span>
										:
										<span className="bg-danger bg-border text-white text-nowrap">{t('no')}</span>
									}
								</td>
								<td className="text-end">
									<div className="d-flex justify-content-center">
										<div className="table-action-button table-action-primary-button"
											onClick={() => toggleModal(true, item)}>
											<i className="uil-edit-alt"></i>
										</div>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			{/* MODAL ID */}
			<Modal show={modal} animation={false} backdrop centered
				size="sm"
				dialogClassName="update-modal-width"
				onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('supplier')} ID <span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="to_pos_id" autoFocus
								value={selectedItem.to_pos_id}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'to_pos_id': e.target.value })} />
						</div>

						<div className="form-group">
							<label>{t('suppliers')} <span className="required-mark">*</span></label>
							<Select
								options={suppliers}
								value={suppliers.find(option => option.id === selectedItem.supplier_id) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'supplier_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>

						{selectedItem.id &&
							<div className="form-group">
								<label>{t('status')} <span className="required-mark">*</span></label>
								<Select
									options={statusTypes}
									value={statusTypes.find(option => option.id === selectedItem.status) || ''}
									onChange={(option) => setSelectedItem({ ...selectedItem, 'status': option.id })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						}
						<div className="form-group">
							<label>Telegram CHAT_ID</label>
							<input type="number" className="form-control" name="telegram_user_id"
								value={selectedItem.telegram_user_id}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'telegram_user_id': e.target.value })} />
						</div>
						<div className="form-group">
							<label>Telegram GROUP_ID</label>
							<input type="number" className="form-control" name="telegram_group_id"
								value={selectedItem.telegram_group_id}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'telegram_group_id': e.target.value })} />
						</div>

						<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL ID */}
		</>
	)
}

export default TransferAccess
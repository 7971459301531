import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import ReactSelect from 'pages/parts/ReactSelect'
import { GET, POST, PUT, DELETE, httpOk } from 'helpers/api'
import { formatDate, formatMoney, returnMinDate, formatMoneyInput } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState({
		"items": [],
		"pos_id": null,
		"total_amount": 0,
		"created_at": formatDate(new Date(), 'yyyy-MM-dd'),
	})
	const [wms, setWms] = useState([])
	const [items, setItems] = useState([])
	const [itemsDuplicate, setItemsDuplicate] = useState([])
	const [addQuantity, setAddQuantity] = useState(1)
	const [modalConfirm, setModalConfirm] = useState(false)
	const [modalTemplate, setModalTemplate] = useState({ 'bool': false, 'templates': [] })

	async function createUpdate(stage = 1) {
		var dataCopy = { ...data }

		if (dataCopy.items.length === 0) {
			toast.error(t('add_elements'))
			return
		}

		for (let i = 0; i < dataCopy.items.length; i++) {
			if (!dataCopy.items[i]['quantity']) {
				dataCopy.error = true
			}
			if (dataCopy.items[i]['price'] === "" || dataCopy.items[i]['price'] < 0.0001) {
				dataCopy.error = true
			}
			var warningArray = []
			if (stage === 1 &&
				(Number(dataCopy.items[i]['price']) / Number(dataCopy.items[i]['original_price'])) > 2
			) {
				warningArray.push(dataCopy.items[i])
				dataCopy.warning = true
			}
		}

		var valueArr = dataCopy.items.map(function (item) { return item.id });
		var isDuplicate = valueArr.some(function (item, idx) {
			return valueArr.indexOf(item) !== idx
		});

		if (isDuplicate) {
			toast.error(t('identical_elements'))
			return
		}

		if (dataCopy.error) {
			toast.error(t('check_filled_inputs'))
			return
		}

		if (stage === 1 || stage === 2) {
			setModalConfirm(true)
			return
		}

		var response;
		if (dataCopy.id) {
			response = await PUT('/documents-implementation', dataCopy, { loader: true })
		} else {
			response = await POST('/documents-implementation', dataCopy, { loader: true })
		}

		if (httpOk(response)) {
			navigate('/documents-implementation')
		}
	}

	function addToList(length = 1) {
		var dataCopy = JSON.parse(JSON.stringify(data))

		for (let i = 0; i < length; i++) {
			dataCopy.items.push({
				'price': '',
				'quantity': '',
				'id': ''
			})
		}
		countTotalAmount(dataCopy)

		setTimeout(() => {
			const byId = document.getElementById(dataCopy.items.length)
			byId?.select()
		}, 200);
	}

	function deleteItem(index) {
		var dataCopy = JSON.parse(JSON.stringify(data))
		dataCopy.items.splice(index, 1)

		countTotalAmount(dataCopy)
	}

	function countTotalAmount(dataCopy) {
		dataCopy.total_quantity = 0
		dataCopy.total_amount = 0
		dataCopy.total_amount_loss = 0
		for (let i = 0; i < dataCopy.items.length; i++) {
			dataCopy.total_quantity += Number(dataCopy.items[i]['quantity'])
			dataCopy.total_amount += Number(dataCopy.items[i]['quantity']) * Number(dataCopy.items[i]['price'])
		}

		setData(dataCopy)
	}

	function handleInputChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.items[index][e.target.name] = e.target.value.replace(/[^0-9.]/g, '')

		countTotalAmount(dataCopy)
	}

	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (!value) {
			setItems([...itemsDuplicate])
			return
		}
		var elementsDuplicateCopy = [...itemsDuplicate]

		var filteredItems = elementsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setItems(filteredItems)
	}

	function handleSelectElement(option, index) {
		var dataCopy = { ...data }
		dataCopy.items[index]['id'] = option.id
		dataCopy.items[index]['name'] = option.name
		dataCopy.items[index]['balance'] = option.balance
		dataCopy.items[index]['unit_name'] = option.unit_name
		dataCopy.items[index]['in_product'] = option.in_product
		dataCopy.items[index]['cost_price'] = option.cost_price
		dataCopy.items[index]['sale_price'] = ""

		setData(dataCopy)
	}

	function closeReactSelect() {
		setTimeout(() => {
			var elementsCopy = [...items]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true
			setItems(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = items.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...items]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[items.length - 1].selected = true
						currentIndex = items.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === items.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setItems(elementsCopy)
		}
	}
	// REACTSELECT LOGIC

	// TEMPLATE LOGIC
	async function toggleModalTemplate(bool) {
		if (bool) {
			const response = await GET(`/documents-implementation-templates?wms_id=${data.wms_id}`, {}, { loader: true })
			if (httpOk(response)) {
				setModalTemplate({ ...modalTemplate, 'bool': bool, 'templates': response.data })
			}
		} else {
			setModalTemplate({ ...modalTemplate, 'bool': bool, 'templates': [] })
		}
	}

	function handleSelectTemplate(item) {
		setData({ ...data, items: item.items })
		toggleModalTemplate(false)
	}

	async function deleteTemplate(item) {
		await DELETE(`/documents-implementation-templates/${item.document_order_id}`, {}, { loader: true })
		toggleModalTemplate(false)
	}
	// TEMPLATE LOGIC

	async function getElements(wmsId) {
		const response = await GET(`/documents-implementation-products?wms_id=${wmsId}`, {}, { loader: true })
		if (httpOk(response)) {
			setItems(response.data)
			setItemsDuplicate(response.data)
		}
	}

	async function getWms() {
		const response = await GET(`/helper/wms?pos_id=${reduxAccount.pos_id}`, {}, { loader: true })
		if (httpOk(response)) {
			setWms(response.data)
		}
	}

	useEffect(() => {
		getWms()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('implementation')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
				<div>
					<button type="button" className="btn btn-primary btn-rounded"
						onClick={() => toggleModalTemplate(true)}>
						{t('templates')}
					</button>
				</div>
			</div>

			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('date')}<span className="required-mark">*</span></label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={data.created_at ? new Date(data.created_at) : ''}
											onChange={(date) => setData({ ...data, 'created_at': formatDate(date, 'yyyy-MM-dd') })}
											minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
											maxDate={new Date()} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('warehouse')}<span className="required-mark">*</span></label>
									<Select
										options={wms}
										value={wms.find(option => option.id === data.wms_id)}
										onChange={(option) => {
											setData({ ...data, 'wms_id': option.id })
											getElements(option.id)
										}}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('note')}</label>
									<input type="text" className="form-control"
										name="note"
										onChange={(e) => setData({ ...data, 'note': e.target.value })} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<table className="table table-bordered" style={{ 'overflow': 'unset' }}>
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('name3')}</th>
									<th style={{ 'width': '15%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end">{t('balance2')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('unit')}</th>
									<th style={{ 'width': '10%' }} className="text-end">
										{t('cost_price')}
									</th>
									<th style={{ 'width': '10%' }} className="text-end">
										{t('quantity')}<span className="required-mark">*</span>
									</th>
									<th style={{ 'width': '10%' }} className="text-end">
										{t('sale_price')}<span className="required-mark">*</span>
									</th>
									<th style={{ 'width': '20%' }} className="text-end">{t('amount')}</th>
									<th style={{ 'minWidth': '33px' }} className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{data.items.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>
											<ReactSelect
												search={handleSelectSearch}
												value={item.name}
												index={index}
												scrollRef={scrollRef}
												options={items}
												onClose={() => closeReactSelect()}
												selectItem={(option) => handleSelectElement(option, index)}
												onKeyUp={(e) => {
													if (e.keyCode === 40 || e.keyCode === 38) {
														handleTableKeyDown(e)
													}
												}}
												table={
													<table className="table mb-0">
														<tbody ref={tbodyRef}>
															{items.map((selectItem, selectIndex) => (
																<tr
																	tabIndex={-1}
																	id={selectItem.id}
																	key={selectIndex}
																	className={`cursor ${((selectItem.selected || selectItem.id === item.id) && 'selected')}`}
																	onClick={() => handleSelectElement(selectItem, index)}
																	onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																	<td>{selectItem.name}</td>
																	<td className="text-end text-nowrap">{selectItem.product_type_name}</td>
																</tr>
															))}
														</tbody>
													</table>
												}
											/>
										</td>
										<td></td>
										<td className="text-end">
											{formatMoney(item.balance)}
										</td>
										<td className="text-start">
											{item.unit_name}
										</td>
										<td className="text-end">
											{formatMoney(item.cost_price)}
										</td>
										<td className="td-input" style={!item.quantity ? { 'borderBottom': '2px solid red' } : {}}>
											<input
												type="text"
												name="quantity"
												className="form-control text-end"
												placeholder="0"
												value={formatMoneyInput(item.quantity) ?? ''}
												onChange={(e) => handleInputChange(e, index)} />
										</td>
										<td className="td-input" style={!item.price ? { 'borderBottom': '2px solid red' } : {}}>
											<input
												type="text"
												name="price"
												placeholder="0"
												className="form-control text-end"
												value={formatMoneyInput(item.price) ?? ''}
												onChange={(e) => handleInputChange(e, index)}
												onKeyUp={(event) => {
													if (event.key === 'Enter') {
														addToList()
													}
												}}
											/>
										</td>
										<td className="text-end text-nowrap vertical-middle">
											{formatMoney(item.quantity * item.price)}
										</td>
										<td className="vertical-middle">
											<div className="d-flex justify-content-end">
												<div className="table-action-button table-action-danger-button"
													onClick={() => deleteItem(index)}>
													<i className="uil uil-times"></i>
												</div>
											</div>
										</td>
									</tr>
								))}
								<tr>
									<td className="td-input">
										<input
											type="text"
											className="form-control text-center"
											style={{ 'padding': '5px 0' }}
											placeholder="xxx"
											value={addQuantity}
											onChange={(e) => setAddQuantity(e.target.value.replace(/[^0-9.]/g, ''))}
										/>
									</td>
									<td colSpan={1} style={{ 'padding': 0 }}>
										<div className="d-flex">
											<div className="w-100">
												<button type="button" className="btn-sm btn-primary w-100"
													disabled={!data.wms_id}
													onClick={() => {
														setAddQuantity(1)
														addToList(addQuantity)
													}}>
													{t('add')}
												</button>
											</div>
										</div>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td className="text-end">
										{formatMoney(data.total_quantity)}
									</td>
									<td></td>
									<td className="text-end">
										{formatMoney(data.total_amount)}
									</td>
									<td></td>
								</tr>
							</tbody>
						</table>

						<div className="btn-wrapper gap-2">
							<Link to="/documents-implementation" className="btn btn-warning btn-rounded btn-wide" tabIndex="-1">
								{t('cancel')}
							</Link>
							<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
								onClick={() => createUpdate(1)}>
								{t('save')}
							</button>
						</div>
					</div>
				</div>
			</form>

			{/* MODAL CONFIRM */}
			<Modal show={modalConfirm} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => setModalConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('confirm')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between mb-1">
						<div>{t('total_amount')}</div>
						<div className="fw-700">{formatMoney(data.total_amount)}</div>
					</div>
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => setModalConfirm(false)}>
							{t('cancel')}
						</button>
						<button className="btn btn-primary w-100"
							onClick={() => createUpdate(3)}>
							{t('confirm')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL CONFIRM */}

			{/* MODAL TEMPLATE */}
			<Modal show={modalTemplate.bool} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => toggleModalTemplate(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('templates')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '10px' }}>#</th>
									<th>{t('name2')}</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{modalTemplate?.templates?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}</td>
										<td onClick={() => handleSelectTemplate(item)}>
											{item.name}
										</td>
										<td>
											<div className="d-flex justify-content-end">
												<div className="table-action-button table-action-danger-button"
													onClick={() => deleteTemplate(item)}>
													<i className="uil uil-times"></i>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL TEMPLATE */}
		</>
	)
}

export default CreateUpdate
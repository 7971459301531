import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DatePicker from "react-datepicker"
import { Dropdown } from 'react-bootstrap'
import 'react-loading-skeleton/dist/skeleton.css'

import { TOGGLE_LEFT_MENU } from 'store/layout/actions'
import { FILE_D, GET, httpOk } from 'helpers/api'
import { formatDate, formatMoney, returnSign } from 'helpers/helpers';

function Rating() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const date = new Date()

	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		ids: []
	})

	async function searchWithFilter(filterDataCopy) {
		//var filterDataCopy = { ...filterData }
		var filterUrl = "/chain/report/service-rating"
		var urlParams = ""

		if (filterDataCopy.ids)
			urlParams += returnSign(urlParams) + 'ids=' + filterDataCopy.ids
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	function handleCheckboxChangeAll(e) {
		var filterDataCopy = { ...filterData }

		filterDataCopy.ids = []
		if (e.target.checked) {
			for (let i = 0; i < poses.length; i++) {
				filterDataCopy.ids.push(poses[i]['id'])
			}
		}

		setFilterData(filterDataCopy)
	}

	function handleCheckboxChange(e, id) {
		var filterDataCopy = { ...filterData }

		if (e.target.checked) {
			filterDataCopy.ids.push(id)
		} else {
			filterDataCopy.ids = filterDataCopy.ids.filter(item => item !== id)
		}

		setFilterData(filterDataCopy)
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function downloadExcelFile() {
		var filterUrl = '/chain/report/service-rating-excel'
		var urlParams = ""

		if (filterData.ids)
			urlParams += returnSign(urlParams) + 'ids=' + filterData.ids
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date

		filterUrl = filterUrl + urlParams
		var response = await FILE_D(filterUrl)
		if (httpOk(response)) {
			// Создаем объект Blob из полученного файла
			const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Создаем ссылку для скачивания файла
			const fileUrl = URL.createObjectURL(file);

			// Создаем ссылку для скачивания файла в браузере
			const link = document.createElement('a');
			link.href = fileUrl;
			link.setAttribute('download', `feedbacks.xlsx`);
			document.body.appendChild(link);
			link.click();

			// Удаляем ссылку после скачивания файла
			URL.revokeObjectURL(fileUrl);
		}
	};
	
	async function getPoses() {
		const response = await GET('/helper/poses')

		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		dispatch(TOGGLE_LEFT_MENU(false))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex gap-3">
				<div style={{ 'minWidth': '252px' }}>
					<div className="card mb-3" style={{ 'height': 'calc(100vh - 160px)', 'overflow': 'auto' }}>
						<div className="card-body">
							<h5 className="mb-3">{t('poses')}</h5>
							<div className="flex-column" style={{ 'height': 'calc(100vh - 267px)', 'overflow': 'auto' }}>
								<div className="form-check">
									<input id="all"
										className="form-check-input"
										type="checkbox"
										onChange={(e) => handleCheckboxChangeAll(e)}
									/>
									<label className="form-check-label" htmlFor="all">
										{t('all')}
									</label>
								</div>
								{poses?.map((item, index) => (
									<div className="form-check" key={index}>
										<input id={`pos${index}`} className="form-check-input" type="checkbox"
											checked={filterData?.ids?.includes(item.id)}
											onChange={(e) => handleCheckboxChange(e, item.id)}
										/>
										<label className="form-check-label" htmlFor={`pos${index}`}>
											{item.short_name}
										</label>
									</div>
								))}
							</div>
							<button className="btn btn-outline-primary btn-wide-2 w-100"
								onClick={() => downloadExcelFile()}>
								EXCEL
							</button>
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 w-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(filterData)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>
				</div>

				<div className="w-100">
					<div className="card mb-3">
						<div className="card-body">
							<h5 className="mb-2">{t('rating')}</h5>
							<div className="table-resposive">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th style={{ 'width': '33px' }} className="text-center">#</th>
											<th>{t('question')}</th>
											{data?.poses?.map((item, index) => (
												<th key={index} className="text-end">{item.pos_name}</th>
											))}
											<th className="text-end">{t('total')}</th>
										</tr>
									</thead>
									<tbody>
										{data?.service_ratings?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{index + 1}</td>
												<td>{item.name}</td>
												{item?.poses?.length &&
													<Fragment>
														{item.poses.map((item2, index2) => (
															<Fragment key={index2}>
																<td className="text-nowrap text-end">
																	{formatMoney(item2.total_answers)}
																</td>
															</Fragment>
														))}
													</Fragment>
												}
												<td className="text-end"><b>{formatMoney(item.total)}</b></td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td></td>
											<td><b>{t('total')}</b></td>
											{data?.poses?.map((item, index) => (
												<Fragment key={index}>
													<td className="text-nowrap text-end">
														<b>{formatMoney(item.overall)}</b>
													</td>
												</Fragment>
											))}
											<td className="text-end"><b>{data.overall}</b></td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	)
}

export default Rating
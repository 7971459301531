import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReactApexChart from 'react-apexcharts';
import DatePicker from "react-datepicker"

import { TOGGLE_LEFT_MENU } from 'store/layout/actions'
import { GET, httpOk } from 'helpers/api'
import { formatDate, returnSign } from 'helpers/helpers';

function Sales() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const date = new Date()

	const [data, setData] = useState({
		'days_cheques': {}
	})
	const [poses, setPoses] = useState([])
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		ids: []
	})
	const [chequesChart, setChequesChart] = useState({
		series: [],
		options: {
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				stackType: '100%',
				toolbar: false
			},
			plotOptions: {
				bar: {
					horizontal: true,
				},
			},
			xaxis: {
				categories: [
					'0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
					'13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
				],
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return val
					}
				}
			},
			legend: {
				show: true,
				position: 'bottom'
			},
			fill: {
				opacity: 1
			}
		},
	})

	async function searchWithFilter(filterDataCopy) {
		//var filterDataCopy = { ...filterData }
		var filterUrl = "/chain/report/cheques-hourly"
		var urlParams = ""

		if (filterDataCopy.ids)
			urlParams += returnSign(urlParams) + 'ids=' + filterDataCopy.ids
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			var chequesChartCopy = { ...chequesChart }
			chequesChartCopy.series = []
			chequesChartCopy.series.push(response.data['cheques']['series']['hall'])
			chequesChartCopy.series.push(response.data['cheques']['series']['takeaway'])
			chequesChartCopy.series.push(response.data['cheques']['series']['delivery'])

			setChequesChart(chequesChartCopy)
			setData(response.data)
		}
	}

	function handleCheckboxChangeAll(e) {
		var filterDataCopy = { ...filterData }

		filterDataCopy.ids = []
		if (e.target.checked) {
			for (let i = 0; i < poses.length; i++) {
				filterDataCopy.ids.push(poses[i]['id'])
			}
		}

		setFilterData(filterDataCopy)
	}

	function handleCheckboxChange(e, id) {
		var filterDataCopy = { ...filterData }

		if (e.target.checked) {
			filterDataCopy.ids.push(id)
		} else {
			filterDataCopy.ids = filterDataCopy.ids.filter(item => item !== id)
		}

		setFilterData(filterDataCopy)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)

			var filterDataCopy = { ...filterData }
			for (let i = 0; i < response.data.length; i++) {
				filterDataCopy.ids.push(response.data[i]['id'])
			}
			setFilterData(filterDataCopy)
			searchWithFilter(filterDataCopy)
		}
	}

	useEffect(() => {
		getPoses()
		dispatch(TOGGLE_LEFT_MENU(false))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex gap-3">
				<div style={{ 'minWidth': '200px' }}>
					<div className="card mb-3" style={{ 'height': 'calc(100vh - 300px)', 'overflow': 'auto' }}>
						<div className="card-body">
							<h5 className="mb-3">{t('poses')}</h5>
							<div className="flex-column">
								<div className="form-check">
									<input id="all"
										className="form-check-input"
										type="checkbox"
										onChange={(e) => handleCheckboxChangeAll(e)}
									/>
									<label className="form-check-label" htmlFor="all">
										{t('all')}
									</label>
								</div>
								{poses?.map((item, index) => (
									<div className="form-check" key={index}>
										<input id={`pos${index}`} className="form-check-input" type="checkbox"
											checked={filterData?.ids?.includes(item.id)}
											onChange={(e) => handleCheckboxChange(e, item.id)}
										/>
										<label className="form-check-label" htmlFor={`pos${index}`}>
											{item.short_name}
										</label>
									</div>
								))}
							</div>
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<div>
								<div className="form-group">
									<label>{t('period')}</label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={filterData.start_date ? new Date(filterData.start_date) : ''}
											onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
								<div className="form-group">
									<label>{t('period')}</label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={filterData.end_date ? new Date(filterData.end_date) : ''}
											onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
								<button className="btn btn-primary w-100"
									onClick={() => searchWithFilter(filterData)}>
									{t('apply')}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div style={{ 'minWidth': '1300px' }}>
					<div className="card mb-3">
						<div className="card-body">
							<div className="d-flex">
								<div>
									<div className="table-resposive">
										<table className="table table-bordered">
											<thead>
												<tr>
													<th className="text-end"
														style={{ 'minWidth': '33px' }}>
														{t('hours')}
													</th>
													<th className="text-end"
														style={{ 'minWidth': '45px' }}>
														{t('hall')}
													</th>
													<th className="text-end"
														style={{ 'minWidth': '45px' }}>
														{t('takeaway')}
													</th>
													<th className="text-end"
														style={{ 'minWidth': '45px' }}>
														{t('delivery')}
													</th>
													<th className="text-end"
														style={{ 'minWidth': '45px' }}>
														{t('total')}
													</th>
												</tr>
											</thead>
											<tbody>
												{data?.cheques_table?.map((item, index) => (
													<tr key={index}>
														<td className="text-end"
															style={{ 'minWidth': '45px' }}>
															{item.hour}
														</td>
														<td className="text-end"
															style={{ 'minWidth': '45px' }}>
															{item.hall}
														</td>
														<td className="text-end"
															style={{ 'minWidth': '45px' }}>
															{item.takeaway}
														</td>
														<td className="text-end"
															style={{ 'minWidth': '45px' }}>
															{item.delivery}
														</td>
														<td className="text-end"
															style={{ 'minWidth': '45px' }}>
															{item.total}
														</td>
													</tr>
												))}
											</tbody>
											<tfoot>
												<tr>
													<td className="text-end">
														<b>{t('total')}</b>
													</td>
													<td className="text-end">
														<b>{data?.cheques_total_hall}</b>
													</td>
													<td className="text-end">
														<b>{data?.cheques_total_takeaway}</b>
													</td>
													<td className="text-end">
														<b>{data?.cheques_total_delivery}</b>
													</td>
													<td className="text-end">
														<b>{data?.cheques_overall}</b>
													</td>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
								<div>
									<ReactApexChart
										options={chequesChart.options}
										series={chequesChart.series}
										type="bar"
										height={820}
										width={300}
									/>
								</div>
								<div style={{ 'overflow': 'auto' }}>
									<div className="table-resposive">
										<table className="table table-bordered">
											<thead>
												<tr>
													<th className="text-end" style={{ 'minWidth': '33px' }}>#</th>
													{data?.days?.map((item, index) => (
														<th className="text-end" style={{ 'minWidth': '45px' }}>
															{item.date}
														</th>
													))}
												</tr>
											</thead>
											<tbody>
												{Object.keys(data?.days_cheques).map((key, i) => (
													<tr>
														<td className="text-end">{i}</td>
														{data?.days_cheques[key].map((item, index) => (
															<td className="text-end">{item.cheque_count}</td>
														))}
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Sales
import React from "react"
import { useTranslation } from 'react-i18next'
import { formatMoney } from "helpers/helpers";

const Tree = ({ children, selectItem, level = 0 }) => {
	const { t } = useTranslation()

	function returnEntityType(item) {
		var name = ''
		if (item?.counterparty === 'supplier') {
			name = t('supplier')
		}
		if (item?.counterparty === 'employee' || item?.counterparty === 'employee_salary') {
			name = t('employee')
		}
		if (item?.counterparty === 'agent') {
			name = t('agent')
		}
		if (item?.counterparty === 'organization') {
			name = t('organization')
		}
		if (item?.counterparty === 'client') {
			name = t('client')
		}

		return name
	}

	function returnStyle(item) {
		if (!item.parent_id) {
			return {}
		}
		if (level === 1) {
			return { 'paddingLeft': String((level + 1) * 10) + "px", 'fontSize': '16px' }
		}

		if (level > 1) {
			return { 'paddingLeft': String((level + 1) * 15) + "px", 'fontSize': '16px' }
		}
	}

	return (
		<>
			{children.map((item, index) => (
				<React.Fragment key={index}>
					<tr>
						<td className={`child-${item.id} cursor`}
							style={returnStyle(item)}
							onClick={() => selectItem(item)}>
							{item.children.length > 0 &&
								<i className="uil uil-angle-down"></i>
							}
							{!item.parent_id &&
								<b>{item.name}</b>
							}
							{item.parent_id &&
								<span>{item.name}</span>
							}
						</td>
						<td>{item.code}</td>
						<td>{item.account_type_name}</td>
						<td>{returnEntityType(item)}</td>
						<td className="text-end">{formatMoney(item.balance, 2)}</td>
					</tr>

					{item.children && item.children.length > 0 && (
						<Tree
							children={item.children}
							selectItem={selectItem}
							level={level + 1} // Увеличиваем уровень вложенности
						/>
					)}
				</React.Fragment>
			))}
		</>
	);
};

export default Tree;

import React, { useEffect, useRef, useState } from "react"
import { NavLink } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'

import { TOGGLE_LEFT_MENU } from 'store/layout/actions'
//Import Scrollbar
import { Nav } from 'react-bootstrap'
import { exportMenu } from './menu'

import logo from "assets/images/logo.png";
import { SET_FILTER_DATA } from "store/filterData/actions";

function SidebarContent() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const ref = useRef();

	const reduxAccount = useSelector(state => state.account)
	const layoutSelector = useSelector(state => state.layout)
	const reduxFilterData = useSelector(state => state.filterData)

	const [menu, setMenu] = useState([])
	const [activeItem, setActiveItem] = useState({
		children: []
	})

	function checkMenuAccess() {
		var menuCopy = [...exportMenu]

		if (reduxAccount.role !== 'ROLE_OWNER') {
			const filteredMenuChildrends = filterMenuByPermissions(menuCopy, reduxAccount.permissions);
			setMenu(filteredMenuChildrends)
			setActiveItem(filteredMenuChildrends[0])
		} else {
			setMenu(menuCopy);
			setActiveItem(menuCopy[0])
		}
	}

	function filterMenuByPermissions(passedMenu, permissions) {
		const permissionNames = permissions.map(p => p.name);

		return passedMenu.map(menuItem => {
			if (menuItem.children) {
				const filteredChildren = menuItem.children.filter(child => {
					return !child.permission || permissionNames.includes(child.permission);
				});
				return { ...menuItem, children: filteredChildren };
			}
			return menuItem;
		});
	}

	function setActiveChild(index) {
		dispatch(TOGGLE_LEFT_MENU(true))
		var menuCopy = [...menu]

		for (let i = 0; i < menuCopy.length; i++) {
			for (let j = 0; j < menuCopy[i]['children'].length; j++) {
				menuCopy[i]['children'][j]['active'] = false;
			}
		}

		const i = menuCopy.findIndex(x => x.icon === activeItem.icon)
		menuCopy[i]['children'][index]['active'] = !menuCopy[i]['children'][index]['active']
		setActiveItem(menuCopy[i])

		if (reduxFilterData?.url) {
			dispatch(SET_FILTER_DATA({}))
		}
	}

	function setActiveTab(item) {
		setActiveItem(item)
		dispatch(TOGGLE_LEFT_MENU(true))
	}

	useEffect(() => {
		checkMenuAccess()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div ref={ref} className={`sidebar-menu-scroll ${layoutSelector.showleftMenu ? 'box-shadow-none' : ''}`}>
				<Nav className="nav-mini" activeKey={activeItem.icon}>
					{menu.map((item, index) => (
						<Nav.Item onClick={() => setActiveTab(item)} key={index}>
							<Nav.Link eventKey={item.icon}>
								<span className="fw-bold"><i className={item.icon}></i></span>
							</Nav.Link>
						</Nav.Item>
					))}
				</Nav>
				<div className="app-version">
					{t('version')}
					<br />
					<div className="text-center">
						{process.env.REACT_APP_VERSION}
					</div>
				</div>
			</div>

			<div className={"second-sidebar" + (layoutSelector.showleftMenu ? '' : ' d-none')}>
				<div className="second-sidebar-logo">
					<img src={logo} alt="" width={120} />
				</div>

				<div id="sidebar-menu">
					<ul className="metismenu list-unstyled" id="side-menu">
						{activeItem.children.map((item, index) => (
							<li className={item.active ? "mm-active" : ""} key={index}>
								{item.title == null ?
									<NavLink to={item.url}
										onClick={() => setActiveChild(index)} className={"waves-effect d-flex " + (item.active ? "active" : "")}>
										<i className={item.icon}></i>
										<div className="vertical-menu-div">{t(item.i18n)}</div>
									</NavLink>
									:
									<div className="second-sidebar-title">
										<div>{t(item.i18n)}</div>
									</div>
								}
							</li>
						))}
					</ul>
				</div>
			</div>
		</>
	)
}

export default SidebarContent
import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import DeleteModal from 'pages/parts/DeleteModal'
import { returnSign, formatDate, findKeyFromArrayByValue, regexPhoneNumber } from "helpers/helpers";
import { DELETE, GET, httpOk } from 'helpers/api'

function Index() {
	const { t } = useTranslation()
	const { id } = useParams()
	const date = new Date()

	const statuses = [
		{ 'id': 1, 'name': t('activated') },
		{ 'id': -1, 'name': t('not_activated') },
	]

	const searchColumns = [
		{ 'id': 'client_phone', 'name': t('phone') },
		{ 'id': 'client_name', 'name': t('name') },
		{ 'id': 'promo_code', 'name': t('promo_code') },
	]

	const orderBy = [
		{ 'id': 'id,asc', 'name': 'ID ASC' },
		{ 'id': 'id,desc', 'name': 'ID DESC' },
		{ 'id': 'created_at,asc', 'name': `${t('created')} ASC` },
		{ 'id': 'created_at,desc', 'name': `${t('created')} DESC` },
		{ 'id': 'activated_at,asc', 'name': `${t('activated')} ASC` },
		{ 'id': 'activated_at,desc', 'name': `${t('activated')} DESC` },
	]

	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		status: null,
		search_by: 'promo_code',
		order_by: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/discount/promo-codes-statuses',
		response: null
	})
	const [data, setData] = useState({})

	async function searchWithFilter() {
		var filterUrl = `/discount/promo-codes-statuses/${id}`
		var urlParams = ""
		checkFilter()
		var filterDataCopy = { ...filterData }

		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterData.status
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search_by)
			urlParams += returnSign(urlParams) + 'search_by=' + filterData.search_by
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterDataCopy.order_by)
			urlParams += returnSign(urlParams) + 'order_by=' + filterData.order_by
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/discount/promo-codes-statuses/${id}${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			status: null,
			search_by: 'promo_code',
			order_by: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function deleteItem() {
		const response = await DELETE('/discount/promo-codes-statuses', { 'id': deleteModalParams.id }, { loader: true })
		if (httpOk(response)) {
			searchWithFilter()
			setModalDelete(false)
		}
	}

	useEffect(() => {
		//
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('report')} [{data.total}]
					</h4>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													isClearable
													options={statuses}
													value={statuses.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('search')}</label>
												<Select
													options={searchColumns}
													value={searchColumns.find(option => option.id === filterData.search_by) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'search_by': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('order_by')}</label>
												<Select
													options={orderBy}
													value={orderBy.find(option => option.id === filterData.order_by) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'order_by': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex gap-2 justify-content-end">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(false)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '10%' }}>{t('phone')}</th>
									<th style={{ 'width': '20%' }}>{t('name')}</th>
									<th style={{ 'width': '10%' }}>{t('promo_code')}</th>
									<th style={{ 'width': '15%' }}>{t('created_by')}</th>
									<th style={{ 'width': '15%' }} className="text-center">{t('created')}</th>
									<th style={{ 'width': '15%' }} className="text-center">{t('activated')}</th>
									<th style={{ 'width': '15%' }} className="text-center"></th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td>{regexPhoneNumber(item.client_phone)}</td>
										<td>{item.client_name}</td>
										<td>{item.promo_code}</td>
										<td>{item.created_by}</td>
										<td className="text-center">
											{formatDate(item.created_at, 'dd.MM.yy HH:mm')}
										</td>
										<td className={`text-center text-white ${item.status ? 'bg-success' : 'bg-danger'}`}>
											{item.status === 1 &&
												<b>{formatDate(item.activated_at, 'dd.MM.yy HH:mm')}</b>
											}
										</td>
										<td></td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<div className="table-dropdown-item text-danger"
															onClick={() => {
																setDeleteModalParams({ id: item.id, index: index });
																setModalDelete(true)
															}}>
															<i className="uil-times"></i>
															{t('delete')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { GET, POST, PUT, httpOk } from 'helpers/api'
import { formatMoney } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { id } = useParams()

	const rulesExample = [
		{
			'from_cheques': 3,
			'award_percentage': 1,
		},
		{
			'from_cheques': 5,
			'award_percentage': 2,
		},
		{
			'from_cheques': 7,
			'award_percentage': 5,
		}
	]
	const [poses, setPoses] = useState([])
	const [rule, setRule] = useState({
		'from_cheques': '',
		'award_percentage': '',
	})
	const [data, setData] = useState({
		"name": "",
		"days": "",
		"loyalty_service_type_id": 3,
		"poses": [],
		"rules": [],
	})

	async function createUpdate() {
		var dataCopy = { ...data }
		var response

		if (!dataCopy.id) {
			response = await POST('/loyalty/services-visit', dataCopy, { loader: true })
		} else {
			response = await PUT('/loyalty/services-visit', dataCopy, { loader: true })
		}

		if (httpOk(response)) navigate(-1)
	}

	function addRule() {
		var dataCopy = { ...data }
		var ruleCopy = { ...rule }

		if (dataCopy.rules.length > 0) {
			if (Number(dataCopy.rules[dataCopy.rules.length - 1]['from_cheques']) >= Number(ruleCopy.from_cheques)) {
				toast.error(t('error'))
				return
			}

			if (Number(dataCopy.rules[dataCopy.rules.length - 1]['award_percentage']) >= Number(ruleCopy.award_percentage)) {
				toast.error(t('error'))
				return
			}
		}

		dataCopy.rules.push(ruleCopy)
		setData(dataCopy)
		setRule({
			'from_cheques': '',
			'award_percentage': '',
			'show_input': false
		})
	}

	function deleteRule(index) {
		var dataCopy = { ...data }
		dataCopy.rules.splice(index, 1)
		setData(dataCopy)
	}


	async function getData() {
		const response = await GET('/helper/poses', {}, { loader: true })
		if (httpOk(response)) setPoses(response.data)
		if (id) {
			const response = await GET(`/loyalty/services-visit/${id}`, {}, { loader: true })
			if (httpOk(response)) setData(response.data)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('loyalty')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">

					<div className="row">
						<div className="col-md-4 border-right">
							<div className="form-group">
								<label>{t('name3')}</label>
								<input type="text" className="form-control" name="name" autoComplete="off"
									value={data.name ?? ''}
									onChange={(e) => setData({ ...data, 'name': e.target.value })} />
							</div>
							<div className="form-group">
								<label>{t('poses')}<span className="required-mark">*</span></label>
								<Select
									isMulti
									value={data.poses}
									options={poses}
									onChange={(options) => setData({ ...data, 'poses': options })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>

							<h5 className="mt-5">{t('example')}</h5>
							<div className="table-responsive">
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											<th>{t('number_of_checks')}</th>
											<th>{t('bonus')} %</th>
										</tr>
									</thead>
									<tbody>
										{rulesExample.map((item, index) => (
											<tr key={index}>
												<td>
													<span>{formatMoney(item.from_cheques)}</span>
												</td>
												<td>
													<span>{item.award_percentage}%</span>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group w-25">
								<label>{t('days')}</label>
								<input type="number" className="form-control" name="days" autoComplete="off"
									value={data.days ?? ''}
									onChange={(e) => setData({ ...data, 'days': e.target.value })} />
							</div>
							<div className="table-responsive">
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											<th>{t('number_of_checks')}</th>
											<th>{t('bonus')} %</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<input type="text" pattern="[0-9]*" className="form-control"
													value={rule.from_cheques ?? ''}
													onChange={(e) => setRule({ ...rule, 'from_cheques': e.target.value.replace(/[^0-9.]/g, '') })} />
											</td>
											<td>
												<input type="text" pattern="[0-9]*" className="form-control"
													value={rule.award_percentage}
													onChange={(e) => setRule({ ...rule, 'award_percentage': e.target.value.replace(/[^0-9.]/g, '') })} />
											</td>
											<td>
												<button className="btn btn-primary" onClick={() => addRule()}
													disabled={!rule.from_cheques || !rule.award_percentage}>
													{t('add')}
												</button>
											</td>
										</tr>
										{data.rules.map((item, index) => (
											<tr key={index}>
												<td>
													{item.showInput ?
														<input type="text" pattern="[0-9]*" className="form-control"
															value={data.rules[0]['from_cheques']}
															onChange={(e) => {
																var dataCopy = { ...data }
																dataCopy.rules[0]['from_cheques'] = e.target.value.replace(/[^0.1-9.]/g, '')
																setData(dataCopy)
															}}
														/>
														:
														<span>{formatMoney(item.from_cheques)}</span>
													}
												</td>
												<td>
													{item.showInput ?
														<input type="text" pattern="[0-9]*" className="form-control"
															value={data.rules[0]['award_percentage']}
															onChange={(e) => {
																var dataCopy = { ...data }
																dataCopy.rules[0]['award_percentage'] = e.target.value.replace(/[^0.1-9.]/g, '')
																setData(dataCopy)
															}}
														/>
														:
														<span>{item.award_percentage}%</span>
													}
												</td>
												<td>
													<div className="d-flex justify-content-center gap-2 d-none">
														<div className="d-flex justify-content-center">
															<div className="table-action-button table-action-success-button me-2" onClick={() => {
																var dataCopy = { ...data }
																dataCopy.rules[0]['showInput'] = !dataCopy.rules[0]['showInput']
																setData(dataCopy)
															}}>
																{item.showInput ?
																	<i className="uil-check-circle"></i>
																	:
																	<i className="uil-edit-alt"></i>
																}
															</div>
														</div>

														<div className="d-flex justify-content-center">
															<div className="table-action-button table-action-danger-button"
																onClick={() => deleteRule(index)}>
																<i className="uil-times"></i>
															</div>
														</div>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div className="btn-wrapper">
						<Link className="btn btn-warning btn-rounded btn-wide me-2" to="/loyalty/services" tabIndex="-1">
							{t('cancel')}
						</Link>
						<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
							onClick={() => createUpdate()}>
							{t('save')}
						</button>
					</div>

				</div>
			</div>
		</>
	)
}

export default CreateUpdate
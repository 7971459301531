import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'; // eslint-disable-line no-unused-vars

import { copyText, formatMoney } from 'helpers/helpers';

function Index() {
	const { t } = useTranslation()

	const socketRef = useRef(null)
	const usersRef = useRef([])

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState([])

	function connectSocket() {
		var wsHost;
		var authEndpoint;
		var isProd;

		if (process.env.NODE_ENV === 'production') {
			wsHost = process.env.REACT_APP_PROD_WSHOST
			authEndpoint = process.env.REACT_APP_PROD_BROADCAST_AUTH
			isProd = true
		} else {
			wsHost = process.env.REACT_APP_DEV_WSHOST
			authEndpoint = process.env.REACT_APP_DEV_BROADCAST_AUTH
			isProd = false
		}

		try {
			socketRef.current = new Echo({
				broadcaster: 'pusher',
				key: "mison_key",
				wsHost: wsHost,
				wsPort: 6001,
				wssPort: 6001,
				enabledTransports: ['ws', 'wss'],
				disableStats: true,
				encrypted: isProd ? true : false,
				useTLS: isProd ? true : false,
				forceTLS: isProd ? true : false,
				authEndpoint: authEndpoint,
				auth: {
					headers: {
						'Authorization': `Bearer ${reduxAccount.token}`
					}
				},
			});
		} catch (error) {
			console.log(error)
		}

		socketRef.current.join(`general`)
			.here((users) => {
				console.log('connected to general channel', users)
				if (reduxAccount.role === 'ROLE_OWNER') {
					users = users.filter(item => item.parent === reduxAccount.login)
				} else {
					users = users.filter(item => item.parent === reduxAccount.parent)
				}
				usersRef.current = users
				setData({ ...data, 'users': users })
			})
			.joining((user) => socketListener(user, 'joining'))
			.leaving((user) => socketListener(user, 'leaving'))
	}

	function socketListener(user, type) {
		var users = usersRef.current
		if (type === 'joining') {
			console.log('user.role', user.role)

			if (!user.role_name) return // это проверка для ROLE_OWNER его не добавлял в базу изза helper-api

			if (user.parent === reduxAccount.parent) {
				usersRef.current = users.push(user)
				setData({ ...data, 'users': users })
			}
		}
		if (type === 'leaving') {
			const index = users.findIndex(item => item.login === user.login);

			if (index !== -1 && index !== undefined) {
				users.splice(index, 1)
			}
			usersRef.current = users
			setData({ ...data, 'users': users })
		}
	}

	useEffect(() => {
		connectSocket()

		return () => {
			if (socketRef.current) {
				socketRef?.current?.leave(`general`)
			}
		}
	}, [])// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		usersRef.current = data.users
	}, [data])  // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('online_users')} [{formatMoney(data?.users?.length)}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '15%' }}>{t('pos')}</th>
									<th style={{ 'width': '15%' }}>{t('FIO')}</th>
									<th style={{ 'width': '15%' }}>{t('phone')}</th>
									<th style={{ 'width': '15%' }}>{t('role')}</th>
									<th style={{ 'width': '40%' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.users?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item?.pos_name}</td>
										<td onClick={() => copyText(item.login)} title={item.login}>
											{item.first_name}
										</td>
										<td>{item?.phone}</td>
										<td>{item?.role_name}</td>
										<td></td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
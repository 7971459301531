import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import { TOGGLE_LEFT_MENU } from 'store/layout/actions'
import { GET, httpOk } from 'helpers/api'
import { formatDate, formatMoney, returnSign } from 'helpers/helpers';

function PlanVsFact() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const date = new Date()

	const types = [
		{ 'id': 1, 'name': t('sales') },
		{ 'id': 2, 'name': t('expenses') },
	]

	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		ids: [],
		activeTab: 1,
	})

	async function searchWithFilter(filterDataCopy) {
		var filterUrl = "/chain/report/plan-fact-sales"
		var urlParams = ""

		if (filterDataCopy.activeTab === 1) {
			filterUrl = "/chain/report/plan-fact-sales"
		}
		if (filterDataCopy.activeTab === 2) {
			filterUrl = "/chain/report/plan-fact-expenses"
		}

		if (filterDataCopy.ids)
			urlParams += returnSign(urlParams) + 'ids=' + filterDataCopy.ids
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		var response = await GET(filterUrl, {}, { loader: true })

		if (httpOk(response)) setData(response.data)
	}

	function handleCheckboxChangeAll(e) {
		var filterDataCopy = { ...filterData }

		filterDataCopy.ids = []
		if (e.target.checked) {
			for (let i = 0; i < poses.length; i++) {
				filterDataCopy.ids.push(poses[i]['id'])
			}
		}

		setFilterData(filterDataCopy)
	}

	function handleCheckboxChange(e, id) {
		var filterDataCopy = { ...filterData }

		if (e.target.checked) {
			filterDataCopy.ids.push(id)
		} else {
			filterDataCopy.ids = filterDataCopy.ids.filter(item => item !== id)
		}

		setFilterData(filterDataCopy)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		dispatch(TOGGLE_LEFT_MENU(false))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex gap-2">
				<div style={{ 'minWidth': '252px' }}>
					<div className="card mb-3" style={{ 'height': 'calc(100vh - 300px)', 'overflow': 'auto' }}>
						<div className="card-body">
							<h5 className="mb-3">{t('poses')}</h5>
							<div className="flex-column">
								<div className="form-check">
									<input id="all"
										className="form-check-input"
										type="checkbox"
										onChange={(e) => handleCheckboxChangeAll(e)}
									/>
									<label className="form-check-label" htmlFor="all">
										{t('all')}
									</label>
								</div>
								{poses?.map((item, index) => (
									<div className="form-check" key={index}>
										<input id={`pos${index}`} className="form-check-input" type="checkbox"
											checked={filterData?.ids?.includes(item.id)}
											onChange={(e) => handleCheckboxChange(e, item.id)}
										/>
										<label className="form-check-label" htmlFor={`pos${index}`}>
											{item.short_name}
										</label>
									</div>
								))}
							</div>
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<div>
								<div className="form-group">
									<label>{t('type')}</label>
									<Select
										options={types}
										value={types.find(option => option.id === filterData.activeTab) || ''}
										onChange={(option) => setFilterData({ ...filterData, 'activeTab': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group">
									<label>{t('date')}</label>
									<div className="calendar-input">
										<DatePicker
											showMonthYearPicker
											showFullMonthYearPicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={filterData.start_date ? new Date(filterData.start_date) : ''}
											onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
								<button className="btn btn-primary w-100"
									onClick={() => searchWithFilter(filterData)}>
									{t('apply')}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="card w-100" style={{ 'height': 'calc(100vh - 75px)', 'overflow': 'auto' }}>
					<div className="card-body">
						{filterData.activeTab === 1 &&
							<div className="table-resposive">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th style={{ 'width': '33px' }} className="text-center">#</th>
											<th>{t('pos')}</th>
											<th className="text-end">{t('sales')}</th>
											<th className="text-end">{t('plan')}</th>
											<th className="text-end">%</th>
											<th className="text-end">{t('income')}</th>
											<th className="text-end">{t('plan')}</th>
											<th className="text-end">%</th>
											<th className="text-end">{t('cheques')}</th>
											<th className="text-end">{t('plan')}</th>
											<th className="text-end">%</th>
											<th className="text-end">{t('products')}</th>
											<th className="text-end">{t('plan')}</th>
											<th className="text-end">%</th>
											<th className="text-end">{t('guests')}</th>
											<th className="text-end">{t('plan')}</th>
											<th className="text-end">%</th>
										</tr>
									</thead>
									<tbody>
										{data?.cheques_table?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{index + 1}</td>
												<td>{item.pos_name}</td>
												<td className="text-end text-nowrap">{formatMoney(item.total_sales)}</td>
												<td className="text-end text-nowrap">{formatMoney(item.plan_sales)}</td>
												<td className={`text-end text-nowrap text-white ${item.total_sales >= item.plan_sales ? 'bg-success' : 'bg-danger'}`}>
													<b>{formatMoney(item.fact_sales, 2)}</b>
												</td>
												<td className="text-end text-nowrap">{formatMoney(item.total_income)}</td>
												<td className="text-end text-nowrap">{formatMoney(item.plan_income)}</td>
												<td className={`text-end text-nowrap text-white ${item.total_income >= item.plan_income ? 'bg-success' : 'bg-danger'}`}>
													<b>{formatMoney(item.fact_income, 2)}</b>
												</td>
												<td className="text-end text-nowrap">{formatMoney(item.total_cheques)}</td>
												<td className="text-end text-nowrap">{formatMoney(item.plan_cheques)}</td>
												<td className={`text-end text-nowrap text-white ${item.total_cheques >= item.plan_cheques ? 'bg-success' : 'bg-danger'}`}>
													<b>{formatMoney(item.fact_cheques, 2)}</b>
												</td>
												<td className="text-end text-nowrap">{formatMoney(item.total_products)}</td>
												<td className="text-end text-nowrap">{formatMoney(item.plan_products)}</td>
												<td className={`text-end text-nowrap text-white ${item.total_products >= item.plan_products ? 'bg-success' : 'bg-danger'}`}>
													<b>{formatMoney(item.fact_products, 2)}</b>
												</td>
												<td className="text-end text-nowrap">{formatMoney(item.total_guests)}</td>
												<td className="text-end text-nowrap">{formatMoney(item.plan_guests)}</td>
												<td className={`text-end text-nowrap text-white ${item.total_guests >= item.plan_guests ? 'bg-success' : 'bg-danger'}`}>
													<b>{formatMoney(item.fact_guests, 2)}</b>
												</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td></td>
											<td><b>{t('total')}</b></td>
											<td className="text-end text-nowrap">
												<b>{formatMoney(data.overall_sales)}</b></td>
											<td className="text-end text-nowrap">
												<b>{formatMoney(data.overall_plan_sales)}</b></td>
											<td className={`text-end text-nowrap text-white ${data.overall_plan_percentage_sales >= 100 ? 'bg-success' : 'bg-danger'}`}>
												<b>{formatMoney(data.overall_plan_percentage_sales, 2)}</b>
											</td>
											<td className="text-end text-nowrap">
												<b>{formatMoney(data.overall_income)}</b></td>
											<td className="text-end text-nowrap">
												<b>{formatMoney(data.overall_plan_income)}</b></td>
											<td className={`text-end text-nowrap text-white ${data.overall_plan_percentage_income >= 100 ? 'bg-success' : 'bg-danger'}`}>
												<b>{formatMoney(data.overall_plan_percentage_income, 2)}</b>
											</td>
											<td className="text-end text-nowrap">
												<b>{formatMoney(data.overall_cheques)}</b></td>
											<td className="text-end text-nowrap">
												<b>{formatMoney(data.overall_plan_cheques)}</b></td>
											<td className={`text-end text-nowrap text-white ${data.overall_plan_percentage_cheques >= 100 ? 'bg-success' : 'bg-danger'}`}>
												<b>{formatMoney(data.overall_plan_percentage_cheques, 2)}</b>
											</td>
											<td className="text-end text-nowrap">
												<b>{formatMoney(data.overall_products)}</b></td>
											<td className="text-end text-nowrap">
												<b>{formatMoney(data.overall_plan_products)}</b></td>
											<td className={`text-end text-nowrap text-white ${data.overall_plan_percentage_products >= 100 ? 'bg-success' : 'bg-danger'}`}>
												<b>{formatMoney(data.overall_plan_percentage_products, 2)}</b>
											</td>
											<td className="text-end text-nowrap">
												<b>{formatMoney(data.overall_people_number)}</b></td>
											<td className="text-end text-nowrap">
												<b>{formatMoney(data.overall_plan_people_number)}</b></td>
											<td className={`text-end text-nowrap text-white ${data.overall_plan_percentage_people_number >= 100 ? 'bg-success' : 'bg-danger'}`}>
												<b>{formatMoney(data.overall_plan_percentage_people_number, 2)}</b>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						}
						{filterData.activeTab === 2 &&
							<div className="table-responsive">
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											<th rowSpan={2} style={{ width: '33px', height: '5rem' }} className="text-center">#</th>
											<th rowSpan={2} style={{ width: '20%' }}>{t('name2')}</th>
											{data?.poses?.map((item, index) => (
												<th key={index} colSpan={3} className="text-center">
													{item.short_name}
												</th>
											))}
											<th rowSpan={2} className="text-end">
												{t('total')} {t('fact')}
											</th>
											<th rowSpan={2} className="text-end">
												{t('total')} {t('plan')}
											</th>
											<th rowSpan={2} className="text-end">
												{t('total')} %
											</th>
										</tr>
										<tr>
											{data?.poses?.map((item, index) => (
												<Fragment key={index}>
													<th className="text-end text-nowrap">{t('fact')}</th>
													<th className="text-end text-nowrap">{t('plan')}</th>
													<th className="text-end text-nowrap">%</th>
												</Fragment>
											))}
										</tr>
									</thead>
									<tbody>
										{data?.payment_purpose_types?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{index + 1}</td>
												<td>{item.name}</td>
												{item?.poses?.length &&
													<Fragment>
														{item.poses.map((item2, index2) => (
															<Fragment key={index2}>
																<td className="text-end text-nowrap">
																	{formatMoney(item2.amount)}
																</td>
																<td className="text-end text-nowrap">
																	{formatMoney(item2.plan)}
																</td>
																<td className={`text-end text-nowrap text-white ${item2.plan_percentage <= 100 ? 'bg-success' : 'bg-danger'}`}>
																	{formatMoney(item2.plan_percentage, 2)}
																</td>
															</Fragment>
														))}
													</Fragment>
												}
												<td className="text-end text-nowrap">
													<b>{formatMoney(item.total_fact)}</b>
												</td>
												<td className="text-end text-nowrap">
													<b>{formatMoney(item.total_plan)}</b>
												</td>
												<td className={`text-end text-nowrap text-white ${item.total_plan_percentage <= 100 ? 'bg-success' : 'bg-danger'}`}>
													<b>{formatMoney(item.total_plan_percentage, 2)}</b>
												</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td></td>
											<td><b>{t('total')}</b></td>
											{data?.poses?.length > 0 &&
												<>
													{data?.poses?.map((item, index) => (
														<Fragment key={index}>
															<th className="text-end text-nowrap">
																{formatMoney(item.total_fact)}
															</th>
															<th className="text-end text-nowrap">
																{formatMoney(item.total_plan)}
															</th>
															<th className={`text-end text-nowrap text-white ${item.total_plan_percentage <= 100 ? 'bg-success' : 'bg-danger'}`}>
																{formatMoney(item.total_plan_percentage, 2)}
															</th>
														</Fragment>
													))}
												</>
											}
											<td className="text-end text-nowrap">
												<b>{formatMoney(data.overall_fact)}</b>
											</td>
											<td className="text-end text-nowrap">
												<b>{formatMoney(data.overall_plan)}</b>
											</td>
											<td className={`text-end text-nowrap text-white ${data.overall_percentage <= 100 ? 'bg-success' : 'bg-danger'}`}>
												<b>{formatMoney(data.overall_percentage)}</b>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default PlanVsFact
import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown, Modal, DropdownButton } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from "react-datepicker"

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { POST, PUT, GET, httpOk, FILE_D } from 'helpers/api'
import { formatDate, formatMoney, regexNumeric, regexPhoneNumber, returnSign } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [smsTemplates, setSmsTemplates] = useState([])
	const [modalSMS, setModalSMS] = useState({ 'bool': false, 'message': '' })
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({})
	const [pagination, setPagination] = useState({
		url: '/agents',
		response: null
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/agents"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.last_cheque_start)
			urlParams += returnSign(urlParams) + 'last_cheque_start=' + filterDataCopy.last_cheque_start
		if (filterDataCopy.last_cheque_end)
			urlParams += returnSign(urlParams) + 'last_cheque_end=' + filterDataCopy.last_cheque_end
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		filterDataCopy.urlParams = urlParams

		if (`/clients${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		if (filterDataCopy.sort) {
			if (filterDataCopy.tableSortKey === filterDataCopy.sort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = filterDataCopy.sort
			urlParams += returnSign(urlParams) + 'sort=' + filterDataCopy.sort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams


		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterData.url, 'response': response.data })
		}

		setFilterData(filterDataCopy)
		setDropdownIsOpen(false)
	}

	async function createUpdate(e) {
		e.preventDefault()

		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.phone = '998' + regexNumeric(selectedItemCopy.phone)

		var response;
		if (selectedItemCopy.id) {
			response = await PUT('/agents', selectedItemCopy, { loader: true })
		} else {
			response = await POST('/agents', selectedItemCopy, { loader: true })
		}

		if (httpOk(response)) {
			setShowModal(false)
		}
	}

	async function sendSms(e) {
		e.preventDefault();
		var filterDataCopy = { ...filterData }
		filterDataCopy.message = modalSMS.message
		filterDataCopy.sms_template_id = modalSMS.sms_template_id

		var response = await POST('/sms/send-agents', filterDataCopy, { loader: true })

		if (httpOk(response)) {
			toggleModalSMS(false)
		}
	}

	function toggleModal(bool, item = {}) {
		var itemCopy = { ...item }
		if (itemCopy.phone)
			itemCopy.phone = itemCopy.phone.substring(3, 12)

		setSelectedItem(item)
		setShowModal(bool)
	}

	async function toggleModalSMS(bool) {
		if (bool) {
			var response = await GET('/helper/sms-templates?sms_type_id=4')
			if (httpOk(response)) setSmsTemplates(response.data)
		}
		setModalSMS({ ...modalSMS, 'bool': bool, 'message': '' })
	}

	function resetFilter() {
		var filterDataCopy = {
			pos_id: null,
			start_date: null,
			end_date: null,
			search: '',
			random: filterData.random + 1
		}
		setFilterData(filterDataCopy)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.start_date === null &&
			filterData.end_date === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function downloadExcelFile() {
		var response = await FILE_D('/agents-excel')
		if (httpOk(response)) {
			// Создаем объект Blob из полученного файла
			const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Создаем ссылку для скачивания файла
			const fileUrl = URL.createObjectURL(file);

			// Создаем ссылку для скачивания файла в браузере
			const link = document.createElement('a');
			link.href = fileUrl;
			link.setAttribute('download', `${t('clients')}.xlsx`);
			document.body.appendChild(link);
			link.click();

			// Удаляем ссылку после скачивания файла
			URL.revokeObjectURL(fileUrl);
		}
	};

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page, filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('agents')} [{formatMoney(data?.total)}]
				</h4>
				<div className="d-flex gap-2">
					<DropdownButton title={t('action')}>
						<Dropdown.Item onClick={() => toggleModal(true)}>
							{t('create')}
						</Dropdown.Item>
						<Dropdown.Item onClick={() => toggleModalSMS(true)}>
							{t('sms_sending')}
						</Dropdown.Item>
						{/* <Dropdown.Item as="li" eventKey="1">
							<input id="actual-btn" hidden type="file" onChange={importFromExel} accept=".xlsx, application/vnd.ms-excel" />
							<label htmlFor="actual-btn" className="upload-from-excel">{t('import_clients')}</label>
						</Dropdown.Item>
						<Dropdown.Item as="li" eventKey="2">
							<a href="/clients_template.xlsx" className="upload-from-excel">
								{t('download_template')}
							</a>
						</Dropdown.Item> */}
					</DropdownButton>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('last_cheque')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.last_cheque_start ? new Date(filterData.last_cheque_start) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'last_cheque_start': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.last_cheque_end ? new Date(filterData.last_cheque_end) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'last_cheque_end': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button type="button" className="btn btn-outline-primary btn-wide-2"
									onClick={() => downloadExcelFile()}>
									<CustomTooltip text={t('EXCEL')}>
										<i className="uil uil-file-download-alt"></i>
									</CustomTooltip>
								</button>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.registration_date_start_date || filterData.registration_date_end) &&
									<span className="me-2">
										<b>{t('registered')}: </b>
										{filterData.registration_date_start_date &&
											<span>{formatDate(filterData.registration_date_start_date)}</span>
										}
										{(filterData.registration_date_start_date && filterData.registration_date_end) &&
											<span>|</span>
										}
										{filterData.registration_date_end &&
											<span>{formatDate(filterData.registration_date_end)}</span>
										}
									</span>
								}
								{(filterData.last_cheque_start || filterData.last_cheque_end) &&
									<span className="me-2">
										<b>{t('last_cheque')}: </b>
										{filterData.last_cheque_start &&
											<span>{formatDate(filterData.last_cheque_start)}</span>
										}
										{(filterData.last_cheque_start && filterData.last_cheque_end) &&
											<span>|</span>
										}
										{filterData.last_cheque_end &&
											<span>{formatDate(filterData.last_cheque_end)}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('name')}</th>
									<th style={{ 'width': '20%' }}>{t('phone')}</th>
									<th style={{ 'width': '10%' }}>{t('car_number')}</th>
									<th style={{ 'width': '10%' }}>{t('car_type')}</th>
									<th style={{ 'width': '30%' }}>{t('comment')}</th>
									<th style={{ 'minWidth': '35px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td>{item.first_name}</td>
										<td>{regexPhoneNumber(item.phone)}</td>
										<td>{item.car_number}</td>
										<td>{item.car_type}</td>
										<td>{item.note}</td>
										<td className="d-flex justify-content-center">
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false}
				centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('edit')}</>
							:
							<>{t('create')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="first_name"
								value={selectedItem.first_name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'first_name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('phone')}<span className="required-mark">*</span></label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone"
									value={selectedItem.phone}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'phone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('car_number')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="car_number"
								value={selectedItem.car_number}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'car_number': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('car_type')}</label>
							<input type="text" className="form-control" name="car_type"
								value={selectedItem.car_type}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'car_type': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('address')}</label>
							<input type="text" className="form-control" name="address"
								value={selectedItem.address}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'address': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								value={selectedItem.note}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL SMS */}
			<Modal show={modalSMS.bool} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModalSMS(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('create')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={sendSms} autoComplete="off">
						<div className="form-group">
							<label>{t('sms_templates')}</label>
							<Select
								options={smsTemplates}
								value={smsTemplates.find(option => option.id === modalSMS.sms_template_id) || ''}
								onChange={(option) => setModalSMS({ ...modalSMS, 'sms_template_id': option.id, 'message': option.text })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>

						<div className="form-group">
							<label>{t("description")}</label>
							<textarea className="form-control" rows="5"
								disabled
								value={modalSMS.message}>
							</textarea>
							<span>{t("letters")} - {modalSMS.message.length}</span>
						</div>

						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalSMS(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL SMS */}
		</>
	)
}

export default Index

import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DatePicker from "react-datepicker"
import 'react-loading-skeleton/dist/skeleton.css'

import { TOGGLE_LEFT_MENU } from 'store/layout/actions'
import { GET, httpOk } from 'helpers/api'
import { formatDate, formatMoney, returnSign } from 'helpers/helpers';

function Rating() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const date = new Date()

	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		ids: []
	})

	async function searchWithFilter(filterDataCopy) {
		//var filterDataCopy = { ...filterData }
		var filterUrl = "/chain/report/cheques-rating"
		var urlParams = ""

		if (filterDataCopy.ids)
			urlParams += returnSign(urlParams) + 'ids=' + filterDataCopy.ids
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
		}
	}

	function handleCheckboxChangeAll(e) {
		var filterDataCopy = { ...filterData }

		filterDataCopy.ids = []
		if (e.target.checked) {
			for (let i = 0; i < poses.length; i++) {
				filterDataCopy.ids.push(poses[i]['id'])
			}
		}

		setFilterData(filterDataCopy)
	}

	function handleCheckboxChange(e, id) {
		var filterDataCopy = { ...filterData }

		if (e.target.checked) {
			filterDataCopy.ids.push(id)
		} else {
			filterDataCopy.ids = filterDataCopy.ids.filter(item => item !== id)
		}

		setFilterData(filterDataCopy)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')

		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		dispatch(TOGGLE_LEFT_MENU(false))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex gap-3">
				<div style={{ 'minWidth': '252px' }}>
					<div className="card mb-3" style={{ 'height': 'calc(100vh - 230px)', 'overflow': 'auto' }}>
						<div className="card-body">
							<h5 className="mb-3">{t('poses')}</h5>
							<div className="flex-column">
								<div className="form-check">
									<input id="all"
										className="form-check-input"
										type="checkbox"
										onChange={(e) => handleCheckboxChangeAll(e)}
									/>
									<label className="form-check-label" htmlFor="all">
										{t('all')}
									</label>
								</div>
								{poses?.map((item, index) => (
									<div className="form-check" key={index}>
										<input id={`pos${index}`} className="form-check-input" type="checkbox"
											checked={filterData?.ids?.includes(item.id)}
											onChange={(e) => handleCheckboxChange(e, item.id)}
										/>
										<label className="form-check-label" htmlFor={`pos${index}`}>
											{item.short_name}
										</label>
									</div>
								))}
							</div>
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<div>
								<div className="form-group">
									<label>{t('period')}</label>
									<div className="calendar-input">
										<DatePicker
											showMonthYearPicker
											showFullMonthYearPicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={filterData.start_date ? new Date(filterData.start_date) : ''}
											onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
								<button className="btn btn-primary w-100"
									onClick={() => searchWithFilter(filterData)}>
									{t('apply')}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="w-100">
					<div className="card mb-3">
						<div className="card-body w-50">
							<h5 className="mb-2">{t('rating')}</h5>
							<div className="table-resposive">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th style={{ 'width': '20px' }}>#</th>
											<th>{t('pos')}</th>
											<th className="text-end text-danger">
												1<i className="uil uil-star"></i>
											</th>
											<th className="text-end text-danger">
												2<i className="uil uil-star"></i>
											</th>
											<th className="text-end text-danger">
												3<i className="uil uil-star"></i>
											</th>
											<th className="text-end text-danger">
												4<i className="uil uil-star"></i>
											</th>
											<th className="text-end text-success">
												5<i className="uil uil-star"></i>
											</th>
											<th className="text-end">{t('total')}</th>
											<th className="text-end">{t('average_rating')}</th>
										</tr>
									</thead>
									<tbody>
										{data?.poses?.map((item, index) => (
											<tr key={index}>
												<td>{index + 1}</td>
												<td>{item.short_name}</td>
												<td className="text-end">{item.count_rating_1}</td>
												<td className="text-end">{item.count_rating_2}</td>
												<td className="text-end">{item.count_rating_3}</td>
												<td className="text-end">{item.count_rating_4}</td>
												<td className="text-end">{item.count_rating_5}</td>
												<td className="text-end"><b>{item.count_rating}</b></td>
												<td className="text-end"><b>{item.avg_rating}</b></td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td></td>
											<td><b>{t('total')}</b></td>
											<td className="text-end"><b>{data.count_rating_1}</b></td>
											<td className="text-end"><b>{data.count_rating_2}</b></td>
											<td className="text-end"><b>{data.count_rating_3}</b></td>
											<td className="text-end"><b>{data.count_rating_4}</b></td>
											<td className="text-end"><b>{data.count_rating_5}</b></td>
											<td className="text-end"><b>{data.count_total_rating}</b></td>
											<td className="text-end"><b>{data.average_rating}</b></td>
										</tr>
										<tr>
											<td><b></b></td>
											<td><b>%</b></td>
											<td className="text-end"><b>{formatMoney(data.percentage_rating_1, 2)}</b></td>
											<td className="text-end"><b>{formatMoney(data.percentage_rating_2, 2)}</b></td>
											<td className="text-end"><b>{formatMoney(data.percentage_rating_3, 2)}</b></td>
											<td className="text-end"><b>{formatMoney(data.percentage_rating_4, 2)}</b></td>
											<td className="text-end"><b>{formatMoney(data.percentage_rating_5, 2)}</b></td>
											<td className="text-end"><b>100</b></td>
											<td className="text-end"><b>{formatMoney(data.percentage_average_rating, 2)}</b></td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	)
}

export default Rating
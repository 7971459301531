import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { POST, PUT, GET, httpOk } from 'helpers/api'
import { formatMoney, findKeyFromArrayByValue, returnSign } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const salaryTypes = [
		{ 'id': 3, 'name': t('salary') },
		{ 'id': 4, 'name': 'KPI' },
		{ 'id': 5, 'name': t('arbitary') },
		{ 'id': 1, 'name': t('schedule') },
		{ 'id': 2, 'name': t('hourly') },
	]

	const [data, setData] = useState([])
	const [sendData, setSendData] = useState({ 'id': 0, 'name': '' })
	const [poses, setPoses] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [pagination, setPagination] = useState({
		url: '/employee-positions',
		response: null
	})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: null,
		search: '',
	})

	async function createUpdate(e) {
		e.preventDefault();

		var response;
		if (sendData.id) {
			response = await PUT('/employee-positions', sendData, { loader: true })
		} else {
			response = await POST('/employee-positions', sendData, { loader: true })
		}

		if (httpOk(response)) {
			searchWithFilter()
			setShowModal(false)
		}
	}

	function openModal(item) {
		if (item.id) {
			setSendData(item)
		} else {
			setSendData({ 'id': 0, 'name': '' })
		}

		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function searchWithFilter() {
		var filterUrl = "/employee-positions"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/employee-positions${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('positions')} [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => openModal({})}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '20%' }}>{t('name2')}</th>
									<th style={{ 'width': '10%' }}>{t('salary_type')}</th>
									<th style={{ 'width': '40%' }}>{t('comment')}</th>
									<th style={{ 'width': '20%' }} className="text-end">{t('salary')}</th>
									<th style={{ 'width': '35px', 'minWidth': '35px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td>{item.pos_name}</td>
										<td>{item.name}</td>
										<td>{salaryTypes.find(e => e.id === item.salary_type_id)['name']}</td>
										<td>{item.note}</td>
										<td className="text-end">
											{(item.salary_type_id === 1 || item.salary_type_id === 2) &&
												<>
													{formatMoney(item.time_rate)} So'm/soat
												</>
											}
											{item.salary_type_id === 3 &&
												<>
													{formatMoney(item.salary)}
												</>
											}
											{(item.salary_type_id === 4 || item.salary_type_id === 5) &&
												0
											}
										</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item" onClick={() => openModal(item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{sendData?.id ?
							<>{t('edit')}</>
							:
							<>{t('create')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name"
								value={sendData.name}
								onChange={(e) => setSendData({ ...sendData, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('pos')}<span className="required-mark">*</span></label>
							<Select
								options={poses}
								value={poses.find(option => option.id === sendData.pos_id)}
								onChange={(option) => setSendData({ ...sendData, 'pos_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('choose_payment_type')}<span className="required-mark">*</span></label>
							<Select
								isDisabled={sendData?.id}
								options={salaryTypes}
								value={salaryTypes.find(option => option.id === sendData.salary_type_id)}
								onChange={(option) => setSendData({ ...sendData, 'salary_type_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						{(
							sendData.salary_type_id === 1 ||
							sendData.salary_type_id === 2
						) &&
							<div className="form-group">
								<label>{t('time_rate')}<span className="required-mark">*</span></label>
								<input type="number" className="form-control" name="time_rate"
									onChange={(e) => setSendData({ ...sendData, 'time_rate': e.target.value })} />
							</div>
						}
						{sendData.salary_type_id === 3 &&
							<div className="form-group">
								<label>{t('salary')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" name="salary"
									value={sendData.salary ? formatMoney(sendData.salary) : ''}
									onChange={(e) => setSendData({ ...sendData, 'salary': e.target.value.replace(/[^0-9.]/g, '') })} />
							</div>
						}
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index

import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import DatePicker from "react-datepicker"
import { Modal, Dropdown } from 'react-bootstrap'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, returnSign, formatDate, findKeyFromArrayByValue } from 'helpers/helpers'

function BalanceDetails({ filterDataParent, setFilterDataParent, poses }) {
	const { t } = useTranslation()
	const date = new Date()

	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: filterDataParent.pos_id,
		search: '',
	})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [selectedItem, setSelectedItem] = useState({});
	const [activeTab, setActiveTab] = useState(1);

	async function searchWithFilter() {
		var filterUrl = "/report-balance-details"
		var urlParams = ""
		var filterDataCopy = { ...filterData }

		urlParams += returnSign(urlParams) + 'element_id=' + filterDataParent.element_id
		if (filterDataParent.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataParent.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setSelectedItem(response.data)
		}

		setDropdownIsOpen(false)
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	function returnStyle(item) {
		var myClass = ""
		if (item.key === 'documents_in') {
			myClass = 'bg-success text-white'
			return myClass
		}
		if (item.key === 'inventory') {
			myClass = 'bg-warning text-white'
			return myClass
		}
		if (item.key === 'write_off') {
			myClass = 'bg-danger text-white'
			return myClass
		}
	}

	useEffect(() => {
		if (filterDataParent.modalProducts)
			searchWithFilter()
		if (!filterDataParent.modalProducts)
			setSelectedItem({})
	}, [filterDataParent.modalProducts]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{/* MODAL */}
			<Modal show={filterDataParent.modalProducts} animation={false} centered size="xl"
				onHide={() => setFilterDataParent({ ...filterDataParent, 'modalProducts': false })}>
				<Modal.Header closeButton>
					<Modal.Title>{t('information')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<>
						<div className="d-flex mb-3">
							<div className="d-flex flex-wrap gap-2">
								<div>
									<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
										<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
											{t('filter')}
										</Dropdown.Toggle>

										<Dropdown.Menu className="dropdown-filter">
											<div className="row">
												<div className="form-group col-md-6">
													<label>{t('period')}</label>
													<div className="calendar-input">
														<DatePicker
															className="form-control"
															dateFormat="dd.MM.yyyy"
															selected={filterData.start_date ? new Date(filterData.start_date) : ''}
															onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
														<i className="uil uil-calendar-alt"></i>
													</div>
												</div>
												<div className="form-group col-md-6">
													<label className="invisible">.</label>
													<div className="calendar-input">
														<DatePicker
															className="form-control"
															dateFormat="dd.MM.yyyy"
															selected={filterData.end_date ? new Date(filterData.end_date) : ''}
															onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
														<i className="uil uil-calendar-alt"></i>
													</div>
												</div>
											</div>
											<div className="row mt-3">
												<div className="col-md-12">
													<div className="d-flex justify-content-end gap-2">
														<button className="btn btn-secondary"
															onClick={() => setDropdownIsOpen(false)}>
															{t('cancel')}
														</button>
														<button className="btn btn-primary"
															onClick={() => searchWithFilter(false)}>
															{t('apply')}
														</button>
													</div>
												</div>
											</div>
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<button className={`btn ${activeTab === 1 ? 'btn-primary' : 'btn-secondary'}`}
									onClick={() => setActiveTab(1)}>
									{t('general')}
								</button>
								<button className={`btn ${activeTab === 2 ? 'btn-primary' : 'btn-secondary'}`}
									onClick={() => setActiveTab(2)}>
									{t('in_detail')}
								</button>
							</div>
						</div>

						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterDataParent.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterDataParent.pos_id, 'name')}
									</span>
								}
							</div>
						</div>
					</>
					{activeTab === 1 &&
						<div className="no-scroll" style={{ 'height': '500px', 'overflow': 'auto' }}>
							<h5 className="mb-2">{t('sales')}</h5>
							<table className="table table-bordered mb-3">
								<thead>
									<tr>
										<th style={{ 'width': '33px' }} className="text-center">#</th>
										<th>{t('name2')}</th>
										<th className="text-end text-nowrap">{t('quantity')}</th>
										<th className="text-start text-nowrap">{t('unit')}</th>
										<th className="text-end text-nowrap">{t('sales')}</th>
										<th className="text-end text-nowrap">{t('total')}</th>
									</tr>
								</thead>
								<tbody>
									{selectedItem?.products?.map((item, index) => (
										<tr key={index}>
											<td className="text-center">{index + 1}</td>
											<td>
												<Link className="text-primary cursor table-first-column-clickable"
													to={`/products/update/${filterData.pos_id}/${item.product_id}`}>
													{item.name}
												</Link>
											</td>
											<td className="text-end text-nowrap">
												{formatMoney(item.quantity, 3)}
											</td>
											<td>{item.unit_name}</td>
											<td className="text-end text-nowrap">
												{formatMoney(item.sales, 2)}
											</td>
											<td className="text-end text-nowrap">
												{formatMoney(item.spend_quantity, 3)}
											</td>
										</tr>
									))}
									<tr>
										<td></td>
										<td><b>{t('total')}</b></td>
										<td></td>
										<td></td>
										<td className="text-end text-nowrap">
											<b>{formatMoney(selectedItem.total_sales, 2)}</b>
										</td>
										<td className="text-end text-nowrap">
											<b>{formatMoney(selectedItem.total_sales_quantity, 2)}</b>
										</td>
									</tr>
								</tbody>
							</table>
							<h5 className="mb-2">{t('good_reception')}</h5>
							<table className="table table-bordered mb-3">
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '20%' }}>{t('date')}</th>
										<th style={{ 'width': '60%' }}></th>
										<th style={{ 'width': '20%' }} className="text-end text-nowrap">{t('income2')}</th>
									</tr>
								</thead>
								<tbody>
									{selectedItem?.document_in_elements?.map((item, index) => (
										<tr key={index}>
											<td className="text-center">{index + 1}</td>
											<td>{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</td>
											<td></td>
											<td className="text-end text-nowrap">{formatMoney(item.quantity, 2)}</td>
										</tr>
									))}
									<tr>
										<td></td>
										<td><b>{t('total')}</b></td>
										<td></td>
										<td className="text-end text-nowrap">
											<b>{formatMoney(selectedItem.total_income)}</b>
										</td>
									</tr>
								</tbody>
							</table>
							<h5 className="mb-2">{t('inventory')}</h5>
							<table className="table table-bordered mb-3">
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '20%' }}>{t('date')}</th>
										<th style={{ 'width': '60%' }}></th>
										<th style={{ 'width': '20%' }} className="text-end text-nowrap">{t('balance2')}</th>
									</tr>
								</thead>
								<tbody>
									{selectedItem?.inventory_elements?.map((item, index) => (
										<tr key={index}>
											<td className="text-center">{index + 1}</td>
											<td style={{ 'width': '20%' }}>{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</td>
											<th style={{ 'width': '60%' }}></th>
											<td style={{ 'width': '20%' }} className="text-end text-nowrap">{formatMoney(item.quantity, 2)}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					}

					{activeTab === 2 &&
						<div className="row" style={{ 'height': '500px' }}>
							<div className="table-responsive table-fixed modal-460">
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
											<th style={{ 'width': '20%' }}>{t('type')}</th>
											<th style={{ 'width': '20%' }}>{t('element')}</th>
											<th style={{ 'width': '20%' }}>{t('product')}</th>
											<th style={{ 'width': '10%' }} className="text-end text-nowrap">{t('quantity')}</th>
											<th style={{ 'width': '10%' }} className="text-end text-nowrap">{t('balance2')}</th>
											<th style={{ 'width': '20%' }} className="text-end text-nowrap">{t('date')}</th>
										</tr>
									</thead>
									<tbody>
										{selectedItem?.combined?.map((item, index) => (
											<tr key={index}>
												<td className={`text-center ${returnStyle(item)}`}>{index + 1}</td>
												<td>
													{item.key === 'inventory' &&
														<span>{t('inventory')}</span>
													}
													{item.key === 'sales' &&
														<span>{t('sales')}</span>
													}
													{item.key === 'documents_in' &&
														<span>{t('good_reception')}</span>
													}
												</td>
												<td className="td-ellipsis">{item.element_name}</td>
												<td className="td-ellipsis">{item.product_name}</td>
												<td className="text-end">{formatMoney(item.quantity, 3)}</td>
												<td className="text-end">{formatMoney(item.balance, 3)}</td>
												<td className="text-end">{formatDate(item.created_at, 'dd.MM.yy HH:mm:ss')}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					}
				</Modal.Body>
			</Modal >
			{/* MODAL */}
		</>
	)
}

export default BalanceDetails
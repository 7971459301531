import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { useTranslation } from 'react-i18next';

//Import Scrollbar
import { Nav } from 'react-bootstrap'

function SidebarContent() {
	const { t } = useTranslation()

	const [exportMenu, setexportMenu] = useState([
		{
			url: "/partner/dashboard",
			i18n: "dashboard",
			icon: "uil-chart-growth",
			active: false,
		},
		{
			url: "/partner/poses",
			i18n: "poses",
			icon: "uil-shop",
			active: false,
		},
		{
			url: "/partner/transactions",
			i18n: "payment_history",
			icon: "uil-history",
			active: false,
		},
	])

	function hadleChange(item) {
		var copyArr = [...exportMenu]
		for (let i = 0; i < copyArr.length; i++) {
			if (copyArr[i]['icon'] === item.icon) {
				copyArr[i]['active'] = true
			} else {
				copyArr[i]['active'] = false
			}
		}
		setexportMenu(copyArr)
	}

	return (
		<>
			<div className={`sidebar-menu-scroll box-shadow-none`}>
				<Nav className="nav-mini">
					{exportMenu.map((item, index) => (
						<Nav.Item key={index}>
							<NavLink className={`nav-link ${item.active ? 'active' : ''}`}
								to={item.url}
								onClick={() => hadleChange(item)}>
								<span className="fw-bold"><i className={item.icon}></i></span>
							</NavLink>
						</Nav.Item>
					))}
				</Nav>
				<div className="app-version">
					{t('version')}
					<br />
					<div className="text-center">
						{process.env.REACT_APP_VERSION}
					</div>
				</div>
			</div>
		</>
	)
}

export default SidebarContent
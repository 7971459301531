import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactApexChart from "react-apexcharts"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { GET, httpOk } from 'helpers/api'
import { formatMoney } from 'helpers/helpers';
import CustomTooltip from 'pages/parts/CustomTooltip'

function Index() {
	const { t } = useTranslation()

	const [topData, setTopData] = useState({})
	const [grossIncome, setGrossIncome] = useState({
		series: [{
			name: 'Валовый текущий',
			data: []
		}, {
			name: 'Валовый прошлый',
			data: []
		}],
		seriesCopy: [{
			name: 'Валовый текущий',
			data: []
		}, {
			name: 'Валовый прошлый',
			data: []
		}],
		options: {
			colors: ['#34c38f', '#f1b44c'],
			chart: {
				height: 350,
				type: 'bar',
				toolbar: {
					show: false
				},
				background: '#fff',
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						return formatMoney(value, 0);
					}
				},
			},
			xaxis: {
				type: 'datetime',
				labels: {
					format: 'dd',
				},
				tooltip: {
					enabled: false
				},
				categories: []
			},
			tooltip: {
				x: {
					format: 'dd.MM.yyyy'
				},
			},
		},
	})
	const [chequeTypes, setChequeTypes] = useState({
		series: [1, 1, 1, 1, 1, 1],
		options: {
			colors: ['#34c38f', '#f1b44c', '#f46a6a', '#5b73e8', '#6f42c1', '#74788d'],
			chart: {
				width: 380,
				type: 'pie',
				toolbar: {
					show: false
				},
			},
			labels: [t('hall'), t('takeaway'), t('delivery')],
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},

		},
	}
	)
	const [chartPieChequeTypes, setChartPieChequeTypes] = useState({
		series: [1, 1, 1, 1, 1, 1, 1],
		options: {
			colors: ['#34c38f', '#f1b44c', '#f46a6a', '#5b73e8', '#6f42c1', '#343a40', '#74788d'],
			chart: {
				width: 380,
				type: 'pie',
				toolbar: {
					show: false
				},
			},
			labels: [t('loyalty'), t('discount'), t('shortage'), t('no_profit'), t('debt'), t('agents'), t('no_service_percentage')],
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
		},
	})
	const [chartPieChequeTypesData, setChartPieChequeTypesData] = useState({});
	const [show] = useState(!false)
	const [loader, setLoader] = useState(true)
	const [grossIncomeFilter, setGrossIncomeFilter] = useState({
		incomeCurrent: true,
		incomeLast: true,
		show: true,
	})

	function handleGrossIncomeFilter(type, bool) {
		if (type === 1) {
			calculateGrossIncome(type, bool)
		}
		if (type === 2) {
			calculateGrossIncome(type, bool)
		}
	}

	function calculateGrossIncome(type, bool) {
		if (type === 1) {
			setGrossIncomeFilter({ ...grossIncomeFilter, 'show': false, 'incomeCurrent': bool })
		}
		if (type === 2) {
			setGrossIncomeFilter({ ...grossIncomeFilter, 'show': false, 'incomeLast': bool })
		}

		var grossIncomeCopy = { ...grossIncome }
		if (type === 1 && bool) {
			grossIncomeCopy.series[1]['data'] = grossIncomeCopy.seriesCopy[1]['data']
			setGrossIncome(grossIncomeCopy)
		}
		if (type === 1 && !bool) {
			grossIncomeCopy.series[1]['data'] = []
			setGrossIncome(grossIncomeCopy)
		}

		if (type === 2 && bool) {
			grossIncomeCopy.series[0]['data'] = grossIncomeCopy.seriesCopy[0]['data']
			setGrossIncome(grossIncomeCopy)
		}
		if (type === 2 && !bool) {
			grossIncomeCopy.series[0]['data'] = []
			setGrossIncome(grossIncomeCopy)
		}

		setTimeout(() => {
			if (type === 1) {
				setGrossIncomeFilter({ ...grossIncomeFilter, 'show': true, 'incomeCurrent': bool })
			}
			if (type === 2) {
				setGrossIncomeFilter({ ...grossIncomeFilter, 'show': true, 'incomeLast': bool })
			}
		}, 500);
	}

	async function getData() {
		const responseGrossIncome = await GET('/dashboard-main-income')
		await getTopData()
		await getChequeTypes()

		if (httpOk(responseGrossIncome)) {
			var grossIncomeCopy = { ...grossIncome }

			var now = new Date();
			var days = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();

			for (let i = 0; i < days; i++) {
				grossIncomeCopy.series[0]['data'].push(responseGrossIncome.data.incomeCurrent[i]['gross_income'])
				grossIncomeCopy.series[1]['data'].push(responseGrossIncome.data.incomeLast[i]['gross_income'])

				grossIncomeCopy.seriesCopy[0]['data'].push(responseGrossIncome.data.incomeCurrent[i]['gross_income'])
				grossIncomeCopy.seriesCopy[1]['data'].push(responseGrossIncome.data.incomeLast[i]['gross_income'])

				grossIncomeCopy.options.xaxis.categories.push(responseGrossIncome.data.incomeCurrent[i]['income_date'])
			}

			setGrossIncome(grossIncomeCopy)
			setLoader(false)
		}
	}

	async function getTopData() {
		const response = await GET('/dashboard-main-info')
		if (httpOk(response)) {
			setTopData(response.data)
			setChequeTypes({ ...chequeTypes, 'series': response.data.order_types.series })
		}
	}

	async function getChequeTypes() {
		const response = await GET('/dashboard-main-cheque-types')
		if (httpOk(response)) {
			setChartPieChequeTypes({ ...chartPieChequeTypes, 'series': response.data['series'] })
			setChartPieChequeTypesData(response.data['data'])
		}

	}

	useEffect(() => {
		getData()
		//setLoader(false)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{show &&
				<>
					{!loader &&
						<>
							<div className="row">
								<div className="col-md-3 mb-3">
									<div className="card h-100 bg-primary-gradient">
										<div className="px-3 pt-3 pb-2 pt-0">
											<div className="d-flex justify-content-between">
												<h6 className="mb-3 tx-12 text-white">{t('sales')}</h6>
												<div className="text-white">
													{topData.sales_percentage > 0 ?
														<CustomTooltip text={t('about_yesterday')}>
															<div className="nowrap">
																<i className="uil uil-arrow-up"></i>
																+{formatMoney(topData.sales_percentage)}%
															</div>
														</CustomTooltip>
														:
														<CustomTooltip text={t('about_yesterday')}>
															<div className="nowrap">
																<i className="uil uil-arrow-down"></i>
																{formatMoney(topData.sales_percentage)}%
															</div>
														</CustomTooltip>
													}
												</div>
											</div>
											<div className="d-flex text-white">
												<div className="w-75">
													<h4 className="text-nowrap text-white">
														<b>{formatMoney(topData.sales)}</b>
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="card h-100 bg-success-gradient">
										<div className="px-3 pt-3 pb-2 pt-0">
											<div className="d-flex justify-content-between">
												<h6 className="mb-3 tx-12 text-white">{t('gross_income')}</h6>
												<div className="text-white">
													{topData.income_percentage > 0 ?
														<CustomTooltip text={t('about_yesterday')}>
															<div className="nowrap">
																<i className="uil uil-arrow-up"></i>
																+{formatMoney(topData.income_percentage)}%
															</div>
														</CustomTooltip>
														:
														<CustomTooltip text={t('about_yesterday')}>
															<div className="nowrap">
																<i className="uil uil-arrow-down"></i>
																{formatMoney(topData.income_percentage)}%
															</div>
														</CustomTooltip>
													}
												</div>
											</div>
											<div className="d-flex text-white">
												<div className="w-75">
													<h4 className="text-nowrap text-white">
														<b>{formatMoney(topData.gross_income)}</b>
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="card h-100 bg-danger-gradient">
										<div className="px-3 pt-3 pb-2 pt-0">
											<div className="d-flex justify-content-between">
												<h6 className="mb-3 tx-12 text-white">{t('cheques')}</h6>
												<div className="text-white">
													{topData.count_cheque_percentage > 0 ?
														<CustomTooltip text={t('about_yesterday')}>
															<div className="nowrap">
																<i className="uil uil-arrow-up"></i>
																+{formatMoney(topData.count_cheque_percentage)}%
															</div>
														</CustomTooltip>
														:
														<CustomTooltip text={t('about_yesterday')}>
															<div className="nowrap">
																<i className="uil uil-arrow-down"></i>
																{formatMoney(topData.count_cheque_percentage)}%
															</div>
														</CustomTooltip>
													}
												</div>
											</div>
											<div className="d-flex text-white">
												<div className="w-75">
													<h4 className="text-nowrap text-white">
														<b>{formatMoney(topData.total_cheque)}</b>
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="card h-100 bg-warning-gradient">
										<div className="px-3 pt-3 pb-2 pt-0">
											<div className="d-flex justify-content-between">
												<h6 className="mb-3 tx-12 text-white">{t('average_check')}</h6>
												<div className="text-white">
													{topData.avg_cheque_percentage > 0 ?
														<CustomTooltip text={t('about_yesterday')}>
															<div className="nowrap">
																<i className="uil uil-arrow-up"></i>
																+{formatMoney(topData.avg_cheque_percentage)}%
															</div>
														</CustomTooltip>
														:
														<CustomTooltip text={t('about_yesterday')}>
															<div className="nowrap">
																<i className="uil uil-arrow-down"></i>
																{formatMoney(topData.avg_cheque_percentage)}%
															</div>
														</CustomTooltip>
													}
												</div>
											</div>
											<div className="d-flex text-white">
												<div className="w-75">
													<h4 className="text-nowrap text-white">
														<b>{formatMoney(topData.avg_cheque)}</b>
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-md-12">
									<div className="card">
										<div className="card-body">
											<div className="d-flex justify-content-between">
												<h4>{t('gross_income')}</h4>
												<div className="d-flex">
													<div
														className="dshbutton me-3"
														onClick={() => handleGrossIncomeFilter(1, !grossIncomeFilter.incomeCurrent)}
														style={{ 'opacity': grossIncomeFilter.incomeCurrent ? "1" : "0.5" }}>
														<span className="current"></span>
														<p>{t('current_month')}</p>
													</div>
													<div
														className="dshbutton me-3"
														onClick={() => handleGrossIncomeFilter(2, !grossIncomeFilter.incomeLast)}
														style={{ 'opacity': grossIncomeFilter.incomeLast ? "1" : "0.5" }}>
														<span className="last"></span>
														<p>{t('last_month')}</p>
													</div>
												</div>
											</div>
											<hr />
											{grossIncomeFilter.show ?
												<ReactApexChart options={grossIncome.options} series={grossIncome.series} type="bar" height={300} />
												:
												<Skeleton height={300} />
											}
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-5">
								<div className="col-md-4">
									<div className="card h-100">
										<div className="card-body">
											<div>
												<h4>{t('order_type')}</h4>
												<span>{t('quantity')}</span>
											</div>
											<div className="d-flex justify-content-center">
												<ReactApexChart options={chequeTypes.options} series={chequeTypes.series} type="pie" width={250} height={250} />
											</div>
											<div className="d-flex justify-content-between">
												<div className="d-flex">
													<div className="chart-legend-color bg-success"></div>
													{t('hall')}
												</div>
												<div>{formatMoney(topData.order_types.total_hall)} %</div>
											</div>
											<div className="d-flex justify-content-between">
												<div className="d-flex">
													<div className="chart-legend-color bg-warning"></div>
													{t('takeaway')}
												</div>
												<div>{formatMoney(topData.order_types.total_takeaway)} %</div>
											</div>
											<div className="d-flex justify-content-between">
												<div className="d-flex">
													<div className="chart-legend-color bg-danger"></div>
													{t('delivery')}
												</div>
												<div>{formatMoney(topData.order_types.total_delivery)} %</div>
											</div>
											<div className="d-flex justify-content-between">
												<div className="d-flex">
													<div className="chart-legend-color bg-primary"></div>
													{t('call_center')}
												</div>
												<div>0 %</div>
											</div>
											<div className="d-flex justify-content-between">
												<div className="d-flex">
													<div className="chart-legend-color bg-purple"></div>
													{t('telegram')}
												</div>
												<div>0 %</div>
											</div>
											<div className="d-flex justify-content-between">
												<div className="d-flex">
													<div className="chart-legend-color bg-secondary"></div>
													{t('website')}
												</div>
												<div>0 %</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="card h-100">
										<div className="card-body">
											<div className="mb-3">
												<h4>{t('top_products')}</h4>
												<span>{t('quantity')}</span>
											</div>
											<div className="d-flex w-100">
												<div style={{ 'width': '60%' }}># {t('name2')}</div>
												<div className="text-center" style={{ 'width': '20%' }}>{t('quantity')}</div>
												<div className="text-end" style={{ 'width': '20%' }}>{t('yesterday')}</div>
											</div>
											{topData?.top_products?.map((item, index) => (
												<div className="d-flex w-100 mb-2" key={index}>
													<div style={{ 'width': '60%' }}>{index + 1} {item.name}</div>
													<div className="text-center" style={{ 'width': '20%' }}>{formatMoney(item.today_total_quantity)}</div>
													<div className="text-end" style={{ 'width': '20%' }}>
														{item.percentage > 0 ?
															<div className="nowrap text-success">
																<i className="uil uil-arrow-growth"></i>
																{formatMoney(item.percentage)} %
															</div>
															:
															<div className="nowrap text-danger">
																<i className="uil uil-chart-down"></i>
																{formatMoney(item.percentage)} %
															</div>
														}
													</div>
												</div>
											))
											}
										</div>
									</div>
								</div>
								<div className="col-md-4">
									{loader ?
										<Skeleton height={450} />
										:
										<div className="card h-100">
											<div className="card-body">
												<div>
													<h4>{t('cheque_types')}</h4>
													<span>{t('quantity')}</span>
												</div>
												<div className="d-flex justify-content-center">
													<ReactApexChart
														type="pie" width={250} height={250}
														options={chartPieChequeTypes.options}
														series={chartPieChequeTypes.series}
													/>
												</div>
												<div className="d-flex justify-content-between">
													<div className="d-flex">
														<div className="chart-legend-color bg-success"></div>
														{t('loyalty')}
													</div>
													<div>{formatMoney(chartPieChequeTypesData.total_loyalty_percentage)} %</div>
												</div>
												<div className="d-flex justify-content-between">
													<div className="d-flex">
														<div className="chart-legend-color bg-warning"></div>
														{t('discount')}
													</div>
													<div>{formatMoney(chartPieChequeTypesData.total_discount_percentage)} %</div>
												</div>
												<div className="d-flex justify-content-between">
													<div className="d-flex">
														<div className="chart-legend-color bg-primary"></div>
														{t('no_profit')}
													</div>
													<div>{formatMoney(chartPieChequeTypesData.total_no_profit_percentage)} %</div>
												</div>
												<div className="d-flex justify-content-between">
													<div className="d-flex">
														<div className="chart-legend-color bg-purple"></div>
														{t('debt')}
													</div>
													<div>{formatMoney(chartPieChequeTypesData.total_debt_percentage)} %</div>
												</div>
												<div className="d-flex justify-content-between">
													<div className="d-flex">
														<div className="chart-legend-color bg-dark"></div>
														{t('agents')}
													</div>
													<div>{formatMoney(chartPieChequeTypesData.total_agent_percentage)} %</div>
												</div>
												<div className="d-flex justify-content-between">
													<div className="d-flex">
														<div className="chart-legend-color bg-secondary"></div>
														{t('no_service_percentage')}
													</div>
													<div>{formatMoney(chartPieChequeTypesData.total_no_service_percentage)} %</div>
												</div>
											</div>
										</div>
									}
								</div>
							</div>
						</>
					}
					{loader &&
						<>
							<div className="row mb-3">
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
							</div>

							<div className="row mb-3">
								<div className="col-md-12">
									<Skeleton height={300} />
								</div>
							</div>

							<div className="row mb-5">
								<div className="col-md-4">
									<Skeleton height={450} />
								</div>
								<div className="col-md-4">
									<Skeleton height={450} />
								</div>
								<div className="col-md-4">
									<Skeleton height={450} />
								</div>
							</div>
						</>
					}
				</>
			}
		</>
	)
}

export default Index

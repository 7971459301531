import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'

import { GET, FILE_D, httpOk } from 'helpers/api'
import { returnSign, findKeyFromArrayByValue, formatDate } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const date = new Date()
	const reduxAccount = useSelector(state => state.account)

	const statuses = [
		{ "id": 0, "name": t('hall') },
		{ "id": 1, "name": t('takeaway') },
		{ "id": 2, "name": t('delivery') },
		{ "id": 3, "name": t('telegram') }
	]

	const [poses, setPoses] = useState([])
	const [menu, setMenu] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		'start_date': formatDate(new Date(date.getFullYear(), 0, 1), 'yyyy-MM-dd'),
		'pos_id': reduxAccount.pos_id,
		'search': '',
	})
	const [data, setData] = useState([])

	async function searchWithFilter() {
		var filterUrl = "/report-menu2"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.menu_id)
			urlParams += returnSign(urlParams) + 'menu_id=' + filterData.menu_id
		if (filterData.order_type)
			urlParams += returnSign(urlParams) + 'order_type=' + filterData.order_type
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (`/report-menu${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams
		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	async function downloadExcelFile() {
		var filterUrl = "/menu2-excel"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.order_type)
			urlParams += returnSign(urlParams) + 'order_type=' + filterData.order_type
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		filterUrl = filterUrl + urlParams
		var response = await FILE_D(filterUrl)
		if (httpOk(response)) {
			// Создаем объект Blob из полученного файла
			const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Создаем ссылку для скачивания файла
			const fileUrl = URL.createObjectURL(file);

			// Создаем ссылку для скачивания файла в браузере
			const link = document.createElement('a');
			link.href = fileUrl;
			link.setAttribute('download', `${t('menu')}.xlsx`);
			document.body.appendChild(link);
			link.click();

			// Удаляем ссылку после скачивания файла
			URL.revokeObjectURL(fileUrl);
		}
	};

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			pos_id: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	async function getMenu() {
		const response = await GET('/helper/menu')
		if (httpOk(response)) {
			setMenu(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		getMenu()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('menu')} [{data?.length}]
				</h4>
			</div>

			<div className="card mb-3">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('menu')}</label>
												<Select
													options={menu}
													value={menu.find(option => option.id === filterData.menu_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'menu_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('year')}</label>
												<div className="calendar-input">
													<DatePicker
														showYearPicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													options={statuses}
													value={statuses.find(option => option.id === filterData.order_type) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'order_type': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary" onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2"
										onClick={() => downloadExcelFile()}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date) &&
									<span className="me-2">
										<b>{t('year')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{(filterData.order_type) &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.order_type, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('menu')}</th>
									<th style={{ 'width': '20%' }}>{t('name3')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('january')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('february')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('march')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('april')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('may')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('june')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('july')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('august')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('september')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('october')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('november')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('december')}</th>
									<th className="text-end">{t('total')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.menu_name}</td>
										<td>{item.product_name}</td>
										<td className="text-end">{item.january}</td>
										<td className="text-end">{item.february}</td>
										<td className="text-end">{item.march}</td>
										<td className="text-end">{item.april}</td>
										<td className="text-end">{item.may}</td>
										<td className="text-end">{item.june}</td>
										<td className="text-end">{item.july}</td>
										<td className="text-end">{item.august}</td>
										<td className="text-end">{item.september}</td>
										<td className="text-end">{item.october}</td>
										<td className="text-end">{item.november}</td>
										<td className="text-end">{item.december}</td>
										<td className="text-end"><b>{item.total_quantity}</b></td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import Rightbar from './Rightbar'
import { GET, PUT, httpOk } from 'helpers/api'
import { formatDate, returnSign, findKeyFromArrayByValue, copyText } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const ratings = [
		{ "id": 1, "name": 1 },
		{ "id": 2, "name": 2 },
		{ "id": 3, "name": 3 },
		{ "id": 4, "name": 4 },
		{ "id": 5, "name": 5 },
	]

	const solutions = [
		{ "id": 5, "name": "Ало" },
		{ "id": 4, "name": "Коникарли" },
		{ "id": 3, "name": "Коникарсиз" },
		{ "id": 2, "name": "Мижоз норози" },
		{ "id": 1, "name": "Мижозни йукотдик" },
	]

	const orderTypes = [
		{ "id": 2, "name": t('takeaway') },
		{ "id": 3, "name": t('delivery') },
	]

	const orderDeliveryTypes = [
		{ "id": 1, "name": t('pos') },
		{ "id": 2, "name": t('telegram') },
		{ "id": 3, "name": t('call_center') },
		{ "id": 4, "name": t('website') },
		{ "id": 5, "name": t('ANDROID') },
		{ "id": 6, "name": t('IOS') }
	]

	const [poses, setPoses] = useState([])
	const [modal, setModal] = useState(false)
	const [showRightBar, setShowRightBar] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: -1,
		random: 0,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/cheques-rating',
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/cheques-rating"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.id)
			urlParams += returnSign(urlParams) + 'id=' + filterDataCopy.id
		if (filterDataCopy.rating)
			urlParams += returnSign(urlParams) + 'rating=' + filterDataCopy.rating
		if (filterDataCopy.order_type)
			urlParams += returnSign(urlParams) + 'order_type=' + filterDataCopy.order_type
		if (filterDataCopy.order_type_delivery)
			urlParams += returnSign(urlParams) + 'order_type_delivery=' + filterDataCopy.order_type_delivery
		if (filterDataCopy.cheque_type)
			urlParams += returnSign(urlParams) + 'cheque_type=' + filterDataCopy.cheque_type
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		filterDataCopy.urlParams = urlParams

		if (`/cheques-rating${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		if (filterDataCopy.sort) {
			if (filterDataCopy.tableSortKey === filterDataCopy.sort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = filterDataCopy.sort
			urlParams += returnSign(urlParams) + 'sort=' + filterDataCopy.sort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterData.url, 'response': response.data })
		}

		setFilterData(filterDataCopy)
		setDropdownIsOpen(false)
	}

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...data.data]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}
		if (boolean) {
			const response = await GET(`/cheque-params/${item.cheque_id}`, {}, { loader: true })

			if (httpOk(response)) {
				dataCopy[index]['products'] = response.data.products
				dataCopy[index]['payments'] = response.data.payments
				dataCopy[index]['showSidebar'] = boolean

				setSelectedItem(dataCopy[index])
				setShowRightBar(boolean)
			}

		} else {
			setShowRightBar(boolean)
			setSelectedItem({})
		}
	}

	function toggleModal(bool, item = {}) {
		if (bool) {
			setModal(bool)
			setSelectedItem(item)
		} else {
			setModal(bool)
		}
	}

	async function solution(e) {
		e.preventDefault();

		var sendData = {
			"id": selectedItem.id,
			"cheque_id": selectedItem.cheque_id,
			"solution": selectedItem.solution,
			"solution_rating": selectedItem.solution_rating,
		}
		var response = await PUT('/cheques-rating', sendData, { loader: true })

		if (httpOk(response)) {
			toggleModal(false)
			searchWithFilter()
		}
	}

	function resetFilter() {
		var filterDataCopy = {
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: null,
			status: null,
			cashier_login: null,
			search: '',
			random: filterData.random + 1
		}
		setFilterData(filterDataCopy)
	}

	async function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.id === null &&
			filterData.rating === null &&
			filterData.start_date === null &&
			filterData.end_date === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		var response = await GET('/helper/poses')
		if (httpOk(response)) {
			response.data.unshift({ 'id': -1, 'name': t('all') })
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page, filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('check_evaluation')} [{data?.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('rating')}</label>
												<Select
													options={ratings}
													value={ratings.find(option => option.id === filterData.rating) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'rating': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('order_type')}</label>
												<Select
													isClearable
													options={orderTypes}
													value={orderTypes.find(option => option.id === filterData.order_type) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'order_type': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('delivery_type')}</label>
												<Select
													isClearable
													options={orderDeliveryTypes}
													value={orderDeliveryTypes.find(option => option.id === filterData.order_type_delivery) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'order_type_delivery': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('cheque')} ID</label>
												<input type="text" className="form-control"
													onChange={(e) => setFilterData({ ...filterData, 'id': e.target.value })} />
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.id &&
									<span className="me-2">
										<b>ID: </b> {filterData.id}
									</span>
								}
								{(filterData.order_type) &&
									<span className="me-2">
										<b>{t('rating')}: </b>
										{findKeyFromArrayByValue(ratings, 'id', filterData.rating, 'name')}
									</span>
								}
								{(filterData.order_type) &&
									<span className="me-2">
										<b>{t('order_type')}: </b>
										{findKeyFromArrayByValue(orderTypes, 'id', filterData.order_type, 'name')}
									</span>
								}
								{(filterData.order_type_delivery) &&
									<span className="me-2">
										<b>{t('delivery_type')}: </b>
										{findKeyFromArrayByValue(orderDeliveryTypes, 'id', filterData.order_type_delivery, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={() => resetFilter()}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered table-hover mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '15%' }}>{t('pos')}</th>
									<th style={{ 'width': '10%' }}>{t('cheque')} ID</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('type')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('order')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('rating')}</th>
									<th style={{ 'width': '20%' }}>{t('note')}</th>
									<th style={{ 'width': '20%' }}>{t('conclusion')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr className={`cursor ${item.showSidebar ? 'table-tr-active' : ''}`} key={index}>
										<td className="text-center"
											onClick={() => toggleRightbar(true, item, index)}>
											<CustomTooltip text={item.id}>
												{item.row_number}
											</CustomTooltip>
										</td>
										<td onClick={() => toggleRightbar(true, item, index)}>
											{item.pos_name}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)}>
											{item.cheque_id}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)} className="text-center">
											{item.order_type === 2 &&
												<span className="bg-success bg-border text-white">
													{t('takeaway')}
												</span>
											}
											{item.order_type === 3 &&
												<span className="bg-warning bg-border text-white">
													{t('delivery')}
												</span>
											}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)} className="text-center">
											{item.order_type_delivery === 1 &&
												<span>{t('pos')}</span>
											}
											{item.order_type_delivery === 2 &&
												<span>{t('telegram')}</span>
											}
											{item.order_type_delivery === 3 &&
												<span>{t('call_center')}</span>
											}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)} className="text-center">
											{formatDate(item.created_at, 'dd.MM.yy HH:mm')}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)} className="text-center text-nowrap">
											{(item.rating === 5) &&
												<span className="bg-success bg-border text-white">
													<span>{item.rating}</span>
												</span>
											}
											{(item.rating === 4 || item.rating === 3) &&
												<span className="bg-warning bg-border text-white">
													<span>{item.rating}</span>
												</span>
											}
											{(item.rating === 2 || item.rating === 1) &&
												<span className="bg-danger bg-border text-white">
													<span>{item.rating}</span>
												</span>
											}
										</td>
										<td className="td-ellipsis"
											onClick={() => copyText(item.note)}
											title={item.note}>
											{item.note}
										</td>
										<td className="td-ellipsis"
											onClick={() => copyText(item.note)}
											title={item.solution}>
											{item.solution}
										</td>
										<td>
											<div className="table-action-button table-action-primary-button"
												onClick={() => toggleModal(true, item)}>
												<i className="uil-clipboard-notes"></i>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL SOLUTION */}
			<Modal show={modal} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('solution')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={solution} autoComplete="off">
						<div className="form-group">
							<label>{t('rating')}</label>
							<Select
								options={solutions}
								value={solutions.find(option => option.id === selectedItem.solution_rating) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'solution_rating': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t("note")}</label>
							<textarea className="form-control"
								style={{ 'resize': 'none' }}
								name="solution"
								rows="3"
								value={selectedItem.solution ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'solution': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL SOLUTION */}

			<Rightbar
				showRightBar={showRightBar}
				setShowRightBar={setShowRightBar}
				selectedItem={selectedItem}>
			</Rightbar>
		</>
	)
}

export default Index

import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

function CustomTooltip({ children, text, params = "text" }) {
	function returnData(key) {
		switch (key) {
			case 'text':
				return <>{text}</>
			case 'image':
				return <div style={{
					'width': '70px',
					'height': '70px',
					'display': 'flex',
					'alignItems': 'center',
				}}>
					<img
						style={{ 'maxWidth': '100%' }}
						src={`https://backend.mison.uz/uploads/products/${text}`} alt={text}
					/>
				</div>
			default:
				return <>{text}</>
		}
	}
	return (
		<>
			<OverlayTrigger
				overlay={
					text ?
						<Tooltip id={text}>
							{returnData(params)}
						</Tooltip>
						:
						<span></span>
				}
				// trigger="click"
				placement="top"
				delayShow={300}
				delayHide={150}
			>
				<div className="custom-tooplip">
					{children}
				</div>
			</OverlayTrigger>
		</>
	)
}

export default CustomTooltip
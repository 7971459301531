import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx'

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, httpOk } from 'helpers/api'
import { formatDate, formatMoney, returnSign, findKeyFromArrayByValue, arrayJoin } from "helpers/helpers"
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const date = new Date()
	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: -1,
		status: 1,
		ids: [],
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '',
		response: null
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/service/documents-orders"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		if (`/service/documents-orders${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams
		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function exportExcel() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/service/documents-orders-excel"
		var urlParams = ""

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.ids.length)
			urlParams += returnSign(urlParams) + 'ids=' + arrayJoin(filterDataCopy.ids, false)

		filterUrl = filterUrl + urlParams
		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			response = response.data
			// all
			var excelHeadersAll = [[
				'#',
				t('name2'),
				t('quantity'),
				t('unit'),
				t('price'),
				t('total'),
			]]

			var totalAmountAll = 0
			for (let i = 0; i < response?.all?.length; i++) {
				var item = response.all[i]
				var excelItemsAll = []
				excelItemsAll.push(i + 1)
				excelItemsAll.push(item.name)
				excelItemsAll.push(Number(item.quantity))
				excelItemsAll.push(item.unit_name)
				excelItemsAll.push(Number(item.price))
				excelItemsAll.push(Number(item.total_price))
				excelHeadersAll.push(excelItemsAll)
				totalAmountAll += Number(item.total_price)
			}
			excelHeadersAll.push(['', t('total'), '', '', '', totalAmountAll])
			excelHeadersAll.push(['', '', '', '', '', ''])
			excelHeadersAll.push(['', '', '', '', '', ''])
			excelHeadersAll.push(['', t('note'), '', '', '', ''])
			for (let i = 0; i < response?.orders?.length; i++) {
				if (response?.orders[i]['note'])
					excelHeadersAll.push(['', `${response?.orders[i]['short_name']}: ${response?.orders[i]['note']}`, '', '', '', ''])
			}

			const wsAll = XLSX.utils.aoa_to_sheet(excelHeadersAll);
			var wchAll = [
				{ wch: 3 },
				{ wch: 30 },
				{ wch: 10 },
				{ wch: 10 },
				{ wch: 15 },
				{ wch: 15 },
			];
			wsAll['!cols'] = wchAll;
			// all

			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, wsAll, t('all'));

			for (let i = 0; i < response?.poses?.length; i++) {
				var totalAmountPos = 0
				var excelHeadersPos = [[
					'#',
					t('name2'),
					t('quantity'),
					t('unit'),
					t('price'),
					t('total'),
				]]

				for (let j = 0; j < response.poses[i]['elements'].length; j++) {
					var element = response.poses[i]['elements'][j]
					var excelItemsPos = []
					excelItemsPos.push(j + 1)
					excelItemsPos.push(element.name)
					excelItemsPos.push(Number(element.quantity))
					excelItemsPos.push(element.unit_name)
					excelItemsPos.push(Number(element.price))
					excelItemsPos.push(Number(element.total_price))
					excelHeadersPos.push(excelItemsPos)
					totalAmountPos += Number(element.total_price)
				}
				excelHeadersPos.push(['', t('total'), '', '', '', totalAmountPos])

				var wsPos = XLSX.utils.aoa_to_sheet(excelHeadersPos);
				var wchPos = [
					{ wch: 3 },
					{ wch: 30 },
					{ wch: 10 },
					{ wch: 15 },
					{ wch: 15 },
				];
				wsPos['!cols'] = wchPos;
				XLSX.utils.book_append_sheet(wb, wsPos, response.poses[i]['short_name']);
			}
			XLSX.writeFile(wb, t('orders') + ".xlsx");
		}

	}

	async function exportExcelOne(id) {
		var filterUrl = `/service/documents-orders-one-excel?id=${id}`

		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			response = response.data
			var excelHeaders = []

			excelHeaders.push([t('document_from'), response.created_at, '', '', '', ''])
			excelHeaders.push([t('pos'), response.pos_name, '', '', '', ''])
			excelHeaders.push([t('external_pos'), response.external_pos_name, '', '', '', ''])
			excelHeaders.push([t('document'), response.document_number, '', '', '', ''])
			excelHeaders.push([t('note'), response.note, '', '', '', ''])
			excelHeaders.push([
				'#',
				t('name2'),
				t('quantity'),
				t('unit'),
				t('price'),
				t('total'),
			])
			excelHeaders.push(['', '', '', '', '', ''])

			for (let i = 0; i < response?.elements.length; i++) {
				var item = response?.elements[i]
				var excelItems = []
				excelItems.push(i + 1)
				excelItems.push(item.name)
				excelItems.push(Number(item.quantity))
				excelItems.push(item.unit_name)
				excelItems.push(Number(item.price))
				excelItems.push(Number(item.total_price))
				excelHeaders.push(excelItems)
			}
			excelHeaders.push([t('total'), '', response.overall.overall_quantity, '', '', response.overall.overall_amount])

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('orders_another_pos') + ".xlsx");
		}
	}

	function handleCheckboxChangeAll(e) {
		var filterDataCopy = { ...filterData }

		filterDataCopy.ids = []
		if (e.target.checked) {
			for (let i = 0; i < data.data.length; i++) {
				filterDataCopy.ids.push(data.data[i]['id'])
			}
		}

		setFilterData(filterDataCopy)
	}

	function handleCheckboxChange(e, id) {
		var filterDataCopy = { ...filterData }

		if (e.target.checked) {
			filterDataCopy.ids.push(id)
		} else {
			filterDataCopy.ids = filterDataCopy.ids.filter(item => item !== id)
		}

		setFilterData(filterDataCopy)
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: filterData.pos_id ?? '',
			ids: [],
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (filterData.search === '') {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		var response = await GET('/helper/integration-access-poses', {}, { loader: true })
		if (httpOk(response)) {
			if (reduxAccount.role === "ROLE_OWNER") response.data.unshift({ 'id': -1, 'name': t('all') })
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex">
				<h4 className="vertical-center">
					{t('orders_another_pos')} [{data.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							{reduxAccount.role === "ROLE_OWNER" &&
								<div>
									<CustomTooltip text={t('EXCEL')}>
										<button className="btn btn-outline-primary btn-wide-2"
											onClick={() => exportExcel()}>
											<i className="uil uil-file-download-alt"></i>
										</button>
									</CustomTooltip>
								</div>
							}
						</div>
						<div className="vertical-center">
							<h4><b>{t('amount')}:</b> {formatMoney(data?.total_amount)}</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '22px' }}>
										<input type="checkbox"
											onChange={(e) => handleCheckboxChangeAll(e)} />
									</th>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '25%' }}>{t('external_pos')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('document')}</th>
									<th style={{ 'width': '30%' }} className="text-center">{t('note')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="vertical-middle">
											<input type="checkbox"
												checked={filterData?.ids?.includes(item.id)}
												onChange={(e) => handleCheckboxChange(e, item.id)} />
										</td>
										<td className="text-center">{item.row_number}</td>
										<td className="td-ellipsis">{item.pos_name}</td>
										<td className="td-ellipsis">{item.external_pos_name}</td>
										<td className="td-ellipsis text-center">{item.document_number}</td>
										<td className="td-ellipsis" title={item.note}>{item.note}</td>
										<td className="text-nowrap text-center">{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_amount)}</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<Link className="table-dropdown-item"
															to={`/service/idocuments-orders/preview/${item.id}`}>
															<i className="uil-eye"></i>
															{t('preview')}
														</Link>
														<div className="table-dropdown-item"
															onClick={() => exportExcelOne(item.id)}>
															<i className="uil uil-file-download-alt"></i>
															EXCEL
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>
		</>
	)
}

export default Index
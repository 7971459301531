import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import { GET, httpOk } from 'helpers/api'
import { copyText, formatMoney, returnSign } from 'helpers/helpers'

function Index({ filterData }) {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [modalDetail, setModalDetail] = useState(false)
	const [overall, setOverall] = useState({})

	async function searchWithFilter() {
		var filterUrl = "/report-cashiers"
		var urlParams = ""

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.cashier_id)
			urlParams += returnSign(urlParams) + 'cashier_id=' + filterData.cashier_id
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data.data)
			setOverall(response.data.overall)
		}
	}

	function toggleModalDetail(item) {
		setSelectedItem(item)
		setModalDetail(true)
	}

	useEffect(() => {
		if (filterData) {
			searchWithFilter()
		}
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<h5 className="mb-3">{t('cashier_report')}</h5>

					<div className="table-responsive">
						<table className="table table-bordered table-hover mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('cashier')}</th>
									<th style={{ 'width': '5%' }}>KPI %</th>
									<th style={{ 'width': '25%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end">{t('cheques')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('products')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('sale_amount')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('gross_income')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('bonus')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="cursor" key={index} onClick={() => toggleModalDetail(item)}>
										<td className="text-center">{index + 1}</td>
										<td className="td-ellipsis"
											onClick={() => copyText(item.cashier_login)}
											title={item.cashier_login}>
											{item.first_name}
										</td>
										<td>
											{formatMoney(item.bonus_percentage)}%
											{item.waiter_percent_bonus &&
												<span className="ms-2">{item.waiter_percent_bonus}%</span>
											}
										</td>
										<td></td>
										<td className="text-end">{item.count_cheque}</td>
										<td className="text-end">{formatMoney(item.count_products)}</td>
										<td className="text-end">{formatMoney(item.total_amount)}</td>
										<td className="text-end">{formatMoney(item.gross)}</td>
										<td className="text-end">{formatMoney(item.bonus)}</td>
									</tr>
								))}
								{data.length > 0 &&
									<tr>
										<td></td>
										<td><b>{t('total')}</b></td>
										<td></td>
										<td></td>
										<td className="text-end text-nowrap"><b>{formatMoney(overall.cheques_overall)}</b></td>
										<td className="text-end text-nowrap"><b>{formatMoney(overall.products_overall)}</b></td>
										<td className="text-end text-nowrap"><b>{formatMoney(overall.sales_overall)}</b></td>
										<td className="text-end text-nowrap"><b>{formatMoney(overall.gross_overall)}</b></td>
										<td className="text-end text-nowrap"><b>{formatMoney(overall.bonus_overall)}</b></td>
									</tr>
								}
							</tbody>
						</table>
					</div>

				</div>
			</div>

			<Modal show={modalDetail} animation={false} size="lg" centered onHide={() => setModalDetail(false)}>
				<Modal.Body>
					<h3 className="mb-3">{selectedItem.first_name}</h3>
					<div className="table-responsive inventory-modal-height">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<td>#</td>
									<th>{t('name2')}</th>
									<th className="text-end">{t('price')}</th>
									<th className="text-end">{t('sale_price')}</th>
									<th className="text-end">{t('quantity')}</th>
									<th className="text-end">{t('income')}</th>
									<th className="text-end">{t('bonus')}</th>
								</tr>
							</thead>
							<tbody>
								{selectedItem?.products?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}</td>
										<td>{item.name}</td>
										<td className="text-end">{formatMoney(item.price)}</td>
										<td className="text-end">{formatMoney(item.sale_price)}</td>
										<td className="text-end">{formatMoney(item.total_quantity)}</td>
										<td className="text-end">{formatMoney((item.sale_price - item.price) * item.total_quantity)}</td>
										<td className="text-end">{formatMoney(item.bonus)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Index
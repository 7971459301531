import React, { useEffect, useState, useRef, Fragment } from 'react'
import { map } from "lodash"
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'; // eslint-disable-line no-unused-vars

import { POST, httpOk } from 'helpers/api';

function Index() {
	const socketRef = useRef(null)

	const [data, setData] = useState([{}])
	const [user, setUser] = useState({
		'login': '',
		'pos_id': '',
		'token': '',
		'type': 'select',
		'query': 'select * from cheques order by id desc limit 200'
	})

	async function connectSocket() {
		const response = await POST('/admin/users-token', { 'login': user.login }, { loader: true })
		if (httpOk(response)) setUser({ ...user, 'token': response.data.data })

		var wsHost;
		var authEndpoint;
		var isProd;

		if (process.env.NODE_ENV === 'production') {
			wsHost = process.env.REACT_APP_PROD_WSHOST
			authEndpoint = process.env.REACT_APP_PROD_BROADCAST_AUTH
			isProd = true
		} else {
			wsHost = process.env.REACT_APP_DEV_WSHOST
			authEndpoint = process.env.REACT_APP_DEV_BROADCAST_AUTH
			isProd = false
		}

		var echo
		try {
			echo = new Echo({
				broadcaster: 'pusher',
				key: "mison_key",
				wsHost: wsHost,
				wsPort: 6001,
				wssPort: 6001,
				enabledTransports: ['ws', 'wss'],
				disableStats: true,
				encrypted: isProd ? true : false,
				useTLS: isProd ? true : false,
				forceTLS: isProd ? true : false,
				authEndpoint: authEndpoint,
				auth: {
					headers: {
						'Authorization': `Bearer ${response.data}`
					}
				},
			});
		} catch (error) {
			console.log(error)
		}
		socketRef.current = echo

		echo.connector.pusher.connection.bind('connected', () => {
			console.log('connected');
		});

		echo.connector.pusher.connection.bind('disconnected', () => {
			console.log('disconnected');
		});

		echo.private(`user.${user.pos_id}`)
			.listen('Connection', (payload) => {
				console.log('payload', payload)
				if (payload.type === 'sqlQueryResult') {
					if (payload.data.length > 0)
						setData(payload.data)
				}
			});
	}

	async function sqlQuery() {
		const response = await POST('/admin/sql-query',
			{
				'pos_id': user.pos_id,
				'type': user.type,
				'query': user.query
			}, { loader: true })
		if(httpOk(response)) setUser({ ...user, 'token': response.data.data })
	}

	useEffect(() => {
		return () => {
			if (socketRef.current) {
				socketRef?.current?.leave(`user.${user.pos_id}`)
			}
		}
	}, [])  // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					SQL
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-md-3">
							<div className="form-group">
								<label>Логин</label>
								<input type="text" className="form-control" name="login"
									value={user.login} autoComplete="off"
									onChange={(e) => setUser({ ...user, 'login': e.target.value })} />
							</div>
							<div className="form-group">
								<label>pos ID</label>
								<input type="text" className="form-control" name="login"
									value={user.pos_id} autoComplete="off"
									onChange={(e) => setUser({ ...user, 'pos_id': e.target.value })} />
							</div>

							<button type="button" className="btn btn-primary w-100 mb-2"
								onClick={() => connectSocket()}>
								Соедениться
							</button>

							<div className="form-group">
								<label>Query</label>
								<input type="text" className="form-control" name="query"
									value={user.query} autoComplete="off"
									onChange={(e) => setUser({ ...user, 'query': e.target.value })} />
							</div>
							<button type="button" className="btn btn-primary w-100 mb-2"
								onClick={() => sqlQuery()}>
								Отправить запрос
							</button>
						</div>
						<div className="col-md-9">
							<div className="table-responsive">
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											{map(Object.keys(data[0]), key => (
												<th key={key}>
													{key}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{data?.map((item, index) => (
											<Fragment key={index}>
												<tr>
													{map(Object.keys(data[index]), key => (
														<td>{data[index][key]}</td>
													))}
												</tr>
											</Fragment>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
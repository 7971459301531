import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, regexPhoneNumber } from "helpers/helpers"

function Payment() {
	const { t } = useTranslation()

	const [data, setData] = useState([])

	async function getData() {
		const response = await GET('/pos', {}, { loader: true })
		if (httpOk(response)) setData(response)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('payment')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th>ID {t('name3')}</th>
									<th>{t('address')}</th>
									<th>{t('phone')}</th>
									<th className="text-center text-nowrap">{t('balance')}</th>
									<th className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{item.id}. {item.name}</td>
										<td>{item.address}</td>
										<td>{regexPhoneNumber(item.phone)}</td>
										<td className="text-center">
											{item.balance >= 0 ?
												<span className="bg-primary bg-border text-white text-nowrap">{formatMoney(item.balance)}</span>
												:
												<span className="bg-danger bg-border text-white text-nowrap">{formatMoney(item.balance)}</span>
											}
										</td>
										<td className="d-flex justify-content-center">
											<form method="POST" action="https://checkout.paycom.uz/" target="_blank">
												<input type="hidden" name="merchant" value="64254d266420371977b5d395" autoComplete="off" />
												<input type="hidden" name="amount" autoComplete="off" value="50000000" />
												<input type="hidden" name="account[id]" autoComplete="off" value="1" />
												<input type="hidden" name="callback" value="https://cabinet.mison.uz/payment" autoComplete="off" />
												<div>
													<button type="submit" className="btn payment-button payme">Payme</button>
												</div>
											</form>
											<form action="https://my.click.uz/services/pay" id="click_form" method="get" target="_blank">
												<input type="hidden" name="amount" value="20000" />
												<input type="hidden" name="merchant_id" value="14673" />
												<input type="hidden" name="merchant_user_id" value="28924" />
												<input type="hidden" name="service_id" value="25532" />
												<input type="hidden" name="transaction_param" value="1" />
												<input type="hidden" name="merchant_trans_id" value="1" />
												<input type="hidden" name="return_url" value="https://cabinet.mison.uz/payment" />
												<div>
													<button type="submit" className="btn payment-button click">Click</button>
												</div>
											</form>

											

											<button className="btn payment-button uzum">Uzum</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div >
		</>
	)
}

export default Payment
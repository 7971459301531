import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import Tree from './Tree'

import { GET, POST, PUT, httpOk } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [dataDuplicate, setDataDuplicate] = useState([])
	const [selectedItem, setSelectedItem] = useState([])
	const [showModal, setShowModal] = useState(false)

	async function createUpdate(e) {
		e.preventDefault()

		var response;
		if (selectedItem.id) {
			response = await PUT('/admin/permissions', selectedItem, { loader: true })
		} else {
			response = await POST('/admin/permissions', selectedItem, { loader: true })
		}

		if (httpOk(response)) {
			getData()
			toggleModal(false)
		}
	}

	function toggleModal(bool = false, item = {}) {
		setSelectedItem(item)
		setShowModal(bool)
	}

	function makeTree(list) {
		var map = {}
		var node = []
		var roots = []
		var i;

		for (i = 0; i < list.length; i += 1) {
			map[list[i]['id']] = i; // initialize the map
			list[i]['children'] = []; // initialize the children
		}

		for (i = 0; i < list.length; i += 1) {
			node = list[i];
			if (node.parent_id) {
				// if you have dangling branches check that map[node.parent_id] exists
				list[map[node.parent_id]]['children'].push(node);
			} else {
				roots.push(node);
			}
		}
		return roots;
	}

	function selectItem(item) {
		var elements = document.getElementsByClassName(`child-${item.id}`);
		var selectedElements = document.getElementsByClassName('step-selected');

		for (let i = 0; i < selectedElements.length; i++) {
			selectedElements[i]['classList'].remove('step-selected');
		}

		elements[0]['classList'].add('step-selected')
		toggleModal(true, item)
	}

	async function getData() {
		var response = await GET('/admin/permissions', {}, { loader: true })

		if (httpOk(response)) {
			//setData([...response.data])

			for (let i = 0; i < response.data.length; i++) {
				response.data[i]['selected'] = false;
				response.data[i]['show'] = true;
			}
			var sortedTree = makeTree(response.data);
			setData(sortedTree);
		}

		response.data.unshift({ 'id': null, 'name': t('main') })
		if (httpOk(response)) {
			setDataDuplicate([...response.data])
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('categories')} [{data.length}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card"
				style={{
					'minHeight': 'calc(100vh - 120px)',
					'overflow': 'auto'
				}}>
				<div className="card-body">
					<div className="d-flex flex-column w-50">
						<Tree
							children={data}
							selectItem={selectItem}>
						</Tree>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name3')}</label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('name3')} RU</label>
							<input type="text" className="form-control" name="name_ru"
								value={selectedItem.name_ru ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name_ru': e.target.value })} />
						</div>
						<div className="form-check">
							<input id="view" className="form-check-input" type="checkbox"
								checked={selectedItem?.all}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'view': e.target.checked })}
							/>
							<label className="form-check-label" htmlFor="view">
								Просмотр
							</label>
						</div>

						<div className="form-check">
							<input id="create" className="form-check-input" type="checkbox"
								checked={selectedItem?.all}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'create': e.target.checked })}
							/>
							<label className="form-check-label" htmlFor="create">
								Создать
							</label>
						</div>

						<div className="form-check">
							<input id="update" className="form-check-input" type="checkbox"
								checked={selectedItem?.all}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'update': e.target.checked })}
							/>
							<label className="form-check-label" htmlFor="update">
								Редактировать
							</label>
						</div>

						<div className="form-check">
							<input id="delete" className="form-check-input" type="checkbox"
								checked={selectedItem?.all}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'delete': e.target.checked })}
							/>
							<label className="form-check-label" htmlFor="delete">
								Удалить
							</label>
						</div>

						<div className="form-group">
							<label>{t('category')}</label>
							<Select
								options={dataDuplicate}
								value={dataDuplicate?.find(option => option.id === selectedItem.parent_id) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'parent_id': option.id })}
								placeholder=""
								noOptionsMessage={() => ""}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal()}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GUESTPOST } from 'helpers/api'
import { regexLogin } from "helpers/helpers"

import bgLogin from "assets/images/bg_login.png";

function ForgotPassword() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [user, setUser] = useState({ "login": "", "otp": "", "password": "" })
	const [show, setShow] = useState(false)

	async function sendConfirmationCode() {
		const response = await GUESTPOST('/send-confirmation-code', user, { loader: true })
		if (response) {
			setShow(true)
		}
	}

	async function changePassword() {
		const response = await GUESTPOST('/change-password', user, { loader: true })
		if (response) {
			toast.success(t('data_succesfully_changed'))
			navigate("/auth/login")
		}
	}

	return (
		<>
			<div className="authentication-bg">
				<div className="auth-card">
					<div className="d-flex justify-content-center">
						<div className="form-wrapper">
							<form className="login-form"
								autoComplete="off">
								<div style={{ fontSize: '35px', color: '#fff' }}>
									{t('recover_your_password')}
								</div>

								<div className="form-group w-100">
									<label style={{ color: '#fff' }}>{t('login')}</label>
									<input className="form-control"
										name="login"
										autoFocus
										value={user.login}
										onChange={(e) => setUser({ ...user, 'login': regexLogin(e.target.value) })} />
								</div>

								{show ?
									<>
										<div className="form-group w-100">
											<label style={{ color: '#fff' }}>{t('code')}</label>
											<input className="form-control"
												autoComplete="off"
												name="otp"
												value={user.otp}
												onChange={(e) => setUser({ ...user, 'otp': e.target.value })} />
										</div>

										<div className="form-group w-100">
											<label style={{ color: '#fff' }}>{t('new_password')}</label>
											<input className="form-control"
												autoComplete="off"
												name="password"
												value={user.password}
												onChange={(e) => setUser({ ...user, 'password': e.target.value })} />
										</div>

										<div className="text-center">
											<button type="button" className="btn btn-dark w-100"
												onClick={changePassword}>
												{t('change_password')}
											</button>
										</div>
									</>
									:
									<div className="text-center">
										<button type="button" className="btn btn-dark w-100"
											onClick={sendConfirmationCode}>
											{t('get_activation_code')}
										</button>
									</div>
								}

								<div className="mt-2 text-center">
									<Link to="/auth/login" className="fw-medium text-white">{t('logging_in')}</Link>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div className="login-about">
					{t('contact_centre')}: +998 55 500-00-89 <br />
					{t('contact_centre')}: +998 99 803-50-34 <br />
					{t('made_by_as')} <br />
					{t('made_by_as2')}
				</div>
			</div>

			<div className="authentication-bg d-none">
				<div className="authentication-left"
					style={{
						backgroundImage: `url(${bgLogin})`,
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat'
					}}>
				</div>
				<div className="authentication-right"
					style={{ backgroundColor: `#11234A` }}>
					<div className="auth-card">
						<div className="w-100">
							<form className="login-form"
								autoComplete="off"
								onSubmit={() => { }}>
								<div style={{ fontSize: '35px', color: '#fff' }}>
									{t('recover_your_password')}
								</div>

								<input className="login-input"
									autoComplete="off"
									autoFocus
									name="login"
									value={user.login}
									placeholder={t('login')}
									onChange={(e) => setUser({ ...user, 'login': regexLogin(e.target.value) })} />

								{show ?
									<>
										<input className="login-input"
											autoComplete="off"
											name="otp"
											value={user.otp}
											placeholder={t('code')}
											onChange={(e) => setUser({ ...user, 'otp': e.target.value })} />

										<input className="login-input"
											autoComplete="off"
											name="password"
											value={user.password}
											placeholder={t('new_password')}
											onChange={(e) => setUser({ ...user, 'password': e.target.value })} />

										<div className="text-center">
											<button type="button" className="login-button"
												onClick={changePassword}>
												{t('change_password')}
											</button>
										</div>
									</>
									:
									<div className="text-center">
										<button type="button" className="login-button"
											onClick={sendConfirmationCode}>
											{t('get_activation_code')}
										</button>
									</div>
								}

								<div className="mt-2 text-center">
									<Link to="/auth/login" className="fw-medium text-white">{t('logging_in')}</Link>
								</div>
							</form>
						</div>
					</div>

					<div className="login-about">
						{t('contact_centre')}: +998 55 500-00-89 <br />
						{t('contact_centre')}: +998 99 803-50-34 <br />
						{t('made_by_as')} <br />
						{t('made_by_as2')}
					</div>
				</div>
			</div>

			<div className="authentication-bg d-none">
				<div className="account-pages h-100 vertical-center">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-md-8 col-lg-6 col-xl-4">

								<div className="auth-card">
									<div className="text-center my-2">
										<h3 className="text-white">{t('recover_your_password')}</h3>
									</div>
									<input className="login-input"
										autoComplete="off"
										autoFocus
										name="login"
										value={user.login}
										placeholder={t('login')}
										onChange={(e) => setUser({ ...user, 'login': regexLogin(e.target.value) })} />

									{show ?
										<>
											<input className="login-input"
												autoComplete="off"
												name="otp"
												value={user.otp}
												placeholder={t('code')}
												onChange={(e) => setUser({ ...user, 'otp': e.target.value })} />

											<input className="login-input"
												autoComplete="off"
												name="password"
												value={user.password}
												placeholder={t('new_password')}
												onChange={(e) => setUser({ ...user, 'password': e.target.value })} />

											<div className="text-center">
												<button type="button" className="login-button"
													onClick={changePassword}>
													{t('change_password')}
												</button>
											</div>
										</>
										:
										<div className="text-center">
											<button type="button" className="login-button"
												onClick={sendConfirmationCode}>
												{t('get_activation_code')}
											</button>
										</div>
									}

									<div className="mt-2 text-center">
										<Link to="/auth/login" className="fw-medium text-white">{t('logging_in')}</Link>
									</div>
								</div>
							</div>
						</div>
					</div>

					<ul className="circles">
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default ForgotPassword

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { TOGGLE_LEFT_MENU } from 'store/layout/actions'
import { GET, httpOk } from 'helpers/api'

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	//const [data, setData] = useState({})
	const [poses, setPoses] = useState([])

	async function getPoses() {
		const response = await GET('/helper/poses', {}, { loader: true })
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		dispatch(TOGGLE_LEFT_MENU(false))
		getPoses()
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex gap-2">
				<div style={{ width: '20%' }}>
					<div className="card">
						<div className="card-body">
							<h5>{t('poses')}</h5>
							<div className="flex-column">
								{poses.map((item, index) => (
									<span key={index}>{item.name}</span>
								))}
							</div>
						</div>
					</div>
				</div>
				<div style={{ width: '80%' }}>
					<div className="card">
						<div className="card-body">
							1
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
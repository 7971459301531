import React, { useState, useEffect, useRef, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, findKeyFromArrayByValue, returnSign, formatDate } from "helpers/helpers"

function Expense() {
	const { t } = useTranslation()
	const date = new Date()

	const filterRef = useRef({ 'mounted': 1, 'pos_id': '' });

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterData, setFilterData] = useState({
		date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		pos_id: null
	})

	async function searchWithFilter() {
		var filterUrl = "/expense-analysis"
		var urlParams = ""
		checkFilter()

		var filterDataCopy = { ...filterData }
		if (filterRef.current.mounted === 1) {
			filterDataCopy = filterRef.current
		}

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.date)
			urlParams += returnSign(urlParams) + 'date=' + filterDataCopy.date

		if (`/expense-analysis${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)

			setDropdownIsOpen(false)
			filterRef.current.mounted = 2
		}

	}

	function resetFilter() {
		setFilterData({
			pos_id: null
		})
		searchWithFilter()
		setFilterDataExist(false)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/helper/poses', {}, { loader: true })
		if (httpOk(response)) {
			setPoses(response.data)
			if (response.data.length > 0) {
				setFilterData({
					...filterData,
					'pos_id': response.data[0]['id'],
					'date': formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd')
				})
				filterRef.current.pos_id = response.data[0]['id']
				filterRef.current.date = formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd')
				searchWithFilter()
			}
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('expense_analysis')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id, 'plan': option.plan })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														showMonthYearPicker
														showFullMonthYearPicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.date ? new Date(filterData.date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.date &&
									<span className="me-2">
										<b>{t('date')}: </b>
										{filterData.date &&
											<span>{formatDate(filterData.date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b> {findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '60%' }}>{t('expense_type')}</th>
									<th className="text-end" style={{ 'width': '10%' }}>{t('plan')}</th>
									<th className="text-end" style={{ 'width': '10%' }}>{t('fact')}</th>
									<th className="text-end" style={{ 'width': '10%' }}>{t('difference')}</th>
									<th className="text-end" style={{ 'width': '10%' }}>{t('done')}%</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td className="text-end">{formatMoney(item.plan)}</td>
										<td className="text-end">{formatMoney(item.fact)}</td>
										<td className="text-end">
											{Number(item.difference) >= 0 ?
												<span className="text-success">{formatMoney(item.difference)}</span>
												:
												<span className="text-danger">{formatMoney(item.difference)}</span>
											}
										</td>
										<td className="text-end">
											{Number(item.done) >= 100 ?
												<span className="text-danger">{formatMoney(item.done)}</span>
												:
												<span className="text-success">{formatMoney(item.done)}</span>
											}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default Expense
import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import { GET, POST, httpOk } from 'helpers/api'
import { formatMoney } from 'helpers/helpers'

function Return() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [poses, setPoses] = useState([])
	const [wms, setWms] = useState([])
	const [elements, setElements] = useState([])
	const [data, setData] = useState({
		elements: [],
		pos_id: null,
		note: '',
		total_amount: 0,
	})

	async function createUpdate(e) {
		e.preventDefault();
		var dataCopy = { ...data }
		dataCopy.error = false

		if (dataCopy.elements.length === 0) {
			toast.error(t('add_elements'))
			return
		}

		for (let i = 0; i < dataCopy.elements.length; i++) {
			if (!dataCopy.elements[i]['quantity']) {
				dataCopy.error = true
			}

			if (Number(dataCopy.elements[i]['quantity']) > Number(dataCopy.elements[i]['wms_balance'])) {
				dataCopy.error = true
			}
		}

		var valueArr = dataCopy.elements.map(function (item) { return item.element_id });
		var isDuplicate = valueArr.some(function (item, idx) {
			return valueArr.indexOf(item) !== idx
		});

		if (isDuplicate) {
			toast.error(t('identical_elements'))
			return
		}

		if (dataCopy.error) {
			toast.error(t('check_filled_inputs'))
			return
		}

		const response = await POST('/wms-transfer-return', dataCopy, { loader: true })

		if (httpOk(response)) {
			navigate('/wms-transfer')
		}
	}

	function addToList() {
		var dataCopy = JSON.parse(JSON.stringify(data))
		var element = {
			'price': '',
			'quantity': '',
			'element_id': ''
		}

		dataCopy.elements.push(element)
		countTotalAmount(dataCopy)

		setTimeout(() => {
			const byId = document.getElementById(dataCopy.elements.length)
			byId.select()
		}, 200);

	}

	function deleteItem(index) {
		var dataCopy = JSON.parse(JSON.stringify(data))
		dataCopy.elements.splice(index, 1)

		setData(dataCopy)
	}

	function countTotalAmount(dataCopy) {
		dataCopy.total_amount = 0
		for (let i = 0; i < dataCopy.elements.length; i++) {
			dataCopy.total_amount += Number(dataCopy.elements[i]['quantity']) * Number(dataCopy.elements[i]['price'])
		}

		setData(dataCopy)
	}

	function handleSelect(option, index) {
		var dataCopy = { ...data }

		dataCopy.elements[index]['element_id'] = option.id
		dataCopy.elements[index]['name'] = option.name
		dataCopy.elements[index]['wms_balance'] = option.wms_balance
		dataCopy.elements[index]['wms_section_balance'] = option.wms_section_balance
		dataCopy.elements[index]['average_price'] = option.average_price
		dataCopy.elements[index]['unit_name'] = option.unit_name

		setData(dataCopy)
	}

	function handleInputChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.elements[index][e.target.name] = e.target.value
		countTotalAmount(dataCopy)
	}

	async function getPoses() {
		const response = await GET('/helper/poses', {}, { loader: true })
		if (response) {
			setPoses(response)
			if (response.length === 1) {
				setData({ ...data, 'pos_id': response[0]['id'] })
			}
		}
	}

	async function getWms(posId) {
		const response = await GET(`/helper/wms?pos_id=${posId}`, {}, { loader: true })

		if (httpOk(response)) setWms(response.data)
	}

	async function getProducts(wmsSectionId) {
		const response = await GET(`/wms-transfer-return-elements
		?pos_id=${data.pos_id}
		&wms_id=${data.wms_id}
		&wms_section_id=${wmsSectionId}`,
			{}, { loader: true })
		if (httpOk(response)) {
			setElements(response.data)
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getWms(data.pos_id)
	}, [data.pos_id]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getProducts(data.wms_section_id)
	}, [data.wms_section_id]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('return')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
			</div>

			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('pos')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={data.elements.length}
										options={poses}
										value={poses.find(option => option.id === data.pos_id)}
										onChange={(option) => setData({ ...data, 'pos_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2 d-none">
								<div className="form-group">
									<label>{t('warehouse')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={data.elements.length}
										options={wms}
										value={wms.find(option => option.id === data.wms_id)}
										onChange={(option) => setData({ ...data, 'wms_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('note')}</label>
									<input type="text" className="form-control" name="note"
										value={data.note}
										onChange={(e) => setData({ ...data, 'note': e.target.value })} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<button type="button" className="btn btn-primary btn-rounded mb-2"
							disabled={!data.wms_section_id}
							onClick={() => addToList()}>
							{t('add')}
						</button>
						<div className="table-responsive">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th>#</th>
										<th>{t('name3')}</th>
										<th className="text-center">{t('quantity')}</th>
										<th className="text-center">{t('unit')}</th>
										<th className="text-center">{t('average_price')}</th>
										<th className="text-center">{t('wms_balance')}</th>
										<th className="text-center">{t('section_balance')}</th>
										<th className="text-end"></th>
									</tr>
								</thead>
								<tbody>
									{data.elements.map((item, index) => (
										<tr key={index}>
											<td>{index + 1}</td>
											<td>
												<div style={{ 'width': '300px' }}>
													<Select
														inputId={index + 1}
														options={elements}
														value={data.elements.find(option => option.element_id === data.elements[index]['element_id'])}
														onChange={(option) => handleSelect(option, index)}
														menuPosition="fixed"
														placeholder=""
														noOptionsMessage={() => t('list_empty')}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>
												</div>
											</td>
											<td>
												<div className="d-flex justify-content-center">
													<input type="number" className={`auto-width-input ${(!item.quantity || Number(item.quantity) > item.wms_balance) ? 'required-border' : ''}`}
														name="quantity"
														value={item.quantity}
														onChange={(e) => handleInputChange(e, index)}
														onKeyUp={(event) => {
															if (event.key === 'Enter') {
																addToList()
															}
														}} />
												</div>
											</td>
											<td className="text-center vertical-middle">
												{item.unit_name}
											</td>
											<td className="text-center vertical-middle">
												{formatMoney(item.average_price)}
											</td>
											<td className="text-center vertical-middle">
												{formatMoney(item.wms_balance)}
											</td>
											<td className="text-center vertical-middle">
												{formatMoney(item.wms_section_balance)}
											</td>
											<td className="vertical-middle">
												<div className="d-flex justify-content-center">
													<div className="table-action-button table-action-danger-button"
														onClick={() => deleteItem(index)}>
														<i className="uil uil-times"></i>
													</div>
												</div>
											</td>
										</tr>
									))
									}
								</tbody>
							</table>
						</div>

						<div className="btn-wrapper">
							<Link className="btn btn-warning btn-rounded btn-wide me-2" to="/wms-transfer" tabIndex="-1">
								{t('cancel')}
							</Link>
							<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
								onClick={createUpdate}>
								{t('save')}
							</button>
						</div>
					</div>
				</div>
			</form>
		</>
	)
}

export default Return
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import { GET, PUT, httpOk } from 'helpers/api'
import { regexPhoneNumber, returnSign, findKeyFromArrayByValue, copyText } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const statusTypes = [
		{ 'id': 1, 'name': t('works') },
		{ 'id': -2, 'name': t('fired') }
	]

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [userRoles, setUserRoles] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [modalFireUser, setModalFireUser] = useState(false)
	const [modalRestore, setModalRestore] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: reduxAccount.pos_id,
		search: '',
	})

	async function fireUser(e) {
		e.preventDefault();

		var sendData = { ...selectedItem }
		sendData.status = -2
		var response = await PUT('/employee-status', sendData, { loader: true })

		if (httpOk(response)) {
			searchWithFilter()
			toggleModalFire(false)
		}
	}

	async function restoreUser(e) {
		e.preventDefault();

		var sendData = { ...selectedItem }
		sendData.status = 1
		var response = await PUT('/employee-status', sendData, { loader: true })

		if (httpOk(response)) {
			searchWithFilter()
			setModalRestore()
		}
	}

	async function searchWithFilter() {
		var filterUrl = "/employees"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.role)
			urlParams += returnSign(urlParams) + 'role=' + filterData.role
		if (filterData.status)
			urlParams += returnSign(urlParams) + 'status=' + filterData.status
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (`/employees${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			status: null,
			role: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.status === null &&
			filterData.role === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	function toggleModalFire(bool, item = {}) {
		if (bool) {
			setSelectedItem(item)
			setModalFireUser(true)
		} else {
			setSelectedItem({})
			setModalFireUser(false)
		}
	}

	function toggleModalRestore(bool, item = {}) {
		if (bool) {
			setSelectedItem(item)
			setModalRestore(true)
		} else {
			setSelectedItem({})
			setModalRestore(false)
		}
	}

	async function getPoses() {
		const response = await GET('/helper/poses-head')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getUserRoles() {
		const response = await GET('/helper/user-roles')
		if (httpOk(response)) setUserRoles(response.data)
	}

	useEffect(() => {
		getPoses()
		getUserRoles()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('staff')} [{data?.length}]
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/employees/create">
						{t('create')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('role')}</label>
												<Select
													options={userRoles}
													value={userRoles.find(option => option.role === filterData.role) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'role': option.role })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.role}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													options={statusTypes}
													value={statusTypes.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.role &&
									<span className="me-2">
										<b>{t('role')}: </b>
										{findKeyFromArrayByValue(userRoles, 'role', filterData.role, 'name')}
									</span>
								}
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statusTypes, 'id', filterData.status, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }} className="text-nowrap">{t('pos')}</th>
									<th style={{ 'width': '10%' }}>{t('login')}</th>
									<th style={{ 'width': '15%' }}>{t('FIO')}</th>
									<th style={{ 'width': '10%' }} className="text-nowrap">{t('phone')}</th>
									<th style={{ 'width': '25%' }} className="text-center">{t('note')}</th>
									<th style={{ 'width': '10%' }}>{t('role')}</th>
									<th style={{ 'width': '10%' }}>{t('position')}</th>
									<th style={{ 'width': '5%' }} className="text-center text-nowrap">{t('pin_code')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.pos_name}</td>
										<td className="td-ellipsis" title={item.login}
											onClick={() => copyText(item.login)}>
											{item.login}
										</td>
										<td className="td-ellipsis" title={item.first_name}>{item.first_name}</td>
										<td className="text-nowrap">{regexPhoneNumber(item.phone)}</td>
										<td className="text-center td-ellipsis" title={item.note}>{item.note}</td>
										<td>{item.role_name}</td>
										<td className="td-ellipsis" title={item.position_name}>{item.position_name}</td>
										<td className="text-center">{item.pin_code}</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item">
															<i className="uil-edit-alt"></i>
															<Link className="table-dropdown-item" to={`/employees/update/${item.id}`}>
																{t('edit')}
															</Link>
														</div>
														{item.status === 1 &&
															<div className="table-dropdown-item text-danger"
																onClick={() => toggleModalFire(true, item)}>
																<i className="uil-times"></i>
																{t('delete')}
															</div>
														}
														{item.status === -2 &&
															<div className="table-dropdown-item"
																onClick={() => toggleModalRestore(true, item)}>
																<i className="uil-history-alt"></i>
																{t('restore')}
															</div>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL DELETE */}
			<Modal show={modalFireUser} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModalFire(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('fired')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => fireUser(e)} autoComplete="off">
						<h4>
							{t('fire_employee')}?
						</h4>
						<h6 className="text-danger">
							{t('fire_employee_text')}?
						</h6>
						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalFire(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL DELETE */}

			{/* MODAL restore */}
			<Modal show={modalRestore} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModalRestore(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('restore')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => restoreUser(e)} autoComplete="off">
						<h5>
							{t('are_you_sure_you_want')} <b>{t('restore')}</b>
						</h5>
						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalRestore(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-success w-100">{t('confirm')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL restore */}
		</>
	)
}

export default Index
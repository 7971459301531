import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DatePicker from "react-datepicker"
import { Dropdown } from 'react-bootstrap'
import XLSX from 'xlsx';

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { SET_REPORT_SELECTED_AGENT } from 'store/report/actions';
import { GET, httpOk } from 'helpers/api'
import { formatMoney, regexPhoneNumber, returnSign, formatDate } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/report-agents',
		response: null
	})

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = "/report-agents"
		var urlParams = ""
		checkFilter()

		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/report-agents${urlParams}` !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		if (tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + tableSort + filterDataCopy.tableSortDirection
		}
		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = "/report-agents-excel" + urlParams + returnSign(filterUrl)

			var excelHeaders = [[t('pos'), t('name2'), t('quantity'), t('unit'), t('total_amount')]]
			var response = await GET(filterUrl)
			if (httpOk(response)) {
				response = response.data
				for (let i = 0; i < response.length; i++) {
					var excelItems = []
					excelItems.push(response[i]['pos_name'])
					excelItems.push(response[i]['element_name'])
					excelItems.push(response[i]['balance'])
					excelItems.push(response[i]['unit_name'])
					excelItems.push(response[i]['total_amount'])
					excelHeaders.push(excelItems)
				}

				const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
				XLSX.writeFile(wb, t('agents') + ".xlsx");
			}
		} else {
			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
			}
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function redirectToInner(item) {
		dispatch(SET_REPORT_SELECTED_AGENT(item))
		navigate('/report-agents-cheques')
	}

	function redirectToSelectOnSale(item) {
		dispatch(SET_REPORT_SELECTED_AGENT(item))
		navigate('/report-agents-debt')
	}

	function selectItem(item) {
		var itemCopy = { ...item }
		itemCopy.pos_id = poses[0]['id']
		itemCopy.payment_type_id = 1
		itemCopy.amount_in = 1
		setSelectedItem(itemCopy)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	function renderArrows(tableSortDirection, tableSortKey, type) {
		if (tableSortDirection === ',asc' && tableSortKey === type) {
			return (
				<i className="uil uil-angle-up sort-active"></i>
			)
		} else if (tableSortDirection === ',desc' && tableSortKey === type) {
			return (
				<i className="uil uil-angle-down sort-active"></i>
			)
		} else {
			return (
				<i className="uil uil-angle-down"></i>
			)
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('agents')} [{pagination?.response?.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<div>
									<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
										<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
											{t('filter')}
										</Dropdown.Toggle>

										<Dropdown.Menu className="dropdown-filter">
											<div className="row">
												<div className="form-group col-md-6">
													<label>{t('period')}</label>
													<div className="calendar-input">
														<DatePicker
															className="form-control"
															dateFormat="dd.MM.yyyy"
															selected={filterData.start_date ? new Date(filterData.start_date) : ''}
															onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
														<i className="uil uil-calendar-alt"></i>
													</div>
												</div>
												<div className="form-group col-md-6">
													<label className="invisible">.</label>
													<div className="calendar-input">
														<DatePicker
															className="form-control"
															dateFormat="dd.MM.yyyy"
															selected={filterData.end_date ? new Date(filterData.end_date) : ''}
															onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
														<i className="uil uil-calendar-alt"></i>
													</div>
												</div>
											</div>
											<div className="row mt-3">
												<div className="col-md-12">
													<div className="d-flex justify-content-end gap-2">
														<button className="btn btn-secondary" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
														<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
													</div>
												</div>
											</div>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>
						</div>

						<div className="vertical-center">
							<div className="d-flex flex-wrap">
								<div>
									<h6 className="d-flex justify-content-between">
										<b className="me-2">{t('sales')}:</b> {formatMoney(data.total_sales)}
									</h6>
									<h6 className="d-flex justify-content-between">
										<b className="me-2">{t('discount')}:</b> {formatMoney(data.total_agents_discount)}
									</h6>
								</div>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }} className="th-sortable" onClick={() => searchWithFilter(false, 'first_name')}>
										{t('client')}
										<div className="table-sort">
											{renderArrows(filterData.tableSortDirection, filterData.tableSortKey, 'first_name')}
										</div>
									</th>
									<th style={{ 'width': '10%' }}>{t("phone")}</th>
									<th style={{ 'width': '10%' }}>{t("car_number")}</th>
									<th style={{ 'width': '10%' }}>{t("note")}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('cheques')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('sales')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t("discount")}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('bonus')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t("action")}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr className={'tr-middle ' + (
										selectedItem.agent_id === item.agent_id ? 'table-tr-active' : '')}
										key={index} onClick={() => selectItem(item)}>
										<td className="text-center">{item.row_number}</td>
										<td>
											<span className="text-primary cursor table-first-column-clickable"
												onClick={() => redirectToInner(item)}>
												{item.first_name}
											</span>
										</td>
										<td>{regexPhoneNumber(item.phone)}</td>
										<td>{item.car_number}</td>
										<td>{item.note}</td>
										<td className="text-end">{item.total_cheques}</td>
										<td className="text-end">{formatMoney(item.total_sales)}</td>
										<td className="text-end">{formatMoney(item.total_agent_amount)}</td>
										<td className="text-end">{formatMoney(item.balance)}</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="table-action-button table-action-primary-button me-2"
													onClick={() => redirectToInner(item)}>
													<CustomTooltip text={t('debtor')}>
														<i className="uil-credit-card-search"></i>
													</CustomTooltip>
												</div>
												<div className="table-action-button table-action-primary-button"
													onClick={() => redirectToSelectOnSale(item)}>
													<CustomTooltip text={t('cheques')}>
														<i className="uil-receipt"></i>
													</CustomTooltip>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>
		</>
	)
}

export default Index

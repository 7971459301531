import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DatePicker from "react-datepicker"
import { Dropdown } from 'react-bootstrap'
import 'react-loading-skeleton/dist/skeleton.css'

import { TOGGLE_LEFT_MENU } from 'store/layout/actions'
import { GET, httpOk } from 'helpers/api'
import { formatDate, returnSign } from 'helpers/helpers'

function Rating() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const date = new Date()

	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		ids: []
	})

	async function searchWithFilter(filterDataCopy) {
		//var filterDataCopy = { ...filterData }
		var filterUrl = "/chain/report/courier-tracking"
		var urlParams = ""

		if (filterDataCopy.ids)
			urlParams += returnSign(urlParams) + 'ids=' + filterDataCopy.ids
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	async function handleSelectedItem(payload = {}) {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/chain/report/courier-tracking-status"
		var urlParams = ""

		if (payload?.item?.id) {
			urlParams += returnSign(urlParams) + 'ids=' + payload?.item?.id
		} else {
			if (filterDataCopy.ids)
				urlParams += returnSign(urlParams) + 'ids=' + filterDataCopy.ids
		}

		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			//
		}
	}

	function handleCheckboxChangeAll(e) {
		var filterDataCopy = { ...filterData }

		filterDataCopy.ids = []
		if (e.target.checked) {
			for (let i = 0; i < poses.length; i++) {
				filterDataCopy.ids.push(poses[i]['id'])
			}
		}

		setFilterData(filterDataCopy)
	}

	function handleCheckboxChange(e, id) {
		var filterDataCopy = { ...filterData }

		if (e.target.checked) {
			filterDataCopy.ids.push(id)
		} else {
			filterDataCopy.ids = filterDataCopy.ids.filter(item => item !== id)
		}

		setFilterData(filterDataCopy)
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')

		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		dispatch(TOGGLE_LEFT_MENU(false))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex gap-3">
				<div style={{ 'minWidth': '200px' }}>
					<div className="card mb-3" style={{ 'height': 'calc(100vh - 160px)', 'overflow': 'auto' }}>
						<div className="card-body">
							<h5 className="mb-3">{t('poses')}</h5>
							<div className="flex-column">
								<div className="form-check">
									<input id="all"
										className="form-check-input"
										type="checkbox"
										onChange={(e) => handleCheckboxChangeAll(e)}
									/>
									<label className="form-check-label" htmlFor="all">
										{t('all')}
									</label>
								</div>
								{poses?.map((item, index) => (
									<div className="form-check" key={index}>
										<input id={`pos${index}`} className="form-check-input" type="checkbox"
											checked={filterData?.ids?.includes(item.id)}
											onChange={(e) => handleCheckboxChange(e, item.id)}
										/>
										<label className="form-check-label" htmlFor={`pos${index}`}>
											{item.short_name}
										</label>
									</div>
								))}
							</div>
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 w-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('period')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.start_date ? new Date(filterData.start_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.end_date ? new Date(filterData.end_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end gap-2">
												<button className="btn btn-secondary"
													onClick={() => setDropdownIsOpen(false)}>
													{t('cancel')}
												</button>
												<button className="btn btn-primary"
													onClick={() => searchWithFilter(filterData)}>
													{t('apply')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				</div>

				<div className="w-100">
					<div className="card mb-3">
						<div className="card-body w-50">
							<h5 className="mb-2">{t('courier_tracking')}</h5>
							<div className="table-resposive">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
											<th style={{ 'minWidth': '150px' }}>{t('pos')}</th>
											<th className="text-nowrap text-end"
												style={{ 'minWidth': '100px' }}
												title="Чеки доставки">
												{t('cheques')}
											</th>
											<th className="text-nowrap text-end text-success"
												style={{ 'minWidth': '100px' }}
												title="Чеки курьерами">
												{t('with_courier')}
											</th>
											<th className="text-nowrap text-end text-danger"
												style={{ 'minWidth': '100px' }}
												title="Чеки без курьеров">
												{t('without_courier')}
											</th>
											<th className="text-nowrap text-end text-success"
												style={{ 'minWidth': '100px' }}
												title="Кол-во чеков где курьер нажал кнопку завершено">
												{t('completed')}
											</th>
											<th className="text-nowrap text-end text-danger"
												style={{ 'minWidth': '100px' }}
												title="Кол-во чеков где курьер НЕ нажал кнопку завершено">
												{t('not_completed')}
											</th>
											<th className="text-nowrap text-end text-success"
												style={{ 'minWidth': '100px' }}
												title="Кол-во чеков доставленых за 30 минут">
												{t('on_time')}
											</th>
											<th className="text-nowrap text-end text-danger"
												style={{ 'minWidth': '100px' }}
												title="Кол-во чеков не доставленых за 30 минут">
												{t('overdue')}
											</th>
										</tr>
									</thead>
									<tbody>
										{data?.poses?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{index + 1}</td>
												<td>{item.short_name}</td>
												<td className="text-end">{item.count_delivery}</td>
												<td className="text-end">{item.count_delivery_with_courier}</td>
												<td className="text-end">{item.count_delivery_without_courier}</td>
												<td className="text-end">{item.count_status_9}</td>
												<td className="text-end hover"
													onClick={() => handleSelectedItem({ 'all': false, 'item': item })}>
													{item.courier_delayed}
												</td>
												<td className="text-end">{item.cheque_on_time}</td>
												<td className="text-end">{item.cheque_delayed}</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td></td>
											<td><b>{t('total')}</b></td>
											<td className="text-end"><b>{data.overall_delivery}</b></td>
											<td className="text-end"><b>{data.overall_courier_delivery}</b></td>
											<td className="text-end"><b>{data.overall_no_courier_delivery}</b></td>
											<td className="text-end"><b>{data.overall_courier_on_time}</b></td>
											<td className="text-end hover"
												onClick={() => handleSelectedItem()}>
												<b>{data.overall_courier_delayed}</b>
											</td>
											<td className="text-end"><b>{data.overall_cheque_on_time}</b></td>
											<td className="text-end"><b>{data.overall_cheque_delayed}</b></td>
										</tr>
									</tfoot>
								</table>
							</div>

							<div className="table-resposive">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th style={{ 'width': '33px' }} className="text-center">#</th>
											<th style={{ 'minWidth': '100px' }}>{t('pos')}</th>
											<th style={{ 'minWidth': '100px' }}>{t('courier')}</th>
											<th style={{ 'minWidth': '100px' }} className="text-end">{t('cheques')}</th>
										</tr>
									</thead>
									<tbody>
										{data?.courierCheques?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{index + 1}</td>
												<td>{item.short_name}</td>
												<td>{item.first_name}</td>
												<td className="text-end">{item.count}</td>
											</tr>
										))}
										<tr>
											<td></td>
											<td><b>{t('total')}</b></td>
											<td></td>
											<td className="text-end"><b>{data.overall_courier_delivery}</b></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	)
}

export default Rating
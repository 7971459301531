import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import XLSX from 'xlsx'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, returnSign, formatDate } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function CheckPoint() {
	const { t } = useTranslation()
	const date = new Date()

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [activeTab, setActiveTab] = useState(2)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = `/report-check-point`
		var urlParams = ""

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
		setDropdownIsOpen(false)
	}

	function exportToExcel() {
		// Поставщик
		var excelHeadersSupplier = [[
			"Поставщик",
			"Инфо",
			"Салдо начало",
			"Приход",
			"Наличные",
			"Перечисления",
			"Салдо на конец",
		]]

		for (let i = 0; i < data?.suppliers?.data?.length; i++) {
			var item = data?.suppliers?.data[i]
			var excelItemsSupplier = []
			excelItemsSupplier.push(item.name)
			excelItemsSupplier.push("")
			excelItemsSupplier.push("")
			excelItemsSupplier.push(Number(item.total_amount))
			excelItemsSupplier.push(Number(item.total_cash))
			excelItemsSupplier.push(Number(item.total_transfer))
			excelItemsSupplier.push(Number(item.balance_end))
			excelHeadersSupplier.push(excelItemsSupplier)
		}

		excelHeadersSupplier.push([
			t('total'),
			"",
			"",
			Number(data?.suppliers?.overall_total_amount),
			Number(data?.suppliers?.overall_total_cash),
			Number(data?.suppliers?.overall_total_transfer),
			Number(data?.suppliers?.overall_balance_end),
		])
		// Поставщик

		// Приход
		var excelHeadersIncome = [[
			"#",
			"Дата",
			"Поставщик",
			"Примечание",
			"Сумма",
		]]

		for (let i = 0; i < data?.incomes?.data?.length; i++) {
			var itemIncome = data?.incomes.data[i]
			var excelItemsIncome = []
			excelItemsIncome.push(i + 1)
			excelItemsIncome.push(formatDate(itemIncome.created_at))
			excelItemsIncome.push(itemIncome.name)
			excelItemsIncome.push(itemIncome.note)
			excelItemsIncome.push(Number(itemIncome.total_amount))
			excelHeadersIncome.push(excelItemsIncome)
		}
		// Приход

		// Продажа
		var excelHeadersPayment = [[
			"#",
			"Дата",
			"Покупатель",
			"Оптала",
			"Сумма",
		]]

		for (let i = 0; i < data?.payments?.data?.length; i++) {
			var itemPayment = data?.payments.data[i]
			var excelItemsPayment = []
			excelItemsPayment.push(i + 1)
			excelItemsPayment.push(formatDate(itemPayment.created_at))
			excelItemsPayment.push("Физ.лицо")
			if (itemPayment.payment_type_id === 1) {
				excelItemsPayment.push(t('cash'))
			}
			if (itemPayment.payment_type_id === 2) {
				excelItemsPayment.push(t('terminal'))
			}
			if (itemPayment.payment_type_id === 1) {
				excelItemsPayment.push(Number(itemPayment.total_cash))
			}
			if (itemPayment.payment_type_id === 2) {
				excelItemsPayment.push(Number(itemPayment.total_terminal))
			}
			excelHeadersPayment.push(excelItemsPayment)
		}
		// Продажа

		// Остаток
		var excelHeadersBalance = [[
			"#",
			"Дата",
			"Сумма",
		]]

		for (let i = 0; i < data?.balances?.data?.length; i++) {
			var itemBalance = data?.balances.data[i]
			var excelItemsBalance = []
			excelItemsBalance.push(i + 1)
			excelItemsBalance.push(formatDate(itemBalance.created_at))
			excelItemsBalance.push(Number(itemBalance.total_amount).toFixed(2))
			excelHeadersBalance.push(excelItemsBalance)
		}
		// Остаток
		const wsBank = XLSX.utils.aoa_to_sheet([[]]);

		const wsSupplier = XLSX.utils.aoa_to_sheet(excelHeadersSupplier);
		var wchSupplier = [
			{ wch: 30 },
			{ wch: 10 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 15 },
		];
		wsSupplier['!cols'] = wchSupplier;

		const wsIncome = XLSX.utils.aoa_to_sheet(excelHeadersIncome);
		var wchIncome = [
			{ wch: 5 },
			{ wch: 10 },
			{ wch: 30 },
			{ wch: 30 },
			{ wch: 15 },
		];
		wsIncome['!cols'] = wchIncome;

		const wsPayment = XLSX.utils.aoa_to_sheet(excelHeadersPayment);
		var wchPayment = [
			{ wch: 5 },
			{ wch: 10 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 15 },
		];
		wsPayment['!cols'] = wchPayment;

		const wsBalance = XLSX.utils.aoa_to_sheet(excelHeadersBalance);
		var wchBalance = [
			{ wch: 5 },
			{ wch: 10 },
			{ wch: 15 },
		];
		wsBalance['!cols'] = wchBalance;

		//const ws2 = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, wsBank, "Безнал");
		XLSX.utils.book_append_sheet(wb, wsSupplier, "Поставщик");
		XLSX.utils.book_append_sheet(wb, wsIncome, "Приход");
		// XLSX.utils.book_append_sheet(wb, ws, "Клиент");
		XLSX.utils.book_append_sheet(wb, wsPayment, "Продажа");
		XLSX.utils.book_append_sheet(wb, wsBalance, "Остатка");
		// XLSX.utils.book_append_sheet(wb, ws, "CashFlow");
		XLSX.writeFile(wb, t('check_point') + ".xlsx");
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex gap-2">
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('pos')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.pos_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('period')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.start_date ? new Date(filterData.start_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label></label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.end_date ? new Date(filterData.end_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2"
										onClick={() => exportToExcel()}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
					</div>

					<div className={`table-responsive ${activeTab !== 2 && 'd-none'}`}
						style={{ 'height': '77vh' }}>
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('supplier')}</th>
									<th style={{ 'width': '5%' }}>Инфо</th>
									<th style={{ 'width': '10%' }}>Салдо начало</th>
									<th style={{ 'width': '15%' }} className="text-end">{t('income2')}</th>
									<th style={{ 'width': '15%' }} className="text-end">{t('cash')}</th>
									<th style={{ 'width': '15%' }} className="text-end">{t('money_transfer')}</th>
									<th style={{ 'width': '15%' }} className="text-end">Салдо на конец</th>
								</tr>
							</thead>
							<tbody>
								{data?.suppliers?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td></td>
										<td></td>
										<td className="text-end">{formatMoney(item.total_amount)}</td>
										<td className="text-end">{formatMoney(item.total_cash)}</td>
										<td className="text-end">{formatMoney(item.total_transfer)}</td>
										<td className={`text-end ${item.balance_end < 0 && 'text-danger'}`}>
											{formatMoney(item.balance_end)}
										</td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td><b>{t('total')}</b></td>
									<td></td>
									<td></td>
									<td className="text-end">
										<b>{formatMoney(data?.suppliers?.overall_total_amount)}</b>
									</td>
									<td className="text-end">
										<b>{formatMoney(data?.suppliers?.overall_total_cash)}</b>
									</td>
									<td className="text-end">
										<b>{formatMoney(data?.suppliers?.overall_total_transfer)}</b>
									</td>
									<td className="text-end">
										<b>{formatMoney(data?.suppliers?.overall_balance_end)}</b>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>

					<div className={`table-responsive table-fixed ${activeTab !== 3 && 'd-none'}`}
						style={{ 'height': '77vh' }}>
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '10%' }}>{t('date')}</th>
									<th style={{ 'width': '30%' }}>{t('supplier')}</th>
									<th style={{ 'width': '30%' }}></th>
									<th style={{ 'width': '20%' }}>{t('note')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{data?.incomes?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{formatDate(item.created_at)}</td>
										<td>{item.name}</td>
										<td></td>
										<td>{item.note}</td>
										<td className="text-end">{formatMoney(item.total_amount)}</td>
										<td></td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td><b>{t('total')}</b></td>
									<td></td>
									<td></td>
									<td></td>
									<td className="text-end">
										<b>{formatMoney(data?.incomes?.overall_total_incomes)}</b>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>

					<div className={`table-responsive table-fixed ${activeTab !== 5 && 'd-none'}`}
						style={{ 'height': '77vh' }}>
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '15%' }}>{t('date')}</th>
									<th style={{ 'width': '15%' }}>{t('buyer')}</th>
									<th style={{ 'width': '40%' }}></th>
									<th style={{ 'width': '15%' }}>{t('payment')}</th>
									<th style={{ 'width': '15%' }} className="text-end">{t('amount')}</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{data?.payments?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{formatDate(item.created_at)}</td>
										<td>Физ.лицо</td>
										<td></td>
										<td>
											{item.payment_type_id === 1 &&
												<span>{t('cash')}</span>
											}
											{item.payment_type_id === 2 &&
												<span>{t('terminal')}</span>
											}
										</td>
										<td className="text-end">
											{item.payment_type_id === 1 &&
												<span>{formatMoney(item.total_cash)}</span>
											}
											{item.payment_type_id === 2 &&
												<span>{formatMoney(item.total_terminal)}</span>
											}
										</td>
										<td></td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td><b>{t('total')}</b></td>
									<td></td>
									<td></td>
									<td>
										<b>{t('cash')}</b>
										<br />
										<b>{t('terminal')}</b>
									</td>
									<td className="text-end">
										<b>{formatMoney(data?.payments?.overall_total_cash)}</b>
										<br />
										<b>{formatMoney(data?.payments?.overall_total_terminal)}</b>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>

					<div className={`table-responsive table-fixed ${activeTab !== 6 && 'd-none'}`}
						style={{ 'height': '77vh' }}>
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '15%' }}>{t('date')}</th>
									<th style={{ 'width': '60%' }}></th>
									<th style={{ 'width': '20%' }} className="text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.balances?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{formatDate(item.created_at)}</td>
										<td></td>
										<td className="text-end">{formatMoney(item.total_amount)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="bottom-tabs">
				<div className={`bottom-tabs-item ${activeTab === 1 && 'active'}`}
					onClick={() => setActiveTab(1)}>
					Безнал
				</div>
				<div className={`bottom-tabs-item ${activeTab === 2 && 'active'}`}
					onClick={() => setActiveTab(2)}>
					Поставщик
				</div>
				<div className={`bottom-tabs-item ${activeTab === 3 && 'active'}`}
					onClick={() => setActiveTab(3)}>
					Приход
				</div>
				<div className={`bottom-tabs-item ${activeTab === 4 && 'active'}`}
					onClick={() => setActiveTab(4)}>
					Клиент
				</div>
				<div className={`bottom-tabs-item ${activeTab === 5 && 'active'}`}
					onClick={() => setActiveTab(5)}>
					Продажа
				</div>
				<div className={`bottom-tabs-item ${activeTab === 6 && 'active'}`}
					onClick={() => setActiveTab(6)}>
					Остатка
				</div>
				<div className={`bottom-tabs-item ${activeTab === 7 && 'active'}`}
					onClick={() => setActiveTab(7)}>
					CashFlow
				</div>
			</div>
		</>
	)
}

export default CheckPoint